import React from "react";
import Colors from "Theme/Colors";
import { TagSD, TagCloseIconSD } from "./TagStyles";
import { FaTimes } from "react-icons/fa";

/**
 * HOOK
 * Detail tag, example E-Teacher, Learn...
 *
 * PROPS: text: string, type: string (selected, active, inactive)
 *
 */

export default function Tag({ tagName, type, selectable, selectTag }) {
  const getBackgroundColor = () => {
    switch (type) {
      case "searchInput":
        return Colors.secondary;
      case "searchedText":
        return Colors.hover;
      case "tag":
        return Colors.tag;
      default:
        return Colors.tag;
    }
  };

  const backgroundColor = getBackgroundColor();

  return (
    <TagSD backgroundColor={backgroundColor}>
      <div>{tagName}</div>
      {selectable ? (
        <TagCloseIconSD
          onClick={() => {
            selectTag(tagName);
          }}
        >
          <FaTimes size={13} fill={Colors.primaryActive} />
        </TagCloseIconSD>
      ) : null}
    </TagSD>
  );
}
