import React from "react";
import Moment from "react-moment";
import { CheckError, PricingDetail, Tag } from "Components/Common";
import {
  GeneralSectionSD,
  DetailSubtitleSD,
  DetailSubsubtitleSD,
  DetailDescriptionSD,
  AdminDetailColumnSD,
} from "Theme/DetailStyles";
import { RowSD, ButtonS } from "Theme/Styles";

/**
 * HOOK
 * Basic Detail Section, render the basic information of the component (service or package)
 */

// only for requests, highlight changes
function mark(key, changes) {
  if (Object.entries(changes).length === 0) {
    return false;
  } else {
    return changes[key];
  }
}

// only for requests, highlight changes in localization fields
function markLocalization(key, selectedLanguage, changes) {
  if (Object.entries(changes).length === 0) {
    return false;
  } else {
    return changes["localization"][selectedLanguage][key];
  }
}

// render the tags of the components, like Blackboard Ally, Blackboard Learn, ...
function renderBasicTag(tags) {
  const renderedTags = [];
  tags.forEach((tag) => {
    renderedTags.push(<Tag tagName={tag} key={tag}></Tag>);
  });
  return renderedTags;
}

export default function BasicDetail({
  detailInfo,
  selectedLanguage,
  changes,
  detailType,
}) {
  const basicTags = renderBasicTag(detailInfo.tags);

  const renderEditors = (detailInfo) => {
    let renderedEditors = "";
    detailInfo.editors.forEach((editor, index) => {
      renderedEditors += editor.email;
      if (index !== detailInfo.editors.length - 1) {
        renderedEditors += ", ";
      }
    });

    return renderedEditors;
  };

  const renderPackageServices = () => {
    const renderedTags = [];
    detailInfo.services.forEach((service, index) => {
      renderedTags.push(
        <a
          key={"a" + service._id}
          href={
            "/service?cpqCode=" +
            service.cpqCode +
            "&name=" +
            service.name +
            "&id=" +
            service._id
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonS buttonType="cancel">
            {service.cpqCode + " - " + service.name}
          </ButtonS>
        </a>
      );
    });
    return renderedTags;
  };

  return (
    <AdminDetailColumnSD>
      <RowSD>
        <GeneralSectionSD>
          <DetailSubtitleSD>Basic</DetailSubtitleSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Name:</DetailSubsubtitleSD>
            <DetailDescriptionSD>{detailInfo.name}</DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Owner:</DetailSubsubtitleSD>
            <DetailDescriptionSD>{detailInfo.owner.email}</DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Product Manager:</DetailSubsubtitleSD>
            <DetailDescriptionSD>
              {detailInfo.productManager
                ? detailInfo.productManager.email
                : "None"}
            </DetailDescriptionSD>
          </RowSD>
          {/* <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Editors:</DetailSubsubtitleSD>
            <DetailDescriptionSD>
              {detailInfo.editors.length > 0
                ? renderEditors(detailInfo)
                : "None"}
            </DetailDescriptionSD>
          </RowSD> */}
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Creator:</DetailSubsubtitleSD>
            <DetailDescriptionSD>
              {detailInfo.creator.firstName + " " + detailInfo.creator.lastName}
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD
              mark={markLocalization("description", selectedLanguage, changes)}
            >
              Description:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              {detailInfo.localization[selectedLanguage].description}
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD
              mark={markLocalization(
                "internalNotes",
                selectedLanguage,
                changes
              )}
            >
              Internal notes:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              {detailInfo.localization[selectedLanguage].internalNotes}
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("tags", changes)}>
              Tags:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>{basicTags}</DetailDescriptionSD>
          </RowSD>
          {detailType === "package" ? (
            <RowSD padding="0.5rem 0rem">
              <DetailSubsubtitleSD mark={mark("tags", changes)}>
                Services:
              </DetailSubsubtitleSD>
              <DetailDescriptionSD>
                {renderPackageServices()}
              </DetailDescriptionSD>
            </RowSD>
          ) : null}
        </GeneralSectionSD>
        <GeneralSectionSD>
          <DetailSubtitleSD>Status</DetailSubtitleSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("dateCreated", changes)}>
              Date created:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <Moment>{detailInfo.dateCreated}</Moment>
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD>Last modification:</DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <Moment>{detailInfo.lastModification}</Moment>
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("pendingApproval", changes)}>
              Pending approval:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <CheckError
                size={15}
                checked={detailInfo.pendingApproval}
              ></CheckError>
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("visibility", changes)}>
              Visibility:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>{detailInfo.visibility}</DetailDescriptionSD>
          </RowSD>
          {/* <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("inSfdc", changes)}>
              inSfdc:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <CheckError size={15} checked={detailInfo.inSfdc}></CheckError>
            </DetailDescriptionSD>
          </RowSD> */}
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("salable", changes)}>
              Sellable:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <CheckError size={15} checked={detailInfo.salable}></CheckError>
            </DetailDescriptionSD>
          </RowSD>
          <RowSD padding="0.5rem 0rem">
            <DetailSubsubtitleSD mark={mark("communicable", changes)}>
              Communicable:
            </DetailSubsubtitleSD>
            <DetailDescriptionSD>
              <CheckError
                size={15}
                checked={detailInfo.communicable}
              ></CheckError>
            </DetailDescriptionSD>
          </RowSD>
          {detailType === "service" ? (
            <div style={{ width: "100%" }}>
              <RowSD padding="0.5rem 0rem">
                <DetailSubtitleSD
                  mark={mark("pricing", changes)}
                  noMarginBottom={true}
                >
                  Pricing
                </DetailSubtitleSD>
              </RowSD>
              <RowSD padding="0.5rem 0rem">
                {detailInfo.estimationRequired === false ? (
                  <PricingDetail
                    service={detailInfo}
                    chargingMode={detailInfo.chargingMode}
                    pricing={detailInfo.pricing}
                    pricingOption={detailInfo.pricingOption}
                    minimumSeats={detailInfo.minimunSeats}
                  ></PricingDetail>
                ) : (
                  "Estimation required"
                )}
              </RowSD>
            </div>
          ) : null}
        </GeneralSectionSD>
      </RowSD>
    </AdminDetailColumnSD>
  );
}
