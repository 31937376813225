import styled from "styled-components/macro";

const CircularButtonSD = styled.div`
  width: ${(props) => (props.size ? props.size / 10 + "rem" : "2.6rem")};
  height: ${(props) => (props.size ? props.size / 10 + "rem" : "2.6rem")};
  border-radius: ${(props) =>
    props.size ? props.size / 20 + "rem" : "1.3rem"};
  background-color: ${(props) => (props.color ? props.color : "transparent")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ${(props) => (props.size ? props.size / 3 + "pt" : "1.0667rem")};
  cursor: pointer;
`;

export { CircularButtonSD };
