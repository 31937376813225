import React from "react";
import { withRouter } from "react-router-dom";
import { Affix } from "antd";
import CollapsibleItem from "./SimpleTableItem";
import { SC } from "./SimpleTableStyles";
import { CustomLoadingOverlay } from "Components";

function SimpleTable(props) {
  const { headers, data, type, loading } = props;

  // render table headers
  function renderHeaders() {
    const renderedHeaders = [];
    renderedHeaders.push(
      <SC.TableHeaderCell
        divisor={0}
        flex="0"
        key={"headerSpace"}
        minWidth="1rem"
      >
        {" "}
      </SC.TableHeaderCell>
    );

    headers.forEach((header, index) => {
      renderedHeaders.push(
        <SC.TableHeaderCell
          divisor={index !== 0 ? 1 : 0}
          key={header.name}
          flex={header.flex}
        >
          {header.name}
        </SC.TableHeaderCell>
      );
    });

    return renderedHeaders;
  }

  const renderRows = () => {
    const renderedRows = [];
    data.forEach((row, index) => {
      renderedRows.push(
        <CollapsibleItem
          key={"CollapsibleItem" + index}
          itemInfo={row}
          headers={headers}
          type={type}
          isEven={index % 2 === 0}
        />
      );
    });

    return renderedRows;
  };

  const renderedHeaders = renderHeaders();
  const renderedRows = renderRows();

  return (
    <SC.SimpleTable>
      <Affix>
        <SC.Header>{renderedHeaders}</SC.Header>
      </Affix>
      <CustomLoadingOverlay active={loading}>
        <SC.TableContent>{renderedRows}</SC.TableContent>
      </CustomLoadingOverlay>
    </SC.SimpleTable>
  );
}

export default withRouter(SimpleTable);
