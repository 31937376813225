import ApiRequests from "Api/requests";
import { put, takeLatest, call } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import { toast } from "react-toastify";

// GET ADMIN REQUESTS
// params: page, limit, searchedText, sortBy, sortOrder
function* getRequestsSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "requestListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiRequests.getRequests, action);
    const { data, pages, count } = response.data;

    yield put({
      type: "SAVE_REQUEST_LIST",
      requestList: data,
      count: count,
      pages: pages,
    });
    yield delay(300);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "requestListLoading",
    loadingValue: false,
  });
}
// Admin detail: all data of request
function* getRequestDetail(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "requestDetailLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiRequests.getRequest, action);
    yield delay(300);
    yield put({
      type: "SAVE_REQUEST_DETAIL",
      requestId: action.id,
      requestDetail: response.data.data.request,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "requestDetailLoading",
    loadingValue: false,
  });
}

// acording to item type (service or package) and according to method (create, update or delete)
function* acceptOrRejectRequest(action) {
  try {
    switch (action.actionToDo) {
      case "Accept":
        if (action.itemType === "Service") {
          switch (action.method) {
            case "Create":
              yield call(ApiRequests.acceptCreateService, action);
              break;
            case "Update":
              yield call(ApiRequests.acceptUpdateService, action);
              break;
            case "Delete":
              yield call(ApiRequests.acceptDeleteService, action);
              break;
            default:
              break;
          }
        } else if (action.itemType === "Package") {
          switch (action.method) {
            case "Create":
              yield call(ApiRequests.acceptCreatePackage, action);
              break;
            case "Update":
              yield call(ApiRequests.acceptUpdatePackage, action);
              break;
            case "Delete":
              yield call(ApiRequests.acceptDeletePackage, action);
              break;
            default:
              break;
          }
        }
        break;
      case "Reject":
        if (action.itemType === "Service") {
          switch (action.method) {
            case "Create":
              yield call(ApiRequests.rejectCreateService, action);
              break;
            case "Update":
              yield call(ApiRequests.rejectUpdateService, action);
              break;
            case "Delete":
              yield call(ApiRequests.rejectDeleteService, action);
              break;
            default:
              break;
          }
        } else if (action.itemType === "Package") {
          switch (action.method) {
            case "Create":
              yield call(ApiRequests.rejectCreatePackage, action);
              break;
            case "Update":
              yield call(ApiRequests.rejectUpdatePackage, action);
              break;
            case "Delete":
              yield call(ApiRequests.rejectDeletePackage, action);
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
    yield put({
      type: "GET_REQUESTS_SAGA",
      searchedText: [],
      page: 1,
      limit: 30,
    });
    yield put({
      type: "GET_REQUEST_DETAIL_SAGA",
      id: action.id,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}

export const requestsSagas = [
  takeLatest("GET_REQUESTS_SAGA", getRequestsSaga),
  takeLatest("GET_REQUEST_DETAIL_SAGA", getRequestDetail),
  takeLatest("ACCEPT_OR_REJECT_REQUEST_SAGA", acceptOrRejectRequest),
];
