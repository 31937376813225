import React from "react";
import numeral from "numeral";
import { Styles } from "./PricingDetailStyles";
import Colors from "Theme/Colors";
import { ColumnSD } from "Theme/Styles";
import PricingCalculator from "./SeatsPricingCalculator";
import { getChargingModeName } from "Helpers/PricingHelpers";

// SERVICE PRICING DETAIL
export default function PricingDetail({
  service,
  chargingMode,
  pricing,
  pricingOption,
  minimumSeats,
}) {
  const pricingName = () => {
    if (chargingMode === "FP") {
      return "Single price: ";
    } else if (chargingMode === "TM") {
      return "Rate per hour: ";
    } else {
      return "Price per seat: ";
    }
  };

  const renderHeaders = () => {
    let headers = [];

    switch (pricingOption) {
      case "tier":
        headers.push(
          <Styles.PricingColumnCell
            flex={4}
            key={"space" + service._id}
          ></Styles.PricingColumnCell>
        );
        for (let i = 1; i <= 4; i++) {
          headers.push(
            <Styles.PricingColumnCell
              key={"headers tier" + String(i) + service._id}
            >
              <strong>{"Tier " + String(i) + service._id}</strong>
            </Styles.PricingColumnCell>
          );
        }
        return headers;
      case "situational":
        headers.push(
          <Styles.PricingColumnCell flex={1} key={"space" + service._id}>
            <strong>{chargingMode === "TM" ? "Roles" : "Range"}</strong>
          </Styles.PricingColumnCell>
        );
        headers.push(
          <Styles.PricingColumnCell key={"price" + service._id} border>
            <strong>
              {chargingMode === "BS" ? "Price per seat (USD)" : "Price (USD)"}
            </strong>
          </Styles.PricingColumnCell>
        );
        return headers;
      case "dual":
        headers.push(
          <Styles.PricingColumnCell flex={4} key={"space"}>
            <strong>{chargingMode === "TM" ? "Roles" : "Range"}</strong>
          </Styles.PricingColumnCell>
        );
        for (let i = 1; i <= 4; i++) {
          headers.push(
            <Styles.PricingColumnCell key={"headers tier" + String(i)} border>
              <strong>{"Tier " + String(i)}</strong>
            </Styles.PricingColumnCell>
          );
        }
        return headers;
      default:
        break;
    }
  };

  const renderPricingTier = (pricing, row) => {
    let tiersColumns = [];
    for (let i = 1; i <= 4; i++) {
      tiersColumns.push(
        <Styles.PricingColumnCell key={"cell tier3" + i + service._id}>
          <Styles.PricingDescr>{pricing[row][i]}</Styles.PricingDescr>
        </Styles.PricingColumnCell>
      );
    }
    return tiersColumns;
  };

  // situational pricing means unchecked tier and checked seats or userband options, depending on the case
  const renderPricingSituational = (pricing, type) => {
    let situationalRows = [];
    for (let i = 0; i < pricing.length - 1; i++) {
      const rowColor = i % 2 === 0 ? Colors.detail : "white";
      situationalRows.push(
        <Styles.PricingRow
          key={"situational row " + String(i) + service._id}
          backgroundColor={rowColor}
        >
          <Styles.PricingColumnCell
            flex={type === "situational" ? 1 : 4}
            key={"cell" + i + service._id}
          >
            {String(pricing[i][0]) + " - " + String(pricing[i + 1][0] - 1)}
          </Styles.PricingColumnCell>
          {type === "situational" ? (
            <Styles.PricingColumnCell key={"cell2" + i + service._id}>
              <Styles.PricingDescr>{pricing[i][1]}</Styles.PricingDescr>
            </Styles.PricingColumnCell>
          ) : (
            renderPricingTier(pricing, i)
          )}
        </Styles.PricingRow>
      );
    }
    const rowColor = (pricing.length - 1) % 2 === 0 ? Colors.detail : "white";

    situationalRows.push(
      <Styles.PricingRow
        key={"row " + String(pricing.length - 1) + service._id}
        backgroundColor={rowColor}
        borderRadius="0rem 0rem 1rem 1rem"
      >
        <Styles.PricingColumnCell
          borderRadius="0rem 0rem 1rem 1rem"
          flex={type === "situational" ? 1 : 4}
          align="start"
          key={"cell situational " + service._id}
        >
          {"more than " + String(pricing[pricing.length - 1][0])}
        </Styles.PricingColumnCell>
        {type === "situational" ? (
          <Styles.PricingColumnCell key={"cell situational2" + service._id}>
            <Styles.PricingDescr>
              {pricing[pricing.length - 1][1]}
            </Styles.PricingDescr>
          </Styles.PricingColumnCell>
        ) : (
          renderPricingTier(pricing, pricing.length - 1)
        )}
      </Styles.PricingRow>
    );
    return situationalRows;
  };

  // situational pricing means unchecked tier and checked role
  const renderPricingSituationalTM = (pricing, type) => {
    let situationalRows = [];
    for (let i = 0; i < pricing.length; i++) {
      const rowColor = i % 2 === 0 ? Colors.detail : "white";
      situationalRows.push(
        <Styles.PricingRow
          backgroundColor={rowColor}
          key={"row situationalTM" + service._id}
        >
          <Styles.PricingColumnCell
            flex={type === "situational" ? 1 : 4}
            align="start"
            key={"cell situationalTM" + service._id}
          >
            {String(pricing[i][0])}
          </Styles.PricingColumnCell>
          {type === "situational" ? (
            <Styles.PricingColumnCell key={"cell situationalTM2" + service._id}>
              <Styles.PricingDescr>{pricing[i][1]}</Styles.PricingDescr>
            </Styles.PricingColumnCell>
          ) : (
            renderPricingTier(pricing, i)
          )}
        </Styles.PricingRow>
      );
    }
    return situationalRows;
  };

  // dual pricing means both pricing options checked
  const renderPricingDual = (pricing) => {
    let situationalRows = [];
    let headers = renderHeaders();
    situationalRows.push(
      <Styles.PricingRow
        key={"title" + service._id}
        backgroundColor={Colors.blackboard}
        borderRadius="1rem 1rem 0rem 0rem "
        color="white"
      >
        <Styles.PricingColumnCell
          flex={4}
          key={"cell dual " + service._id}
        ></Styles.PricingColumnCell>
        <Styles.BasicPricingHeader>
          <strong>
            {chargingMode === "BS" ? "Price per seat (USD)" : "Price (USD)"}
          </strong>
        </Styles.BasicPricingHeader>
      </Styles.PricingRow>
    );
    situationalRows.push(
      <Styles.PricingRow
        key={"row dual " + service._id}
        backgroundColor={Colors.blackboard}
        color="white"
      >
        {headers}
      </Styles.PricingRow>
    );

    chargingMode === "TM"
      ? situationalRows.push(renderPricingSituationalTM(pricing, "dual"))
      : situationalRows.push(renderPricingSituational(pricing, "dual"));

    return situationalRows;
  };

  const renderPricingDetail = () => {
    const headers = renderHeaders();
    switch (pricingOption) {
      case "single":
        return (
          <Styles.PricingRow key={"pricing single" + service._id}>
            <strong>{pricingName()}</strong>
            <div flex={3} left={"2rem"} align="start" key="div single">
              {String(numeral(pricing[0][1]).format("0,0.00")) + " USD"}
            </div>
          </Styles.PricingRow>
        );
      case "tier":
        const renderPricingTiers = renderPricingTier(pricing, 0);
        return (
          <Styles.PricingColumnCell flex={1} key={"cell tier1" + service._id}>
            <Styles.PricingRow
              key={"headers tier" + service._id}
              backgroundColor={Colors.blackboard}
              borderRadius="1rem 1rem 0rem 0rem "
              color="white"
            >
              {headers}
            </Styles.PricingRow>
            <Styles.PricingRow key={"pricing tier" + service._id}>
              <Styles.PricingColumnCell
                flex={4}
                key={"cell tier2" + service._id}
              >
                Price (USD)
              </Styles.PricingColumnCell>
              {renderPricingTiers}
            </Styles.PricingRow>
          </Styles.PricingColumnCell>
        );
      case "situational":
        let renderPricingSituationals =
          chargingMode === "TM"
            ? renderPricingSituationalTM(pricing, "situational")
            : renderPricingSituational(pricing, "situational");
        return (
          <ColumnSD centered>
            <Styles.PricingRow
              key={"headers situational" + service._id}
              backgroundColor={Colors.blackboard}
              borderRadius="1rem 1rem 0rem 0rem "
              color="white"
            >
              {headers}
            </Styles.PricingRow>
            {renderPricingSituationals}
          </ColumnSD>
        );
      case "dual":
        const renderPricingDuals = renderPricingDual(pricing);
        return renderPricingDuals;

      default:
        break;
    }
  };

  let renderedPricingDetail = [];

  if (pricing.length !== 0) {
    renderedPricingDetail = renderPricingDetail(chargingMode);
  }

  return (
    <ColumnSD key={"principal column" + service._id} centered>
      <Styles.PricingRow key={"charging mode detail" + service._id}>
        <strong>Charging mode: &nbsp;</strong>
        <div>{getChargingModeName(chargingMode)}</div>
      </Styles.PricingRow>
      {chargingMode === "BS" ? (
        <Styles.PricingRow key={"min seats detail" + service._id}>
          <strong>Minimum seats: &nbsp;</strong>
          <div>{minimumSeats}</div>
        </Styles.PricingRow>
      ) : null}
      <Styles.PricingDetail
        key={"render pricing detail" + service._id}
        isTable={pricingOption !== "single"}
      >
        {renderedPricingDetail}
      </Styles.PricingDetail>
      {chargingMode === "BS" ? (
        <ColumnSD key={"pricing calculator column" + service._id} centered>
          <PricingCalculator
            service={service}
            chargingMode={chargingMode}
            pricing={pricing}
            pricingOption={pricingOption}
            minimumSeats={minimumSeats}
          />
        </ColumnSD>
      ) : null}
    </ColumnSD>
  );
}
