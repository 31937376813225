import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const TagSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  padding: 0.2rem 1rem;
  margin-bottom: 0.5rem;
  height: auto;
  font-family: "SourceSansPro-Semibold";
  font-size: 1.2rem;
  border-radius: 0.3rem;
  margin-right: 1.2rem;
  color: ${Colors.tagText};
  svg {
    color: ${Colors.tagText};
  }
  @media ${mediaQueries.tablet} {
    font-size: 0.8rem;
    margin-right: 1rem;
  }
`;
const TagCloseIconSD = styled.div`
  display: initial;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
`;

export { TagSD, TagCloseIconSD };
