import { initialState } from "Redux/initialState";

const usersReducer = (state = initialState.users, action) => {
  switch (action.type) {
    // save current user info
    case "SAVE_USER":
      return {
        ...state,
        currentUser: action.userInfo,
      };
    // save user list
    case "SAVE_USERS":
      return {
        ...state,
        userList: action.users,
        count: action.count,
        pages: action.pages,
      };
    // save users with "Administrator" or "Service owner" role
    case "SAVE_OWNERS":
      return {
        ...state,
        ownerList: action.users,
      };

    case "SAVE_ADMIN":
      return {
        ...state,
        adminUser: action.adminUser,
      };

    default:
      return state;
  }
};

export default usersReducer;
