import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { AdminHeader, Footer, PrivateRoute } from "Components";
import AdminPackages from "./AdminPackages/AdminPackagesContainer";
import AdminRequests from "./AdminRequests/AdminRequestsContainer";
import AdminServices from "./AdminServices/AdminServicesContainer";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import AdminReports from "./AdminReports/AdminReportsContainer";
import {
  AdminLeftSD,
  AdminViewContentSD,
  AdminViewSD,
  AdminSectionSD,
} from "./AdminStyles";
import UserContainer from "./Users/UsersContainer";
import userContext from "../../Context/user-context";
import { PageNotFound } from "Components/Common";

/**
 * HOOK
 * Admin view, the view groups and sorts the visual components
 */

export default function AdminView({ logoutSaga, goToSales }) {
  const currentUser = useContext(userContext);
  return (
    <AdminViewSD>
      <AdminViewContentSD>
        <AdminSidebar />
        <AdminLeftSD>
          <AdminHeader goToSales={goToSales} logoutSaga={logoutSaga} />
          <AdminSectionSD>
            <Switch>
              <PrivateRoute
                path={`/admin/users`}
                roles={[0]}
                currentUser={currentUser}
                component={UserContainer}
              />
              <PrivateRoute
                path={`/admin/requests`}
                roles={[0]}
                currentUser={currentUser}
                component={AdminRequests}
              />
              <Route path={`/admin/services`} component={AdminServices} />
              <Route path={`/admin/packages`} component={AdminPackages} />
              <Route
                path={`/admin/reports`}
                roles={[0]}
                currentUser={currentUser}
                component={AdminReports}
              />
              <Route component={PageNotFound} />
            </Switch>
          </AdminSectionSD>
        </AdminLeftSD>
      </AdminViewContentSD>
      <Footer />
    </AdminViewSD>
  );
}
