import React from "react";
import { withRouter } from "react-router-dom";
import { SearchedTagsSD } from "./SearchedTagStyles";
import { ColumnSD, ClearButtonSD, RowSD, SubtitleSD } from "Theme/Styles";
import { Tag } from "Components/Common";

function SearchedTags(props) {
  const {
    selectTag,
    selectedTags,
    searchedText,
    removeSearchedText,
    searchInput,
    setSearchInput,
    clearAllSelected,
  } = props;

  function renderTags() {
    const renderedTags = [];
    selectedTags.forEach((tag) => {
      renderedTags.push(
        <Tag tagName={tag} type="tag" selectTag={selectTag} selectable></Tag>
      );
    });
    return renderedTags;
  }
  const renderTextTags = () => {
    const renderedTextTags = [];
    searchedText.forEach((text) => {
      renderedTextTags.push(
        <Tag
          key={text}
          tagName={text}
          type="searchedText"
          selectTag={removeSearchedText}
          selectable
        ></Tag>
      );
    });
    return renderedTextTags;
  };
  let renderedTags = [];
  if (selectedTags) {
    renderedTags = renderTags();
  }
  const renderTexts = renderTextTags();

  return (
    <ColumnSD centered>
      {renderedTags.length === 0 &&
      searchInput === "" &&
      searchedText.length === 0 ? null : (
        <RowSD>
          <SubtitleSD marginRight="0.5rem">Current filters</SubtitleSD>

          <ClearButtonSD
            onClick={() => {
              clearAllSelected();
            }}
          >
            Clear all
          </ClearButtonSD>
        </RowSD>
      )}
      <SearchedTagsSD>
        {renderedTags}
        {renderTexts}

        {searchInput !== "" ? (
          <RowSD>
            <Tag
              type="searchInput"
              tagName={searchInput}
              selectTag={() => setSearchInput("")}
              selectable
            />
            &nbsp; Press enter to search
          </RowSD>
        ) : null}
      </SearchedTagsSD>
    </ColumnSD>
  );
}

export default withRouter(SearchedTags);
