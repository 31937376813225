import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import SidebarButton from "./SidebarButton";
import { AdminSidebarSD, CompleteSidebarSD } from "./AdminSidebarStyles";
import userContext from "Context/user-context";
import icons_admin_menu from "Assets/Icons/AdminIcons/PNG/Icons_admin-menu.png";
import icons_admin_user from "Assets/Icons/AdminIcons/PNG/Icons_admin-user.png";
import icons_admin_request from "Assets/Icons/AdminIcons/PNG/Icons_admin-request.png";
import icons_admin_package from "Assets/Icons/AdminIcons/PNG/Icons_admin-package.png";
import icons_admin_service from "Assets/Icons/AdminIcons/PNG/Icons_admin-service.png";
import icons_admin_reports from "Assets/Icons/AdminIcons/PNG/Icons_admin-reports.png";

/**
 * HOOK
 * Admin sidebar, is the sidebar in admin view
 */

function AdminSidebar(props) {
  const { history } = props;
  const selectedKey = getSelectedKey(props.location.pathname);
  const [opened, setOpened] = useState(false);
  const currentUser = useContext(userContext);
  const goToRoute = (routeName) => {
    redirectTo(routeName, history);
    setOpened(false);
  };
  return (
    <AdminSidebarSD opened={opened} className="AdminSidebarSD">
      <CompleteSidebarSD className="CompleteSidebarSD">
        <SidebarButton
          icon={icons_admin_menu}
          handlerClick={() => setOpened(!opened)}
          name="Toggle open"
          width="2rem"
        ></SidebarButton>
        {currentUser.role === 0 ? (
          <SidebarButton
            icon={icons_admin_user}
            handlerClick={() => goToRoute("users")}
            selected={selectedKey === "users"}
            name="Users"
          ></SidebarButton>
        ) : null}
        {currentUser.role === 0 ? (
          <SidebarButton
            icon={icons_admin_request}
            handlerClick={() => goToRoute("requests")}
            selected={selectedKey === "requests"}
            name="Requests"
          ></SidebarButton>
        ) : null}
        <SidebarButton
          icon={icons_admin_package}
          handlerClick={() => goToRoute("packages")}
          selected={selectedKey === "packages"}
          name="Packages"
        ></SidebarButton>
        <SidebarButton
          icon={icons_admin_service}
          handlerClick={() => goToRoute("services")}
          selected={selectedKey === "services"}
          name="Services"
        ></SidebarButton>
        <SidebarButton
          icon={icons_admin_reports}
          handlerClick={() => goToRoute("reports")}
          selected={selectedKey === "reports"}
          name="Reports"
        ></SidebarButton>
      </CompleteSidebarSD>
    </AdminSidebarSD>
  );
}

function redirectTo(route, history) {
  switch (route) {
    case "users":
      history.push("/admin/users");
      break;
    case "requests":
      history.push("/admin/requests");
      break;
    case "packages":
      history.push("/admin/packages");
      break;
    case "services":
      history.push("/admin/services");
      break;
    case "reports":
      history.push("/admin/reports");
      break;
    default:
      history.push("/admin/services");
      break;
  }
}

function getSelectedKey(pathName) {
  switch (pathName) {
    case "/admin/users":
      return "users";
    case "/admin/requests":
      return "requests";
    case "/admin/packages":
      return "packages";
    case "/admin/services":
      return "services";
    case "/admin/reports":
      return "reports";
    default:
      return "services";
  }
}

export default withRouter(AdminSidebar);
