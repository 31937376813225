import React from "react";

import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdminView } from "Components";
import Cookie from "js-cookie";
import userContext from "../../Context/user-context";

/**
 * HOOK
 * This is the component calls the Admin View
 */

function AdminContainer(props) {
  // get the token from cookies
  const token = Cookie.get("token");
  const jwtDecode = require("jwt-decode");
  const decoded = jwtDecode(token);
  // get the current user from the token
  const currentUser = decoded.user;
  const dispatch = useDispatch();
  // SAGA
  // logout the user, delete the token and the user
  const logoutSaga = () => {
    dispatch({ type: "LOGOUT_SAGA", history: props.history });
  };

  // send the user to sales page
  const goToSales = () => {
    props.history.push("/");
  };

  return (
    // context to use the current user in any component contained in AdminContainer component
    <userContext.Provider value={currentUser}>
      <AdminView logoutSaga={logoutSaga} goToSales={goToSales} />
    </userContext.Provider>
  );
}

export default withRouter(AdminContainer);
