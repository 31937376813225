import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { history } from "Helpers";
import { Affix } from "antd";
import CollapsibleItem from "./CollapsibleItem";
import { SC } from "./CollapsibleTableStyles";
import { CustomLoadingOverlay } from "Components";

/**
 * HOOK
 * Collapsible table in admin view, prints every collpase item
 */

function CollapsibleTable(props) {
  const {
    headers,
    data,
    type,
    loading,
    forReports,
    onClickItem,
    selectedItemId,
    setSelectedItemId,
    onChangeQueryParams,
    isCollapsible,
    roles,
  } = props;
  const location = useLocation();
  // set the url, depends on the sortBy filters
  const onClickHeader = (newKeyParam) => {
    const searchParams = new URLSearchParams(location.search);
    let keyParam = searchParams.get("key");
    let valueParam = parseInt(searchParams.get("value"));
    if (newKeyParam !== "localization.EN-US.internalNotes") {
      let newValueParam = 1;
      if (valueParam === 1 && newKeyParam === keyParam) {
        newValueParam = -1;
      }
      if (newKeyParam === "owner.email") {
        history.push({
          search: "?key=owner&value=" + newValueParam,
        });
      } else {
        history.push({
          search: "?key=" + newKeyParam + "&value=" + newValueParam,
        });
      }
    }
  };

  // get the current url
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let keyParam = searchParams.get("key");
    let valueParam = parseInt(searchParams.get("value"));
    if (keyParam && valueParam) {
      onChangeQueryParams(keyParam, valueParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // render table headers
  function renderHeaders() {
    const renderedHeaders = [];
    renderedHeaders.push(
      <SC.TableHeaderCell key="extra" divisor={0} flex="0" minWidth="1rem">
        {" "}
      </SC.TableHeaderCell>
    );
    if (forReports) {
      renderedHeaders.push(
        <SC.TableHeaderCell
          divisor={0}
          key={"Extra"}
          flex={"0.5 0"}
          onClick={() => {}}
        >
          {" "}
        </SC.TableHeaderCell>
      );
    }
    headers.forEach((header, index) => {
      renderedHeaders.push(
        <SC.TableHeaderCell
          divisor={index !== 0 ? 1 : 0}
          key={header.name}
          flex={header.flex}
          onClick={() => onClickHeader(header.key)}
        >
          {header.name}
        </SC.TableHeaderCell>
      );
    });
    if (isCollapsible || roles) {
      renderedHeaders.push(
        <SC.TableHeaderCell
          divisor={0}
          key={"Extra"}
          flex={"0.5 0"}
          onClick={() => {}}
        >
          {" "}
        </SC.TableHeaderCell>
      );
    }

    return renderedHeaders;
  }

  const renderRows = () => {
    const renderedRows = [];
    data.forEach((row, index) => {
      renderedRows.push(
        <CollapsibleItem
          key={"CollapsibleItem" + index}
          itemInfo={row}
          headers={headers}
          type={type}
          isEven={index % 2 === 0}
          onClickItem={onClickItem}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          isCollapsible={isCollapsible}
          roles={roles}
          forReports={forReports}
        />
      );
    });

    return renderedRows;
  };

  const renderedHeaders = renderHeaders();
  const renderedRows = renderRows();

  return (
    <SC.CollapsibleTable>
      <Affix>
        <SC.Header>{renderedHeaders}</SC.Header>
      </Affix>
      <CustomLoadingOverlay active={loading}>
        <SC.TableContent>{renderedRows}</SC.TableContent>
      </CustomLoadingOverlay>
    </SC.CollapsibleTable>
  );
}

export default withRouter(CollapsibleTable);
