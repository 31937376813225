import React, { useState } from "react";
import { Modal } from "antd";
import {
  DeleteServiceModalSD,
  TitleSD,
  SubtitleSD,
  StrongSD,
  ButtonSD,
} from "./DeleteModalStyles";
import { ButtonS, ModalFooterSD } from "Theme/Styles";
import Media from "react-media";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function DeleteModal({ onDelete, id, cpqCode }) {
  const [visible, setVisible] = useState(false);
  const onClickDelete = () => {
    setVisible(false);
    onDelete();
  };
  return (
    <div>
      <Media query={{ minWidth: 768 }}>
        {(matches) =>
          matches ? (
            <ButtonS
              width="auto"
              height="4rem"
              buttonType="cancel"
              onClick={() => {
                setVisible(true);
              }}
            >
              DELETE
            </ButtonS>
          ) : (
            <ButtonS
              width="6rem"
              height="2.5rem"
              type="reset"
              buttonType="cancel"
              onClick={() => {
                setVisible(true);
              }}
            >
              DELETE
            </ButtonS>
          )
        }
      </Media>

      <Modal
        visible={visible}
        footer={null}
        centered
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <DeleteServiceModalSD>
          <TitleSD>Are you sure?</TitleSD>
          <SubtitleSD>
            Remove the item with CPQCode <StrongSD>{cpqCode}</StrongSD> ?
          </SubtitleSD>

          <Media query={{ minWidth: 768 }}>
            {(matches) =>
              matches ? (
                <ModalFooterSD>
                  <ButtonSD>
                    <ButtonS
                      key="back"
                      type="reset"
                      buttonType="cancel"
                      onClick={() => setVisible(false)}
                    >
                      CANCEL
                    </ButtonS>
                  </ButtonSD>
                  <ButtonSD>
                    <ButtonS onClick={() => onClickDelete()}>DELETE</ButtonS>
                  </ButtonSD>
                </ModalFooterSD>
              ) : (
                <ModalFooterSD>
                  <ButtonSD>
                    <ButtonS
                      key="back"
                      type="reset"
                      buttonType="cancel"
                      onClick={() => setVisible(false)}
                    >
                      CANCEL
                    </ButtonS>
                  </ButtonSD>
                  <ButtonSD>
                    <ButtonS onClick={() => onClickDelete()}>DELETE</ButtonS>
                  </ButtonSD>
                </ModalFooterSD>
              )
            }
          </Media>
        </DeleteServiceModalSD>
      </Modal>
    </div>
  );
}
