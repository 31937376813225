import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "Helpers";
import {
  PrivateRoute,
  AdminContainer,
  SalesContainer,
  LoginContainer,
  LoginSSOContainer,
  NewPasswordContainer,
  ReleaseNotesContainer,
} from "Components";
import { PageNotFound } from "Components/Common";
import { AppS } from "./AppStyles";
import StyledToastContainer from "./StyledToastContainer";

function App() {
  // console.error = () => {};
  return (
    <Router history={history}>
      <StyledToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <AppS className="AppS">
        <Switch>
          <PrivateRoute
            exact
            path="/"
            roles={[0, 1, 2, 3, 4]}
            component={SalesContainer}
          />
          <PrivateRoute
            exact
            path="/:type(service|package)"
            roles={[0, 1, 2, 3, 4]}
            component={SalesContainer}
          />
          <PrivateRoute
            path="/admin"
            roles={[0, 1, 2, 4]}
            component={AdminContainer}
          />
          <Route
            exact
            path="/IPQRs"
            component={() =>
              (window.location =
                "https://forms.office.com/Pages/ResponsePage.aspx?id=oikLv1xeqkq6TayYjfFeptToLD3VbYBDuLpJmbdA_kpUM1pJTUdZWUIzVzNYOEtMRFBDN1FONzY4Ry4u")
            }
          />

          <Route exact path="/login" component={LoginContainer} />
          <Route path="/ReleaseNotes" component={ReleaseNotesContainer} />
          <Route exact path="/loginSSO" component={LoginSSOContainer} />
          {/* <Link to="/loginSSO?code=code"></Link>
          <Link to="/resetPassword?token=token"></Link> */}
          <Route path="/resetPassword" component={NewPasswordContainer} />
          <Route component={PageNotFound} />
        </Switch>
      </AppS>
    </Router>
  );
}

export default App;
