// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { topFadeIn } from "Theme/Styles/Animations";
import loginBackground from "Assets/Images/loginBackground.jpg";
import { mediaQueries } from "Theme/MediaQueries";

const LoginViewSD = styled.div`
  display: flex;
  min-height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${loginBackground});
  background-size: cover;
  padding-left: 3rem;
  padding-right: 3rem;
  @media ${mediaQueries.tablet} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media ${mediaQueries.mobileL} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const LoginContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60rem;
  @media ${mediaQueries.tablet} {
    max-width: 40rem;
  }

  @media ${mediaQueries.mobileL} {
    max-width: 30rem;
  }
  z-index: 1;
  animation: ${topFadeIn} 1s;
`;
const LoginSectionSD = styled.div`
  padding-top: 1.5rem;
  width: 100%;
`;

const LoginTitleSD = styled.div`
  font-family: "Georgia-Regular";
  font-size: 2.6667rem;
  color: ${Colors.thirdGray};
  padding-top: 2%;
  padding-bottom: 2%;

  @media ${mediaQueries.tablet} {
    font-size: 2rem;
  }
`;

const LoginCardSD = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  padding: 2rem 7rem 4rem 7rem;
  border-radius: 0.3rem;

  @media ${mediaQueries.tablet} {
    padding: 6rem;
    min-width: 40rem;
  }

  @media ${mediaQueries.mobileL} {
    padding: 4rem;
    min-width: 20rem;
  }
`;

export {
  LoginViewSD,
  LoginContentSD,
  LoginSectionSD,
  LoginTitleSD,
  LoginCardSD,
};
