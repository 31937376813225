import React, { useState, useEffect } from "react";
import { CollateralsLabel } from "Components/Common";
import {
  CollaterallsItemSD,
  CollateralsLineSD,
  DetailSubtitleSD,
  SalesDetailColumnSD,
} from "Theme/DetailStyles";
import { RowSD } from "Theme/Styles";
import { validURL } from "Helpers/Helpers";
/**
 * HOOK
 */

export default function DetailCollaterals({
  serviceInfo,
  selectedLanguage,
  getReports,
}) {
  const [collateralsLinks, setCollateralsLinks] = useState([]);
  const [showWBS, setShowWBS] = useState(false);
  const [showSOW, setShowSOW] = useState(false);

  useEffect(() => {
    renderCollaterals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInfo.localization[selectedLanguage].collaterals]);

  const renderCollaterals = () => {
    const collaterals = [
      ...serviceInfo.localization[selectedLanguage].collaterals,
    ];

    const renderedLinks = [];
    collaterals.forEach((collateral, index) => {
      if (validURL(collateral.link)) {
        if (collateral.name != "Sales sheet") {
          renderedLinks.push(
            <CollaterallsItemSD key={"link" + index}>
              <CollateralsLabel
                name={collateral.name}
                link={collateral.link}
                getReports={getReports}
              ></CollateralsLabel>
            </CollaterallsItemSD>
          );
        }
      } else {
        if (collateral.name === "SOW (Autogenerate)") {
          setShowSOW(true);
        }
        if (collateral.name === "WBS (Autogenerate)") {
          setShowWBS(true);
        }
      }
      setCollateralsLinks(renderedLinks);
    });
  };
  return (
    <SalesDetailColumnSD padding="1rem 3rem">
      <CollateralsLineSD></CollateralsLineSD>
      <DetailSubtitleSD>Collaterals</DetailSubtitleSD>
      <RowSD>
        <strong>Collaterals links</strong>
        <RowSD>{collateralsLinks}</RowSD>
      </RowSD>
      {showWBS || showSOW ? (
        <RowSD>
          <strong>Autogenerated</strong>
          <RowSD>
            {false ? ( // TODO: Implement SOW
              <CollaterallsItemSD key="autogenerated sow">
                <CollateralsLabel
                  name={"SOW (Autogenerate)"}
                  link={""}
                  getReports={getReports}
                ></CollateralsLabel>
              </CollaterallsItemSD>
            ) : null}
            {/* {showWBS ? (
              <CollaterallsItemSD key="autogenerated wbs">
                <CollateralsLabel
                  name={"WBS (Autogenerate)"}
                  link={""}
                  getReports={getReports}
                ></CollateralsLabel>
              </CollaterallsItemSD>
            ) : (
              "WBS NOT RENDERED"
            )} */}
          </RowSD>
        </RowSD>
      ) : null}
      <CollateralsLineSD></CollateralsLineSD>
    </SalesDetailColumnSD>
  );
}
