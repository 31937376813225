import React from "react";
import Media from "react-media";
import { withRouter } from "react-router-dom";

import { DetailTableSD } from "./SalesDetailTableStyles";
import Table from "./Table/Table";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

function openDrawer(props, row) {
  const type = row.services ? "package" : "service";
  props.history.push({
    pathname: "/" + type,
    search: "?cpqCode=" + row.cpqCode + "&name=" + row.name + "&id=" + row._id
  });
}

function DetailTable(props) {
  const headers = [
    { name: "CODE", id: "cpqCode", flex: 1 },
    { name: "NAME", id: "name", flex: 1, color: "primaryActive" },
    { name: "SUMMARY", id: "summary", flex: 2 },
    { name: "LAST UPDATE", id: "lastModification", flex: 1 },
    { name: "TAGS", id: "tags", flex: 3 }
  ];

  const mobileHeaders = [
    { name: "CODE", id: "cpqCode", flex: 1 },
    { name: "NAME", id: "name", flex: 1 },
    { name: "LAST UPDATE", id: "lastModification", flex: 1 },
    { name: "TAGS", id: "tags", flex: 1 }
  ];
  return (
    <DetailTableSD>
      <Media query={{ minWidth: 992 }}>
        {matches =>
          matches ? (
            <Table
              headers={headers}
              data={props.salesList}
              onClickRow={row => {
                openDrawer(props, row);
              }}
            />
          ) : (
            <Table
              headers={mobileHeaders}
              data={props.salesList}
              onClickRow={row => {
                openDrawer(props, row);
              }}
            />
          )
        }
      </Media>
    </DetailTableSD>
  );
}

export default withRouter(DetailTable);
