// Reports tabs styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";
import Colors from "Theme/Colors";

const TabsSD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const TabsInsideSD = styled.div`
  width: 100%;
`;

const TabContentSD = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const CheckboxTitleSD = styled.div`
  display: flex;
  /* min-width: 6rem; */
  justify-content: center;
  flex-direction: column;
  font-family: "SourceSansPro-Regular";
  font-size: ${(props) => (props.size ? props.size : "1.4rem")};
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  color: ${Colors.black};
  margin-right: 1rem;
  @media ${mediaQueries.mobileL} {
    flex: 1;
    font-size: 1.2rem;
    padding-left: 2rem;
  }
`;

export { TabsSD, TabsInsideSD, CheckboxTitleSD, TabContentSD };
