import { Form, Select } from "antd";
import Checkbox from "Components/Common/Form/Checkbox";
import React, { useEffect } from "react";
import { FormSubtitleSD } from "Theme/Styles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";

import {
  ChecksRowSD,
  PricingSD,
  ServiceFormSD,
  ErrorMsgSD,
} from "./PricingTabStyles";
import { setPricingStructure, getPricingOption } from "Helpers/PricingHelpers";
import ServicePricing from "./ServicePricing";

const { Option } = Select;

function PricingTab(props) {
  const {
    estimationRequired,
    setEstimationRequired,
    renewable,
    setRenewable,
    pricing,
    setPricing,
    pricingOption,
    setPricingOption,
    chargingMode,
    setChargingMode,
    minimumSeats,
    changeMinimumSeats,
    error,
    setError,
    checkboxValues,
    setcheckboxValues,
  } = props;

  const onChangeTier = (e) => {
    e.persist();
    setcheckboxValues((checkboxValues) => ({
      ...checkboxValues,
      tier: e.target.checked || false,
    }));
    resetPricing();
  };

  const onChangeCondition = (e) => {
    e.persist();
    setcheckboxValues((checkboxValues) => ({
      ...checkboxValues,
      condition: e.target.checked,
    }));
    resetPricing();
  };

  const resetPricing = () => {
    setPricing(setPricingStructure(chargingMode, pricingOption, minimumSeats));
  };

  useEffect(() => {
    const newPricingOption = getPricingOption(checkboxValues);
    if (pricingOption !== newPricingOption) {
      setPricingOption(newPricingOption);
    }
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxValues]);

  return (
    <ServiceFormSD>
      <ChecksRowSD>
        <Checkbox
          label={"Estimation required"}
          checked={estimationRequired}
          onChange={(e) => setEstimationRequired(e.target.checked)}
        />

        {/* <Checkbox
          label={"Renewable"}
          checked={renewable}
          onChange={(e) => setRenewable(e.target.checked)}
        /> */}
      </ChecksRowSD>

      {estimationRequired ? null : (
        <PricingSD>
          <ExtendedColumnSD>
            <FormSubtitleSD>Charging mode</FormSubtitleSD>
            <Select
              value={chargingMode}
              onChange={(value) => {
                setChargingMode(value);
              }}
            >
              <Option value="FP">Fixed price</Option>
              <Option value="TM">Time and materials</Option>
              <Option value="BS">By seats</Option>
            </Select>
          </ExtendedColumnSD>
          <ExtendedColumnSD>
            <FormSubtitleSD>PriceType</FormSubtitleSD>
            <Form.Item>
              <ChecksRowSD>
                <Checkbox
                  label={"Tier"}
                  checked={checkboxValues.tier}
                  onChange={(e) => onChangeTier(e)}
                ></Checkbox>
                <Checkbox
                  label={showCondition(chargingMode)}
                  checked={checkboxValues.condition}
                  onChange={(e) => onChangeCondition(e)}
                ></Checkbox>
              </ChecksRowSD>
            </Form.Item>
          </ExtendedColumnSD>
          <ServicePricing
            pricing={pricing}
            setPricing={setPricing}
            pricingOption={pricingOption}
            chargingMode={chargingMode}
            minimumSeats={minimumSeats}
            changeMinimumSeats={changeMinimumSeats}
            setError={setError}
            error={error}
          ></ServicePricing>
          {error ? <ErrorMsgSD>{error}</ErrorMsgSD> : null}
        </PricingSD>
      )}
    </ServiceFormSD>
  );
}

function showCondition(priceType) {
  switch (priceType) {
    case "FP":
      return "User band";
    case "TM":
      return "Roles";
    case "BS":
      return "By seats";
    default:
      return "User band";
  }
}

export default PricingTab;
