import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm/UserForm";
import userContext from "Context/user-context";

/**
 * HOOK
 * Create user container, calls the User form. Hsas all the logic
 */

function CreateUserContainer({ setVisible, roles }) {
  // we create the functions that the Sagas execute
  const dispatch = useDispatch();
  // obtain the current user through the context
  const currentUser = useContext(userContext);
  // STATES
  // userValues: info of current user values
  const [userValues] = useState({});
  // we obtain all user form loading states
  const createUserLoading = useSelector(
    (state) => state.system.loadings.createUserLoading
  );

  // SAGA
  // it going to send a request to create a new user with the info in newUserInfo
  const createUserSaga = (newUserInfo) => {
    dispatch({ type: "CREATE_USER_SAGA", newUserInfo: newUserInfo });
  };

  // calls createUserSaga to create a new user
  const createUser = (formInfo) => {
    const newUserInfo = {
      firstName: formInfo.firstName,
      lastName: formInfo.lastName,
      role: formInfo.role,
      email: formInfo.email,
      password: formInfo.password,
    };
    createUserSaga(newUserInfo);
  };

  return (
    <UserForm
      roles={roles}
      userValues={userValues}
      setVisible={setVisible}
      type="create"
      submitUser={createUser}
      userFormLoading={createUserLoading}
      currentUser={currentUser}
    />
  );
}

export default CreateUserContainer;
