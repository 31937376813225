import axios from "axios";
import { ROOT_URL, instance } from "./apiConfig";

export default class ApiAuth {
  //    DELETE logout

  static logout(action) {
    const url = `${ROOT_URL}/logout`;
    const request = instance.delete(url);
    return request;
  }
  //    POST Manual login
  static loginUser(action) {
    const url = `${ROOT_URL}/login`;
    const request = axios.post(url, action.user);
    return request;
  }

  //    GET Verify token
  static verifyToken(action) {
    const url = `${ROOT_URL}/auth/verifyToken`;
    const request = instance.get(url);
    return request;
  }

  //  GET Login URL Office365
  static getLoginURL(action) {
    const url = `${ROOT_URL}/login/url`;
    const request = axios.get(url);
    return request;
  }

  //  POST Login Office 365
  static loginSSO(action) {
    const url = `${ROOT_URL}/login/oauth2`;
    const request = axios.post(url, { code: action.code });
    return request;
  }

  //  Send and email to reset the password
  static resetPassword(action) {
    const url = `${ROOT_URL}/login/resetPassword`;
    const request = axios.post(url, { email: action.email });
    return request;
  }

  static updatePasswordFromEmail(action) {
    const url = `${ROOT_URL}/login/updatePasswordFromEmail`;
    const request = axios.post(url, {
      token: action.token,
      password: action.password,
    });
    return request;
  }
}
