import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { CustomLoadingOverlay } from "Components";
import FormLocalizationTags from "./FormLocalizationTags/FormLocalizationTags";
import ServiceFormTabs from "./ServiceFormTabs/ServiceFormTabs";
import { deep } from "Helpers/Helpers";
import { validateFields } from "Helpers/FormHelpers.js";
import { ButtonSD, MissingSD, ServiceFormSD } from "./ServiceFormStyles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";
import { FormSubtitleSD, ModalFooterSD } from "Theme/Styles";
import { ButtonS } from "Theme/Styles";
import {
  setPricingStructure,
  getCheckboxValues,
  changeMinimumSeatsPricing,
} from "Helpers/PricingHelpers";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

function ServiceForm(props) {
  const {
    tagList,
    ownerList,
    serviceFormLoading,
    serviceMandatoryFields,
    setVisible,
    userBands,
    seats,
    type,
  } = props;

  const getSelectedEditors = () => {
    const selectedEditors = [];
    props.serviceValues.editors.forEach((editor) => {
      selectedEditors.push(editor._id);
    });
    return selectedEditors;
  };
  const ownersListLoading = useSelector(
    (state) => state.system.loadings.ownersListLoading
  );

  const salesMenuLoading = useSelector(
    (state) => state.system.loadings.salesMenuLoading
  );
  // validServiceForm is a trigger or flag that indicates that the modal form must be closed
  const validServiceForm = useSelector(
    (state) => state.system.validations.validServiceForm
  );
  const [selectedLanguage, setSelectedLanguage] = useState("EN-US");
  const [missingFields, setMissingFields] = useState([]);
  const [error, setError] = useState(null);

  //form data
  const [localization, setLocalization] = useState(
    deep(props.serviceValues.localization)
  );
  const [name, setName] = useState(props.serviceValues.name);
  //Basic tab
  const verifyOwner = () => {
    let owner = props.serviceValues.owner;
    if (
      !ownerList.some((element) => element._id === owner._id) &&
      owner._id !== ""
    ) {
      owner = ownerList.find(
        (element) => element.email === process.env.REACT_APP_API_DEFAULT_ADMIN
      );
    }
    return owner;
  };

  const verifyEditors = () => {
    let NewEditors = editors;
    editors.forEach((editor, index) => {
      if (
        !ownerList.some((element) => element._id === editor) &&
        editor._id !== ""
      ) {
        NewEditors.splice(index, index + 1);
      }
    });
    return NewEditors;
  };

  const verifyProductManager = () => {
    let productManager = props.serviceValues.productManager;
    if (
      productManager &&
      !ownerList.some((element) => element._id === productManager._id) &&
      productManager._id !== ""
    ) {
      productManager = "";
    }
    return productManager;
  };

  useEffect(() => {
    setOwner(verifyOwner());
    setEditors(verifyEditors());
    setProductManager(verifyProductManager());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerList]);

  const [cpqCode, setCPQCode] = useState(props.serviceValues.cpqCode);
  const [owner, setOwner] = useState(props.serviceValues.owner);
  const [editors, setEditors] = useState(getSelectedEditors());
  const [productManager, setProductManager] = useState(
    props.serviceValues.productManager
  );

  const [tags, setTags] = useState(props.serviceValues.tags);
  const [visibility, setVisibility] = useState(props.serviceValues.visibility);
  const [inSfdc, setInSfdc] = useState(props.serviceValues.inSfdc);
  const [salable, setSalable] = useState(props.serviceValues.salable);
  const [communicable, setCommunicable] = useState(
    props.serviceValues.communicable
  );
  const [serviceType, setServiceType] = useState(
    props.serviceValues.serviceType
  );
  const [thirdPartyDelivery, setThirdPartyDelivery] = useState(
    props.serviceValues.thirdPartyDelivery
  );
  const [pricing, setPricing] = useState(deep(props.serviceValues.pricing));
  const [pricingOption, setPricingOption] = useState(
    props.serviceValues.pricingOption
  );
  const [estimationRequired, setEstimationRequired] = useState(
    props.serviceValues.estimationRequired
  );
  const [renewable, setRenewable] = useState(props.serviceValues.renewable);
  const [chargingMode, setChargingMode] = useState(
    props.serviceValues.chargingMode || "FP"
  );
  const [minimumSeats, setMinimumSeats] = useState(
    props.serviceValues.minimunSeats || 1
  );
  const [checkboxValues, setcheckboxValues] = useState({
    tier: false,
    condition: false,
  });

  useEffect(() => {
    setcheckboxValues(getCheckboxValues(pricingOption));
    setPricing(setPricingStructure(chargingMode, pricingOption, minimumSeats));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingOption]);

  useEffect(() => {
    setPricing(setPricingStructure(chargingMode, pricingOption, minimumSeats));
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingMode]);

  const changeMinimumSeats = (minimumSeats, copyPricing) => {
    setMinimumSeats(minimumSeats);
    if (minimumSeats) {
      copyPricing = changeMinimumSeatsPricing(minimumSeats, copyPricing);
      setPricing(copyPricing);
    }
  };

  const cancelForm = () => {
    setSelectedLanguage("EN-US");
    setName(props.serviceValues.name);
    setCPQCode(props.serviceValues.cpqCode);
    setOwner(props.serviceValues.owner);
    setProductManager(props.serviceValues.productManager);
    setEditors(getSelectedEditors());
    setTags(props.serviceValues.tags);
    setLocalization(deep(props.serviceValues.localization));
    setMissingFields([]);
    setVisible(false);
    setVisibility(props.serviceValues.visibility);
    setInSfdc(props.serviceValues.inSfdc);
    setSalable(props.serviceValues.salable);
    setCommunicable(props.serviceValues.communicable);
    setServiceType(props.serviceValues.serviceType);
    setThirdPartyDelivery(props.serviceValues.thirdPartyDelivery);
    setPricingOption(props.serviceValues.pricingOption);
    setEstimationRequired(props.serviceValues.estimationRequired);
    setRenewable(props.serviceValues.renewable);
    setChargingMode(props.serviceValues.chargingMode);
    setMinimumSeats(props.serviceValues.minimunSeats || 1);
    setPricing(deep(props.serviceValues.pricing));
    setError(null);
  };

  const onChangeMLField = (field, value) => {
    const newLocalization = deep(localization);
    newLocalization[selectedLanguage][field] = value;
    setLocalization(newLocalization);
  };

  const submitService = () => {
    const newService = {
      localization,
      name: name.trim(),
      cpqCode: cpqCode.trim(),
      owner: owner._id,
      editors: editors,
      tags,
      visibility,
      inSfdc,
      salable,
      communicable,
      serviceType,
      thirdPartyDelivery,
      estimationRequired,
      renewable,
    };

    if (productManager) {
      newService.productManager = productManager._id;
    }

    if (!estimationRequired) {
      if (props.type === "edit") {
        newService.minimunSeats = minimumSeats;
      } else {
        newService.minimumSeats = minimumSeats;
      }
      newService.chargingMode = chargingMode;
      newService.pricing = pricing;
      newService.pricingOption = pricingOption;
    }

    const miss = validateFields(
      newService,
      serviceMandatoryFields,
      setMissingFields
    );
    if (newService.localization["EN-US"].collaterals.length === 0) {
      miss.push(
        "Oh, something is wrong on collaterals tab, please help us with your feedback"
      );
    }
    if (miss.length === 0) {
      props.submitService(newService);
    }
  };

  useEffect(() => {
    setSelectedLanguage("EN-US");
    setName(props.serviceValues.name);
    setCPQCode(props.serviceValues.cpqCode);
    setOwner(props.serviceValues.owner);
    setProductManager(props.serviceValues.productManager);
    setEditors(getSelectedEditors());
    setTags(props.serviceValues.tags);
    setLocalization(deep(props.serviceValues.localization));
    setMissingFields([]);
    setVisibility(props.serviceValues.visibility);
    setInSfdc(props.serviceValues.inSfdc);
    setSalable(props.serviceValues.salable);
    setCommunicable(props.serviceValues.communicable);
    setServiceType(props.serviceValues.serviceType);
    setThirdPartyDelivery(props.serviceValues.thirdPartyDelivery);
    setPricingOption(props.serviceValues.pricingOption);
    setEstimationRequired(props.serviceValues.estimationRequired);
    setRenewable(props.serviceValues.renewable);
    setChargingMode(props.serviceValues.chargingMode);
    setMinimumSeats(props.serviceValues.minimunSeats || 1);
    setPricing(deep(props.serviceValues.pricing));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceValues]);

  // if user values changes and are correct, the form hides
  useEffect(() => {
    if (validServiceForm) {
      cancelForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validServiceForm]);

  return (
    <CustomLoadingOverlay
      active={serviceFormLoading || ownersListLoading || salesMenuLoading}
    >
      <ServiceFormSD>
        <ExtendedColumnSD>
          <FormSubtitleSD>Localization</FormSubtitleSD>
          <FormLocalizationTags
            languages={props.languages}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            localization={localization}
            setLocalization={setLocalization}
          ></FormLocalizationTags>
        </ExtendedColumnSD>
        <ExtendedColumnSD>
          <FormSubtitleSD size="1.7333rem">Service Name</FormSubtitleSD>
          <Input
            name="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </ExtendedColumnSD>
        <ExtendedColumnSD>
          <ServiceFormTabs
            cpqCode={cpqCode}
            setCPQCode={setCPQCode}
            owner={owner}
            setOwner={setOwner}
            editors={editors}
            setEditors={setEditors}
            ownerList={ownerList}
            selectedLanguage={selectedLanguage}
            localization={localization}
            onChangeMLField={onChangeMLField}
            tags={tags}
            setTags={setTags}
            tagList={tagList}
            // Status tab
            visibility={visibility}
            setVisibility={setVisibility}
            inSfdc={inSfdc}
            setInSfdc={setInSfdc}
            salable={salable}
            setSalable={setSalable}
            communicable={communicable}
            setCommunicable={setCommunicable}
            //DeliveryTab
            serviceType={serviceType}
            setServiceType={setServiceType}
            thirdPartyDelivery={thirdPartyDelivery}
            setThirdPartyDelivery={setThirdPartyDelivery}
            //pricing tab
            estimationRequired={estimationRequired}
            setEstimationRequired={setEstimationRequired}
            renewable={renewable}
            setRenewable={setRenewable}
            pricing={pricing}
            setPricing={setPricing}
            pricingOption={pricingOption}
            setPricingOption={setPricingOption}
            userBands={userBands}
            seats={seats}
            chargingMode={chargingMode}
            setChargingMode={setChargingMode}
            minimumSeats={minimumSeats}
            changeMinimumSeats={changeMinimumSeats}
            error={error}
            setError={setError}
            checkboxValues={checkboxValues}
            setcheckboxValues={setcheckboxValues}
            type={type}
            productManager={productManager}
            setProductManager={setProductManager}
          />
        </ExtendedColumnSD>
        {missingFields.length !== 0 ? (
          <MissingSD>
            <h4>
              <b>Please input the following fields:</b>
            </h4>
            <p>
              {missingFields.map((field) =>
                field !== missingFields[missingFields.length - 1]
                  ? field + ", "
                  : field
              )}
            </p>
          </MissingSD>
        ) : null}
        <ModalFooterSD>
          <ButtonSD>
            <ButtonS
              key="back"
              type="reset"
              buttonType="cancel"
              onClick={() => cancelForm()}
            >
              CANCEL
            </ButtonS>
          </ButtonSD>
          <ButtonSD>
            <ButtonS onClick={() => submitService()}>
              {props.type === "create" ? "ADD SERVICE" : "SAVE CHANGES"}
            </ButtonS>
          </ButtonSD>
        </ModalFooterSD>
      </ServiceFormSD>
    </CustomLoadingOverlay>
  );
}

export default React.memo(ServiceForm);
