import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookie from "js-cookie";

// import { authenticationService } from "@/_services";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = Cookie.get("token");
      let currentUser = null;
      if (token) {
        const jwtDecode = require("jwt-decode");
        const decoded = jwtDecode(token);
        currentUser = decoded.user;
      }
      if (!currentUser || token === null) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      } else {
        if (!roles.includes(currentUser.role)) {
          return <Redirect to={{ pathname: "/" }} />;
        }
      }
      return <Component {...props} />;
    }}
  />
);
