import { Select, Input, Tooltip } from "antd";
import React from "react";
import { FormSubtitleSD } from "Theme/Styles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";
import { ServiceFormSD } from "./BasicTabStyles";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
// On change multilanguage field

function BasicTab({
  cpqCode,
  setCPQCode,
  owner,
  setOwner,
  editors,
  setEditors,
  ownerList,
  selectedLanguage,
  localization,
  onChangeMLField,
  tags,
  setTags,
  tagList,
  productManager,
  setProductManager,
}) {
  const renderOwners = () => {
    const options = [];

    ownerList.forEach((owner, index) => {
      options.push(
        <Option key={index} value={owner._id}>
          {owner.email}
        </Option>
      );
    });
    return options;
  };

  const renderTags = () => {
    const options = [];
    tagList.forEach((tag) => {
      options.push(<Option key={tag}>{tag}</Option>);
    });

    return options;
  };
  const renderedOwners = renderOwners();
  const renderedTags = renderTags();

  return (
    <ServiceFormSD>
      <ExtendedColumnSD key="CPQ">
        <FormSubtitleSD>CPQ</FormSubtitleSD>
        <Input
          name="input"
          placeholder="MR-SVC"
          value={cpqCode}
          onChange={(e) => setCPQCode(e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD key="Owner">
        <FormSubtitleSD>Owner</FormSubtitleSD>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select an owner"
          value={owner._id}
          onChange={(value) =>
            setOwner({
              _id: value,
            })
          }
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD>
      <ExtendedColumnSD key="Product Manager">
        <FormSubtitleSD>Product Manager</FormSubtitleSD>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Product Manager"
          value={productManager ? productManager._id : ""}
          onChange={(value) =>
            setProductManager({
              _id: value,
            })
          }
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD>
      {/*  <ExtendedColumnSD key="Editors">
        <FormSubtitleSD>Editors</FormSubtitleSD>
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          placeholder="Editors"
          value={editors}
          onChange={(value) => setEditors(value)}
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD> */}
      <ExtendedColumnSD key="Summary">
        <FormSubtitleSD>Summary</FormSubtitleSD>
        <TextArea
          placeholder="Service Summary"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].summary}
          onChange={(e) => onChangeMLField("summary", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD key="Description">
        <FormSubtitleSD>Description</FormSubtitleSD>
        <TextArea
          placeholder="Service description"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].description}
          onChange={(e) => onChangeMLField("description", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD key="Internal notes">
        <FormSubtitleSD>Internal notes</FormSubtitleSD>
        <TextArea
          placeholder="Internal notes"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].internalNotes}
          onChange={(e) => onChangeMLField("internalNotes", e.target.value)}
        />
      </ExtendedColumnSD>

      <ExtendedColumnSD key="Tags">
        <FormSubtitleSD>
          Tags
          <Tooltip title="Select the tags with priority, that will influence the sales page searches">
            <QuestionCircleOutlined />
          </Tooltip>
        </FormSubtitleSD>
        <Select
          mode="tags"
          placeholder="Please select"
          value={tags}
          onChange={(value) => setTags(value)}
        >
          {renderedTags}
        </Select>
      </ExtendedColumnSD>
    </ServiceFormSD>
  );
}

export default BasicTab;
