import styled from "styled-components/macro";
import { fadeIn } from "Theme/Styles/Animations";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";
import salesHeader from "Assets/Images/SalesImages/SalesHeader.jpg";
const SalesHeaderSD = styled.div`
  display: flex;
  height: 16.5rem;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s;
  background-image: url(${salesHeader});
  background-size: cover;
  @media ${mediaQueries.mobileL} {
    height: 12.2rem;
  }
  position: relative;
`;

const SalesHeaderContentSD = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: flex-start;
  justify-content: center;
  width: 30rem;
  height: 100%;
  color: ${Colors.white};
  padding-left: 4rem;
  padding-right: 4rem;
  img {
    height: 5.4rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 65%;
    img {
      height: 4rem;
    }
  }
`;

const SalesHeaderImgSD = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  /* 
  background-size: 122%;
  background-position-y: 58%; */
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  @media ${mediaQueries.mobileL} {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50rem;
  }
`;

const HeaderAdminSubtitleSD = styled.div`
  font-size: 1.2rem;
  padding-top: 1rem;
`;

const SalesHeaderButtonsSD = styled.div`
  padding: 1rem;
`;

export {
  SalesHeaderSD,
  SalesHeaderContentSD,
  SalesHeaderImgSD,
  HeaderAdminSubtitleSD,
  SalesHeaderButtonsSD,
};
