// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";
import Colors from "Theme/Colors";

const SimpleTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SimpleTableItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SimpleTableItemPanel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-left: 1rem solid ${(props) => props.rowBorderColor};
  border-radius: 0.3rem;
  cursor: ${(props) => (props.isCollapsible ? "pointer" : "default")};
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  outline: none;
  background-color: ${(props) =>
    props.isEven
      ? props.isActive
        ? Colors.hover
        : Colors.detail
      : props.isActive
      ? Colors.hover
      : "white"};
  transition: background-color 0.3s ease;
  position: relative;
  justify-content: center;
  font-size: 1.3rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.1rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
  }
`;

const SimpleTableItemContent = styled.div`
  display: flex;
  font-family: "SourceSansPro-Light";
  width: 100%;
  max-height: ${(props) => props.height};
  background-color: ${Colors.detail};
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

const CollapsiblItemIcon = styled.div`
  position: absolute;
  margin-left: auto;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  transform: ${(props) => (props.isActive ? `rotate(180deg)` : "")};
  right: 1.5rem;
`;
/* Style the accordion chevron icon */

const Cell = styled.div`
  font-family: "SourceSansPro-Light";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: ${(props) =>
    props.accent ? Colors.primaryActive : Colors.blackboard};
  overflow-wrap: break-word;
  word-break: break-word;
  border: 0.2rem solid ${Colors.blackboard};
  border-color: transparent;
  padding-left: "0.5rem";
`;

const CellContent = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-family: "SourceSansPro-Semibold";
  border-width: 0rem 0rem 0rem ${(props) => (props.divisor ? "0.2rem" : "0rem")};
  border-style: solid;
  border-color: ${Colors.secondary};
  border: none;
  border-color: transparent;
  border-left: 0.2rem solid
    ${(props) => (props.divisor ? Colors.secondary : "transparent")};
  padding-left: 0.5rem;

  @media ${mediaQueries.mobileL} {
    padding-left: 0.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: ${(props) => (props.minWidth ? props.minWidth : 0)};
    white-space: nowrap;
    display: block;
  }
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderContainer = styled.div`
  display: flex;
  background: white;
`;

const Header = styled.div`
  display: flex;
  background-color: ${Colors.blackboard};
  color: ${Colors.white};
  border-radius: 1rem;
  padding: 0.8rem 1%;
  padding-left: 1.5%;
  width: 100%;
  align-self: center;
  z-index: 5;
  font-size: 1.4rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5%;
  z-index: 3;
  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  @media ${mediaQueries.mobileL} {
    margin: 0 0.5rem;
  }
`;

const IconRequestSD = styled.div`
  width: auto;
`;

const IconRequestID = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  width: ${(props) => (props.width ? props.width : "1.5rem")};
`;

const TableButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin-left: 0.9rem;
`;

export const SC = {
  SimpleTable,
  SimpleTableItem,
  SimpleTableItemPanel,
  SimpleTableItemContent,
  CollapsiblItemIcon,
  Cell,
  CellContent,
  TableHeaderCell,
  Header,
  TableContent,
  HeaderContainer,
  IconRequestSD,
  IconRequestID,
  TableButton,
};
