import React, { useState } from "react";
import { CircularButton } from "Components";
import { Modal } from "antd";
import CreatePackageContainer from "./CreatePackageContainer";
import Media from "react-media";

/**
 * HOOK
 * Create package modal, calls the create package container
 */

export default function CreatePackageModal() {
  // STATES
  // visible: to determinate if modal is shown. The default value is false
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Media query={{ minWidth: 768 }}>
        {(matches) =>
          matches ? (
            <CircularButton
              iconName="plus"
              size="28"
              onClick={() => setVisible(true)}
            />
          ) : (
            <CircularButton
              iconName="plus"
              size="24"
              onClick={() => setVisible(true)}
            />
          )
        }
      </Media>

      <Modal
        title="Add package"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <CreatePackageContainer setVisible={setVisible} />
      </Modal>
    </div>
  );
}
