import { setAuthToken } from "Api/apiConfig";
import ApiAuth from "Api/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import Cookie from "js-cookie";

//validate if a token is valid

function* verifyTokenSaga(action) {
  try {
    const jwtDecode = require("jwt-decode");
    const token = Cookie.get("token");
    const decoded = jwtDecode(token);
    yield call(ApiAuth.verifyToken, action);
    yield put({ type: "SAVE_USER", userInfo: decoded.user });
    setAuthToken();
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        const { history } = action;
        if (history.location.pathname !== "/loginSSO/") {
          Cookie.remove("token");
          localStorage.removeItem("currentUser");
          history.push("/");
        }
      }
    }
  }
}

// Manual login
function* loginSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "loginLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiAuth.loginUser, {
      type: "LOGIN",
      user: action.userInfo,
    });
    // save token and userInfo
    const userInfo = response.data.data.user;
    Cookie.set("token", response.data.data.token);
    localStorage.setItem(
      "currentUser",
      JSON.stringify(response.data.data.user)
    );
    // set token in axios instance header
    setAuthToken();
    yield put({ type: "SAVE_USER", userInfo });
    const { history } = action;
    yield delay(300);
    history.push("/");

    if (Cookie.get("rememberMe" === false)) {
      Cookie.remove("email");
    } else {
      if (!Cookie.get("email")) {
        Cookie.set("email", btoa(response.data.data.user.email));
      } else if (atob(Cookie.get("email")) !== response.data.data.user.email) {
        Cookie.set("email", btoa(response.data.data.user.email));
      }
    }
    // 6 Notification
    toast.success("Login successful");
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "loginLoading",
    loadingValue: false,
  });
}

// Login with SSO
function* loginSSOSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "loginSSOLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiAuth.loginSSO, action);
    Cookie.set("token", response.data.data.token);
    localStorage.setItem(
      "currentUser",
      JSON.stringify(response.data.data.user)
    );
    Cookie.set(
      "refreshTokenOffice365",
      response.data.data.refreshTokenOffice365
    );
    Cookie.set("tokenOffice365", response.data.data.tokenOffice365);
    Cookie.set(
      "tokenOffice365Expiration",
      response.data.data.tokenOffice365Expiration
    );
    // set token in axios instance header
    setAuthToken();
    const { history } = action;
    yield delay(300);
    history.push("/");

    toast.success("Login 365 successful");
  } catch (error) {
    const { history } = action;
    history.push("/");
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "loginSSOLoading",
    loadingValue: false,
  });
}

// logout
function* logoutSaga(action) {
  try {
    yield call(ApiAuth.logout, action);
    Cookie.remove("token");
    localStorage.removeItem("currentUser");
    const { history } = action;
    history.push("/login");
    toast.success("Logout successful");
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}
// We obtain the SSO login URL
function* getLoginURLSaga(action) {
  try {
    const response = yield call(ApiAuth.getLoginURL, action);
    yield put({
      type: "SAVE_LOGIN_URL",
      loginURL: response.data.data.URL,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}

//  Send and email to reset the password
function* resetPasswordSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "resetPasswordLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiAuth.resetPassword, action);
    toast.success(response.data.data.message);
    yield delay(300);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "resetPasswordLoading",
    loadingValue: false,
  });
}

// Reset password with the new credentials and a token url from email
function* updatePasswordFromEmail(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "resetPasswordLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiAuth.updatePasswordFromEmail, action);
    toast.success(response.data.data.message);
    const { history } = action;
    yield delay(300);
    history.push("/");
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "resetPasswordLoading",
    loadingValue: false,
  });
}

export const authSagas = [
  takeLatest("LOGIN_SAGA", loginSaga),
  takeLatest("LOGOUT_SAGA", logoutSaga),
  takeLatest("VERIFY_TOKEN_SAGA", verifyTokenSaga),
  takeLatest("GET_LOGIN_URL_SAGA", getLoginURLSaga),
  takeLatest("LOGIN_SSO_SAGA", loginSSOSaga),
  takeLatest("RESET_PASSWORD_SAGA", resetPasswordSaga),
  takeLatest("RESET_PASSWORD_FROM_EMAIL_SAGA", updatePasswordFromEmail),
];
