import React from "react";
import footerImg from "Assets/Icons/Icon_sales-footer.png";
import {
  FooterSD,
  FooterCenterSD,
  FooterInfoSD,
  StrongLinkSa,
} from "./FooterStyles";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function Footer() {
  return (
    <FooterSD className="FooterSD">
      <FooterInfoSD>
        Help us with your feedback in this
        <StrongLinkSa
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=oikLv1xeqkq6TayYjfFeptToLD3VbYBDuLpJmbdA_kpUM1pJTUdZWUIzVzNYOEtMRFBDN1FONzY4Ry4u"
          target="_blank"
        >
          &thinsp;link
        </StrongLinkSa>
        ,
        <div>
          take a look at the
          <StrongLinkSa
            href="https://excelsior.blackboard.com/ReleaseNotes/1_2_0"
            target="_blank"
          >
            &thinsp;release notes
          </StrongLinkSa>
        </div>
      </FooterInfoSD>
      <FooterCenterSD>
        <img src={footerImg} alt="Blackboard Logo" width="150" />
      </FooterCenterSD>
    </FooterSD>
  );
}
