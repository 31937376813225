import React, { useState } from "react";
import Media from "react-media";
import { CollapsibleTable } from "Components/Common";
import { RequestsTableSD } from "./RequestsTableStyles";
import { useDispatch } from "react-redux";

/**
 * HOOK
 * Requests table, calls Collapse table component
 */

export default function RequestsTable({
  requestList,
  requestListLoading,
  onChangeQueryParams,
  filters,
}) {
  // STATES
  // selectedItemId: is the ID of the current item selected in the table
  const [selectedItemId, setSelectedItemId] = useState("NOID");
  const headers = [
    { name: "CPQ", key: "itemCpqCode", flex: 2, color: "primaryActive" },
    { name: "OWNER", key: "owner.email", flex: 2 },
    { name: "ACTION", key: "action", flex: 1 },
    { name: "TYPE", key: "type", flex: 1 },
    { name: "STATUS", key: "status", flex: 1 },
    { name: "LAST MODIFICATION", key: "lastModification", flex: 2 },
    { name: "DATE CREATED", key: "dateCreated", flex: 2 },
  ];

  const mobileHeaders = [
    { name: "CPQ", key: "itemCpqCode", flex: 1, color: "primaryActive" },
    { name: "ACTION", key: "action", flex: 1 },
    { name: "STATUS", key: "status", flex: 1 },
    { name: "LAST MODIFICATION", key: "lastModification", flex: 3 },
  ];
  const dispatch = useDispatch();
  // SAGA
  // obtain the detail of the current request
  const getRequestDetail = (id) => {
    dispatch({ type: "GET_REQUEST_DETAIL_SAGA", id });
  };

  // on click an item call getRequestDetail function
  const onClickItem = (itemInfo) => {
    getRequestDetail(itemInfo._id);
  };
  return (
    <RequestsTableSD>
      <Media query={{ minWidth: 992 }}>
        {(matches) =>
          matches ? (
            <CollapsibleTable
              headers={headers}
              data={requestList}
              type="request"
              loading={requestListLoading}
              onChangeQueryParams={onChangeQueryParams}
              filters={filters}
              onClickItem={onClickItem}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              isCollapsible
            />
          ) : (
            <CollapsibleTable
              headers={mobileHeaders}
              data={requestList}
              type="request"
              loading={requestListLoading}
              onChangeQueryParams={onChangeQueryParams}
              filters={filters}
              onClickItem={onClickItem}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              isCollapsible
            />
          )
        }
      </Media>
    </RequestsTableSD>
  );
}
