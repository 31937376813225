import React, { useContext } from "react";
import {
  PackagesHeaderSD,
  CreatePackageSD,
  TableContentSD,
} from "./AdminPackagesViewStyles";
import { SectionTitleSD } from "Theme/Styles";
import PackagesTable from "./PackagesTable/PackagesTable";
import CreatePackageModal from "./CreatePackage/CreatePackageModal";
import { Pagination, SearchBox } from "Components/Common";
import userContext from "Context/user-context";

/**
 * HOOK
 * Admin Packages view, the view groups and sorts the visual components
 */

export default function AdminPackagesView({
  pages,
  selectedPage,
  filters,
  onChangeQueryParams,
  selectPage,
  packageList,
  packageListLoading,
  searchedText,
  setSearchedText,
}) {
  const currentUser = useContext(userContext);

  return (
    <div>
      <SectionTitleSD>Packages</SectionTitleSD>

      <PackagesHeaderSD>
        <SearchBox
          searchedText={searchedText}
          setSearchedText={setSearchedText}
        />
        {currentUser.role !== 2 ? (
          <CreatePackageSD>
            <CreatePackageModal />
          </CreatePackageSD>
        ) : null}
      </PackagesHeaderSD>
      <TableContentSD>
        <PackagesTable
          packageList={packageList}
          packageListLoading={packageListLoading}
          onChangeQueryParams={onChangeQueryParams}
          filters={filters}
        />
      </TableContentSD>
      <Pagination
        pages={pages}
        selectPage={selectPage}
        selectedPage={selectedPage}
      ></Pagination>
    </div>
  );
}
