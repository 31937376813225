// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const AdminSidebarSD = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: width 0.5s;
  width: ${(props) => (props.opened ? "1.5rem" : "4.3rem")};
  box-shadow: 0.3rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  z-index: 20;
  @media ${mediaQueries.tablet} {
    width: ${(props) => (props.opened ? "11rem" : "3.8rem")};
  }
  @media ${mediaQueries.mobileL} {
    width: ${(props) => (props.opened ? "10rem" : "3rem")};
  }
`;

const CompleteSidebarSD = styled.div`
  width: 12rem;
  overflow: hidden;
  @media ${mediaQueries.tablet} {
    width: 11rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 10.5rem;
  }
`;

const SidebarButtonSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: ${(props) =>
    props.selected
      ? "0.3rem solid " + Colors.primaryActive
      : " 0.3rem solid white"};
  background-color: ${(props) => (props.selected ? Colors.hover : "white")};
  cursor: pointer;
`;

const SidebarButtonIconSD = styled.div`
  padding: 1rem 1.1rem 1rem 0.8rem;
  @media ${mediaQueries.mobileL} {
    padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  }
`;

const SidebarButtonNameSD = styled.div`
  color: ${Colors.primaryActive};
  padding: 1rem 0.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const IconID = styled.img`
  width: ${(props) => (props.width ? props.width : "1.8rem")};
  src: url(${(props) => props.src});
  alt: ${(props) => props.alt};
  @media ${mediaQueries.tablet} {
    width: 1.7rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 1.5rem;
  }
`;

export {
  AdminSidebarSD,
  CompleteSidebarSD,
  SidebarButtonSD,
  SidebarButtonIconSD,
  SidebarButtonNameSD,
  IconID,
};
