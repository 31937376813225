// this is the component called by the route "/Login" of the main file "App.js"

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminDetailView } from "Components/Common";
export default function ServiceDetailContainer({ id, itemIndex }) {
  const [selectedLanguage, setLanguage] = useState("EN-US");
  const serviceDetail = useSelector(
    (state) => state.service.servicesDetail[id]
  );
  const userBands = useSelector((state) => state.system.userBands);
  const seats = useSelector((state) => state.system.seats);
  const filters = useSelector((state) => state.system.filters);
  const serviceDetailLoading = useSelector(
    (state) => state.system.loadings.serviceDetailLoading
  );
  const dispatch = useDispatch();

  const saveFilters = (newFilters) => {
    dispatch({
      type: "SAVE_FILTERS",
      filters: { ...filters, ...newFilters },
    });
  };
  const deleteServiceSaga = (id) => {
    dispatch({ type: "DELETE_SERVICE_SAGA", id: id });
  };

  let changes = {};

  return (
    <AdminDetailView
      detailInfo={serviceDetail}
      detailType="service"
      selectedLanguage={selectedLanguage}
      setLanguage={setLanguage}
      deleteSaga={deleteServiceSaga}
      detailLoading={serviceDetailLoading}
      userBands={userBands}
      seats={seats}
      filters={filters}
      saveFilters={saveFilters}
      itemIndex={itemIndex}
      changes={changes}
    />
  );
}
