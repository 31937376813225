import React from "react";
import { withRouter } from "react-router-dom";
import { SelectedTagsSD } from "./SalesSelectedTagStyles";
import { ClearButtonSD, ColumnSD, RowSD, SubtitleSD } from "Theme/Styles";
import { Tag } from "Components/Common";

function SelectedTags(props) {
  const {
    selectTag,
    selectedTags,
    searchedText,
    removeSearchedText,
    searchInput,
    setSearchInput,
    clearAllSelected,
  } = props;

  function renderTags() {
    const renderedTags = [];
    selectedTags.forEach((tag) => {
      renderedTags.push(
        <Tag tagName={tag} selectTag={selectTag} key={tag} selectable></Tag>
      );
    });
    return renderedTags;
  }
  const renderTextTags = () => {
    const renderedTextTags = [];
    searchedText.forEach((text) => {
      renderedTextTags.push(
        <Tag
          tagName={text}
          type="searchedText"
          selectTag={removeSearchedText}
          key={text}
          selectable
        ></Tag>
      );
    });
    return renderedTextTags;
  };
  const renderedTags = renderTags();
  const renderTexts = renderTextTags();

  return (
    <ColumnSD centered>
      {selectedTags.length === 0 &&
      searchInput === "" &&
      searchedText.length === 0 ? null : (
        <RowSD>
          <SubtitleSD marginRight="0.5rem">Current filters</SubtitleSD>

          <ClearButtonSD
            onClick={() => {
              clearAllSelected();
            }}
          >
            Clear all
          </ClearButtonSD>
        </RowSD>
      )}
      <SelectedTagsSD>
        {renderedTags}
        {renderTexts}

        {searchInput !== "" ? (
          <RowSD>
            <Tag
              type="searchInput"
              tagName={searchInput}
              selectTag={() => setSearchInput("")}
              selectable
            />
            &nbsp; Press enter to search
          </RowSD>
        ) : null}
      </SelectedTagsSD>
    </ColumnSD>
  );
}

export default withRouter(SelectedTags);
