// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const SearchedTagsSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.2rem;
  color: ${Colors.secondGray};
`;

const TagContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
`;

export { SearchedTagsSD, TagContentSD };
