import styled from "styled-components/macro";

const CheckErrorSD = styled.div`
  width: ${(props) => (props.size ? props.size + "px" : "2.6rem")};
  height: ${(props) => (props.size ? props.size + "px" : "2.6rem")};
  border-radius: ${(props) => (props.size ? props.size / 10 + "px" : "1.3rem")};
  font-size: ${(props) => (props.size ? props.size / 3 + "pt" : "1.0667rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export { CheckErrorSD };
