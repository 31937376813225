import React from "react";
import CreateUserModal from "../CreateUser/CreateUserModal";
import { UsersHeaderSD, CreateUserSD, RoleFilterSD } from "./UserFiltersStyles";
import { Select } from "antd";
import { SearchBox } from "Components/Common";

const { Option } = Select;

/**
 * HOOK
 * User Filters, all the filters in users view
 */

export default function UserFilters({
  roles,
  filters,
  setFilters,
  searchedText,
  setSearchedText,
}) {
  return (
    <UsersHeaderSD className="UsersHeaderSD">
      <SearchBox
        searchedText={searchedText}
        setSearchedText={setSearchedText}
      ></SearchBox>

      <RoleFilterSD className="RoleFilterSD">
        <Select
          defaultValue="All"
          placeholder="Role"
          onChange={(value) =>
            setFilters(() => ({ ...filters, roleFilter: value }))
          }
        >
          <Option value="All">All</Option>
          {renderRoles(roles)}
        </Select>
      </RoleFilterSD>
      <CreateUserSD className="CreateUserSD">
        <CreateUserModal roles={roles} />
      </CreateUserSD>
    </UsersHeaderSD>
  );
}

// render roles in the select
function renderRoles(roles) {
  const roleIds = Object.keys(roles);
  const options = [];
  roleIds.forEach((id) => {
    options.push(
      <Option key={id} value={id}>
        {roles[id]}
      </Option>
    );
  });
  return options;
}
