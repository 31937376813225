import { validURL } from "Helpers/Helpers";

// validate fields in the form
export const validateFields = (
  itemValues,
  itemMandatoryFields,
  setMissingFields
) => {
  const newMissingFields = [];
  //validating other fields
  itemMandatoryFields.fields.forEach((field) => {
    if (!validateNormalField(itemValues, field)) {
      newMissingFields.push(field);
    }
  });

  // validating localization, all the fields must be filled in all selected languages
  const languagesKeys = Object.keys(itemValues.localization);
  languagesKeys.forEach((language) => {
    // here the collaterals are validated
    // ... SOW and WBS collaterals are not taken into account since they do not have any
    // ... associated links, instead, if they exist, they have an associated document
    if (itemValues.localization[language].collaterals) {
      for (let collateral of itemValues.localization[language].collaterals) {
        if (
          collateral.name !== "SOW (Autogenerate)" &&
          collateral.name !== "WBS (Autogenerate)"
        ) {
          if (collateral.link && !validURL(collateral.link)) {
            newMissingFields.push(language + " collaterals: link is not valid");
            break;
          }
        }
      }
    }

    // here the other localization things are validated
    itemMandatoryFields.localization.forEach((localizationField) => {
      if (localizationField !== "tasks") {
        if (itemValues.localization[language][localizationField] === "") {
          newMissingFields.push(language + " " + localizationField);
        }
      } else {
        if (itemValues.localization[language][localizationField].length === 0) {
          newMissingFields.push(language + " " + localizationField);
        }
      }
    });
  });

  // here is validated minimum seats
  if (itemValues.estimationRequired === false) {
    let minimumSeats;
    if (itemValues.minimumSeats) {
      minimumSeats = itemValues.minimumSeats;
    } else if (itemValues.minimunSeats) {
      minimumSeats = itemValues.minimunSeats;
    }

    if (itemValues.chargingMode === "BS" && !minimumSeats) {
      newMissingFields.push("Minimum seats");
    }
  }
  setMissingFields(newMissingFields);
  return newMissingFields;
};

// validate if normal fields (all fields except location) are empty or not
function validateNormalField(itemValues, fieldName) {
  switch (fieldName) {
    case "tasks":
      return false;
    default:
      return itemValues[fieldName] !== "";
  }
}

export const findUserInOwners = (user) => {
  let userInList = true;
  if (user.role === 2 || user.role === 3) {
    userInList = false;
  }
  return userInList;
};
