import React from "react";
import Colors from "Theme/Colors";
import { DetailTagSD } from "./DetailTagStyles";

/**
 * HOOK
 * Detail tag, example EN-US, ES...
 */

export default function DetailTag({ text, isActive, onClick, onRemove, size }) {
  const bg = isActive ? Colors.primaryActive : Colors.white;
  const color = isActive ? Colors.white : Colors.primaryActive;

  return (
    <DetailTagSD bg={bg} color={color} onClick={onClick} size={size}>
      {text}
    </DetailTagSD>
  );
}
