import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const ReportsFiltersSD = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media ${mediaQueries.mobileL} {
    flex-direction: column;
  }
`;

const ReportsFiltersColumnSD = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex: 1;
  flex-direction: column;
  margin-right: ${(props) => (props.isLeftBox ? "1rem" : "0rem")};
  padding: 1rem;
  @media ${mediaQueries.mobileL} {
    margin: 1rem 0rem;
  }
  box-shadow: 0rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
`;

const ReportsHeaderSD = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
  }
`;

const SubtitleSD = styled.div`
  font-family: "SourceSansPro-Bold";
  font-weight: 600;
  display: flex;
  padding-right: 1rem;
  color: ${Colors.primaryText};
  font-size: 1.6rem;
`;

const ReportsRowSD = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
  @media ${mediaQueries.mobileL} {
    justify-content: space-between;
  }
`;

const ReportsExtRowSD = styled.div`
  display: flex;

  width: 100%;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const ReportsColumnSD = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;
  @media ${mediaQueries.mobileL} {
    padding-right: 0rem;
    margin: ${(props) => (props.margin ? props.margin : "1rem")};
  }
`;

const ReportsTableSD = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  @media ${mediaQueries.mobileL} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const GlobalCheckSD = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const CheckboxTitleSD = styled.div`
  display: flex;
  /* min-width: 6rem; */
  justify-content: center;
  flex-direction: column;
  font-family: "SourceSansPro-Regular";
  font-size: ${(props) => (props.size ? props.size : "1.4rem")};
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  color: ${Colors.black};
`;

const ReportButtonSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem;
  img {
    cursor: pointer;
  }
`;

const ReportButtonSB = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: "auto"; */
  background-color: ${Colors.primaryActive};
  color: ${Colors.white};
  border: 0.1rem solid
    ${(props) =>
      props.buttonType === "cancel" ? Colors.primaryActive : "transparent"};
  border-radius: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover:before {
    width: 100%;
  }

  img {
    margin-left: 1rem;
  }

  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
    padding-left: 0rem;
    padding-right: 0rem;
    height: ${(props) => (props.height ? props.height : "3rem")};
  }

  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
`;

export {
  ReportsFiltersSD,
  ReportsFiltersColumnSD,
  ReportsHeaderSD,
  SubtitleSD,
  ReportsRowSD,
  ReportsExtRowSD,
  ReportsColumnSD,
  ReportsTableSD,
  GlobalCheckSD,
  CheckboxTitleSD,
  ReportButtonSB,
  ReportButtonSD,
};
