import { CustomLoadingOverlay } from "Components";
import React from "react";
import { LoginContentSD, LoginViewSD } from "./LoginViewStyles";
import { SSOCardSD } from "./SSOCard/SSOCardStyles";

/**
 * HOOK
 * Login SSO view, the view groups and sorts the visual components
 */

export default function LoginView({ code }) {
  return (
    <LoginViewSD>
      <LoginContentSD>
        <CustomLoadingOverlay active={true}>
          <SSOCardSD className="SSOCardSD"></SSOCardSD>
        </CustomLoadingOverlay>
      </LoginContentSD>
    </LoginViewSD>
  );
}
