/**
 * HOOK
 * This is the component called by the route "/Login" of the main file "App.js"
 * calls the Login view
 */

import React, { useEffect, useState, useCallback } from "react";
import { LoginView } from "Components/Login";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookie from "js-cookie";

function LoginContainer(props) {
  // we obtain all login loading states
  const resetPasswordLoading = useSelector(
    (state) => state.system.loadings.resetPasswordLoading
  );
  const loginLoading = useSelector(
    (state) => state.system.loadings.loginLoading
  );

  // we obtain login URL to login with SSO
  const loginURL = useSelector((state) => state.system.loginURL);

  // STATES
  // showLogin: to show login card or reset password card
  // rememberMe: save if Remember me is selected
  const [showLogin, togglLoginCard] = useState(true);
  const [rememberMe, setRememberMe] = useState(
    useSelector((state) => state.system.rememberMe)
  );

  const { history } = props;

  // dispatch sagas
  const dispatch = useDispatch();
  const loginSaga = (userInfo) => {
    dispatch({
      type: "LOGIN_SAGA",
      userInfo: userInfo,
      history: history,
      remember: rememberMe,
    });
  };

  const resetPasswordSaga = (values) => {
    dispatch({ type: "RESET_PASSWORD_SAGA", email: values.email });
  };

  const getLoginURLSaga = useCallback(() => {
    dispatch({ type: "GET_LOGIN_URL_SAGA" });
  }, [dispatch]);

  const changeRememberMe = (remember) => {
    setRememberMe(remember);
    Cookie.set("rememberMe", remember);
  };

  // when component did mount, get loginURL
  useEffect(() => {
    getLoginURLSaga();
  }, [getLoginURLSaga]);

  return (
    <LoginView
      loginSaga={loginSaga}
      loginLoading={loginLoading}
      loginURL={loginURL}
      showLogin={showLogin}
      togglLoginCard={togglLoginCard}
      resetPasswordSaga={resetPasswordSaga}
      resetPasswordLoading={resetPasswordLoading}
      rememberMe={rememberMe}
      changeRememberMe={changeRememberMe}
    />
  );
}

export default withRouter(LoginContainer);
