import React, { useRef } from "react";
import { Tag } from "Components/Common";
import Moment from "react-moment";
import {
  DetailSubtitleSD,
  FullRowSD,
  GeneralContentSD,
  GeneralDetailSD,
  GeneralSectionSD,
  DetailStrongContentSD,
} from "Theme/DetailStyles";
import { PricingDetail } from "Components/Common";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function SalesGeneralDetailInfo({
  detailInfo,
  type,
  selectedLanguage,
  filters,
  setFilters,
}) {
  const basicTags = renderBasicTag(detailInfo.tags);
  const ref = useRef(null);

  const renderEditors = (detailInfo) => {
    let renderedEditors = "";
    detailInfo.editors.forEach((editor, index) => {
      renderedEditors += editor.email;
      if (index !== detailInfo.editors.length - 1) {
        renderedEditors += ", ";
      }
    });

    return renderedEditors;
  };

  return (
    <GeneralDetailSD ref={ref}>
      {type === "service" ? (
        <GeneralSectionSD>
          <GeneralContentSD>
            <DetailSubtitleSD>Benefits</DetailSubtitleSD>
            <div>{detailInfo.localization[selectedLanguage].benefits}</div>
          </GeneralContentSD>
          <GeneralContentSD>
            <DetailSubtitleSD>Audience</DetailSubtitleSD>
            <div>{detailInfo.localization[selectedLanguage].audience}</div>
          </GeneralContentSD>
        </GeneralSectionSD>
      ) : (
        <GeneralSectionSD>
          <GeneralContentSD>
            <DetailSubtitleSD>Owner</DetailSubtitleSD>
            <div>{detailInfo.owner.email}</div>
          </GeneralContentSD>
          <GeneralContentSD>
            <DetailSubtitleSD>Product Manager</DetailSubtitleSD>
            <div>
              {detailInfo.productManager
                ? detailInfo.productManager.email
                : "None"}
            </div>
          </GeneralContentSD>
          {/* <GeneralContentSD>
            <DetailSubtitleSD>Editors</DetailSubtitleSD>
            <div>
              {detailInfo.editors.length > 0
                ? renderEditors(detailInfo)
                : "None"}
            </div>
          </GeneralContentSD> */}
          <GeneralContentSD>
            <DetailSubtitleSD>Last modification</DetailSubtitleSD>
            <Moment>{detailInfo.lastModification}</Moment>
          </GeneralContentSD>
        </GeneralSectionSD>
      )}

      <GeneralSectionSD middle={true}>
        <GeneralContentSD>
          <DetailSubtitleSD>Description</DetailSubtitleSD>
          <div>{detailInfo.localization[selectedLanguage].description}</div>
        </GeneralContentSD>
      </GeneralSectionSD>
      <GeneralSectionSD>
        {type === "service" ? (
          <GeneralContentSD>
            <DetailSubtitleSD>Pricing</DetailSubtitleSD>
            {detailInfo.estimationRequired === false ? (
              <PricingDetail
                service={detailInfo}
                chargingMode={detailInfo.chargingMode}
                pricing={detailInfo.pricing}
                pricingOption={detailInfo.pricingOption}
                minimumSeats={detailInfo.minimunSeats}
              ></PricingDetail>
            ) : (
              <DetailStrongContentSD>Estimation required</DetailStrongContentSD>
            )}
          </GeneralContentSD>
        ) : null}

        <GeneralContentSD>
          <DetailSubtitleSD>Tags</DetailSubtitleSD>
          <FullRowSD>{basicTags}</FullRowSD>
        </GeneralContentSD>
      </GeneralSectionSD>
    </GeneralDetailSD>
  );
}

function renderBasicTag(tags) {
  const renderedTags = [];
  tags.forEach((tag) => {
    renderedTags.push(<Tag key={tag} tagName={tag}></Tag>);
  });
  return renderedTags;
}
