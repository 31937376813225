import React, { useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import Colors from "Theme/Colors";
import iconBbSolution from "Assets/Icons/SalesIcons/PNG/blackboard_solution.png";
import iconPrice from "Assets/Icons/SalesIcons/PNG/Icon_sales-price.png";
import iconServiceType from "Assets/Icons/SalesIcons/PNG/Icon_sales-serviceType.png";
import { SC } from "./FiltersMenuStyles";
function FiltersMenuSection(props) {
  const { headerName, tags, selectedTags, selectTag } = props;
  const [height, setHeight] = useState("auto");
  const [isActive, setActive] = useState(true);
  const myRef = useRef();

  const toggleAccordion = () => {
    setHeight(!isActive ? `auto` : `0rem`);
    setActive(!isActive);
  };

  function renderIcon(title) {
    switch (title) {
      case " Solution":
        return <img src={iconBbSolution} alt="icon BB Solution" />;
      case "Service Type":
        return <img src={iconServiceType} alt="icon Service Type" />;
      case "Price":
        return <img src={iconPrice} alt="icon Price" />;
      default:
        return <img src={iconBbSolution} alt="icon Alternative" />;
    }
  }

  const panel = (
    <SC.FiltersMenuItemPanel isActive={isActive} onClick={toggleAccordion}>
      <SC.TagsHeaderIconSD>{renderIcon(headerName)}</SC.TagsHeaderIconSD>
      <SC.TagsHeaderTextSD>{headerName}</SC.TagsHeaderTextSD>
      <SC.CollapsiblItemIcon isActive={isActive}>
        <FaChevronDown size={10} fill={Colors.primaryActive} />
      </SC.CollapsiblItemIcon>
    </SC.FiltersMenuItemPanel>
  );
  function renderTags() {
    const renderedTags = [];
    // const lowerSelectedTags = selectedTags.map(toLower);
    tags.forEach((tag) => {
      const isActive = selectedTags.includes(tag);
      // const isActive = lowerSelectedTags.includes(tag.toLowerCase());
      renderedTags.push(
        <SC.TagSD
          isActive={isActive}
          key={tag}
          onClick={() => {
            selectTag(tag);
          }}
        >
          {tag}
        </SC.TagSD>
      );
    });
    return renderedTags;
  }
  const renderedTags = renderTags();
  return (
    <SC.FiltersMenuItem className={"Item" + headerName}>
      {panel}
      <SC.FiltersMenuItemContent height={height} ref={myRef}>
        {renderedTags}
      </SC.FiltersMenuItemContent>
    </SC.FiltersMenuItem>
  );
}

export default FiltersMenuSection;
