import React, { useState } from "react";
import searchIcon from "Assets/Icons/AdminIcons/PNG/Icons_admin-search.png";
import { SearchBoxSD } from "./SearchBoxStyles";
import { Input } from "antd";
import SearchedTags from "./SearchedTags/SearchedTags";
import { RowSD } from "Theme/Styles";
/**
 * HOOK
 * Search box component
 */

export default function SearchBox({ searchedText, setSearchedText }) {
  const [searchInput, setSearchInput] = useState("");

  // add searched text if it doesn't exist in searchedText array yet
  const addSearchedText = (searchInput) => {
    if (searchInput.trim()) {
      let newSearchedText = [...searchedText];
      if (!newSearchedText.includes(searchInput)) {
        newSearchedText.push(searchInput);
        setSearchedText(newSearchedText);
      }
      setSearchInput("");
    }
  };

  // select/unselect a tag from left panel
  const removeSearchedText = (searchInput) => {
    let newSearchedText = [...searchedText];
    // if the tag exists, we unselect it
    // remove from the selectedTags array
    if (newSearchedText.includes(searchInput)) {
      newSearchedText = newSearchedText.filter((e) => e !== searchInput);
    }
    setSearchedText(newSearchedText);
  };

  // remove all selected items
  const clearAllSelected = () => {
    setSearchedText([]);
    setSearchInput("");
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addSearchedText(searchInput);
    }
  };

  const handleChange = (e) => {
    if (e.target.value !== " ") {
      setSearchInput(e.target.value);
    }
  };

  return (
    <SearchBoxSD>
      <RowSD className="SearchRow" padding="1rem 0rem">
        <img
          id="searchIcon"
          src={searchIcon}
          alt="searchIcon"
          onClick={() => addSearchedText(searchInput)}
        ></img>
        <Input
          placeholder="Search"
          value={searchInput}
          onChange={handleChange}
          onKeyDown={_handleKeyDown}
        />
      </RowSD>

      <SearchedTags
        searchedText={searchedText}
        removeSearchedText={removeSearchedText}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        clearAllSelected={clearAllSelected}
      ></SearchedTags>
    </SearchBoxSD>
  );
}
