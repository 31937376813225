// this is the component called by the route "/Login" of the main file "App.js"

import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceForm from "../NewServiceForm/ServiceForm";
import userContext from "../../../../Context/user-context";

function CreateServiceContainer({ setVisible }) {
  const dispatch = useDispatch();
  const getOwners = () => {
    dispatch({ type: "GET_OWNERS_SAGA" });
  };
  const getTags = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };
  const createServiceSaga = (newServiceInfo) => {
    dispatch({ type: "CREATE_SERVICE_SAGA", newServiceInfo: newServiceInfo });
  };

  useEffect(() => {
    getOwners();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tags = useSelector((state) => state.system.tags);
  const roles = useSelector((state) => state.system.roles);
  const ownerList = useSelector((state) => state.users.ownerList);
  const languages = useSelector((state) => state.system.languages);
  const currentUser = useContext(userContext);
  const userBands = useSelector((state) => state.system.userBands);
  const seats = useSelector((state) => state.system.seats);
  const createServiceLoading = useSelector(
    (state) => state.system.loadings.createServiceLoading
  );
  const serviceMandatoryFields = useSelector(
    (state) => state.system.mandatoryFields.service
  );

  const tagsArray = getTagsArray(tags);

  const [serviceValues] = useState({
    localization: {
      "EN-US": {
        description: "",
        summary: "",
        objective: "",
        benefits: "",
        audience: "",
        scope: "",
        artifacts: "",
        collaterals: [
          {
            name: "SOW",
            link: "",
            visibility: "Everyone",
          },
          {
            name: "Sales sheet",
            link: "",
            visibility: "Everyone",
          },
          {
            name: "Datasheet",
            link: "",
            visibility: "Everyone",
          },
        ],
        tasks: [],
      },
    },
    cpqCode: "",
    name: "",
    owner: { _id: "" },
    productManager: { _id: "" },
    editors: [],
    tags: [],
    visibility: "Everyone",
    inSfdc: true,
    salable: true,
    communicable: true,
    serviceType: "Subscription",
    chargingMode: "FP",
    thirdPartyDelivery: true,
    creator: {
      _id: currentUser._id,
    },
    pricing: [[0, 0, 0, 0, 0]],
    estimationRequired: true,
    renewable: true,
    pricingOption: "single",
  });

  const createService = (newService) => {
    createServiceSaga(newService);
  };

  return (
    <ServiceForm
      ownerList={ownerList}
      tagList={tagsArray}
      roles={roles}
      languages={languages}
      serviceValues={serviceValues}
      setVisible={setVisible}
      type="create"
      submitService={createService}
      serviceFormLoading={createServiceLoading}
      serviceMandatoryFields={serviceMandatoryFields}
      userBands={userBands}
      seats={seats}
    />
  );
}

function getTagsArray(tags) {
  let tagsArray = [];
  const tagsHeaders = Object.keys(tags);
  tagsHeaders.forEach((header) => {
    tagsArray = tagsArray.concat(tags[header]);
  });

  return tagsArray;
}

export default CreateServiceContainer;
