import React from "react";
import { SalesDetailSD, SalesAffixSD } from "Theme/DetailStyles";
import DetailCollaterals from "./DetailCollaterals";
import { SalesDetailHeader } from "Components/Common";
import { CustomLoadingOverlay } from "Components";
import { Affix } from "antd";
import SalesGeneralDetail from "Components/Common/Sales/SalesGeneralDetail";
import PackagePricingDetail from "Components/Common/PackagePricingDetail/PackagePricingDetail";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function packageDetailView({
  packageInfo,
  selectedLanguage,
  setLanguage,
  deletePackageSaga,
  packageDetailLoading,
  filters,
  setFilters,
}) {
  var general = document.getElementById("general");
  var objective = document.getElementById("objective");
  var scope = document.getElementById("scope");
  var drawer = document.getElementsByClassName("ant-drawer-wrapper-body");
  const scrollToRef = (id) => {
    const headerHeight = document.getElementById("affix").clientHeight;
    var y = 0;
    switch (id) {
      case "general":
        y = general.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        general.scrollIntoView();
        break;
      case "objective":
        y = objective.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
      case "scope":
        y = scope.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
      default:
        y = scope.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
    }
  };

  return (
    <CustomLoadingOverlay active={packageDetailLoading}>
      {packageInfo === undefined ? (
        <SalesDetailSD>No detail</SalesDetailSD>
      ) : (
        <SalesDetailSD>
          <SalesAffixSD id="affix">
            <Affix offsetTop={0.1}>
              <SalesDetailHeader
                name={packageInfo.name}
                cpqCode={packageInfo.cpqCode}
                localization={packageInfo.localization}
                selectedLanguage={selectedLanguage}
                setLanguage={setLanguage}
                id={packageInfo._id}
                type="package"
                scrollToRef={scrollToRef}
              />
            </Affix>
          </SalesAffixSD>
          <SalesGeneralDetail
            type="package"
            detailInfo={packageInfo}
            selectedLanguage={selectedLanguage}
            filters={filters}
            setFilters={setFilters}
          />
          <PackagePricingDetail
            packageInfo={packageInfo}
          ></PackagePricingDetail>
          {/* <PackageDetailTable packageInfo={packageInfo} filters={filters} /> */}
          <DetailCollaterals
            packageInfo={packageInfo}
            selectedLanguage={selectedLanguage}
          ></DetailCollaterals>
        </SalesDetailSD>
      )}
    </CustomLoadingOverlay>
  );
}
