import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const TableSD = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  width: 100%;
  /* @media ${mediaQueries.mobileL} {
    width: 50vw;
  } */
`;

const HeaderSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryText};
  color: ${Colors.white};
  border-radius: 1rem 1rem 0rem 0rem;
  font-size: 1.2rem;
  padding: 1rem 0rem 1rem 0;
  z-index: 5;
  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
    padding: 0.5rem 0rem 0.5rem 0;
  }
`;

const HeaderOptionSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-family: "SourceSansPro-Bold";
  font-size: 1.4667rem;
  border-width: 0rem 0rem 0rem ${(props) => (props.divisor ? "0.3rem" : "0rem")};
  border-style: solid;
  border-color: ${Colors.secondary};
  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
  }
`;

const ContentSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 0rem 0rem 1rem 1rem;
  border: 0.1rem solid rgb(238, 238, 238);
  padding: 1rem 0rem 1rem 0rem;
  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  @media ${mediaQueries.mobileL} {
    padding: 0.5rem 0rem 0.5rem 0;
  }
`;

const RowSD = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  padding-top: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  .ant-select-selection,
  .ant-select-selection--single {
    box-shadow: none;
  }
  background-color: ${(props) => (props.purple ? Colors.hover : Colors.white)};
`;

const LabelSD = styled.div`
  font-family: "SourceSansPro-Light";
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  font-size: 1.4rem;
  input {
    box-shadow: none;
    border-color: #e1cbf7;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
    padding: 0rem 0.7rem;
  }
`;

export { TableSD, HeaderSD, HeaderOptionSD, ContentSD, RowSD, LabelSD };
