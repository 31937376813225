import { CircularButton } from "Components";
import React from "react";
import { Input } from "antd";
import { FaBars } from "react-icons/fa";
import Colors from "Theme/Colors";
import { InputNumber } from "antd";
import {
  DraggableListSD,
  InputLabelSD,
  LabelSD,
  RowSD,
} from "./TasksTableStyles";

export default class DraggableList extends React.Component {
  state = {
    items: this.props.taskList,
    indexToEdit: null,
    headers: [
      { name: "Task", id: "task" },
      { name: "Role", id: "role" },
      { name: "Time", id: "time" },
    ],
  };

  onDragStart = (e, index) => {
    this.draggedItem = this.props.taskList[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (index) => {
    const draggedOverItem = this.props.taskList[index];

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let items = this.props.taskList.filter((item) => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem);
    this.props.orderTasks(items);
  };

  onDragEnd = () => {
    this.draggedIdx = null;
  };

  setIndexToEdit = (row, index) => {
    if (this.state.indexToEdit === index) {
      this.setState({
        indexToEdit: null,
        task: "",
        role: "",
        time: "",
      });
    } else {
      this.setState({
        indexToEdit: index,
        task: row.task,
        role: row.role,
        time: row.time,
      });
    }
  };

  handleChange = (headerId, text) => {
    this.setState({
      [headerId]: text,
    });
  };

  renderInputLabels = (row, index) => {
    let renderedLabels = [];
    renderedLabels.push(
      <LabelSD
        flex={0.2}
        key={"dragButton"}
        className="drag"
        draggable
        onDragStart={(e) => this.onDragStart(e, index)}
        onDragEnd={this.onDragEnd}
      >
        <FaBars
          onClick={() => {
            this.setIndexToEdit(index);
          }}
        />
      </LabelSD>
    );
    this.state.headers.forEach((header) => {
      if (header.id === "time") {
        renderedLabels.push(
          <InputLabelSD key={header.id + index}>
            <InputNumber
              min={0}
              placeholder={"hours"}
              value={row[header.id]}
              onChange={(value) => this.props.editTask(index, header.id, value)}
            />
          </InputLabelSD>
        );
      } else {
        renderedLabels.push(
          <InputLabelSD key={header.id + index}>
            <Input.TextArea
              name={header.id}
              placeholder={header.id}
              autoSize={{ minRows: 1, maxRows: 6 }}
              value={row[header.id]}
              onChange={(e) =>
                this.props.editTask(index, header.id, e.target.value)
              }
            />
          </InputLabelSD>
        );
      }
    });
    renderedLabels.push(
      <LabelSD flex={0.2} key={"deleteButton"}>
        <CircularButton
          size={15}
          color={Colors.primaryActive}
          iconName="minus"
          onClick={() => this.props.removeTask(index)}
        />
      </LabelSD>
    );
    return renderedLabels;
  };
  render() {
    return (
      <DraggableListSD className="DraggableList">
        {this.props.taskList.map((item, idx) => (
          <RowSD key={idx} onDragOver={() => this.onDragOver(idx)}>
            {this.renderInputLabels(item, idx)}
          </RowSD>
        ))}
      </DraggableListSD>
    );
  }
}
