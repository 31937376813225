import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const LocalizationTagSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bg};
  flex-direction: row;
  border: 0.1rem solid ${Colors.primaryActive};
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.8rem;
  border-radius: 0.3rem;
`;

const LocalizationTextSD = styled.div`
  font-size: 0.9rem;
  color: ${(props) => props.color};
  cursor: pointer;
`;

const CloseIconSD = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
  cursor: pointer;
`;

// TODO: COLORS
const DeleteServiceModalSD = styled.div`
  padding: 5%;
`;

const TitleSD = styled.div`
  font-family: "Georgia-Regular";
  font-size: 4rem;
  color: ${Colors.thirdGray};
  padding-top: 5%;
  padding-bottom: 5%;
`;

const SubtitleSD = styled.div`
  font-family: "Roboto-Regular";
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  padding-top: 5%;
  padding-bottom: 5%;
`;

const StrongSD = styled.div`
  display: inline-flex;
  font-family: "SourceSansPro-Semibold";
`;

const ButtonSD = styled.div`
  display: flex;
`;

export {
  LocalizationTagSD,
  LocalizationTextSD,
  CloseIconSD,
  DeleteServiceModalSD,
  TitleSD,
  SubtitleSD,
  StrongSD,
  ButtonSD,
};
