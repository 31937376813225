import React, { useState } from "react";
import { Modal } from "antd";
import { FaTimes } from "react-icons/fa";
import {
  DeleteServiceModalSD,
  TitleSD,
  SubtitleSD,
  StrongSD,
  ButtonSD,
  CloseIconSD,
} from "./LocalizationTagStyles";
import { ButtonS, ModalFooterSD } from "Theme/Styles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function DeleteTagModal({ onRemove, color, languageId }) {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <CloseIconSD color={color} onClick={() => setVisible(true)}>
        <FaTimes />
      </CloseIconSD>
      <Modal
        visible={visible}
        footer={null}
        centered
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <DeleteServiceModalSD>
          <TitleSD>Are you sure?</TitleSD>
          <SubtitleSD>
            Do you want to remove the language&thinsp;
            <StrongSD>{languageId}</StrongSD> ?
          </SubtitleSD>

          <ModalFooterSD>
            <ButtonSD>
              <ButtonS
                key="back"
                type="reset"
                buttonType="cancel"
                onClick={() => setVisible(false)}
              >
                CANCEL
              </ButtonS>
            </ButtonSD>
            <ButtonSD>
              <ButtonS onClick={() => onRemove()}>DELETE</ButtonS>
            </ButtonSD>
          </ModalFooterSD>
        </DeleteServiceModalSD>
      </Modal>
    </div>
  );
}
