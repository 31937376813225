import React from "react";
import adminCheck from "Assets/Icons/AdminIcons/PNG/checkIcon.png";
import adminNo from "Assets/Icons/AdminIcons/PNG/noIcon.png";
import { CheckErrorSD } from "./CheckErrorStyles";

/**
 * HOOK
 * Check icon, if some value is true (e.g communicable), show checkIcon, otherwise noIcon
 */

export default function CheckError({ checked, size }) {
  return (
    <CheckErrorSD checked={checked} size={size}>
      {checked ? (
        <img src={adminCheck} alt="admin check" width={size} />
      ) : (
        <img src={adminNo} alt="admin no" width={size} />
      )}
    </CheckErrorSD>
  );
}
