export const menu = [
  {
    version: "1.2.0",
    versionName: "Reports",
    date: "April 03,2020",
    routeName: "/ReleaseNotes/1_2_0",
    description: `We are delighted to announce a new feature in Excelsior, the download of WBS and reports of multiple services or packages`,
    news: [
      "Autogenerated Work Breakdown Structure",
      "Reports of multiple services",
      "Reports of multiple packages",
      "Fields customization for reports",
      "Visibility permisions for downloads"
    ],
    fixes: [
      "Update a package detail after editing it",
      "Links for a service detail",
      "Time input on Tasks table as a number"
    ],
    roles: {
      Administrator: [
        {
          subtitle: "Reports management",
          list: [
            "You can download reports with multiple services or packages in the Reports section of the Admin view",
            "Custom fields (Owner, editors, description, internal notes, tags) for a report. You can select the fields you want to include in the report of multiple services or packages"
          ]
        },
        {
          subtitle: "Work Breakdown Structure",
          list: [
            "You can download the Work Breakdown Structure in the collaterals section in the detail of a service in the Sales view",
            "You can assign permissions by role to download the autogenerated Work Breakdown Structure"
          ]
        },
        {
          subtitle: "Pricing",
          list: ["Readable pricing on service detail on Admin view"]
        }
      ],
      "Service Owner": [
        {
          subtitle: "Work Breakdown Structure",
          list: [
            "Depending on the assigned permissions, you can download the Work Breakdown Structure in the collaterals section in the detail of a service in the Sales view",
            "Assign permissions by role to download the autogenerated Work Breakdown Structure"
          ]
        },
        {
          subtitle: "Pricing",
          list: ["Readable pricing on service detail on Admin view"]
        }
      ],
      "GCX Team": [
        {
          subtitle: "Work Breakdown Structure",
          list: [
            "Depending on the assigned permissions, you can download the Work Breakdown Structure in the collaterals section in the detail of a service in the Sales view"
          ]
        },
        {
          subtitle: "Pricing",
          list: ["Readable pricing on service detail on Admin view"]
        }
      ],
      Consumer: [
        {
          subtitle: "Work Breakdown Structure",
          list: [
            "Depending on the assigned permissions, you can download the Work Breakdown Structure in the collaterals section in the detail of a service in the Sales view"
          ]
        }
      ]
    }
  },
  {
    version: "1.1.0",
    versionName: "Delta",
    date: "March 09,2020",
    routeName: "/ReleaseNotes/1_1_0",
    description: `We're delighted to announce that the next Blackboard Open LMS release is December 27, 2019. This release brief is your primary source of release information. It is a living document that includes high-level information about the new release components, links to key documentation and release notes, and links to access the recordings of our customer webinars.`,
    news: [
      "Custom range of userbands for pricing",
      "Custom range of seats for pricing",
      "Collaterals permissions by role"
    ],
    fixes: [
      "Update service or package detail view after edit",
      "Pricing filters on sales view",
      "Visibility of services and packages on sales view",
      "Pagination sorting"
    ],
    roles: {
      Administrator: [
        {
          subtitle: "Customizable ranges for pricing",
          list: ["Userbands range", "Seats range"]
        },
        {
          subtitle: "Package pricing",
          list: [
            "The pricing of the Packages is calculated as the sum of its services based on the different custom user bands or seats"
          ]
        },
        {
          subtitle: "Collaterals",
          list: [
            "Collaterals in packages",
            "In the form to create or edit a service or package, you can select the visibility for each link"
          ]
        },
        {
          subtitle: "Services management ",
          list: [
            "Multiple editors of a service",
            "Differentiate visibility of services in Sales view",
            "Readable URL in detail of services",
            "Pricing on detail"
          ]
        },
        {
          subtitle: "Packages management ",
          list: [
            "Multiple editors of a package",
            "Differentiate visibility of packages in Sales view",
            "Readable URL in detail of packages",
            "Pricing on detail"
          ]
        },
        {
          subtitle: "Sales view ",
          list: [
            "Add 'Training Services' tag in the Service Type category.",
            "Remove 'Community Engagement Services' tag",
            "Add CPQ on detail view"
          ]
        }
      ],
      "Service Owner": [
        {
          subtitle: "Customizable ranges for pricing",
          list: ["Userbands range", "Seats range"]
        },
        {
          subtitle: "Package pricing",
          list: [
            "The pricing of the Packages is calculated as the sum of its services based on the different custom user bands or seats"
          ]
        },
        {
          subtitle: "Collaterals",
          list: [
            "Collaterals in packages",
            "In the form to create or edit a service or package, you can select the visibility for each link"
          ]
        },
        {
          subtitle: "Services management ",
          list: [
            "Multiple editors of a service",
            "Differentiate visibility of services in Sales view",
            "Readable URL in detail of services",
            "Pricing on detail"
          ]
        },
        {
          subtitle: "Packages management ",
          list: [
            "Multiple editors of a package",
            "Differentiate visibility of packages in Sales view",
            "Readable URL in detail of packages",
            "Pricing on detail"
          ]
        },
        {
          subtitle: "Sales view ",
          list: [
            "Add 'Training Services' tag in the Service Type category.",
            "Remove 'Community Engagement Services' tag",
            "Add CPQ on detail view"
          ]
        }
      ],
      Consultant: [
        {
          subtitle: "Services management ",
          list: [
            "Readable URL in detail of services",
            "Pricing on detail",
            "Add CPQ on detail of packages",
            "Filter pricing by tier, userband or seats on detail view"
          ]
        },
        {
          subtitle: "Packages management ",
          list: [
            "Readable URL in detail of packages",
            "Pricing on detail",
            "Add CPQ on detail of packages",
            "Filter pricing by tier, userband or seats on detail view",
            "The pricing of the Packages is calculated as the sum of its services based on the different custom user bands or seats"
          ]
        },
        {
          subtitle: "Sales view ",
          list: [
            "Add 'Training Services' tag in the Service Type category.",
            "Remove 'Community Engagement Services' tag"
          ]
        }
      ],
      Consumer: [
        {
          subtitle: "Services management ",
          list: [
            "Readable URL in detail of services",
            "Pricing on detail",
            "Add CPQ on detail of packages",
            "Filter pricing by tier, userband or seats on detail view"
          ]
        },
        {
          subtitle: "Packages management ",
          list: [
            "Readable URL in detail of packages",
            "Pricing on detail",
            "Add CPQ on detail of packages",
            "Filter pricing by tier, userband or seats on detail view",
            "The pricing of the Packages is calculated as the sum of its services based on the different custom user bands or seats"
          ]
        },
        {
          subtitle: "Sales view ",
          list: [
            "Add 'Training Services' tag in the Service Type category.",
            "Remove 'Community Engagement Services' tag"
          ]
        }
      ]
    }
  },
  {
    version: "1.0.0",
    versionName: "Delta",
    date: "Jan 17,2020",
    routeName: "/ReleaseNotes/1_0_0",
    description: `We're delighted to announce that the next Blackboard Open LMS release is December 27, 2019. This release brief is your primary source of release information. It is a living document that includes high-level information about the new release components, links to key documentation and release notes, and links to access the recordings of our customer webinars.`,
    news: [
      "Manual and SSO Authentication",
      "User management (Administrator, Service owner, Consultant, Consumer)",
      "Requests management",
      "Services management",
      "Packages management"
    ],
    fixes: [],
    roles: {
      Administrator: [
        {
          subtitle: "Authentication",
          list: [
            "Authentication with Office 365 (Blackboard account)",
            "Manual authentication (if you have a manual user)"
          ]
        },
        {
          subtitle: "User management",
          list: [
            "List view and user detail",
            "User search and filtering",
            "Creating users manually",
            "User Edition ",
            "User Deletion"
          ]
        },
        {
          subtitle: "Requests management",
          list: [
            "List view and request detail ",
            "Search and filtering of requests",
            "Approval and rejection of requests"
          ]
        },
        {
          subtitle: "Packages management",
          list: [
            "List view and package detail ",
            "Package Search ",
            "Package filtering (By tags, text, price or language) ",
            "Package creation request creation",
            "Package editing (in case sensitive fields are not modified) and package update request creation",
            "Package Deletion Request Creation"
          ]
        },
        {
          subtitle: "Services management ",
          list: [
            "List view and service detail",
            "Search for services ",
            "Service filtering (By tags, text, price or language)",
            "Creation of service creation request ",
            "Service editing (in case sensitive fields are not modified) and service update request creation",
            "Creation of removal request services"
          ]
        }
      ],
      "Service Owner": [
        {
          subtitle: "Authentication",
          list: [
            "Authentication with Office 365 (Blackboard account)",
            "Manual authentication (if you have a manual user)"
          ]
        },
        {
          subtitle: "Request management",
          list: [
            "List view and detail of requests (The detail only if the service owner is the creator of the service or package related to the request) ",
            "Search and filtering of requests "
          ]
        },

        {
          subtitle: "Packages management",
          list: [
            "List view and package detail ",
            "Package Search ",
            "Package filtering (By tags, text, price or language) ",
            "Package creation request creation",
            "Editing packages that you own (in case sensitive fields are not modified) and creating update request for packages that you own ",
            "Creation of request to remove packages that you own "
          ]
        },
        {
          subtitle: "Services management ",
          list: [
            "List view and service detail",
            "Search for services ",
            "Service filtering (By tags, text, price or language)",
            "Creation of service creation request ",
            "Edition of services that you own (in case sensitive fields are not modified) and creation of request for update of services that you own",
            "Creation of removal request services that you own "
          ]
        }
      ],
      Consultant: [
        {
          subtitle: "Authentication",
          list: [
            "Authentication with Office 365 (Blackboard account)",
            "Manual authentication (if you have a manual user)"
          ]
        },
        {
          subtitle: "Packages view",
          list: [
            "List view and package detail (with Sales or Consultant visibility) ",
            "Package Search",
            "Package filtering (By tags, text, price or language)"
          ]
        },

        {
          subtitle: "Services view",
          list: [
            "List view and service detail (with Sales or Consultant visibility)",
            "Search for services",
            "Service filtering (By tags, text, price or language)"
          ]
        }
      ],
      Consumer: [
        {
          subtitle: "Authentication",
          list: [
            "Authentication with Office 365 (Blackboard account)",
            "Manual authentication (if you have a manual user)"
          ]
        },
        {
          subtitle: "Packages view",
          list: [
            "List view and package detail (with Sales or Consultant visibility) ",
            "Package Search",
            "Package filtering (By tags, text, price or language)"
          ]
        },

        {
          subtitle: "Services view",
          list: [
            "List view and service detail (with Sales or Consultant visibility)",
            "Search for services",
            "Service filtering (By tags, text, price or language)"
          ]
        }
      ]
    }
  }
];
