import React from "react";
import searchIcon from "Assets/Icons/AdminIcons/PNG/Icons_admin-search.png";

import { SalesSearchSD } from "./SalesSearchStyles";
import { Input } from "antd";

export default function SalesSearch({
  addSearchedText,
  searchInput,
  setSearchInput,
}) {
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addSearchedText(searchInput);
    }
  };

  const handleChange = (e) => {
    if (e.target.value !== " ") {
      setSearchInput(e.target.value);
    }
  };

  return (
    <SalesSearchSD className="Search">
      <img
        src={searchIcon}
        alt="searchIcon"
        onClick={() => addSearchedText(searchInput)}
      ></img>

      <Input
        placeholder="Search"
        value={searchInput}
        onChange={handleChange}
        onKeyDown={_handleKeyDown}
      />
    </SalesSearchSD>
  );
}
