// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const ShadowSD = styled.div`
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  pointer-events: none;
  -webkit-box-shadow: inset 0rem -4.5rem 1.2rem -4.2rem rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0rem -4.5rem 1.2rem -4.2rem rgba(255, 255, 255, 1);
  box-shadow: inset 0rem -4.5rem 1.2rem -4.2rem rgba(255, 255, 255, 1);
`;

const SelectedTagsSD = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
`;

const ReportsSelectedTagSD = styled.div`
  display: flex;
  padding: 0.3rem;
  background-color: ${Colors.primaryActive};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.white};
  border-radius: 0.3rem;
  padding: 0.2rem;
`;

const TagContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
`;

const TagNameSD = styled.div`
  display: flex;
  padding-left: 1rem;
  background-color: ${Colors.primaryActive};
  align-items: center;
  justify-content: flex-start;
  font-family: "SourceSansPro-Semibold";
  font-size: 0.9rem;
`;

const TagCloseIconSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
`;

const ContentSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 5rem;
  max-height: 10rem;
  position: relative;
  overflow: auto;
`;

export {
  SelectedTagsSD,
  ReportsSelectedTagSD,
  TagNameSD,
  TagCloseIconSD,
  TagContentSD,
  ShadowSD,
  ContentSD,
};
