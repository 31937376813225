import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import LoginSSOView from "./LoginSSOView";

/**
 * HOOK
 * This is the component called by the route "/loginSSO" of the main file "App.js"
 * calls the Login SSO view
 */

function LoginSSOContainer(props) {
  const { history } = props;
  // get url params
  const params = new URLSearchParams(props.location.search);
  const code = params.get("code");

  const dispatch = useDispatch();

  // dispatch sagas
  const loginSSO = useCallback(() => {
    dispatch({ type: "LOGIN_SSO_SAGA", code: code, history: history });
  }, [dispatch, code, history]);

  // when code change, call login SSO saga
  useEffect(() => {
    loginSSO(code);
  }, [code, loginSSO]);

  return <LoginSSOView code={code} />;
}

export default withRouter(LoginSSOContainer);
