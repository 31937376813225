import React from "react";
import { Form, Input } from "antd";
import { FormLabelSD } from "Theme/Styles/FormStyles";
import { ButtonS } from "Theme/Styles";
import { ForgotPasswordSD } from "./MainLoginCardStyles";
import Checkbox from "Components/Common/Form/Checkbox";

export default function ManualLoginForm(props) {
  const onFinish = (values) => {
    props.loginSaga(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.info("Form failed:", errorInfo);
  };
  return (
    <Form
      name="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name={"email"}
        label="Email"
        rules={[
          {
            transform: (value) => value.trim(),
            required: true,
            type: "email",
            message: "Please input a valid email!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <FormLabelSD>
        <Form.Item
          label="Password"
          name={"password"}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <ForgotPasswordSD onClick={() => props.togglLoginCard(false)}>
          Forgot password?
        </ForgotPasswordSD>
      </FormLabelSD>
      <FormLabelSD>
        <Checkbox
          checked={props.rememberMe}
          label={"Remember me"}
          onChange={(e) => props.changeRememberMe(e.target.checked)}
        ></Checkbox>
      </FormLabelSD>
      <ButtonS type="submit" width="100%">
        LOG IN
      </ButtonS>
    </Form>
  );
}
