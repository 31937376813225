import React from "react";
import { Select } from "antd";
import Checkbox from "Components/Common/Form/Checkbox";
import { FormSubtitleSD } from "Theme/Styles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";

import { ChecksRowSD, ServiceFormSD } from "./StatusTabStyles";

const { Option } = Select;

function StatusTab(props) {
  const {
    visibility,
    setVisibility,
    inSfdc,
    setInSfdc,
    salable,
    setSalable,
    communicable,
    setCommunicable,
  } = props;
  return (
    <ServiceFormSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Visibility</FormSubtitleSD>
        <Select value={visibility} onChange={(value) => setVisibility(value)}>
          <Option value={"Everyone"}>Everyone</Option>
          <Option value={"Service owner"}>Service owner</Option>
          <Option value={"GCX team"}>GCX team</Option>
        </Select>
      </ExtendedColumnSD>
      <ChecksRowSD>
        {/* <Checkbox
          checked={inSfdc}
          label={"inSfdc"}
          onChange={(e) => setInSfdc(e.target.checked)}
        /> */}
        <Checkbox
          checked={salable}
          label={"Sellable"}
          onChange={(e) => setSalable(e.target.checked)}
        />

        <Checkbox
          checked={communicable}
          label={"Communicable"}
          onChange={(e) => setCommunicable(e.target.checked)}
        />
      </ChecksRowSD>
    </ServiceFormSD>
  );
}

export default StatusTab;
