// this is the component called by the route "/Login" of the main file "App.js"

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminServicesView from "./AdminServicesView";

export default function AdminServicesContainer() {
  const globalItemsPerPage = useSelector(
    (state) => state.system.globalItemsPerPage
  );
  const pages = useSelector((state) => state.service.pages);
  const [filters, setFilters] = useState({
    searchedText: [],
    page: 1,
    sortBy: null,
    sortOrder: null,
  });
  const serviceListLoading = useSelector(
    (state) => state.system.loadings.serviceListLoading
  );
  const [searchedText, setSearchedText] = useState([]);

  const dispatch = useDispatch();
  const getServices = (filters) => {
    dispatch({
      type: "GET_SERVICES_SAGA",
      searchedText,
      page: filters.page,
      limit: globalItemsPerPage,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  const selectPage = (index) => {
    if (index >= 1 && index <= pages) {
      setFilters((filters) => ({ ...filters, page: index }));
      getServices(index);
    }
  };

  const onChangeQueryParams = (keyParam, valueParam) => {
    setFilters((filters) => ({
      ...filters,
      sortBy: keyParam,
      sortOrder: valueParam,
    }));
  };

  useEffect(() => {
    getServices(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchedText]);

  // useEffect(() => {
  //   setFilters((filters) => ({ ...filters, page: 1 }));
  // }, [pages]);

  const serviceList = useSelector((state) => state.service.serviceList);

  return (
    <AdminServicesView
      pages={pages}
      selectedPage={filters.page}
      filters={filters}
      selectPage={selectPage}
      serviceList={serviceList}
      serviceListLoading={serviceListLoading}
      searchedText={searchedText}
      setSearchedText={setSearchedText}
      onChangeQueryParams={onChangeQueryParams}
    />
  );
}
