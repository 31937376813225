import React, { useEffect, useState, useContext } from "react";
import { Switch } from "antd";
import { Tooltip } from "antd";
import { Route } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import filterIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-filters.png";
import salesPackageIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-package.png";
import salesServiceIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-service.png";
import detailList from "Assets/Icons/SalesIcons/PNG/SalesDetailList.png";
import simpleList from "Assets/Icons/SalesIcons/PNG/SalesSimpleList.png";
import { Footer, SalesHeader, DetailDrawer } from "Components";
import DetailTable from "./SalesDetailTable/SalesDetailTable";
import SalesFilters from "./SalesFilters/SalesFilters";
import SalesCollapsibleTable from "./SalesCollapsibleTable/SalesCollapsibleTable";
import SalesSearch from "./SalesSearch/SalesSearch";
import {
  AffixSA,
  FilterIconSD,
  FilterTagSD,
  FilterTextSD,
  LeftMenuSD,
  ListButtonsSD,
  SalesContentSD,
  SalesFiltersSD,
  SalesListSD,
  SalesMenuTagsSD,
  SalesViewSD,
  SwitchIconSD,
  SwitchNameSD,
  SwitchSD,
  TableContentSD,
  SalesFiltersRowSD,
  FilterSectionSD,
} from "./SalesStyles";
import { RowSD } from "Theme/Styles";
import SelectedTags from "./SelectedTags/SelectedTags";
import FiltersMenu from "./FiltersMenu/FiltersMenu";
import userContext from "../../Context/user-context";

/**
 * HOOK
 * Sales view, the view groups and sorts the visual components
 */

export default function SalesView(props) {
  const [shadowOn, shadowOnBoolean] = useState(0);
  const {
    salesList,
    clearAllSelected,
    tags,
    languages,
    selectedItems,
    switchItem,
    salesTableLoading,
    salesMenuLoading,
    //filters
    salesFilters,

    // filter functions
    saveSalesFilters,
    // setBasicFilter,
    sortBy,
    selectTag,
    addSearchedText,
    removeSearchedText,
    filterByLanguage,
    // filter by pricing
    filterByTier,
    filterByPricing,
    setSortBy,
    simpleListOn,
    showSimpleList,
    goToAdmin,
    logoutSaga,
    searchInput,
    setSearchInput,
    rangePricing,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (simpleListOn === false) {
      shadowOnBoolean(false);
    } else {
      shadowOnBoolean(true);
    }
  });

  const currentUser = useContext(userContext);
  return (
    <SalesViewSD>
      <Route path="/:type(service|package)" children={<DetailDrawer />} />
      <SalesHeader
        goToAdmin={goToAdmin}
        logoutSaga={logoutSaga}
        currentUser={currentUser}
      />
      <SalesContentSD>
        <LeftMenuSD>
          <SalesMenuTagsSD>
            <RowSD>
              <FilterTagSD>
                <FilterIconSD>
                  <img src={filterIcon} alt="Filter" />
                </FilterIconSD>
                <FilterTextSD>Filters</FilterTextSD>
              </FilterTagSD>
              <Tooltip title="Between tags on the same category, an OR operation is done. Between tags of different category, and AND operation is done, In the case that we have both cases, for example when we search “Blackboard Learn”, 'Blackboard Analytics' (Blackboard Solution category) and 'Training Services' (Service type category), then the operation done is (services that contains (“Blackboard Learn” OR 'Blackboard Analytics' tags)  AND contains 'Training Services' tag.">
                <QuestionCircleOutlined />
              </Tooltip>
            </RowSD>
            <FiltersMenu
              salesFilters={salesFilters}
              saveSalesFilters={saveSalesFilters}
              salesMenuLoading={salesMenuLoading}
              tags={tags}
              selectTag={selectTag}
              filterByTier={filterByTier}
              filterByPricing={filterByPricing}
              rangePricing={rangePricing}
              selectedItems={selectedItems}
            />
          </SalesMenuTagsSD>
        </LeftMenuSD>
        <SalesListSD>
          <ListButtonsSD
            onClick={() => {
              showSimpleList(!simpleListOn);
            }}
          >
            {simpleListOn ? (
              <img src={simpleList} alt="change list view" />
            ) : (
              <img src={detailList} alt="change list view" />
            )}
          </ListButtonsSD>
          <AffixSA id="AffixSearch" shadowon={shadowOn.toString()}>
            <SalesFiltersSD>
              <SalesFiltersRowSD>
                <FilterSectionSD>
                  <SalesSearch
                    addSearchedText={addSearchedText}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                  ></SalesSearch>

                  <Tooltip
                    title={
                      "This search bar gets the services or packages that have the searched text in their name, cpq code, summary, description or tags"
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </FilterSectionSD>
                <FilterSectionSD>
                  <SwitchSD>
                    <SwitchIconSD>
                      <img src={salesPackageIcon} alt="salesPackageIcon" />
                    </SwitchIconSD>
                    <SwitchNameSD itemType="packages">Packages</SwitchNameSD>
                    <Switch
                      size="small"
                      checked={selectedItems.packages}
                      onChange={(value) => {
                        switchItem("packages", value);
                      }}
                    />
                  </SwitchSD>
                  <SwitchSD>
                    <SwitchIconSD>
                      <img src={salesServiceIcon} alt="salesServiceIcon" />
                    </SwitchIconSD>
                    <SwitchNameSD itemType="services">Services</SwitchNameSD>
                    <div>
                      <Switch
                        size="small"
                        checked={selectedItems.services}
                        onChange={(value) => {
                          switchItem("services", value);
                        }}
                      />
                    </div>
                  </SwitchSD>
                </FilterSectionSD>

                <SalesFilters
                  languages={languages}
                  filterByLanguage={filterByLanguage}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  actualLanguage={salesFilters.selectedLanguage}
                ></SalesFilters>
              </SalesFiltersRowSD>

              <SelectedTags
                selectTag={selectTag}
                selectedTags={salesFilters.selectedTags}
                searchedText={salesFilters.searchedText}
                removeSearchedText={removeSearchedText}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                clearAllSelected={clearAllSelected}
              ></SelectedTags>
            </SalesFiltersSD>
          </AffixSA>
          <TableContentSD>
            {simpleListOn ? (
              <SalesCollapsibleTable
                salesList={salesList}
                salesTableLoading={salesTableLoading}
                selectedLanguage={salesFilters.selectedLanguage}
                currentUser={currentUser}
                salesFilters={salesFilters}
              ></SalesCollapsibleTable>
            ) : (
              <DetailTable salesList={salesList}></DetailTable>
            )}
          </TableContentSD>
        </SalesListSD>
      </SalesContentSD>
      <Footer />
    </SalesViewSD>
  );
}
