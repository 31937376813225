import React, { useEffect, useState } from "react";
import { ServiceFormSD } from "./PricingTabStyles";
import {
  PricingRowSD,
  PricingNameSD,
  PricingColumnSD,
  DescrNameSD,
  PricingContainerSD,
  FormDescription,
} from "Theme/Styles/FormStyles";
import {
  onChangeSingle,
  onChangeTier,
  onChangeSituational,
  onRemove,
  onAdd,
} from "Helpers/PricingHelpers";
import { CircularButton } from "Components";
import Colors from "Theme/Colors";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";

function ServicePricing(props) {
  const {
    pricing,
    setPricing,
    pricingOption,
    chargingMode,
    minimumSeats,
    changeMinimumSeats,
    setError,
    error,
  } = props;

  const placeholderName = (chargingMode) => {
    if (chargingMode === "FP") {
      return "Point ";
    } else if (chargingMode === "TM") {
      return "Role";
    } else {
      return "Seat";
    }
  };

  const [inputsValue, setInputsValue] = useState(["", "", "", "", ""]);

  useEffect(() => {
    if (error === null) {
      setInputsValue(["", "", "", "", ""]);
    }
  }, [error]);

  const renderTier = (pricing, row) => {
    let tiersColumns = [];
    for (let i = 1; i <= 4; i++) {
      tiersColumns.push(
        <PricingColumnSD key={i - 1}>
          {row === 0 ? <DescrNameSD>Tier {i}</DescrNameSD> : null}
          <InputNumber
            min="0"
            name={"tier" + String(i)}
            value={pricing[row][i]}
            onChange={(value) => {
              onChangeTier(pricing, setPricing, i, parseInt(value), row);
            }}
          ></InputNumber>
        </PricingColumnSD>
      );
    }
    return tiersColumns;
  };

  const renderSituational = (pricing, chargingMode) => {
    let situationalRows = [];
    pricing.forEach((row, index) =>
      situationalRows.push(
        <PricingRowSD key={"row " + String(index)}>
          <PricingNameSD>
            {chargingMode === "TM"
              ? row[0]
              : index < pricing.length - 1
              ? String(pricing[index][0]) +
                " - " +
                String(pricing[index + 1][0] - 1)
              : "more than " + row[0]}
          </PricingNameSD>
          <PricingColumnSD width={"9rem"}>
            <InputNumber
              min="0"
              value={pricing[index][1]}
              key={index}
              onChange={(value) => {
                let situationalName = null;
                chargingMode === "TM"
                  ? (situationalName = row[0])
                  : (situationalName = parseInt(row[0]));
                onChangeSituational(
                  pricing,
                  setPricing,
                  parseInt(value),
                  index,
                  situationalName
                );
              }}
            ></InputNumber>
          </PricingColumnSD>
          {index !== 0 ? (
            <CircularButton
              size={18}
              color={Colors.primaryActive}
              iconName="minus"
              onClick={() => onRemove(pricing, setPricing, index)}
            />
          ) : null}
        </PricingRowSD>
      )
    );

    situationalRows.push(
      <PricingRowSD key="row add">
        <PricingColumnSD width={"10rem"}>
          <InputNumber
            type={chargingMode === "TM" ? "auto" : "number"}
            min="0"
            placeholder={placeholderName(chargingMode)}
            value={inputsValue[0]}
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              chargingMode === "TM"
                ? (inputsCopy[0] = value)
                : (inputsCopy[0] = parseInt(value));
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <PricingColumnSD width={"9rem"}>
          <InputNumber
            min="0"
            placeholder="Value"
            value={inputsValue[1]}
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              inputsCopy[1] = parseInt(value);
              inputsCopy[2] = parseInt(value);
              inputsCopy[3] = parseInt(value);
              inputsCopy[4] = parseInt(value);
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <CircularButton
          size={18}
          color={Colors.primaryActive}
          iconName="plus"
          onClick={() => {
            setError(
              onAdd(
                pricing,
                setPricing,
                inputsValue,
                placeholderName(chargingMode),
                chargingMode,
                minimumSeats
              )
            );
          }}
        />
      </PricingRowSD>
    );
    return situationalRows;
  };

  const renderDual = (pricing, chargingMode) => {
    let renderDualRows = [];
    pricing.forEach((row, index) => {
      renderDualRows.push(
        <PricingRowSD key={"row " + String(index)}>
          <PricingNameSD>
            {chargingMode === "TM"
              ? row[0]
              : index < pricing.length - 1
              ? String(pricing[index][0]) +
                " - " +
                String(pricing[index + 1][0] - 1)
              : "more than " + row[0]}
          </PricingNameSD>
          {renderTier(pricing, index)}
          {index !== 0 ? (
            <CircularButton
              size={18}
              color={Colors.primaryActive}
              iconName="minus"
              onClick={() => onRemove(pricing, setPricing, index)}
            />
          ) : (
            <div style={{ width: 20 }}></div>
          )}
        </PricingRowSD>
      );
    });
    renderDualRows.push(
      <FormDescription key="Add a new pricing range">
        Add a new pricing range
        <Tooltip title="By adding a number (point) it will create a new range">
          <QuestionCircleOutlined />
        </Tooltip>
      </FormDescription>
    );
    renderDualRows.push(
      <PricingRowSD key="row add">
        <PricingColumnSD width={"10rem"}>
          <InputNumber
            type={chargingMode === "TM" ? "auto" : "number"}
            min="0"
            value={inputsValue[0]}
            placeholder={placeholderName(chargingMode)}
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              chargingMode === "TM"
                ? (inputsCopy[0] = value)
                : (inputsCopy[0] = parseInt(value));
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <PricingColumnSD>
          <InputNumber
            min="0"
            value={inputsValue[1]}
            placeholder="Value"
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              inputsCopy[1] = parseInt(value);
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <PricingColumnSD>
          <InputNumber
            min="0"
            value={inputsValue[2]}
            placeholder="Value"
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              inputsCopy[2] = parseInt(value);
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <PricingColumnSD>
          <InputNumber
            min="0"
            value={inputsValue[3]}
            placeholder="Value"
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              inputsCopy[3] = parseInt(value);
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <PricingColumnSD>
          <InputNumber
            min="0"
            value={inputsValue[4]}
            placeholder="Value"
            onChange={(value) => {
              let inputsCopy = [...inputsValue];
              inputsCopy[4] = parseInt(value);
              setInputsValue(inputsCopy);
            }}
          ></InputNumber>
        </PricingColumnSD>
        <CircularButton
          size={18}
          color={Colors.primaryActive}
          iconName="plus"
          onClick={() => {
            setError(
              onAdd(
                pricing,
                setPricing,
                inputsValue,
                placeholderName(chargingMode)
              )
            );
          }}
        />
      </PricingRowSD>
    );
    return renderDualRows;
  };

  const pricingName = (chargingMode) => {
    if (chargingMode === "FP") {
      return "Single price";
    } else if (chargingMode === "TM") {
      return "Rate per hour";
    } else {
      return "Price per seat";
    }
  };

  const renderPricing = (chargingMode) => {
    switch (pricingOption) {
      case "single":
        return (
          <PricingContainerSD>
            <PricingRowSD>
              <PricingNameSD>{pricingName(chargingMode)}</PricingNameSD>
              <PricingColumnSD width={"9rem"}>
                <InputNumber
                  name="singleInput"
                  min="1"
                  value={pricing[0][1]}
                  onChange={(value) =>
                    onChangeSingle(
                      pricing,
                      setPricing,
                      chargingMode,
                      parseInt(value),
                      minimumSeats
                    )
                  }
                ></InputNumber>
              </PricingColumnSD>
            </PricingRowSD>
          </PricingContainerSD>
        );
      case "tier":
        const renderTiers = renderTier(pricing, 0);
        return (
          <PricingContainerSD>
            <PricingRowSD>
              <PricingNameSD>Tier price</PricingNameSD>
              {renderTiers}
            </PricingRowSD>
          </PricingContainerSD>
        );
      case "situational":
        const renderSituationals = renderSituational(pricing, chargingMode);
        return <PricingContainerSD>{renderSituationals}</PricingContainerSD>;
      case "dual":
        const renderDuals = renderDual(pricing, chargingMode);
        return <PricingContainerSD>{renderDuals}</PricingContainerSD>;
      default:
        break;
    }
  };

  const renderedPricing = renderPricing(chargingMode);

  return (
    <ServiceFormSD>
      {chargingMode === "BS" ? (
        <PricingContainerSD>
          <PricingRowSD>
            <PricingNameSD>Minimum seats</PricingNameSD>
            <PricingColumnSD width={"9rem"}>
              <InputNumber
                min="1"
                value={minimumSeats}
                onChange={(value) => {
                  let copyPricing = [...pricing];
                  changeMinimumSeats(parseInt(value), copyPricing);
                }}
              ></InputNumber>
            </PricingColumnSD>
          </PricingRowSD>
        </PricingContainerSD>
      ) : null}
      {renderedPricing}
    </ServiceFormSD>
  );
}

export default ServicePricing;
