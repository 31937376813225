import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const ServiceFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    .ant-select {
      font-size: 1.2rem;
      height: 2.8rem;
    }
    .ant-select-selector {
      align-items: center;
      height: 100%;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2.8rem;
    }
    .ant-input {
      font-size: 1.2rem;
    }
    .ant-input-suffix {
      font-size: 1.2rem;
    }
    .ant-input-affix-wrapper {
      padding: 0.2rem 1.1rem;
    }
  }
`;

const ServiceFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`;

const ButtonSD = styled.div`
  display: flex;
`;
const MissingSD = styled.div`
  display: flex;
  padding: 2rem;
  color: red;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.5rem;
    color: red;
  }
`;

export { ButtonSD, MissingSD, ServiceFormSD, ServiceFormTabsSD };
