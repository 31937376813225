// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const RNInfoSD = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.detail};
`;

const RNInfoTitleSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.firstGray};
  color: white;
  width: 100%;
  height: 10rem;
  font-size: 3rem;
  font-family: "SourceSansPro-Semibold";
  @media ${mediaQueries.tablet} {
    font-size: 3rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 2rem;
  }
`;

const HLineSD = styled.div`
  display: flex;
  height: 0.2rem;
  border-top: 0.1rem solid ${Colors.thirdGray};
  width: 30%;
  margin: 2rem 0rem;
`;

const RNTextSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 2rem 0rem;
  font-size: 2rem;
  text-align: center;
`;

const RNSectionSD = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  padding: 0rem 4rem;
  @media ${mediaQueries.tablet} {
    padding: 0rem;
  }
`;

const RNTitleSD = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
  font-family: "SourceSansPro-Bold";
  padding: 1rem 0rem;
`;

const RNItemSD = styled.div`
  text-align: left;
  font-size: 2rem;
`;

const RNRowSD = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem;
  @media ${mediaQueries.tablet} {
    padding: 2rem 0.5rem;
  }
`;

export {
  RNInfoSD,
  RNInfoTitleSD,
  HLineSD,
  RNTextSD,
  RNSectionSD,
  RNTitleSD,
  RNItemSD,
  RNRowSD,
};
