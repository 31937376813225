import React from "react";
import { CustomLoadingOverlay } from "Components";
import {
  ReportsHeaderSD,
  ReportsTableSD,
  ReportButtonSD,
  GlobalCheckSD,
  ReportsExtRowSD,
  ReportsFiltersSD,
  ReportsFiltersColumnSD,
} from "./AdminReportsViewStyles";
import { SectionTitleSD } from "Theme/Styles";
import { SearchBox, Pagination } from "Components/Common";
import ReportsFilters from "./ReportsFilters/ReportsFilters";
import ReportsTabs from "./ReportsTabs/ReportsTabs";
import ServicesTable from "../AdminServices/ServicesTable/ServicesTable";
import PackagesTable from "../AdminPackages/PackagesTable/PackagesTable";
import SelectedTags from "./ReportsTags/SelectedTags";
import Icons_admin_reports_download from "Assets/Icons/AdminIcons/PNG/Icons_admin-reports-download.png";
import Checkbox from "Components/Common/Form/Checkbox";

/*
HOOK: 
Admin Reports View
Organize the visual components
*/

export default function AdminReportsView(props) {
  const {
    filters,
    reportItemType,
    setReportItemType,
    selectLanguage,
    languages,
    searchedText,
    setSearchedText,
    //report
    checkItem,
    checkedItems,
    getItemsReport,
    // fields
    fields,
    serviceFields,
    packageFields,
    setField,
    //table
    selectAll,
    selectAllFromButton,
    onChangeQueryParams,
    serviceList,
    packageList,
    serviceListLoading,
    packageListLoading,
    reportsLoading,
    selectPage,
    currentPages,
    clearAllSelected,
  } = props;
  return (
    <div>
      <SectionTitleSD>Reports</SectionTitleSD>
      <CustomLoadingOverlay active={reportsLoading}>
        <ReportsFiltersSD>
          <ReportsFiltersColumnSD isLeftBox>
            <ReportsTabs
              type={reportItemType}
              setField={setField}
              fields={fields}
              serviceFields={serviceFields}
              packageFields={packageFields}
            />
          </ReportsFiltersColumnSD>
          <ReportsFiltersColumnSD>
            <SelectedTags
              reportItemType={reportItemType}
              selectedTags={checkedItems}
              selectTag={checkItem}
              clearAllSelected={clearAllSelected}
            ></SelectedTags>
          </ReportsFiltersColumnSD>
        </ReportsFiltersSD>
        <ReportButtonSD>
          <img
            src={Icons_admin_reports_download}
            alt="download Report"
            width="40"
            onClick={() => getItemsReport()}
          />
        </ReportButtonSD>
        <ReportsHeaderSD>
          <SearchBox
            searchedText={searchedText}
            setSearchedText={setSearchedText}
          />
          <ReportsFilters
            languages={languages}
            selectLanguage={selectLanguage}
            selectedLanguage={filters.selectedLanguage}
            reportItemType={reportItemType}
            setReportItemType={setReportItemType}
          />
        </ReportsHeaderSD>
        <ReportsExtRowSD>
          <GlobalCheckSD>
            <Checkbox
              checked={selectAll}
              onChange={(e) => selectAllFromButton(e.target.checked)}
            />
            Select all {reportItemType}
          </GlobalCheckSD>
        </ReportsExtRowSD>

        <ReportsTableSD>
          {reportItemType === "services" ? (
            <ServicesTable
              serviceList={serviceList}
              serviceListLoading={serviceListLoading}
              onChangeQueryParams={onChangeQueryParams}
              filters={filters}
              forReports
            />
          ) : (
            <PackagesTable
              packageList={packageList}
              packageListLoading={packageListLoading}
              onChangeQueryParams={onChangeQueryParams}
              filters={filters}
              tableReports
              forReports
            />
          )}
        </ReportsTableSD>
        <ReportButtonSD onClick={() => getItemsReport()}>
          <img
            src={Icons_admin_reports_download}
            alt="download Report"
            width="40"
          />
        </ReportButtonSD>
        <Pagination
          pages={currentPages}
          selectPage={selectPage}
          selectedPage={filters.page}
        ></Pagination>
      </CustomLoadingOverlay>
    </div>
  );
}
