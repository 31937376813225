import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const FormSectionTitle = styled.div`
  font-family: "SourceSansPro-Semibold";
  font-size: 1.5rem;
`;

const FormDescription = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "SourceSansPro-Regular";
  font-size: 1.3rem;
  justify-content: flex-start;
  align-items: center;
  color: ${Colors.primaryText};
  padding-top: 1rem;
`;

const FormPaddingSD = styled.div`
  display: flex;
  width: 100%;
  /* padding: 2rem; */
`;

const FormSD = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0rem;
`;

const FormLabelSD = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0rem;
`;

const ExtendedColumnSD = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0rem;
`;
const ExtendedRowSD = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 1rem 0rem;
  align-items: center;
`;

const LoginInputSI = styled.input`
  font-family: "SourceSansPro-Regular";
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  padding: 0.5rem;
  width: 100%;
  border: none;
  border-bottom: 0.3rem solid;
  border-color: ${Colors.disabledGray};
  :focus {
    outline: none;
    border-color: ${Colors.secondGray};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 1.2rem;
    color: ${Colors.fourthGray};
    opacity: 1; /* Firefox */
    height: 10rem;
  }

  ::-webkit-input-placeholder {
    line-height: normal;
  }
`;

const SingleCaseSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PricingRowSD = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5rem;
`;

const PricingNameSD = styled.div`
  display: flex;
  width: 11rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: flex-start;
`;

const DescrNameSD = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
`;

const PricingInputSD = styled.div`
  input {
    width: 15rem;
    font-size: 0.9rem;
  }
`;

const PricingColumnSD = styled.div`
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "7rem")};
  font-size: 0.9rem;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-right: 1rem;
  .ant-input-number {
    width: 100%;
  }
`;

const PricingContainerSD = styled.div`
  width: 100%;
  padding: 1rem;
`;

export {
  FormSD,
  FormLabelSD,
  FormPaddingSD,
  SingleCaseSD,
  PricingRowSD,
  PricingNameSD,
  PricingInputSD,
  PricingColumnSD,
  LoginInputSI,
  DescrNameSD,
  PricingContainerSD,
  ExtendedColumnSD,
  ExtendedRowSD,
  FormSectionTitle,
  FormDescription,
};
