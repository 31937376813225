// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const RNSidebarSD = styled.div`
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Colors.white};
  overflow: hidden;
  transition: width 0.5s;
  width: ${(props) => (props.opened ? "20rem" : "6rem")};
  box-shadow: 0.3rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  z-index: 20;
  @media ${mediaQueries.tablet} {
    width: ${(props) => (props.opened ? "19rem" : "5rem")};
  }
  @media ${mediaQueries.mobileL} {
    width: ${(props) => (props.opened ? "18rem" : "4rem")};
  }
`;

const RNSidebarButtonSD = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  border-left: ${(props) =>
    props.selected
      ? "0.3rem solid " + Colors.primaryActive
      : " 0.3rem solid white"};
  background-color: ${(props) => (props.selected ? Colors.hover : "white")};
  font-size: 1.5rem;
  color: ${(props) =>
    props.selected ? Colors.primaryActive : Colors.fourthGray};
  cursor: pointer;
  font-family: "SourceSansPro-Semibold";
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const RNSidebarButtonNameSD = styled.div`
  display: flex;
  padding: 2rem;
  width: 6rem;
  align-items: center;
  justify-content: center;
  height: 5rem;
  @media ${mediaQueries.tablet} {
    width: 5rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 4rem;
  }
`;

const RNSidebarButtonLabelSD = styled.div`
  padding: 1rem;
  white-space: nowrap;
`;

const IconID = styled.img`
  width: ${(props) => (props.width ? props.width : "1.8rem")};
  src: url(${(props) => props.src});
  alt: ${(props) => props.alt};
  @media ${mediaQueries.tablet} {
    width: 1.7rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 1.5rem;
  }
`;

export {
  RNSidebarSD,
  RNSidebarButtonSD,
  RNSidebarButtonNameSD,
  RNSidebarButtonLabelSD,
  IconID,
};
