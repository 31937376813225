import React from "react";
import { history } from "Helpers/History";
import { FaUnlink } from "react-icons/fa";
import Colors from "Theme/Colors";
import { Styles } from "./PageNotFoundStyles";
import {
  LandingViewSD,
  LandingCardSD,
  LandingCardTitleS,
  StrongLinkSa,
  ButtonS,
  RowSD,
} from "Theme/Styles";

/**
 * HOOK
 * Login SSO view, the view groups and sorts the visual components
 */

export default function PageNotFound() {
  const goToAdmin = () => {
    history.push("/");
  };
  return (
    <LandingViewSD>
      <LandingCardSD>
        <RowSD centered>
          <FaUnlink color={Colors.primaryDisabled} size={55}></FaUnlink>
          <Styles.BigTitle>404</Styles.BigTitle>
        </RowSD>
        <RowSD centered>
          <LandingCardTitleS>Page not found</LandingCardTitleS>
        </RowSD>
        <RowSD padding="1rem 0rem">
          <div>
            We’re sorry, the page you requested could not be found. Please go
            back to the homepage or contact us at this
            <StrongLinkSa
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=oikLv1xeqkq6TayYjfFeptToLD3VbYBDuLpJmbdA_kpUM1pJTUdZWUIzVzNYOEtMRFBDN1FONzY4Ry4u"
              target="_blank"
            >
              &thinsp;link
            </StrongLinkSa>
            .
          </div>
        </RowSD>
        <RowSD padding="1rem 0rem">
          <ButtonS onClick={() => goToAdmin()}>GO TO HOMEPAGE</ButtonS>
        </RowSD>
      </LandingCardSD>
    </LandingViewSD>
  );
}
