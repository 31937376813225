import { ROOT_URL, instance } from "./apiConfig";

export default class SystemServices {
  //    TODO: Set filter
  //    GET all filter tags
  static getFilterTags(action) {
    const url = `${ROOT_URL}/filters`;
    const request = instance.get(url);
    return request;
  }
}
