import React, { useState } from "react";
import { CircularButton } from "Components";
import { Modal } from "antd";
import UpdateUserContainer from "./UpdateUserContainer";

/**
 * HOOK
 * Update User Modal, calls Update user container
 */

export default function UpdateUserModal({ userInfo, roles, setSearchText }) {
  // STATES
  // visible: to show or hide the modal
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <CircularButton
        iconName="edit"
        size={size()}
        onClick={() => setVisible(true)}
      />
      <Modal
        title="Update user"
        visible={visible}
        footer={null}
        centered
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <UpdateUserContainer
          setVisible={setVisible}
          userInfo={userInfo}
          roles={roles}
        />
      </Modal>
    </div>
  );
}

// to determinate the size of the modal depends on the device
function size() {
  if (window.screen.width >= 768) {
    return 23;
  } else {
    return 20;
  }
}
