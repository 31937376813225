import React from "react";
import Moment from "react-moment";
import { SC } from "./SimpleTableStyles";
import Colors from "Theme/Colors";
import RequestIcon from "./RequestIcon";
import { getChargingModeName } from "Helpers/Helpers";
import { CheckError } from "Components";

function SimpleTableItem(props) {
  const { itemInfo, headers, type, isEven } = props;

  const renderCells = (itemInfo) => {
    const renderedCells = [];

    headers.forEach((header, index) => {
      // extract content after search a nested key
      const content = header.key.split(".").reduce(function (p, prop) {
        return p[prop];
      }, itemInfo);

      const renderedCellContent = renderCellContent(content, header);
      renderedCells.push(
        <SC.Cell key={header.key} flex={header.flex} accent={header.accent}>
          {renderedCellContent}
        </SC.Cell>
      );
    });

    return renderedCells;
  };

  const renderCellContent = (content, header) => {
    switch (typeof content) {
      case "string":
        if (header.key === "status") {
          return (
            <SC.CellContent>
              <RequestIcon iconName={content} />
              {content}
            </SC.CellContent>
          );
        } else if (
          header.key === "lastModification" ||
          header.key === "dateCreated"
        ) {
          return <Moment>{content}</Moment>;
        } else if (header.key === "chargingMode") {
          return getChargingModeName(content);
        } else {
          return content;
        }
      case "boolean":
        return <CheckError checked={content} size={15} />;
      case "number":
        return content;
      case "undefined":
        if (header.key === "chargingMode") {
          return "Estimation required";
        } else {
          return content;
        }

      default:
        return content;
    }
  };
  function getRowColor(type) {
    switch (type) {
      case "service":
        return Colors.service;
      case "package":
        return Colors.package;
      case "request":
        return Colors.disabledGray;
      default:
        return Colors.selectedItem;
    }
  }

  const renderedCells = renderCells(itemInfo);
  const rowBorderColor = getRowColor(type);
  const panel = (
    <SC.SimpleTableItemPanel rowBorderColor={rowBorderColor} isEven={isEven}>
      {renderedCells}
    </SC.SimpleTableItemPanel>
  );

  return (
    <SC.SimpleTableItem className={"Item" + itemInfo._id}>
      {panel}
    </SC.SimpleTableItem>
  );
}

export default SimpleTableItem;
