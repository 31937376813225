// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";
import Colors from "Theme/Colors";

const FiltersMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  border: 0.1rem solid ${Colors.secondaryBorder};
`;

const FiltersMenuItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FiltersMenuItemPanel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0rem 2rem;
  padding-right: 3rem;
  background-color: ${Colors.selectedItem};
  border-bottom: 0.2rem solid
    ${(props) => (props.isActive ? Colors.secondary : Colors.white)};
  cursor: pointer;
  min-height: 3.5rem;
  align-items: center;
  font-family: "SourceSansPro-SemiBold";
  font-size: 1.6rem;
  color: ${Colors.primaryActive};
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const FiltersMenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => props.height};
  background-color: white;
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

const CollapsiblItemIcon = styled.div`
  position: absolute;
  margin-left: auto;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  transform: ${(props) => (props.isActive ? `rotate(180deg)` : "")};
  right: 1.5rem;
`;
/* Style the accordion chevron icon */

const Cell = styled.div`
  font-family: "SourceSansPro-Light";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: ${(props) =>
    props.accent ? Colors.primaryActive : Colors.blackboard};
  overflow-wrap: break-word;
  word-break: break-word;
  border: 0.2rem solid ${Colors.blackboard};
  border-color: transparent;
  padding-left: "0.5rem";
`;

const CellContent = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-family: "SourceSansPro-Semibold";
  border-width: 0rem 0rem 0rem ${(props) => (props.divisor ? "0.2rem" : "0rem")};
  border-style: solid;
  border-color: ${Colors.secondary};
  border: none;
  border-color: transparent;
  border-left: 0.2rem solid
    ${(props) => (props.divisor ? Colors.secondary : "transparent")};
  padding-left: 0.5rem;

  @media ${mediaQueries.mobileL} {
    padding-left: 0.3rem;
  }
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderContainer = styled.div`
  display: flex;
  background: white;
`;

const Header = styled.div`
  display: flex;
  background-color: ${Colors.blackboard};
  color: ${Colors.white};
  border-radius: 1rem;
  padding: 0.8rem 1%;
  padding-left: 1.5%;
  width: 100%;
  align-self: center;
  z-index: 5;
  font-size: 1.4rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconRequestSD = styled.div`
  width: auto;
`;

const IconRequestID = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  width: ${(props) => (props.width ? props.width : "1.5rem")};
  @media ${mediaQueries.tablet} {
    width: 1rem;
  }
`;

const ItemIconSD = styled.div`
  padding: 1rem;
  img {
    height: 2.2rem;
  }

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media ${mediaQueries.tablet} {
    padding: 0.5rem;
    img {
      height: 1.8rem;
    }
  }
  @media ${mediaQueries.mobileL} {
    padding: 0.5rem;
    img {
      height: 1.8rem;
    }
  }
`;

const ContentSD = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  float: left;
`;

const TitleSD = styled.div`
  font-family: "SourceSansPro-SemiBold";
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: ${Colors.primaryActive};
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const SubtitleSD = styled.div`
  font-family: "SourceSansPro-Regular";
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  color: ${Colors.primaryText};
  @media ${mediaQueries.tablet} {
    font-size: 1.2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const SubsubtitleSD = styled.div`
  font-size: 1.2rem;
  font-family: "SourceSansPro-Light";
  display: flex;
  flex-direction: row;
  color: ${Colors.primaryText};
  @media ${mediaQueries.tablet} {
    font-size: 1.2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 0.8rem;
  }
`;

const SalesItemTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SalesTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media ${mediaQueries.mobileL} {
    flex: 1;
  }
`;

const TableButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin-left: 0.9rem;
`;

const SalesCollapseColumntSD = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 50rem;
  @media ${mediaQueries.mobileL} {
    text-align: justify;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    width: 100%;
    align-items: center;
  }
`;

const DescriptionSD = styled.div`
  font-family: "SourceSansPro-Semibold";
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${Colors.primaryText};
`;

const DescriptionTextSD = styled.div`
  text-align: justify;
  color: ${Colors.primaryText};
`;
const TagsHeaderSD = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "SourceSansPro-Semibold";
  color: ${Colors.primaryActive};
  font-size: 1.6rem;
  width: 100%;
  height: 100%;
  padding-left: 2em;
  @media ${mediaQueries.mobileL} {
    font-size: 1.3rem;
  }
  @media ${mediaQueries.tablet} {
    font-size: 1.3rem;
    padding-left: 0.8em;
  }
`;
const TagsHeaderIconSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem 0 0.5rem;

  img {
    width: 1.8rem;
    height: auto;
  }
`;

const TagsHeaderTextSD = styled.div`
  align-items: center;
  display: flex;
`;

const TagSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Light";
  font-size: 1.5rem;
  min-height: 3.5rem;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.primaryActive};
  padding-left: 6rem;
  background-color: ${(props) =>
    props.isActive ? Colors.hover : Colors.white};
  border-bottom: 0.1rem solid ${Colors.secondaryBorder};
  border-style: thin;
  border-left: 0.4rem solid
    ${(props) => (props.isActive ? Colors.primaryActive : "none")};
  cursor: pointer;
  @media ${mediaQueries.tablet} {
    padding-left: 1em;
    height: 2.7em;
    font-size: 1.2rem;
  }
  @media ${mediaQueries.mobileL} {
    padding-left: 1em;
    height: 2.7em;
    font-size: 1.2rem;
  }
`;

const PriceFilterSD = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
`;

const PriceFilterRowSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: ${(props) => (props.padding ? props.padding : "1rem 0rem")};
  align-items: center;
`;
const PriceItemNameSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Light";
  padding-right: 1rem;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) => (props.width ? props.width : "inherit")};
  color: ${Colors.primaryActive};
  font-size: 1.4rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.2rem;
    padding-right: 0.5rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    padding-right: 1rem;
  }
`;
const PriceItemSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;

  width: 100%;
  .ant-select {
    width: 100%;
  }
  .ant-slider {
    width: 100%;
  }
  .ant-input-number {
    width: 100%;
  }
`;

export const SC = {
  FiltersMenu,
  FiltersMenuItem,
  FiltersMenuItemPanel,
  FiltersMenuItemContent,
  CollapsiblItemIcon,
  Cell,
  CellContent,
  TableHeaderCell,
  Header,
  TableContent,
  HeaderContainer,
  IconRequestSD,
  IconRequestID,
  TableButton,
  SubtitleSD,
  SubsubtitleSD,
  SalesItemTop,
  SalesTags,
  ContentSD,
  TitleSD,
  ItemIconSD,
  SalesCollapseColumntSD,
  DescriptionSD,
  DescriptionTextSD,
  TagsHeaderSD,
  TagsHeaderIconSD,
  TagsHeaderTextSD,
  TagSD,
  PriceFilterRowSD,
  PriceItemNameSD,
  PriceItemSD,
  PriceFilterSD,
};
