// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const AdminPackagesSD = styled.div`
  display: flex;
  width: 100%;
  padding: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${mediaQueries.mobileL} {
    padding: 1rem;
  }
`;

const PackagesHeaderSD = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const CreatePackageSD = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  @media ${mediaQueries.mobileL} {
    flex: 0;
    margin-left: 1rem;
  }
`;
const TableContentSD = styled.div`
  display: flex;
  width: 100%;
  padding: "1rem 0rem";
`;

export { AdminPackagesSD, PackagesHeaderSD, CreatePackageSD, TableContentSD };
