// this is the component called by the route "/Login" of the main file "App.js"

import React from "react";
import Colors from "Theme/Colors";
import { FaNewspaper, FaWrench } from "react-icons/fa";
import {
  RNInfoSD,
  RNInfoTitleSD,
  RNSectionSD,
  RNTitleSD,
  RNItemSD,
  RNRowSD,
} from "./RNInfoStyles";
import RNRoleBox from "./RNRoleBox/RNRoleBox";

/**
 * HOOK
 * displays information for a specific release
 */

export default function RNInfo(props) {
  // What's new section
  const renderNewItems = () => {
    return props.releaseInfo.news.map((item, index) => (
      <RNItemSD key={"news" + index}>
        <span>&#9679;</span> &nbsp;{item}
      </RNItemSD>
    ));
  };
  // Fixes section
  const renderFixItems = () => {
    return props.releaseInfo.fixes.map((item, index) => (
      <RNItemSD key={"fixes" + index}>
        <span>&#9679;</span> &nbsp;{item}
      </RNItemSD>
    ));
  };

  // render roles display one box for each role with the new features
  const renderRoles = () => {
    return Object.keys(props.releaseInfo.roles).map((role) => (
      <RNRoleBox
        key={role}
        roleName={role}
        roleInfo={props.releaseInfo.roles[role]}
      />
    ));
  };
  const renderedNewItems = renderNewItems();
  const renderedFixItems = renderFixItems();
  const renderedRoles = renderRoles();
  return (
    <RNInfoSD className="RNInfoSD">
      <RNInfoTitleSD>
        Excelsior catalog {props.releaseInfo.version} release
      </RNInfoTitleSD>
      {/* <HLineSD />
      <RNTextSD>{props.releaseInfo.description}</RNTextSD>
      <HLineSD /> */}
      <RNRowSD className="RNRowSD">
        {props.releaseInfo.news.length !== 0 ? (
          <RNSectionSD className="RNSectionSD">
            <div>
              <RNTitleSD>
                <FaNewspaper color={Colors.checkIcon} />
                &nbsp;What's new?
              </RNTitleSD>
              {renderedNewItems}
            </div>
          </RNSectionSD>
        ) : null}
        {props.releaseInfo.fixes.length !== 0 ? (
          <RNSectionSD className="RNSectionSD">
            <div>
              <RNTitleSD>
                <FaWrench color={Colors.secondGray} />
                &nbsp;Fixes
              </RNTitleSD>
              {renderedFixItems}
            </div>
          </RNSectionSD>
        ) : null}
      </RNRowSD>
      <RNRowSD>{renderedRoles}</RNRowSD>
    </RNInfoSD>
  );
}
