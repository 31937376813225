import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const ServiceFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ServiceFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ModalFooterSD = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
`;

const LabelRowSD = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0.5rem;
  @media ${mediaQueries.mobileL} {
    flex-direction: column;
  }
`;

const DraggerContentSD = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem;
  height: 3.5rem;
  span {
    width: 100%;
  }
`;

const InputContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  @media ${mediaQueries.mobileL} {
    flex: 1;
    padding-bottom: 0.5rem;
  }
`;

const VisibilityContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  padding-left: 0.5rem;
  @media ${mediaQueries.mobileL} {
    flex: 1;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
  }
`;

const ErrorMsgSD = styled.div`
  align-items: center;
  justify-content: center;
  color: ${Colors.errorIcon};
  font-size: 0.9rem;
`;

const ButtonSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  width: 4rem;
  @media ${mediaQueries.mobileL} {
    width: 100%;
  }
`;

const CollabSubtitleSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  flex: 1.5;
  color: ${Colors.primaryText};
  @media ${mediaQueries.mobileL} {
    flex: 1;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.2rem;
  }
`;
const SpaceSD = styled.div`
  display: flex;
  flex: 2;
`;

export {
  ServiceFormSD,
  ServiceFormTabsSD,
  ModalFooterSD,
  ButtonSD,
  LabelRowSD,
  DraggerContentSD,
  InputContentSD,
  ErrorMsgSD,
  CollabSubtitleSD,
  VisibilityContentSD,
  SpaceSD,
};
