import React, { useState } from "react";
import { Popover } from "antd";
import { CircularButton, LocalizationTag } from "Components";
import Colors from "Theme/Colors";
import { deep } from "Helpers/Helpers";
import {
  TagsSD,
  TagSD,
  LanguageSD,
  LanguagePopoverSD,
} from "./FormLocalizationTagsStyles";
/**
 * HOOK
 * Detail tag, example E-Teacher, Learn...
 */

export default function FormLocalizationTags(props) {
  const {
    languages,
    setSelectedLanguage,
    selectedLanguage,
    localization,
    setLocalization,
  } = props;

  const onAddTag = (languageId) => {
    const newLocalization = JSON.parse(JSON.stringify(localization));
    newLocalization[languageId] = newLocalization["EN-US"];
    setLocalization(newLocalization);
    setSelectedLanguage(languageId);
  };

  const onRemoveTag = (languageId) => {
    const newLocalization = deep(localization);
    delete newLocalization[languageId];
    setLocalization(newLocalization);
    setSelectedLanguage("EN-US");
  };

  const renderLanguages = () => {
    const renderedLanguages = [];
    languages.forEach((item) => {
      renderedLanguages.push(
        <LanguageSD key={item.id} onClick={() => onAddTag(item.id)}>
          {item.name} - {item.id}
        </LanguageSD>
      );
    });
    return renderedLanguages;
  };

  const renderTags = () => {
    const tags = [];
    const languagesIds = Object.keys(localization);
    languagesIds.forEach((languageId) => {
      if (
        languageId !== "internalNotes" &&
        languageId !== "collaterals" &&
        languageId !== "description" &&
        languageId !== "summary"
      ) {
        tags.push(
          <TagSD key={languageId}>
            <LocalizationTag
              text={languageId}
              isActive={selectedLanguage === languageId ? true : false}
              onClick={() => {
                setSelectedLanguage(languageId);
              }}
              onRemove={() => onRemoveTag(languageId)}
              languageId={languageId}
            />
          </TagSD>
        );
      }
    });
    return tags;
  };

  const renderedLanguages = renderLanguages();
  const renderedTags = renderTags();
  const [popoverIsVisible, togglePopover] = useState(false);
  return (
    <TagsSD>
      {renderedTags}
      <TagSD>
        <Popover
          placement="right"
          trigger="click"
          visible={popoverIsVisible}
          onVisibleChange={() => togglePopover(!popoverIsVisible)}
          content={<LanguagePopoverSD>{renderedLanguages}</LanguagePopoverSD>}
        >
          <CircularButton
            iconName={popoverIsVisible ? "minus" : "plus"}
            onClick={() => togglePopover(!popoverIsVisible)}
            color={
              popoverIsVisible ? Colors.primarySelected : Colors.primaryActive
            }
            size={20}
          />
        </Popover>
      </TagSD>
    </TagsSD>
  );
}
