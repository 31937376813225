import { CircularButton } from "Components";
import React from "react";
import Colors from "Theme/Colors";
import DraggableList from "./DraggableList";
import {
  ContentSD,
  HeaderOptionSD,
  HeaderSD,
  TableSD,
} from "./TasksTableStyles";

/**
 * HOOK
 * Tasks table, render all components in the tables
 */

function TasksTable({ headers, taskList, onChangeMLField }) {
  // add tasks into the taskList
  const addTask = (newTask) => {
    const newTasks = [...taskList];
    newTasks.push({ task: "", role: "", time: "" });
    onChangeMLField("tasks", newTasks);
  };

  // edit some task of the taskList
  const editTask = (index, field, value) => {
    const newTasks = [...taskList];
    newTasks[index][field] = value;
    onChangeMLField("tasks", newTasks);
  };

  // change the order of tasks in taskList
  const orderTasks = (newTasks) => {
    onChangeMLField("tasks", newTasks);
  };

  // remove some task of the taskList
  const removeTask = (taskIndex) => {
    const newTasks = taskList.filter(function (task, index) {
      return index !== taskIndex;
    });
    onChangeMLField("tasks", newTasks);
  };

  const header = renderHeaders(headers);
  return (
    <TableSD>
      <HeaderSD>{header}</HeaderSD>
      <ContentSD>
        <DraggableList
          taskList={taskList}
          addTask={addTask}
          editTask={editTask}
          orderTasks={orderTasks}
          removeTask={removeTask}
        />
        <CircularButton
          size={15}
          color={Colors.primaryActive}
          iconName="plus"
          onClick={() => {
            addTask();
          }}
        />
      </ContentSD>
    </TableSD>
  );
}

// render table headers
function renderHeaders(headers) {
  const renderedHeaders = [];
  renderedHeaders.push(<HeaderOptionSD flex={0.2} key={"draggButton"} />);

  headers.forEach((header, index) => {
    renderedHeaders.push(
      <HeaderOptionSD divisor={index !== 0 ? 1 : 0} key={index}>
        {header.name}
      </HeaderOptionSD>
    );
  });
  renderedHeaders.push(<HeaderOptionSD flex={0.4} key={"buttons"} />);
  return renderedHeaders;
}

export default TasksTable;
