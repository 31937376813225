import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import userContext from "Context/user-context";
import DeleteUserModal from "./DeleteUserModal";

/**
 * HOOK
 * Delete User Container, calls Delete User Modal, has all the logic
 */

function DeleteUserContainer(props) {
  const { id, email, setSearchText } = props;
  // obtain the current user through the context
  const currentUser = useContext(userContext);

  // we create the functions that the Sagas execute
  const dispatch = useDispatch();

  // SAGA
  // it going to send a request to remove the user
  const deleteUserSaga = () => {
    dispatch({
      type: "DELETE_USER_SAGA",
      id: id,
      modifierId: currentUser._id,
      history: props.history,
    });
  };

  return (
    <DeleteUserModal
      onDelete={deleteUserSaga}
      email={email}
      setSearchText={setSearchText}
    />
  );
}

export default withRouter(DeleteUserContainer);
