import { initialState } from "Redux/initialState";
/// node: requests
const requestsReducer = (state = initialState.requests, action) => {
  switch (action.type) {
    case "SAVE_REQUEST_LIST":
      return {
        ...state,
        requestList: action.requestList,
        count: action.count,
        pages: action.pages,
      };
    // save all data of an specific request and save it into state.requests.detail.[request._id]
    case "SAVE_REQUEST_DETAIL":
      let requestDetailState = { ...state.requestDetail };
      requestDetailState[action.requestId] = action.requestDetail;
      return {
        ...state,
        requestDetail: requestDetailState,
      };

    default:
      return state;
  }
};

export default requestsReducer;
