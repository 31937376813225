import React from "react";
import { CollapsibleTable } from "Components/Common";
import Media from "react-media";
import { UsersTableSD } from "./UsersTableStyles";

/**
 * HOOK
 * Users table, calls Collapse table component
 */

export default function UsersTable({
  userList,
  roles,
  usersListLoading,
  onChangeQueryParams,
}) {
  const headers = [
    { name: "FIRST NAME", key: "firstName", flex: 1, accent: "primaryActive" },
    { name: "LAST NAME", key: "lastName", flex: 1, accent: "primaryActive" },
    { name: "EMAIL", key: "email", flex: 1 },
    { name: "ROLE", key: "role", flex: 1 },
  ];

  const mobileHeaders = [
    {
      name: "FIRST NAME",
      key: "firstName",
      flex: "1 0",
      accent: "primaryActive",
    },
    {
      name: "LAST NAME",
      key: "lastName",
      flex: "1 0",
      accent: "primaryActive",
    },
    { name: "ROLE", key: "role", flex: "1 0" },
  ];
  return (
    <UsersTableSD>
      <Media query={{ minWidth: 992 }}>
        {(matches) =>
          matches ? (
            <CollapsibleTable
              headers={headers}
              data={userList}
              roles={roles}
              loading={usersListLoading}
              onChangeQueryParams={onChangeQueryParams}
            />
          ) : (
            <CollapsibleTable
              headers={mobileHeaders}
              data={userList}
              roles={roles}
              loading={usersListLoading}
              onChangeQueryParams={onChangeQueryParams}
            />
          )
        }
      </Media>
    </UsersTableSD>
  );
}
