import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const LabelSL = styled.label`
  /* The container */

  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 0.2rem solid ${Colors.primaryActive};
  }

  /* On mouse-over, add a grey background color */

  /* When the radio button is checked, add a blue background */

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 0.4rem;
    left: 0.4rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: ${Colors.primaryActive};
  }
`;

export { LabelSL };
