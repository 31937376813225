import { combineReducers } from "redux";
import system from "./systemReducer";
import users from "./usersReducer";
import service from "./servicesReducer";
import packages from "./packagesReducer";
import requests from "./requestsReducer";
import reports from "./reportsReducer";
const reducer = combineReducers({
  system,
  users,
  service,
  packages,
  requests,
  reports
});
export default reducer;
