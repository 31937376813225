// Application state, it's updated by redux

export const initialState = {
  system: {
    loginURL: "",
    clearCollapse: false,
    roles: {
      0: "Administrator",
      1: "Service Owner",
      2: "GCX team",
      3: "Consumer",
      4: "Product Manager",
    },
    tags: {
      "Blackboard Solution": ["Blackboard Data"],
      "Service Type": ["Analytics Services"],
    },
    globalItemsPerPage: 30,
    pricing: {
      absoluteMin: 0,
      maximumServicePrice: null,
      maximumPackagePrice: null,
    },
    salesFilters: {
      tier: "T2",
      userBand: 2000,
      seats: 1,
      // minPricingFilter: 0,
      // maxPricingFilter: null,
      selectedMin: null,
      selectedMax: null,
      selectedTags: [],
      searchedText: [],
      selectedLanguage: "EN-US",
      orderBy: "byRelevance",
    },
    userBands: [0, 500, 2000, 5000, 10000, 20000, 50000],
    seats: [1, 15, 30, 45, 100, 200, 500, 1000],

    loadings: {
      filtersLoading: false,
      serviceDetailLoading: false,
      packageDetailLoading: false,
      serviceListLoading: false,
      packageListLoading: false,
      requestListLoading: false,
      usersListLoading: false,
      requestDetailLoading: false,
      salesMenuLoading: false,
      loginLoading: false,
      createServiceLoading: false,
      createPackageLoading: false,
      salesTableLoading: false,
      createUserLoading: false,
      editUserLoading: false,
      loginSSOLoading: false,
      resetPasswordLoading: false,
      reportsLoading: false,
      ownersListLoading: false,
    },

    validations: {
      validUserForm: false,
      validServiceForm: false,
      validPackageForm: false,
    },

    mandatoryFields: {
      service: {
        localization: [
          "description",
          "summary",
          "objective",
          "benefits",
          "audience",
          // "scope",
          // "artifacts",
        ],
        fields: ["name", "cpqCode", "owner", "tags"],
        tasksFields: ["task", "role", "time"],
      },
      package: {
        localization: ["description", "summary"],
        fields: ["name", "cpqCode", "owner", "services", "tags"],
      },
    },
    languages: [
      {
        name: "English",
        id: "EN-US",
      },
    ],
    salesDetail: {
      itemType: "",
      itemId: "",
    },
  },
  users: {
    ownerList: [],
    userList: [],
    adminUser: "",
  },
  service: {
    serviceList: [],
    servicesDetail: {},
    pages: 1,
  },
  packages: {
    packageList: [],
    packageDetail: {},
    pages: 1,
  },
  requests: {
    requestList: [],
    requestDetail: {},
  },
  reports: {
    selectedLanguage: "EN-US",
    selectedItems: [],
    allServices: [],
    allPackages: [],
    fields: {
      cpqCode: {
        name: "CPQ Code",
        value: true,
      },
      owner: {
        name: "Owner",
        value: true,
      },
      productManager: {
        name: "Product Manager",
        value: true,
      },
      /*   editors: {
        name: "Editors",
        value: true,
      }, */
      summary: {
        name: "Summary",
        value: true,
      },
      description: {
        name: "Description",
        value: true,
      },
      internalNotes: {
        name: "Internal notes",
        value: true,
      },
      tags: {
        name: "Tags",
        value: true,
      },
      visibility: {
        name: "Visibility",
        value: true,
      },
      /*  inSdfc: {
        name: "inSfdc",
        value: true,
      }, */
      sellable: {
        name: "Sellable",
        value: true,
      },
      communicable: {
        name: "Communicable",
        value: true,
      },
      objective: {
        name: "Objective",
        value: true,
      },
      benefits: {
        name: "Benefits",
        value: true,
      },
      audience: {
        name: "Audience",
        value: true,
      },
      /* scope: {
        name: "Scope",
        value: true,
      }, 
      tasks: {
        name: "Tasks roles",
        value: true,
      },*/
      serviceType: {
        name: "Service type",
        value: true,
      },
      /* thirdPartyDelivery: {
        name: "Third party delivery",
        value: true,
      }, 
      artifacts: {
        name: "Artifacts",
        value: true,
      },*/
      pricingType: {
        name: "Pricing type",
        value: true,
      },
      /*  renewable: {
        name: "Renewable",
        value: true,
      }, */
      SOW: {
        name: "SOW link",
        value: true,
      },
      datasheet: {
        name: "Datasheet link",
        value: true,
      },
      /*  salesSheet: {
        name: "Sales sheet link",
        value: true,
      }, 
      agWBS: {
        name: "Autogenerated WBS",
        value: true,
      },*/
      agSOW: {
        name: "Autogenerated SOW",
        value: false,
      },
      services: {
        name: "Services",
        value: true,
      },
    },
    serviceFields: {
      basic: [
        "owner",
        "productManager",
        //"editors",
        "summary",
        "description",
        "internalNotes",
        "tags",
      ],
      status: ["visibility", "inSfdc", "sellable", "communicable"],
      delivery: [
        "objective",
        "benefits",
        "scope",
        "audience",
        "scope",
        "tasks",
        "serviceType",
        "thirdPartyDelivery",
        "artifacts",
      ],
      pricing: ["pricingType", "renewable"],
      collaterals: ["SOW", "datasheet", "salesSheet", "agWBS"],
    },
    packageFields: {
      basic: [
        "owner",
        "productManager",
        //"editors",
        "summary",
        "description",
        "internalNotes",
        "services",
        "tags",
      ],
      status: ["visibility", "inSfdc", "sellable", "communicable"],
      collaterals: ["SOW", "datasheet", "salesSheet"],
    },
  },
  error: "",
};
