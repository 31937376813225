import React from "react";
import { SC } from "./SimpleTableStyles";
import circularCheck from "Assets/Icons/AdminIcons/PNG/checkIcon.png";
import circularNo from "Assets/Icons/AdminIcons/PNG/noIcon.png";
import circularPending from "Assets/Icons/AdminIcons/PNG/Icons_admin-pending2.png";

function renderRequestIcon(iconName, size) {
  switch (iconName) {
    case "Approved":
      return (
        <SC.IconRequestID
          src={circularCheck}
          alt="radio button check"
          width={size || 15}
        />
      );

    case "Pending":
      return (
        <SC.IconRequestID
          src={circularPending}
          alt="radio button pending"
          width={size || 15}
        />
      );
    case "Rejected":
      return (
        <SC.IconRequestID
          src={circularNo}
          alt="radio button no"
          width={size || 15}
        />
      );
    default:
      return null;
  }
}

export default function RequestIcon({ color, size, iconName }) {
  return (
    <SC.IconRequestSD size={size} color={color}>
      {renderRequestIcon(iconName, size)} &nbsp;
    </SC.IconRequestSD>
  );
}
