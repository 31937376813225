import React, { useState, useEffect } from "react";
import { InputNumber } from "antd";
import { RowSD } from "Theme/Styles";
import {
  HeaderOptionSD,
  LabelSD,
  PackageDetailTableSD,
  HeaderSD,
  ContentSD,
  PackagePricingRowSD,
} from "Theme/Styles/TableStyles";
import { FilterLabelSD } from "Theme/Styles";
import TierSelect from "Components/Common/Selectors/TierSelect";
import {
  formatPricing,
  calculateServicePrice_FP_or_BS,
} from "Helpers/PricingHelpers";
/**
 * HOOK
 * Package pricing table
 * Calculates the package pricing according to the services of the package
 */

export default function PackageDetailTable({ services }) {
  const [selectedTier, setSelectedTier] = useState("T1");
  const [selectedUserband, setSelectedUserband] = useState(1);
  const [selectedSeats, setSelectedSeats] = useState(1);

  const [renderedRows, setRenderedRows] = useState([]);
  const [renderedHeaders, setRenderedHeaders] = useState([]);

  const [renderTiers, setRenderTiers] = useState(false);
  const [renderUserbands, setRenderUserbands] = useState(false);
  const [renderSeats, setRenderSeats] = useState(false);

  const calculateServicesPrice = () => {
    let totalPrice = 0;
    const packageServices = [];
    services.forEach((service, index) => {
      const price = calculateServicePrice_FP_or_BS(
        service,
        selectedTier,
        selectedUserband,
        selectedSeats
      );
      packageServices.push({
        cpqCode: service.cpqCode,
        name: service.name,
        price: price,
        pricingOption: service.pricingOption,
        chargingMode: service.chargingMode,
      });

      if (!isNaN(packageServices[index].price)) {
        totalPrice = totalPrice + packageServices[index].price;
      }
    });
    packageServices.totalPrice = totalPrice;
    // setTotalPackagePrice(totalPrice);
    return packageServices;
  };
  const [packageServices, setPackageServices] = useState(
    calculateServicesPrice(services)
  );

  const headers = [
    { name: "CPQCode", id: "cpqCode" },
    { name: "NAME", id: "name" },
    { name: "PRICING", id: "pricing" },
  ];

  function renderRows(headers, data) {
    const renderedRows = [];
    data.forEach((row, index) => {
      const renderedLabels = renderCells(headers, row);
      renderedRows.push(
        <RowSD key={index} purple={index % 2 !== 0}>
          {renderedLabels}
        </RowSD>
      );
    });

    return renderedRows;
  }

  function renderHeaders(headers) {
    const renderedHeaders = [];
    headers.forEach((header, index) => {
      renderedHeaders.push(
        <HeaderOptionSD divisor={index !== 0 ? 1 : 0} key={index}>
          {header.name}
        </HeaderOptionSD>
      );
    });

    return renderedHeaders;
  }

  function renderCells(headers, row) {
    const renderedLabels = [];

    headers.forEach((header) => {
      renderedLabels.push(
        <LabelSD key={header.id}>
          {header.id === "pricing" ? formatPricing(row.price) : row[header.id]}
        </LabelSD>
      );
    });

    return renderedLabels;
  }

  useEffect(() => {
    console.log("setPackageServices");
    setPackageServices(calculateServicesPrice(services));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, selectedTier, selectedUserband, selectedSeats]);

  useEffect(() => {
    setRenderedRows(renderRows(headers, packageServices));
    setRenderedHeaders(renderHeaders(headers));
    const verifiedSelectors = verifySelectors(packageServices);
    console.log("verifySelectors", verifiedSelectors);
    setRenderTiers(verifiedSelectors[0]);
    setRenderUserbands(verifiedSelectors[1]);
    setRenderSeats(verifiedSelectors[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageServices]);

  return (
    <PackageDetailTableSD>
      {renderTiers ? (
        <RowSD>
          <FilterLabelSD>Tier:&nbsp;</FilterLabelSD>
          <FilterLabelSD>
            <TierSelect
              selectedTier={selectedTier}
              setSelectedTier={setSelectedTier}
            />
          </FilterLabelSD>
        </RowSD>
      ) : null}
      {renderUserbands ? (
        <RowSD>
          <FilterLabelSD>User band:&nbsp;</FilterLabelSD>
          <FilterLabelSD>
            <InputNumber
              placeholder={"Userband"}
              value={selectedUserband}
              onChange={(value) => {
                setSelectedUserband(value);
              }}
              width="9rem"
            ></InputNumber>
          </FilterLabelSD>
        </RowSD>
      ) : null}
      {renderSeats ? (
        <RowSD>
          <FilterLabelSD>Seats:&nbsp;</FilterLabelSD>
          <FilterLabelSD>
            <InputNumber
              placeholder={"Seats"}
              value={selectedSeats}
              onChange={(value) => {
                setSelectedSeats(value);
              }}
              width="9rem"
            ></InputNumber>
          </FilterLabelSD>
        </RowSD>
      ) : null}
      <HeaderSD>{renderedHeaders}</HeaderSD>
      <ContentSD>
        {renderedRows}
        <PackagePricingRowSD key={"packagePricing"}>
          <LabelSD key={"tp1"}>
            <strong>Total price</strong>
          </LabelSD>
          <LabelSD key={"pp2"}></LabelSD>
          <LabelSD key={"pprice"}>
            <strong>{formatPricing(packageServices.totalPrice)}</strong>
          </LabelSD>
        </PackagePricingRowSD>
      </ContentSD>
    </PackageDetailTableSD>
  );
}

// We decide to render the filters/selector according to the two checkboxes in the service form pricing
// The checkboxes can be
// Single: when the user doesn't check any checkbox
// Situational: when the user only checks the right checkbox,
//              it can be "user band" (In Fixed price)
//              it can be "seats" (In By seats price)
//              it can be "roles" (In Time and materials price)
// Tier: when the user only checks the left checkbox "tier"
// Dual: when the user checks both checkboxes (tier and situational)

function verifySelectors(services) {
  //  selectorsToRender : [tier,userband,seats];
  const selectorsToRender = [false, false, false];
  services.forEach((service, index) => {
    if (service.chargingMode !== "TM" && !service.estimationRequired) {
      // Render tier selector?
      if (
        service.pricingOption === "tier" ||
        service.pricingOption === "dual"
      ) {
        selectorsToRender[0] = true;
      }

      // Render userband selector? (Fixed price)
      if (
        (service.pricingOption === "situational" ||
          service.pricingOption === "dual") &&
        service.chargingMode === "FP"
      ) {
        selectorsToRender[1] = true;
      }
      // Render seats selector? (By seats)

      if (
        (service.pricingOption === "situational" ||
          service.pricingOption === "dual") &&
        service.chargingMode === "BS"
      ) {
        selectorsToRender[2] = true;
      }
    }
  });

  return selectorsToRender;
}
