import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const AdminHeaderSD = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  display: flex;
  position: relative;
  height: 16.5rem;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${mediaQueries.mobileL} {
    height: 12.2rem;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
`;

const AdminHeaderContentSD = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.blackboard};
  align-items: flex-start;
  justify-content: center;
  width: 30rem;
  height: 100%;
  color: ${Colors.white};
  padding-left: 4rem;
  padding-right: 4rem;
  img {
    height: 5.4rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 65%;
    img {
      height: 4rem;
    }
  }
`;

const AdminHeaderRightSD = styled.div`
  display: flex;
  flex: 1;

  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
`;

const HeaderAdminSubtitleSD = styled.div`
  font-size: 1.2rem;
  padding-top: 1rem;
`;

const AdminHeaderButtonsSD = styled.div`
  padding: 1rem;
`;

export {
  AdminHeaderSD,
  AdminHeaderContentSD,
  AdminHeaderRightSD,
  HeaderAdminSubtitleSD,
  AdminHeaderButtonsSD,
};
