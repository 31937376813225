import styled from "styled-components/macro";

const AppS = styled.div`
  height: 100%;
  margin: auto;
  font-family: "SourceSansPro-Regular";
  white-space: pre-wrap;
`;

export { AppS };
