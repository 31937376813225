import React from "react";
import ReportsSelectedTag from "./ReportsSelectedTag";
import {
  SelectedTagsSD,
  TagContentSD,
  ShadowSD,
  ContentSD,
} from "./ReportsSelectedTagStyles";
import { SubtitleSD, ReportsRowSD } from "../AdminReportsViewStyles";
import { ClearButtonSD } from "Theme/Styles";
import { withRouter } from "react-router-dom";
/*
HOOK: 
SelectedTags
Show the selected services or packages, show the cpqCode for each one
*/

function SelectedTags(props) {
  const { selectedTags, selectTag, reportItemType, clearAllSelected } = props;

  const renderTags = (selectedTags, selectTag) => {
    const renderedTags = [];
    selectedTags.forEach((tag) => {
      renderedTags.push(
        <TagContentSD key={tag._id}>
          <ReportsSelectedTag
            tagName={tag.cpqCode}
            selectTag={() => selectTag(tag._id, tag.cpqCode)}
          ></ReportsSelectedTag>
        </TagContentSD>
      );
    });
    return renderedTags;
  };

  const renderedTags = renderTags(selectedTags, selectTag);
  return (
    <SelectedTagsSD className={"SelectedTagsSD"}>
      <ReportsRowSD>
        <SubtitleSD>Selected {reportItemType}</SubtitleSD>
        {selectedTags.length === 0 ? null : (
          <ClearButtonSD
            onClick={() => {
              clearAllSelected();
            }}
          >
            Clear all
          </ClearButtonSD>
        )}
      </ReportsRowSD>
      <ReportsRowSD>
        <ShadowSD></ShadowSD>
        <ContentSD>
          {selectedTags.length === 0
            ? "No " + reportItemType + " selected"
            : renderedTags}
        </ContentSD>
      </ReportsRowSD>
    </SelectedTagsSD>
  );
}

export default withRouter(SelectedTags);
