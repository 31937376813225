import logoBb from "Assets/Icons/SalesIcons/PNG/Logo-BB.png";
import React from "react";
import { ButtonS } from "Theme/Styles";
import {
  SalesHeaderButtonsSD,
  SalesHeaderContentSD,
  SalesHeaderImgSD,
  SalesHeaderSD,
} from "./SalesHeaderStyles";

function SalesHeader({ goToAdmin, logoutSaga, currentUser }) {
  const authorizedRoles = [0, 1, 2, 4];
  return (
    <SalesHeaderSD className="SalesHeaderSD">
      <SalesHeaderContentSD className="SalesHeaderContentSD">
        <img src={logoBb} alt="logoBb" />
      </SalesHeaderContentSD>
      <SalesHeaderImgSD className="SalesHeaderImgSD">
        <SalesHeaderButtonsSD>
          {authorizedRoles.includes(currentUser.role) ? (
            <ButtonS
              buttonType="cancel"
              width={size("width")}
              height={size("height")}
              onClick={() => goToAdmin()}
            >
              GO TO ADMIN
            </ButtonS>
          ) : null}
        </SalesHeaderButtonsSD>
        <SalesHeaderButtonsSD>
          <ButtonS
            width={size("width")}
            height={size("height")}
            onClick={() => logoutSaga()}
          >
            LOGOUT
          </ButtonS>
        </SalesHeaderButtonsSD>
      </SalesHeaderImgSD>
    </SalesHeaderSD>
  );
}

function size(type) {
  if (window.screen.width >= 768) {
    if (type === "height") {
      return "4rem";
    } else if (type === "width") {
      return "auto";
    }
  } else {
    if (type === "width") {
      return "9rem";
    }
  }
}

export default SalesHeader;
