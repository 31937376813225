import React, { useState } from "react";
import { Select, Input } from "antd";
import { CircularButton } from "Components";
import Colors from "Theme/Colors";
import {
  ExtendedColumnSD,
  ExtendedRowSD,
  FormDescription,
} from "Theme/Styles/FormStyles";
import {
  ButtonSD,
  CollabSubtitleSD,
  ErrorMsgSD,
  InputContentSD,
  VisibilityContentSD,
} from "./CollateralsTabStyles";

/**
 * HOOK
 * Collaterals tab in package form
 */

export default function CollateralsTab({
  localization,
  onChangeMLField,
  selectedLanguage,
}) {
  // STATES
  // inputs: inputs in collaterals. As default it is only the visibility, not a link
  // errors: if there is any error in the collateral link
  const [inputs, setInputs] = useState({ visibility: "Everyone" });
  const [error, setError] = useState(false);

  // when edit a collateral link or visibility
  const editCollateral = (e, index, type) => {
    const newCollaterals = [...localization[selectedLanguage].collaterals];
    switch (type) {
      // if is a link, and does not have any visibility
      // ... set visibility as "Everyone" in the current collateral.
      // ... If there is no link, set visibility as ""
      case "link":
        if (e.target.value && !newCollaterals[index].visibility) {
          newCollaterals[index].visibility = "Everyone";
        } else if (!e.target.value && newCollaterals[index].visibility) {
          newCollaterals[index].visibility = "";
        }
        const linkText = e.target.value;
        newCollaterals[index].link = linkText;
        break;
      // if is the visibility, and is not undefined
      // set visibility
      case "visibility":
        let visibilityOption = e;
        if (visibilityOption === undefined) {
          visibilityOption = "";
        }
        newCollaterals[index].visibility = visibilityOption;
        break;
      default:
        break;
    }

    onChangeMLField("collaterals", newCollaterals);
  };

  // add a new collateral
  const addCollateral = (newCollateral) => {
    const newCollaterals = [
      ...localization[selectedLanguage].collaterals,
      newCollateral,
    ];
    onChangeMLField("collaterals", newCollaterals);
  };

  // remove a collateral
  const removeCollateral = (collateralIndex) => {
    const newCollaterals = localization[selectedLanguage].collaterals.filter(
      function (task, index) {
        return index !== collateralIndex;
      }
    );
    onChangeMLField("collaterals", newCollaterals);
  };

  // on try to add a new collateral, verify errors, if there is not, add the collateral
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (Object.entries(inputs).length < 3 || !inputs.name || !inputs.link) {
      setError(true);
    } else {
      addCollateral({
        ...inputs,
      });
      setInputs({ visibility: "Everyone" });
      setError(false);
    }
  };

  // on try to edit a collateral, update fields
  const handleChange = (e, type) => {
    if (type === "visibility") {
      setInputs((inputs) => ({
        ...inputs,
        visibility: e,
      }));
    } else {
      e.persist();
      setInputs((inputs) => ({
        ...inputs,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const renderedCollaterals = renderCollaterals(
    localization,
    onChangeMLField,
    selectedLanguage,
    editCollateral,
    addCollateral,
    removeCollateral,
    inputs,
    setInputs,
    handleChange,
    handleSubmit
  );

  return (
    <ExtendedColumnSD>
      {renderedCollaterals}
      {error ? <ErrorMsgSD>Please fill all the fields</ErrorMsgSD> : null}
    </ExtendedColumnSD>
  );
}

function placeholderInput(name) {
  if (name === "SOW") {
    return "Statement of Work";
  } else if (name === "Sales sheet") {
    return "Sales sheet";
  } else if (name === "Datasheet") {
    return "Datasheet";
  }
}

function renderCollaterals(
  localization,
  onChangeMLField,
  selectedLanguage,
  editCollateral,
  addCollateral,
  removeCollateral,
  inputs,
  setInputs,
  handleChange,
  handleSubmit
) {
  const renderedCollaterals = [];
  const { Option } = Select;
  const mandatoryIndex = [0, 1, 2, 4];
  localization[selectedLanguage].collaterals.forEach((collateral, index) => {
    if (collateral.name !== "Sales sheet") {
      const renderButton = !mandatoryIndex.includes(index);
      renderedCollaterals.push(
        <ExtendedRowSD key={"collateral" + index}>
          <CollabSubtitleSD
            size={"1.0667rem"}
            key={"collateral subtitle" + index}
          >
            {collateral.name}
          </CollabSubtitleSD>
          <InputContentSD key={"collateral input" + index}>
            <Input
              placeholder={placeholderInput(collateral.name)}
              value={collateral.link}
              onChange={(e) => {
                editCollateral(e, index, "link");
              }}
            />
          </InputContentSD>
          <VisibilityContentSD key={"collateral select" + index}>
            <Select
              placeholder="Visibility"
              onChange={(e) => {
                editCollateral(e, index, "visibility");
              }}
              value={collateral.visibility ? collateral.visibility : "Everyone"}
              disabled={collateral.link ? false : true}
            >
              <Option value={"Everyone"}>Everyone</Option>
              <Option value={"Service owner"}>Service owner</Option>
              <Option value={"GCX team"}>GCX team</Option>
            </Select>
          </VisibilityContentSD>
          {renderButton ? (
            <ButtonSD>
              <CircularButton
                size={18}
                color={Colors.primarySelected}
                iconName="minus"
                onClick={() => removeCollateral(index)}
              />
            </ButtonSD>
          ) : (
            <ButtonSD></ButtonSD>
          )}
        </ExtendedRowSD>
      );
    }
  });
  renderedCollaterals.push(
    <FormDescription key={"collateral description"}>
      Add a new collateral link (Press + icon)
    </FormDescription>
  );
  renderedCollaterals.push(
    <ExtendedRowSD key="colInput">
      <CollabSubtitleSD size={"1.0667rem"} key={"new collateral subtitle"}>
        <Input
          name="name"
          placeholder="Name"
          value={inputs.name || ""}
          onChange={(e) => {
            handleChange(e, "name");
          }}
          key={"new collateral input1"}
        />
      </CollabSubtitleSD>
      <InputContentSD>
        <Input
          name="link"
          placeholder="Link"
          value={inputs.link || ""}
          onChange={(e) => {
            handleChange(e, "link");
          }}
          key={"new collateral input2"}
        />
      </InputContentSD>
      <VisibilityContentSD key={"new collateral select"}>
        <Select
          onChange={(e) => {
            handleChange(e, "visibility");
          }}
          value={inputs.link && inputs.name ? inputs.visibility : "Everyone"}
          disabled={inputs.link && inputs.name ? false : true}
        >
          <Option value={"Everyone"}>Everyone</Option>
          <Option value={"Service owner"}>Service owner</Option>
          <Option value={"GCX team"}>GCX team</Option>
        </Select>
      </VisibilityContentSD>
      <ButtonSD>
        <CircularButton
          size={18}
          color={Colors.primaryActive}
          iconName="plus"
          onClick={(e) => {
            handleSubmit(e);
          }}
        />
      </ButtonSD>
    </ExtendedRowSD>
  );

  return renderedCollaterals;
}
