import ApiPackages from "Api/packages";
import ApiRequests from "Api/requests";
import { put, takeLatest, call } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import { toast } from "react-toastify";

// GET ADMIN PACKAGES
// params: page, limit, searchedText, sortBy, sortOrder, searchedLanguage
function* getPackagesSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "packageListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.getPackages, action);
    const { packages, pages, count } = response.data.data;
    yield put({
      type: "SAVE_PACKAGE_LIST",
      packageList: packages,
      count: count,
      pages: pages,
    });

    yield delay(300);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "packageListLoading",
    loadingValue: false,
  });
}

// Admin report, packages
function* getPackagesReport(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "reportsLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.getPackagesReport, action);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let documentName = "Packages" + date + ".xlsx";
    link.setAttribute("download", documentName);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "reportsLoading",
    loadingValue: false,
  });
}
// Sales list, packages
function* getSalesPackagesSaga(action) {
  try {
    const response = yield call(ApiPackages.getSalesPackages, action);
    if (response.data.data) {
      yield put({
        type: "SAVE_PACKAGE_LIST",
        packageList: response.data.data.packages,
      });
      yield put({
        type: "SAVE_PACKAGE_MAX_PRICING",
        maximumPackagePrice: response.data.data.maxPrice,
      });
      // if (
      //   Cookie.get("maxPrice") < response.data.data.maxPrice ||
      //   !Cookie.get("maxPrice")
      // ) {
      //   Cookie.set("maxPrice", response.data.data.maxPrice);
      // }
    }
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}

// Admin detail: all data of package
function* getPackageDetail(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "packageDetailLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.getPackage, action);
    yield delay(300);
    yield put({
      type: "SAVE_PACKAGE_DETAIL",
      packageId: action.id,
      packageDetail: response.data.data.package,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "packageDetailLoading",
    loadingValue: false,
  });
}

// Sales detail: all data of package
function* getSalesPackageDetail(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "packageDetailLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.getPackageDetail, action);
    yield delay(300);
    yield put({
      type: "SAVE_PACKAGE_DETAIL",
      packageId: action.id,
      packageDetail: response.data.data.package,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "packageDetailLoading",
    loadingValue: false,
  });
}
// Create package from admin
function* createPackageSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "createPackageLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.createPackage, action);
    yield delay(300);
    yield put({
      type: "SET_VALIDATION",
      validationName: "validPackageForm",
      validationValue: true,
    });
    yield delay(300);
    toast.success(response.data.data.message);
    yield put({
      type: "SET_VALIDATION",
      validationName: "validPackageForm",
      validationValue: false,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "createPackageLoading",
    loadingValue: false,
  });
}
// delete package from admin
function* deletePackageSaga(action) {
  try {
    const response = yield call(ApiPackages.deletePackage, action);
    toast.success(response.data.data.message);
    //  TODO: ready to put action
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}
// update package from admin, returns updated package
function* updatePackageSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "editPackageLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.updatePackage, action);
    if (response.data.data.package !== undefined) {
      yield put({
        type: "SAVE_UPDATED_PACKAGE",
        updatedPackage: response.data.data.package,
      });
    }
    if (response.data.data.request !== undefined) {
      yield put({
        type: "ACCEPT_UPDATE_PACKAGE_REQUEST",
        id: response.data.data.request,
      });
      yield put({
        type: "GET_PACKAGES_SAGA",
        page: 1,
        limit: 30,
        searchedText: [],
        selectedLanguage: "EN-US",
      });
    }
    yield put({
      type: "SET_VALIDATION",
      validationName: "validPackageForm",
      validationValue: true,
    });
    yield delay(300);
    toast.success(response.data.data.message); // TODO: must be response.data.data.message
    yield put({
      type: "SET_VALIDATION",
      validationName: "validPackageForm",
      validationValue: false,
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "editPackageLoading",
    loadingValue: false,
  });
}

// acording to item type (service or package) and according to method (create, update or delete)
function* acceptUpdatePackageRequest(action) {
  try {
    const response = yield call(ApiRequests.acceptUpdatePackage, action);
    toast.success(response.data.message);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}

export const packagesSagas = [
  takeLatest("GET_PACKAGES_SAGA", getPackagesSaga),
  takeLatest("GET_PACKAGE_DETAIL_SAGA", getPackageDetail),
  takeLatest("GET_SALES_PACKAGE_DETAIL_SAGA", getSalesPackageDetail),
  takeLatest("CREATE_PACKAGE_SAGA", createPackageSaga),
  takeLatest("DELETE_PACKAGE_SAGA", deletePackageSaga),
  takeLatest("UPDATE_PACKAGE_SAGA", updatePackageSaga),
  takeLatest("ACCEPT_UPDATE_PACKAGE_REQUEST", acceptUpdatePackageRequest),
  takeLatest("GET_PACKAGES_REPORT", getPackagesReport),
  takeLatest("GET_SALES_PACKAGES_SAGA", getSalesPackagesSaga),
];
