import { initialState } from "Redux/initialState";

const systemReducer = (state = initialState.system, action) => {
  switch (action.type) {
    case "SET_LOADING":
      let loadingState = { ...state.loadings };
      loadingState[action.loadingName] = action.loadingValue;
      return {
        ...state,
        loadings: loadingState,
      };
    // Each validation is a trigger or flag that indicates that the modal form must be closed
    case "SET_VALIDATION":
      let validationsState = { ...state.validations };
      validationsState[action.validationName] = action.validationValue;
      return {
        ...state,
        validations: validationsState,
      };
    // for sales, save maximumPackagePrice
    case "SAVE_PACKAGE_MAX_PRICING":
      let maximumPackagePrice = state.pricing.maximumPackagePrice;
      if (state.pricing.maximumPackagePrice === null) {
        console.log("entra en null packages");
        maximumPackagePrice = action.maximumPackagePrice;
      }
      return {
        ...state,
        pricing: {
          ...state.pricing,
          maximumPackagePrice: maximumPackagePrice,
        },
      };

    // for sales, save maximumServicePrice
    case "SAVE_SERVICE_MAX_PRICING":
      let maximumServicePrice = state.pricing.maximumServicePrice;
      if (state.pricing.maximumServicePrice === null) {
        console.log("entra en null services");
        maximumServicePrice = action.maximumServicePrice;
      }
      return {
        ...state,
        pricing: {
          ...state.pricing,
          maximumServicePrice: maximumServicePrice,
        },
      };

    // save sales menu tags
    case "SAVE_TAGS":
      return {
        ...state,
        tags: action.tags,
      };
    // save SSO login url to login
    case "SAVE_LOGIN_URL":
      return {
        ...state,
        loginURL: action.loginURL,
      };
    // save all languages, used as filter and used as localization
    case "SAVE_LANGUAGES":
      return {
        ...state,
        languages: action.languages,
      };

    // absolute pricing is calculated according to the max and minprice between packagelist and service list

    case "SAVE_FILTERS":
      return {
        ...state,
        filters: action.filters,
      };
    // save sales filters, for example, searchedTags, searchedText
    case "SAVE_SALES_FILTERS":
      return {
        ...state,
        salesFilters: action.filters,
      };

    default:
      return state;
  }
};

export default systemReducer;
