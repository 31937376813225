import React from "react";
import { Input } from "antd";
import { SearchInputSD } from "./SearchInputStyles";

const { Search } = Input;
/**
 * HOOK
 * Search Input, this component has the search bar
 */

export default function SearchInput({
  searchText,
  setSearchText,
  filterTable,
}) {
  return (
    <SearchInputSD>
      <Search
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onSearch={(value) => filterTable(value)}
      />
    </SearchInputSD>
  );
}
