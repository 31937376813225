import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PackageForm from "../NewPackageForm/PackageForm";
import userContext from "../../../../Context/user-context";

/**
 * HOOK
 * Create package container, calls the Package form. Has all the logic
 */

function CreatePackageContainer({ setVisible }) {
  const dispatch = useDispatch();
  // SAGA
  // obtain the list of all users who have any of the following roles: Administrator, Service Owner or Product Manager.
  // ... These users are saved in the store
  const getOwners = () => {
    dispatch({ type: "GET_OWNERS_SAGA" });
  };

  // SAGA
  // obtain all the diferent tags and languages.
  // ... These filters are saved in the store
  const getTags = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };

  // SAGA
  // obtain all the services
  // ... These services are saved in the store
  const getServices = () => {
    dispatch({ type: "GET_SERVICES_SAGA", searchedText: [] });
  };

  // SAGA
  // send the information of the created package to the backend
  const createPackageSaga = (newPackageInfo) => {
    dispatch({ type: "CREATE_PACKAGE_SAGA", newPackageInfo: newPackageInfo });
  };

  // we obtain all packages loading states
  const createPackageLoading = useSelector(
    (state) => state.system.loadings.createPackageLoading
  );

  // get all tags from the store
  const tags = useSelector((state) => state.system.tags);
  // get user roles from the store
  const roles = useSelector((state) => state.system.roles);
  // get all languages from the store
  const languages = useSelector((state) => state.system.languages);
  // get all services from the store
  const serviceList = useSelector((state) => state.service.serviceList);
  // get the current user (the sesion user) from the user context
  const currentUser = useContext(userContext);
  // get the owner list from the store
  const ownerList = useSelector((state) => state.users.ownerList);
  // get the mandatory fields in the package form
  const packageMandatoryFields = useSelector(
    (state) => state.system.mandatoryFields.package
  );

  const tagsArray = getTagsArray(tags);

  // STATES
  // packageValues: set default  packages form values
  const [packageValues] = useState({
    localization: {
      "EN-US": {
        description: "",
        internalNotes: "",
        summary: "",
        collaterals: [
          {
            name: "SOW",
            link: "",
            visibility: "Everyone",
          },
          {
            name: "Sales sheet",
            link: "",
            visibility: "Everyone",
          },
          {
            name: "Datasheet",
            link: "",
            visibility: "Everyone",
          },
        ],
      },
    },
    cpqCode: "",
    name: "",
    tags: [],
    owner: { _id: "" },
    productManager: { _id: "" },
    editors: [],
    inSfdc: true,
    salable: true,
    communicable: true,
    visibility: "Everyone",
    services: [],
    creator: {
      _id: currentUser._id,
    },
  });

  const createPackage = (newPackage) => {
    createPackageSaga(newPackage);
  };

  useEffect(() => {
    getOwners();
    getServices();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PackageForm
      ownerList={ownerList}
      tagList={tagsArray}
      type="create"
      roles={roles}
      languages={languages}
      packageValues={packageValues}
      setVisible={setVisible}
      serviceList={serviceList}
      submitPackage={createPackage}
      packageFormLoading={createPackageLoading}
      packageMandatoryFields={packageMandatoryFields}
    />
  );
}
// receives a tag object and returns an array of tags
function getTagsArray(tags) {
  let tagsArray = [];
  const tagsHeaders = Object.keys(tags);
  tagsHeaders.forEach((header) => {
    tagsArray = tagsArray.concat(tags[header]);
  });

  return tagsArray;
}

export default CreatePackageContainer;
