import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const FooterSD = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.firstGray};
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${Colors.white};
  padding: 2rem;
  z-index: 50;
`;

const FooterCenterSD = styled.div``;

const FooterInfoSD = styled.div`
  width: 30rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
`;

const StrongLinkSa = styled.a`
  display: contents;
  color: ${Colors.secondary};
`;

export { FooterSD, FooterCenterSD, FooterInfoSD, StrongLinkSa };
