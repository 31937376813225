import { Tabs } from "antd";
import React from "react";
import BasicTab from "./BasicTab";
import StatusTab from "./StatusTab";
import CollateralsTab from "./CollateralsTab/CollateralsTab";

/**
 * HOOK
 * Package Tabs, calls all the tabs in the forms
 */

export default function PackageFormTabs(props) {
  const {
    cpqCode,
    setCPQCode,
    owner,
    setOwner,
    editors,
    setEditors,
    ownerList,
    selectedLanguage,
    localization,
    onChangeMLField,
    tags,
    setTags,
    tagList,
    visibility,
    setVisibility,
    inSfdc,
    setInSfdc,
    salable,
    setSalable,
    communicable,
    setCommunicable,
    serviceList,
    selectedServices,
    setSelectedServices,
    productManager,
    setProductManager,
  } = props;

  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Basic" key="1">
        <BasicTab
          cpqCode={cpqCode}
          setCPQCode={setCPQCode}
          owner={owner}
          setOwner={setOwner}
          editors={editors}
          setEditors={setEditors}
          ownerList={ownerList}
          selectedLanguage={selectedLanguage}
          localization={localization}
          onChangeMLField={onChangeMLField}
          serviceList={serviceList}
          tags={tags}
          setTags={setTags}
          tagList={tagList}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          productManager={productManager}
          setProductManager={setProductManager}
        />
      </TabPane>
      <TabPane tab="Status" key="2">
        <StatusTab
          visibility={visibility}
          setVisibility={setVisibility}
          inSfdc={inSfdc}
          setInSfdc={setInSfdc}
          salable={salable}
          setSalable={setSalable}
          communicable={communicable}
          setCommunicable={setCommunicable}
        />
      </TabPane>
      <TabPane tab="Collaterals" key="3">
        <CollateralsTab
          localization={localization}
          onChangeMLField={onChangeMLField}
          selectedLanguage={selectedLanguage}
        />
      </TabPane>
    </Tabs>
  );
}
