import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { Styles } from "./PricingDetailStyles";
import { ColumnSD } from "Theme/Styles";
import { DetailSubsubtitleSD } from "Theme/DetailStyles";
import { formatPricing, calculateServicePricing } from "Helpers/PricingHelpers";
import { Tooltip, InputNumber } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function SeatsPricingCalculator({
  service,
  chargingMode,
  pricing,
  pricingOption,
  minimumSeats,
}) {
  const [calculatorParams, setCalculatorParams] = useState({
    seats: 1,
    tier: "T1",
  });
  const [calculatedPricing, setCalculatedPricing] = useState(
    calculateServicePricing(service, calculatorParams)
  );

  useEffect(() => {
    setCalculatedPricing(calculateServicePricing(service, calculatorParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricing, calculatorParams.seats, calculatorParams.tier]);

  return (
    <ColumnSD type="calculator" centered>
      <Styles.PricingRow>
        <DetailSubsubtitleSD>Pricing calculator</DetailSubsubtitleSD>
        <Tooltip
          title=" Calculate the total pricing by seats according to a number of seats, minimum seats
        and/or Tier"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Styles.PricingRow>

      {pricingOption !== "single" ? (
        <Styles.PricingRow>
          <div>Tier: </div>
          <div>
            <Select
              value={calculatorParams.tier}
              onChange={(value) => {
                setCalculatorParams({
                  ...calculatorParams,
                  tier: value,
                });
              }}
              style={{ marginLeft: "0.5rem" }}
            >
              <Option value={"T1"}>Tier 1</Option>
              <Option value={"T2"}>Tier 2</Option>
              <Option value={"T3"}>Tier 3</Option>
              <Option value={"T4"}>Tier 4</Option>
            </Select>
          </div>
        </Styles.PricingRow>
      ) : null}
      <Styles.PricingRow>
        <div>Seats : </div>
        <InputNumber
          width="8rem"
          min={minimumSeats}
          value={calculatorParams.seats}
          placeholder="seats"
          onChange={(value) => {
            setCalculatorParams({
              ...calculatorParams,
              seats: parseInt(value),
            });
          }}
          style={{ marginLeft: "0.5rem" }}
        ></InputNumber>
      </Styles.PricingRow>
      <Styles.PricingRow>
        <div>Price : {formatPricing(calculatedPricing.price)}</div>
      </Styles.PricingRow>
    </ColumnSD>
  );
}
