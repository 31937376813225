import React, { useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import Moment from "react-moment";
import salesPackageIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-package.png";
import salesServiceIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-service.png";
import Colors from "Theme/Colors";
import { Tag } from "Components/Common";
import { SC } from "./SalesCollapsibleTableStyles";
import SalesCollapsibleContent from "./SalesCollapsibleContent";
import { getChargingModeName, formatPricing } from "Helpers/PricingHelpers";

function SalesCollapsibleItem(props) {
  const { itemInfo, type, currentUser, selectedLanguage } = props;

  const [height, setHeight] = useState("0rem");
  const [isActive, setActive] = useState(false);
  const summary = itemInfo.localization[selectedLanguage]
    ? itemInfo.localization[selectedLanguage].summary
    : itemInfo.localization["EN-US"].summary;
  const myRef = useRef();

  const toggleAccordion = () => {
    setHeight(!isActive ? `auto` : `0rem`);
    setActive(!isActive);
  };

  function getRowColor(type) {
    switch (type) {
      case "service":
        return Colors.service;
      case "package":
        return Colors.package;
      case "request":
        return Colors.disabledGray;
      default:
        return Colors.selectedItem;
    }
  }

  function renderTags(tags) {
    const renderedTags = [];
    tags.forEach((tag) => {
      renderedTags.push(<Tag tagName={tag} key={tag}></Tag>);
    });
    return renderedTags;
  }

  function getPricingType(itemInfo) {
    switch (itemInfo.chargingMode) {
      case "FP":
        switch (itemInfo.pricingOption) {
          case "single":
            return "Single price";
          case "dual":
            return "Userband and tier based";
          case "situational":
            return "Userband based";
          default:
            return "Not specified";
        }
      case "TM":
        switch (itemInfo.pricingOption) {
          case "single":
            return "Rate per hour";
          case "dual":
            return "Role and tier based";
          case "situational":
            return "Role based";
          default:
            return "Not specified";
        }
      case "BS":
        switch (itemInfo.pricingOption) {
          case "single":
            return "Price per seat";
          case "dual":
            return "Seats range and tier based";
          case "situational":
            return "Seats range based";
          default:
            return "Not specified";
        }
      default:
        return "Not specified";
    }
  }

  function renderServicePricing() {
    const renderedPricing = [];
    if (itemInfo.estimationRequired) {
      renderedPricing.push(
        <div key={"ER" + itemInfo.cpqCode}>Pricing: Estimation required</div>
      );
    } else {
      renderedPricing.push(
        <div key={"CM" + itemInfo.cpqCode}>
          Charging mode:&nbsp;
          {getChargingModeName(itemInfo.chargingMode)}
        </div>
      );
      if (itemInfo.pricingOption === "single") {
        renderedPricing.push(
          <div key={"Pr" + itemInfo.cpqCode}>
            Pricing:&nbsp;
            {formatPricing(itemInfo.pricing[0][1]) +
              " (" +
              getPricingType(itemInfo) +
              ")"}
          </div>
        );
      } else {
        renderedPricing.push(
          <div key={"PT" + itemInfo.cpqCode}>
            Pricing type:&nbsp;
            {getPricingType(itemInfo)}
          </div>
        );
      }
    }

    return renderedPricing;
  }

  function renderPackagePricing() {
    let renderedPackagePricing = "";
    let chargingModeServices = [
      ...new Set(itemInfo["services"].map((service) => service.chargingMode)),
    ];

    for (const chargingMode of chargingModeServices) {
      renderedPackagePricing += getChargingModeName(chargingMode);
      if (
        chargingMode !== chargingModeServices[chargingModeServices.length - 1]
      ) {
        renderedPackagePricing += ",";
      }
    }
    return renderedPackagePricing;
  }

  const rowBorderColor = getRowColor(type);
  const panel = (
    <SC.CollapsibleTableItemPanel
      isActive={isActive}
      onClick={toggleAccordion}
      rowBorderColor={rowBorderColor}
    >
      <SC.ItemIconSD className="ItemIconSD">
        {type === "service" ? (
          <img src={salesServiceIcon} alt="Service icon" />
        ) : (
          <img src={salesPackageIcon} alt="Package icon" />
        )}
      </SC.ItemIconSD>
      <SC.ContentSD>
        <SC.SalesItemTop>
          <SC.TitleSD>{itemInfo.name}</SC.TitleSD>
          <SC.SalesTags>{renderTags(itemInfo.tags)}</SC.SalesTags>
        </SC.SalesItemTop>
        <SC.SubtitleSD>{itemInfo.cpqCode}</SC.SubtitleSD>
        {type === "service" ? (
          renderServicePricing()
        ) : (
          <div>Pricing:&thinsp; {renderPackagePricing()}</div>
        )}
        {currentUser.role !== 3 && itemInfo.visibility !== "Everyone" ? (
          <div>
            Visibility:&thinsp;
            {itemInfo.visibility}
          </div>
        ) : null}
        <SC.SubsubtitleSD className="SubsubtitleSD">
          Last update:&thinsp;
          <Moment>{itemInfo.lastModification}</Moment>
        </SC.SubsubtitleSD>
      </SC.ContentSD>
      <SC.CollapsiblItemIcon isActive={isActive}>
        <FaChevronDown size={10} fill={Colors.primaryActive} />
      </SC.CollapsiblItemIcon>
    </SC.CollapsibleTableItemPanel>
  );
  return (
    <SC.CollapsibleTableItem className={"Item" + itemInfo._id}>
      {panel}
      <SC.CollapsibleTableItemContent height={height} ref={myRef}>
        <SalesCollapsibleContent
          name={itemInfo.name}
          cpq={itemInfo.cpqCode}
          id={itemInfo._id}
          type={type}
          summary={summary}
        ></SalesCollapsibleContent>
      </SC.CollapsibleTableItemContent>
    </SC.CollapsibleTableItem>
  );
}

export default SalesCollapsibleItem;
