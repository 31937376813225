import React from "react";
import ServicePricing from "./ServicePricing";
import { DetailSubtitleSD, GeneralDetailSD } from "Theme/DetailStyles";
import { RowSD } from "Theme/Styles";
import PackagePricingTable from "./PackagePricingTable";
/**
 * HOOK
 * Package detail information
 */

export default function PackageDetailTable({ packageInfo }) {
  const renderServices = () => {
    const renderedServices = [];
    packageInfo.services.forEach((service) => {
      renderedServices.push(
        <ServicePricing service={service} key={service._id}></ServicePricing>
      );
    });
    return renderedServices;
  };

  const renderedServices = renderServices();
  return (
    <GeneralDetailSD flexDirection="column">
      <DetailSubtitleSD>Package pricing</DetailSubtitleSD>
      <div>
        The package pricing doesn't include services with Time & Materials
        pricing
      </div>
      <PackagePricingTable
        services={packageInfo.services}
      ></PackagePricingTable>
      <DetailSubtitleSD>Pricing of each service</DetailSubtitleSD>
      <RowSD>{renderedServices}</RowSD>
    </GeneralDetailSD>
  );
}
