import { CustomLoadingOverlay } from "Components";
import React from "react";
import NewPasswordForm from "./NewPasswordForm";
import { LandingViewSD, LandingCardSD } from "Theme/Styles";

/**
 * HOOK
 * New Password view, the view groups and sorts the visual components
 */

export default function LoginView({
  updatePasswordSaga,
  resetPasswordLoading,
}) {
  return (
    <LandingViewSD>
      <LandingCardSD>
        <CustomLoadingOverlay active={resetPasswordLoading}>
          <NewPasswordForm
            updatePasswordSaga={updatePasswordSaga}
          ></NewPasswordForm>
        </CustomLoadingOverlay>
      </LandingCardSD>
    </LandingViewSD>
  );
}
