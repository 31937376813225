import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminDetailView } from "Components/Common";
import { CustomLoadingOverlay } from "Components";
import { NoDetailSD } from "./RequestDetailStyles";

/**
 * HOOK
 * the request detail container
 * calls the service detail view or package detail view
 */

export default function RequestDetailContainer({ id, itemIndex }) {
  // we obtain all request loading states
  const requestDetailLoading = useSelector(
    (state) => state.system.loadings.requestDetailLoading
  );
  // get request detail, includes original al requested service or package
  const requestDetail = useSelector(
    (state) => state.requests.requestDetail[id]
  );

  // get all filters
  const filters = useSelector((state) => state.system.filters);

  // STATES
  // selectedLanguage: current selected language in the detail
  const [selectedLanguage, setLanguage] = useState("EN-US");

  const dispatch = useDispatch();
  // SAGA
  // obtains the etail of the current request
  const getRequestSaga = (id) => {
    dispatch({ type: "GET_REQUEST_DETAIL_SAGA", id: id });
  };

  // REDUCER
  // save filters in the store
  const saveFilters = (newFilters) => {
    dispatch({
      type: "SAVE_FILTERS",
      filters: { ...filters, ...newFilters },
    });
  };

  // SAGA
  // accept or reject the request, and send it to the database
  const requestActionSaga = (actionToDo) => {
    dispatch({
      type: "ACCEPT_OR_REJECT_REQUEST_SAGA",
      id: id,
      actionToDo: actionToDo,
      itemType: requestDetail.type,
      method: requestDetail.action,
    });
  };

  const renderedRequestDetail = renderRequestDetail(
    requestDetail,
    selectedLanguage,
    setLanguage,
    requestActionSaga,
    requestDetailLoading,
    filters,
    saveFilters,
    itemIndex
  );

  // when a new request is open, get information of that request
  useEffect(() => {
    getRequestSaga(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <CustomLoadingOverlay active={requestDetailLoading}>
      {renderedRequestDetail}
    </CustomLoadingOverlay>
  );
}

// To know what variables in localitation change in the requested
function changesLocalization(requested, original) {
  let localizatonObject;
  Object.entries(requested.localization).forEach((language) => {
    let localizatonLanguage;
    if (original.localization[language[0]]) {
      Object.entries(language[1]).forEach((element) => {
        let pair;

        if (
          JSON.stringify(original.localization[language[0]][element[0]]) ===
          JSON.stringify(requested.localization[language[0]][element[0]])
        ) {
          pair = { [element[0]]: false };
          localizatonLanguage = { ...localizatonLanguage, ...pair };
        } else {
          pair = { [element[0]]: true };
          localizatonLanguage = { ...localizatonLanguage, ...pair };
        }
      });
    } else {
      Object.entries(language[1]).forEach((element) => {
        let pair;
        pair = { [element[0]]: true };
        localizatonLanguage = { ...localizatonLanguage, ...pair };
      });
    }

    let localizationPair = { [language[0]]: localizatonLanguage };
    localizatonObject = { ...localizatonObject, ...localizationPair };
  });
  return localizatonObject;
}

// To know what variables change in the requested
// ... used to paint the changed variables in detail
function changesRequest(requested, original) {
  let changes;
  Object.entries(requested).forEach((element) => {
    let pair;
    if (element[0] !== "pendingApproval") {
      if (element[0] === "localization") {
        let localizationChanges = changesLocalization(requested, original);
        pair = { localization: localizationChanges };
        changes = { ...changes, ...pair };
      } else {
        if (
          JSON.stringify(original[element[0]]) ===
          JSON.stringify(requested[element[0]])
        ) {
          pair = { [element[0]]: false };
          changes = { ...changes, ...pair };
        } else {
          pair = { [element[0]]: true };
          changes = { ...changes, ...pair };
        }
      }
    }
  });
  return changes;
}

// call the respective detail (service or package)
function renderRequestDetail(
  requestDetail,
  selectedLanguage,
  setLanguage,
  requestActionSaga,
  requestDetailLoading,
  filters,
  saveFilters,
  itemIndex
) {
  if (requestDetail) {
    // choose what what information to show in detail
    // ... detail of the original or requested
    if (requestDetail.type === "Service") {
      const serviceInfo = requestDetail.requestedService
        ? requestDetail.requestedService
        : requestDetail.originalService;
      let changes;
      if (requestDetail.action === "Update") {
        changes = changesRequest(
          requestDetail.requestedService,
          requestDetail.originalService
        );
      } else {
        changes = {};
      }
      return (
        <AdminDetailView
          detailInfo={serviceInfo}
          detailType="service"
          selectedLanguage={selectedLanguage}
          setLanguage={setLanguage}
          isRequest
          requestActionSaga={requestActionSaga}
          status={requestDetail.status}
          detailLoading={requestDetailLoading}
          filters={filters}
          saveFilters={saveFilters}
          itemIndex={itemIndex}
          changes={changes}
        />
      );
    } else {
      const packageInfo = requestDetail.requestedPackage
        ? requestDetail.requestedPackage
        : requestDetail.originalPackage;
      let changes;
      if (requestDetail.action === "Update") {
        changes = changesRequest(
          requestDetail.requestedPackage,
          requestDetail.originalPackage
        );
      } else {
        changes = {};
      }

      return (
        <AdminDetailView
          detailInfo={packageInfo}
          detailType="package"
          selectedLanguage={selectedLanguage}
          setLanguage={setLanguage}
          isRequest
          requestActionSaga={requestActionSaga}
          status={requestDetail.status}
          detailLoading={requestDetailLoading}
          filters={filters}
          saveFilters={saveFilters}
          itemIndex={itemIndex}
          changes={changes}
        />
      );
    }
  } else {
    return <NoDetailSD>No detail</NoDetailSD>;
  }
}
