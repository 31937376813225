// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const SalesSelectedTagSD = styled.div`
  display: flex;
  padding: 0.3rem;
  background-color: ${(props) => props.backgroundColor};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${Colors.selectedText};
  border-radius: 0.3rem;
  padding: 0.2rem;
`;

const TagNameSD = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: center;
  justify-content: flex-start;
  font-family: "SourceSansPro-Semibold";
  font-size: 0.9rem;
`;

const TagCloseIconSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
`;

const TagSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${Colors.secondGray};
  padding: 1rem;
  /* margin-bottom: 0.5rem; */
  height: auto;
  font-family: "SourceSansPro-Semibold";
  font-size: 1.2rem;
  border-radius: 2.5rem;
  margin-left: 1.2rem;
  color: ${Colors.white};
  line-height: 0;
  @media ${mediaQueries.tablet} {
    font-size: 0.8rem;
    margin-left: 1rem;
  }
`;

const SelectedTagsSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.2rem;
  color: ${Colors.secondGray};
`;

const TagContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
`;

export {
  SalesSelectedTagSD,
  TagCloseIconSD,
  TagNameSD,
  TagSD,
  SelectedTagsSD,
  TagContentSD,
};
