import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const ResetPasswordSdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginCardTitleS = styled.div`
  font-family: "Georgia-Regular";
  font-size: 4rem;
  color: ${Colors.thirdGray};
  padding-top: 5%;
  padding-bottom: 5%;
`;

const LoginInputSdiv = styled.div`
  display: flex;
  position: relative;
`;
const LoginCardSubtitleS = styled.div`
  font-family: "Roboto-Regular";
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  padding-top: 4%;
  padding-bottom: 4%;

  @media ${mediaQueries.tablet} {
    font-size: 0.9rem;
  }

  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
  }
`;

const ButtonSdiv = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const ReturnLabelSdiv = styled.div`
  font-family: "Roboto-Regular";
  font-size: 1.7333rem;
  color: ${Colors.thirdGray};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  cursor: pointer;
`;
const ErrorSdiv = styled.div`
  display: flex;
  color: ${Colors.thirdGray};
  font-size: 0.9rem;
`;

const UserFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const LoginCardSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  padding: 2rem 7rem 2rem 7rem;

  @media ${mediaQueries.tablet} {
    min-width: 40rem;
    padding: 6rem;
  }

  @media ${mediaQueries.mobileL} {
    min-width: 30rem;
    padding: 4rem;
  }
`;

export {
  ResetPasswordSdiv,
  LoginInputSdiv,
  LoginCardTitleS,
  ReturnLabelSdiv,
  ButtonSdiv,
  ErrorSdiv,
  LoginCardSubtitleS,
  UserFormSD,
  LoginCardSD,
};
