import React, { useState, useRef, useEffect } from "react";
import { Select, Slider } from "antd";
import { FaChevronDown } from "react-icons/fa";
import Colors from "Theme/Colors";
import iconPrice from "Assets/Icons/SalesIcons/PNG/Icon_sales-price.png";
import { SC } from "./FiltersMenuStyles";
import { InputNumber } from "antd";

function PriceSection(props) {
  const {
    filterByTier,
    filterByPricing,
    salesFilters,
    saveSalesFilters,
    rangePricing,
    selectedItems,
  } = props;
  const [height, setHeight] = useState("auto");
  const [isActive, setActive] = useState(true);
  const myRef = useRef();

  const toggleAccordion = () => {
    setHeight(!isActive ? `auto` : `0rem`);
    setActive(!isActive);
  };
  const { Option } = Select;
  const [priceRange, setPriceRange] = useState([null, null]);

  const [absoluteMax, setAbsoluteMax] = useState([null]);

  useEffect(() => {
    if (priceRange[0] > priceRange[1]) {
      setPriceRange([priceRange[1], priceRange[0]]);
    } else {
      filterByPricing(priceRange[0], priceRange[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  useEffect(() => {}, [rangePricing]);

  useEffect(() => {
    if (absoluteMax > 0) {
      setPriceRange([rangePricing["absoluteMin"], absoluteMax]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absoluteMax]);

  useEffect(() => {
    if (rangePricing.maximumServicePrice && rangePricing.maximumPackagePrice) {
      if (
        selectedItems.services &&
        selectedItems.packages &&
        rangePricing.maximumServicePrice &&
        rangePricing.maximumPackagePrice
      ) {
        setAbsoluteMax(
          Math.max(
            rangePricing["maximumServicePrice"],
            rangePricing["maximumPackagePrice"]
          )
        );
      } else if (selectedItems.services && !selectedItems.packages) {
        setAbsoluteMax(rangePricing["maximumServicePrice"]);
      } else if (!selectedItems.services && selectedItems.packages) {
        setAbsoluteMax(rangePricing["maximumPackagePrice"]);
      }
    }
  }, [selectedItems, rangePricing]);
  return (
    <SC.FiltersMenuItem className={"Item price"}>
      <SC.FiltersMenuItemPanel isActive={isActive} onClick={toggleAccordion}>
        <SC.TagsHeaderIconSD>
          <img src={iconPrice} alt="icon Price" />
        </SC.TagsHeaderIconSD>
        <SC.TagsHeaderTextSD>Price</SC.TagsHeaderTextSD>
        <SC.CollapsiblItemIcon isActive={isActive}>
          <FaChevronDown size={10} fill={Colors.primaryActive} />
        </SC.CollapsiblItemIcon>
      </SC.FiltersMenuItemPanel>
      <SC.FiltersMenuItemContent height={height} ref={myRef}>
        <SC.PriceFilterSD>
          <SC.PriceFilterRowSD>
            <SC.PriceItemNameSD>Tier</SC.PriceItemNameSD>
            <SC.PriceItemSD>
              <Select
                value={salesFilters.tier}
                onChange={(value) => filterByTier(value)}
              >
                <Option value="T1">Tier 1</Option>
                <Option value="T2">Tier 2</Option>
                <Option value="T3">Tier 3</Option>
                <Option value="T4">Tier 4</Option>
              </Select>
            </SC.PriceItemSD>
          </SC.PriceFilterRowSD>
          <SC.PriceFilterRowSD>
            <SC.PriceItemNameSD>FTE User Bands</SC.PriceItemNameSD>
            <SC.PriceItemSD>
              <InputNumber
                value={salesFilters.userBand}
                min={0}
                onChange={(value) => {
                  saveSalesFilters({ userBand: value });
                }}
              />
            </SC.PriceItemSD>
          </SC.PriceFilterRowSD>
          <SC.PriceFilterRowSD>
            <SC.PriceItemSD>
              <Slider
                range
                // tooltipVisible
                // tooltipPlacement="top"
                min={rangePricing.absoluteMin}
                max={absoluteMax}
                value={priceRange}
                onAfterChange={(value) => {
                  filterByPricing(value[0], value[1]);
                }}
                onChange={(value) => {
                  setPriceRange(value);
                }}
              />
            </SC.PriceItemSD>
          </SC.PriceFilterRowSD>
          <SC.PriceFilterRowSD className="left pricing input">
            <SC.PriceItemNameSD width="auto">USD</SC.PriceItemNameSD>
            <SC.PriceItemSD>
              <InputNumber
                min={rangePricing.absoluteMin}
                max={absoluteMax - 1}
                value={priceRange[0]}
                onChange={(value) => {
                  setPriceRange([parseInt(value), priceRange[1]]);
                }}
              />
            </SC.PriceItemSD>
            <SC.PriceItemSD>
              <InputNumber
                min={rangePricing.absoluteMin + 1}
                max={absoluteMax}
                value={priceRange[1]}
                onChange={(value) => {
                  setPriceRange([priceRange[0], parseInt(value)]);
                }}
              />
            </SC.PriceItemSD>
          </SC.PriceFilterRowSD>
        </SC.PriceFilterSD>
      </SC.FiltersMenuItemContent>
    </SC.FiltersMenuItem>
  );
}

export default PriceSection;
