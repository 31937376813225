// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";

import { mediaQueries } from "Theme/MediaQueries";

const PaginationSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  @media ${mediaQueries.mobileL} {
    padding: 0rem;
    flex-direction: column;
  }
`;

const PagIconSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: ${Colors.primaryActive};
  cursor: pointer;
  @media ${mediaQueries.mobileL} {
    width: 2rem;
    height: 2rem;
  }
`;

const PagNumberSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? Colors.primaryActive : Colors.white};
  color: ${(props) => (props.selected ? Colors.white : Colors.primaryActive)};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.8rem;
  cursor: pointer;

  @media ${mediaQueries.mobileL} {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.2rem;
  }
`;

const PagNumberListSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
  }
`;

const PaginationLeftSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 0.2rem solid gray;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;
    border-right: 0rem;
  }
`;

const PaginationRightSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;
  }
`;
const PagTextSD = styled.div`
  font-size: 1.2rem;
  min-width: 9rem;
  padding-left: 1rem;
  @media ${mediaQueries.mobileL} {
    font-size: 0.9rem;
    padding-left: 0rem;
  }
`;

const PagInputSD = styled.div`
  input {
    width: 8rem;
  }
  @media ${mediaQueries.mobileL} {
    input {
      width: 8rem;
    }
  }
`;

export {
  PaginationSD,
  PagIconSD,
  PagNumberSD,
  PagNumberListSD,
  PaginationLeftSD,
  PaginationRightSD,
  PagTextSD,
  PagInputSD,
};
