import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const BigTitle = styled.div`
  font-size: 7.5rem;
  color: ${Colors.primaryDisabled};
  font-family: "SourceSansPro-Bold";
  @media ${mediaQueries.tablet} {
    font-size: 4.5rem;
  }
`;

export const Styles = {
  BigTitle,
};
