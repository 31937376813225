import axios from "axios";
import CookieService from "./cookieService";
import { history } from "Helpers/History";
import { toast } from "react-toastify";

const cookieService = CookieService.getService();

const instance = axios.create({
  headers: {
    common: {
      token: null,
    },
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = cookieService.getAccessToken();
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      cookieService.clearToken();
      history.push("/");
      toast.warning("Your session has expired");
    }
    return Promise.reject(error);
  }
);

const setAuthToken = () => {
  instance.defaults.headers.common["token"] = cookieService.getAccessToken();
};

const removeAuthToken = () => {
  instance.defaults.headers.common["token"] = null;
};

export { instance, setAuthToken, removeAuthToken };

export const ROOT_URL = process.env.REACT_APP_API_URL;
