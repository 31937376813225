import React from "react";
import {
  CollateralsLabelSD,
  CollateralsTitle,
  CollateralLinkSa,
} from "./CollateralsStyles";

export default function CollateralsLabel({ name, link, getReports }) {
  return (
    <div>
      {name === "SOW (Autogenerate)" || name === "WBS (Autogenerate)" ? (
        <CollateralLinkSa
          onClick={() => {
            getReports(name);
          }}
        >
          <CollateralsLabelSD>
            <CollateralsTitle>{name}</CollateralsTitle>
          </CollateralsLabelSD>
        </CollateralLinkSa>
      ) : (
        <CollateralLinkSa href={link} target="_blank">
          <CollateralsLabelSD>
            <CollateralsTitle>{name}</CollateralsTitle>
          </CollateralsLabelSD>
        </CollateralLinkSa>
      )}
    </div>
  );
}
