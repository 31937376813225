import React from "react";
import styled from "styled-components/macro";
import { ToastContainer } from "react-toastify";

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
    .Toastify__close-button {
      display: none;
    }
  }
  .Toastify__toast {
    background-color: #736582;
    border-radius: 0.3rem;
    color: white;
    font-weight: 800;
    padding-left: 2rem;
  }
  .Toastify__toast--error {
    background-color: #585160;
    border-radius: 0.3rem;
    color: white;
    font-weight: 800;
    padding-left: 2rem;
  }
  .Toastify__toast--warning {
    background-color: #332b3c;
    border-radius: 0.3rem;
    color: white;
    font-weight: 800;
    padding-left: 2rem;
  }
  .Toastify__toast--success {
    background-color: #5a3d77;
    border-radius: 0.3rem;
    color: white;
    font-weight: 800;
    padding-left: 2rem;
  }
  .Toastify__toast-body {
    font-family: "SourceSansPro-Semibold";
    font-size: 1.5rem;
  }
  .Toastify__progress-bar {
  }
`;
