// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";
import { leftFadeIn, topFadeIn } from "Theme/Styles/Animations";
import Colors from "Theme/Colors";
import { Affix } from "antd";

const SalesViewSD = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  @media ${mediaQueries.tablet} {
    padding: 0em;
  }

  @media ${mediaQueries.mobileL} {
    padding: 0em;
    align-items: left;
    .ant-select {
      font-size: 1.2rem;
      height: 2.8rem;
    }
    .ant-select-selector {
      align-items: center;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2.8rem;
    }
    .ant-input {
      font-size: 1.2rem;
    }
    .ant-input-suffix {
      font-size: 1.2rem;
    }
    .ant-input-affix-wrapper {
      padding: 0.2rem 1.1rem;
    }
  }
`;

const AffixSA = styled(Affix)`
  width: 100%;
  @media ${mediaQueries.mobileL} {
    .ant-affix {
      box-shadow: ${(props) =>
        props.shadowon === "false"
          ? null
          : "0.3rem 0.3rem 0.6rem 0rem rgba(255, 255, 255, 1)"};
      z-index: 5;
    }
  }
`;

const SalesFiltersSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  align-items: center;
`;

const FilterSectionSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 0.5rem 0rem;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};

  @media ${mediaQueries.tablet} {
    width: 100%;
    justify-content: flex-start;
  }

  @media ${mediaQueries.mobileL} {
    width: 100%;
    justify-content: flex-start;
  }
`;

const SalesFiltersRowSD = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  align-items: center;
  @media ${mediaQueries.tablet} {
    flex-direction: column-reverse;
  }

  @media ${mediaQueries.mobileL} {
    flex-direction: column-reverse;
  }
`;

// const SalesFiltersRowSD = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   margin-bottom: 0.5rem;
//   align-items: center;
//   @media ${mediaQueries.mobileL} {
//     justify-content: space-between;
//   }
// `;

const SalesContentSD = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  animation: ${topFadeIn} 1s;
  @media ${mediaQueries.tablet} {
    padding-top: 3rem;
    flex-wrap: nowrap;
  }

  @media ${mediaQueries.mobileL} {
    padding: 2rem 0.8rem 0rem 0.8rem;
    flex-wrap: wrap;
  }
`;

const SalesMenuTagsSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 1.2rem;
  width: 28rem;
  @media ${mediaQueries.tablet} {
    width: 20rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 100%;
  }
`;

const SalesDrawerImg = styled.div`
  position: absolute;
  z-index: 101;
  top: 4rem;
  left: -3.8rem;
  cursor: pointer;
`;

const LeftMenuSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 2rem 2rem 2rem;
  animation: ${leftFadeIn} 1s;
  @media ${mediaQueries.tablet} {
    padding: 0rem 2rem 2rem 2rem;
  }
  @media ${mediaQueries.mobileL} {
    padding: 0rem;
    width: 100%;
  }
`;

const SalesListSD = styled.div`
  display: flex;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${mediaQueries.tablet} {
    padding-left: 0rem;
    padding-right: 2rem;
  }

  @media ${mediaQueries.mobileL} {
    padding-top: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
    width: 100%;
    justify-content: flex-start;
  }
`;

const SalesListsHeaderSD = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  @media ${mediaQueries.tablet} {
    flex-direction: column;
    align-items: left;
    width: 100%;
  }
  @media ${mediaQueries.mobileL} {
    flex-direction: column;
    align-items: left;
    width: 100%;
    padding-top: 2rem;
  }
`;

const CreateSalesListSD = styled.div`
  position: absolute;
  right: 0;
`;
const TableContentSD = styled.div`
  display: flex;
  width: 100%;
  padding: "1rem 0rem";
`;

const SearchSD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  padding-right: 2rem;
  @media ${mediaQueries.tablet} {
    max-width: 100%;
    padding-right: 0rem;
  }
  @media ${mediaQueries.mobileL} {
    max-width: 100vw;
    padding-right: 0rem;
  }
`;

const SwitchContentSD = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: auto;
  flex-direction: row;
`;

const SwitchSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40rem;
  padding-right: 2rem;
  @media ${mediaQueries.tablet} {
    padding-right: 0rem;
  }
  @media ${mediaQueries.mobileL} {
    width: 100%;
    padding-right: 0rem;
    margin: 0.5rem;
  }
`;

const SwitchIconSD = styled.div`
  display: flex;
  padding-right: 0.5rem;
  img {
    height: 2rem;
  }
  @media ${mediaQueries.mobileL} {
    img {
      height: 1.8rem;
    }
  }
`;

const SwitchNameSD = styled.div`
  display: flex;
  max-width: 40rem;
  padding-right: 0.5rem;
  color: ${(props) =>
    props.itemType === "services" ? Colors.service : Colors.package};
  font-family: "SourceSansPro-Semibold";
  font-size: 1.6rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.4rem;
  }
`;

const FilterTagSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Semibold";
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  align-self: flex-start;
  width: 9rem;
  background-color: ${Colors.secondGray};
  color: ${Colors.white};
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  font-size: 1.6rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.3rem;
    width: 7.5rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.3rem;
  }
`;

const FilterIconSD = styled.div`
  display: flex;
  width: 2.5rem;
  padding: 0rem 0.5rem 0 0.5rem;
  img {
    height: 1.5rem;
  }
`;

const FilterTextSD = styled.div`
  display: flex;
`;

const ListButtonsSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  img {
    height: 2rem;
  }
  cursor: pointer;
  @media ${mediaQueries.tablet} {
    flex-direction: column;
  }
  @media ${mediaQueries.mobileL} {
    align-items: center;
    flex-direction: column;
  }
`;

const ListButtonSD = styled.div`
  display: flex;
  flex-direction: row;
  height: 2rem;
`;

export {
  SalesViewSD,
  SalesContentSD,
  SalesMenuTagsSD,
  LeftMenuSD,
  SalesListSD,
  SalesListsHeaderSD,
  CreateSalesListSD,
  TableContentSD,
  SearchSD,
  SwitchSD,
  SwitchContentSD,
  SwitchNameSD,
  FilterTagSD,
  FilterIconSD,
  FilterTextSD,
  ListButtonsSD,
  ListButtonSD,
  SwitchIconSD,
  SalesDrawerImg,
  AffixSA,
  SalesFiltersSD,
  SalesFiltersRowSD,
  FilterSectionSD,
};
