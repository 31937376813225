// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const SalesDrawerImg = styled.div`
  position: absolute;
  z-index: 101;
  top: 4rem;
  left: -3.8rem;
  cursor: pointer;
  img {
    width: 6rem;
  }
  @media ${mediaQueries.mobileL} {
    left: -2.6rem;
    img {
      width: 4rem;
    }
  }
`;

export { SalesDrawerImg };
