import React, { useEffect, useState } from "react";
import { Affix } from "antd";
import { Tag } from "Components/Common";
import Moment from "react-moment";
import {
  ContentSD,
  HeaderOptionSD,
  HeaderSD,
  LabelSD,
  RowSD,
  TableSD,
} from "./TableStyles";

/**
 * HOOK
 * Table to list services and packages
 * Receive a type (service or package)
 */

export default function Table({ headers, data, onClickRow }) {
  const [tableData, setTableData] = useState(data);
  const rows = renderRows(headers, tableData, onClickRow);
  const header = renderHeaders(headers, tableData, setTableData);
  const [basicHeight, basicHeightCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    basicHeightCount(document.getElementById("AffixSearch").clientHeight);
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <TableSD>
      <Affix offsetTop={basicHeight}>
        <HeaderSD>{header}</HeaderSD>
      </Affix>
      <ContentSD>{rows}</ContentSD>
    </TableSD>
  );
}

function renderHeaders(headers, tableData, setTableData) {
  const renderedHeaders = [];
  headers.forEach((header, index) => {
    renderedHeaders.push(
      <HeaderOptionSD
        className="HeaderOptionSD"
        divisor={index !== 0 ? 1 : 0}
        key={header.name}
        flex={header.flex}
      >
        {header.name}
      </HeaderOptionSD>
    );
  });
  return renderedHeaders;
}

function renderRows(headers, data, onClickRow) {
  const renderedRows = [];
  data.forEach((row, index) => {
    const type = row.services ? "package" : "service";
    const renderedLabels = renderLabels(headers, row);
    renderedRows.push(
      <RowSD
        key={index}
        type={type}
        onClick={() => {
          onClickRow(row);
        }}
      >
        {renderedLabels}
      </RowSD>
    );
  });

  return renderedRows;
}

function renderLabels(headers, row) {
  const renderedLabels = [];
  headers.forEach((header) => {
    switch (header.id) {
      case "tags":
        renderedLabels.push(
          <LabelSD key={header.id} flex={header.flex}>
            {renderTags(row[header.id])}
          </LabelSD>
        );
        break;
      case "description":
        renderedLabels.push(
          <LabelSD key={header.id} flex={header.flex}>
            {row.localization["EN-US"].description}
          </LabelSD>
        );
        break;
      case "summary":
        renderedLabels.push(
          <LabelSD key={header.id} flex={header.flex}>
            {row.localization["EN-US"].summary}
          </LabelSD>
        );
        break;
      case "lastModification":
        renderedLabels.push(
          <LabelSD key={header.id} flex={header.flex}>
            <Moment>{row.lastModification}</Moment>
          </LabelSD>
        );
        break;
      default:
        renderedLabels.push(
          <LabelSD key={header.id} flex={header.flex} color={header.color}>
            {row[header.id]}
          </LabelSD>
        );
        break;
    }
  });
  return renderedLabels;
}

function renderTags(tags) {
  const renderedTags = [];
  tags.forEach((tag) => {
    renderedTags.push(<Tag tagName={tag} key={tag}></Tag>);
  });
  return renderedTags;
}
