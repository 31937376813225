import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminReportsView from "./AdminReportsView";
import { toast } from "react-toastify";

/*
 * HOOK
 * Admin Reports Container, calls the Admin Reports View, has all the logic
 *
 */

export default function AdminReportsContainer() {
  const [searchedText, setSearchedText] = useState([]);

  // We create the functions that the Sagas execute
  const dispatch = useDispatch();

  //SAGA
  // obtains the current page services
  // ... These services are saved in the store
  const getServicesSaga = (filters) => {
    dispatch({
      type: "GET_SERVICES_SAGA",
      searchedText: searchedText,
      page: filters.page,
      limit: globalItemsPerPage,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
      selectedLanguage: filters.selectedLanguage,
    });
  };
  // SAGA
  // obtains the current page packages
  // ... These packages are saved in the store
  const getPackagesSaga = () => {
    dispatch({
      type: "GET_PACKAGES_SAGA",
      searchedText: searchedText,
      page: filters.page,
      limit: globalItemsPerPage,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
      selectedLanguage: filters.selectedLanguage,
    });
  };

  // SAGA
  // obtain all the diferent tags and languages.
  // ... These filters are saved in the store
  const getFilters = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };

  // REDUCER
  // Modify the value of a fields to define if they are selected or not
  const setField = (fieldName, fieldValue) => {
    dispatch({
      type: "SET_REPORT_FIELD",
      fieldName: fieldName,
      fieldValue: fieldValue,
    });
  };

  // Modify the selected items, it is an arrangement of cpq Codes
  const setCheckedItems = (checkedItems) => {
    dispatch({
      type: "SET_CHECKED_ITEMS",
      checkedItems,
    });
  };
  // the report can be of services or packages, by default, is services
  const [reportItemType, setReportItemType] = useState("services");

  // we get the list of languages ​​for the selector
  const languages = useSelector((state) => state.system.languages);
  // When a report is requested the loading is True
  const reportsLoading = useSelector(
    (state) => state.system.loadings.reportsLoading
  );
  // we get the list of services and packages to show them in the table
  const serviceList = useSelector((state) => state.service.serviceList);
  const packageList = useSelector((state) => state.packages.packageList);

  // for pagination
  const servicePages = useSelector((state) => state.service.pages);
  const packagePages = useSelector((state) => state.packages.pages);

  // initially the current number of pages is for services because initially the selected item is "Services"
  const [currentPages, setCurrentPages] = useState(
    useSelector((state) => state.service.pages)
  );
  // fields for the report on excel
  const fields = useSelector((state) => state.reports.fields);

  // list of fields to render them in each case
  const serviceFields = useSelector((state) => state.reports.serviceFields);
  const packageFields = useSelector((state) => state.reports.packageFields);
  // selected cpqCodes

  const checkedItems = useSelector((state) => state.reports.selectedItems);

  //all cpqCodes
  const allServices = useSelector((state) => state.reports.allServices);
  const allPackages = useSelector((state) => state.reports.allPackages);

  // add or remove cpqCode to selected items
  const checkItem = (_id, cpqCode) => {
    let newCheckedItems = [...checkedItems];
    let checked = newCheckedItems.find((o) => o._id === _id);
    if (checked) {
      newCheckedItems = newCheckedItems.filter(function (el) {
        return el._id !== _id;
      });
    } else {
      newCheckedItems.push({
        cpqCode,
        _id,
      });
    }
    setCheckedItems(newCheckedItems);
  };

  // Table loading flags, for table
  const serviceListLoading = useSelector(
    (state) => state.system.loadings.serviceListLoading
  );
  const packageListLoading = useSelector(
    (state) => state.system.loadings.packageListLoading
  );

  // how much items we can view in the table
  const globalItemsPerPage = useSelector(
    (state) => state.system.globalItemsPerPage
  );

  const [selectAll, setSelectAll] = useState(false);

  // selected filters to get a service List or a package List
  const [filters, setFilters] = useState({
    selectedLanguage: "EN-US",
    page: 1,
  });
  // text typed on keyboard in search bar

  const getCpqCodes = (type) => {
    if (type === "services") {
      dispatch({
        type: "GET_SERVICES_CPQ",
      });
    } else {
      dispatch({
        type: "GET_PACKAGES_CPQ",
      });
    }
  };
  // we obtain all the cpqCodes of services of packages and add them to checkedItems
  const selectAllFromButton = (checked) => {
    if (checked) {
      getCpqCodes(reportItemType);
    } else setCheckedItems([]);
    setSelectAll(checked);
  };
  // pagination

  // from language selector, change the selected filters
  const selectLanguage = (language) => {
    setFilters((filters) => ({
      ...filters,
      selectedLanguage: language,
    }));
  };
  /*
    sort the table, when filters change, there is an api request to 
    get the list of services or packages sorted
  */
  const onChangeQueryParams = (sortBy, sortOrder) => {
    setFilters((filters) => ({
      ...filters,
      sortBy: sortBy,
      sortOrder: sortOrder,
    }));
  };
  /*
    pagination, select a page, when filters change, there is an api request to 
    get the list of services or packages sorted
    */
  const selectPage = (index) => {
    if (index >= 1 && index <= currentPages) {
      setFilters((filters) => ({ ...filters, page: index }));
    }
    // setSelectAll(false);
  };

  // remove all selected items
  const clearAllSelected = () => {
    setCheckedItems([]);
  };

  /*
    from item type selector,
    when filters change, there is an api request to get the list of services or packages sorted
  */
  useEffect(() => {
    if (reportItemType === "services") {
      getServicesSaga(filters);
    } else if (reportItemType === "packages") {
      getPackagesSaga(filters);
    }
    setSelectAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, reportItemType, searchedText]);

  // if report type changes, we remove all selected items and set the number of page
  // depending on the selected report type
  useEffect(() => {
    if (reportItemType === "services") {
      setCurrentPages(servicePages);
    } else {
      setCurrentPages(packagePages);
    }
    // setSelectAll(false);

    setCheckedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportItemType]);

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // in case of clicking the download report button
  const getItemsReport = () => {
    if (checkedItems.length === 0) {
      toast.error("Please select at least one item");
    } else {
      if (reportItemType === "services") {
        getServicesReport();
      } else {
        getPackagesReport();
      }
    }
  };
  //SAGA
  // obtains the services report
  const getServicesReport = () => {
    dispatch({
      type: "GET_SERVICES_REPORT",
      itemList: checkedItems,
      flagOwner: fields.owner.value,
      flagProductManager: fields.productManager.value,
      flagEditors: fields.editors?.value,
      flagSummary: fields.summary.value,
      flagDescription: fields.description.value,
      flagInternalNotes: fields.internalNotes.value,
      flagTags: fields.tags.value,
      flagVisibility: fields.visibility.value,
      flagInSdfc: fields.inSdfc?.value,
      flagSellable: fields.sellable.value,
      flagCommunicable: fields.communicable.value,
      flagObjective: fields.objective.value,
      flagBenefits: fields.benefits.value,
      flagAudience: fields.audience.value,
      flagScope: fields.scope?.value,
      flagTasks: fields.tasks?.value,
      flagServiceType: fields.serviceType.value,
      flagThirdPartyDelivery: fields.thirdPartyDelivery?.value,
      flagArtifacts: fields.artifacts?.value,
      flagPricingType: fields.pricingType.value,
      flagRenewable: fields.renewable?.value,
      flagSOW: fields.SOW.value,
      flagDatasheet: fields.datasheet.value,
      flagSalesSheet: fields.salesSheet?.value,
      flagAgWBS: fields.agWBS?.value,
      flagAgSOW: fields.agSOW.value,
      language: filters.selectedLanguage,
    });
  };
  //SAGA
  // obtains the packages report
  const getPackagesReport = () => {
    dispatch({
      type: "GET_PACKAGES_REPORT",
      itemList: checkedItems,
      flagOwner: fields.owner.value,
      flagProductManager: fields.productManager.value,
      flagEditors: fields.editors?.value,
      flagSummary: fields.summary.value,
      flagDescription: fields.description.value,
      flagInternalNotes: fields.internalNotes.value,
      flagServices: fields.services.value,
      flagTags: fields.tags.value,
      flagVisibility: fields.visibility.value,
      flagInSdfc: fields.inSdfc?.value,
      flagSellable: fields.sellable.value,
      flagCommunicable: fields.communicable.value,
      flagSOW: fields.SOW.value,
      flagDatasheet: fields.datasheet.value,
      flagSalesSheet: fields.salesSheet?.value,
      language: filters.selectedLanguage,
    });
  };

  useEffect(() => {
    const currentLenght =
      reportItemType === "services" ? allServices.length : allPackages.length;
    if (currentLenght !== checkedItems.length || currentLenght === 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  return (
    <AdminReportsView
      //filters
      filters={filters}
      reportItemType={reportItemType}
      setReportItemType={setReportItemType}
      selectLanguage={selectLanguage}
      languages={languages}
      searchedText={searchedText}
      setSearchedText={setSearchedText}
      //report
      reportsLoading={reportsLoading}
      clearAllSelected={clearAllSelected}
      checkItem={checkItem}
      checkedItems={checkedItems}
      getItemsReport={getItemsReport}
      // fields
      fields={fields}
      setField={setField}
      serviceFields={serviceFields}
      packageFields={packageFields}
      //table
      selectAll={selectAll}
      selectAllFromButton={selectAllFromButton}
      onChangeQueryParams={onChangeQueryParams}
      serviceList={serviceList}
      packageList={packageList}
      serviceListLoading={serviceListLoading}
      packageListLoading={packageListLoading}
      selectPage={selectPage}
      currentPages={currentPages}
    />
  );
}
