import { CustomLoadingOverlay } from "Components";
import React, { useState } from "react";
import { LandingCardSD, LandingCardTitleS } from "Theme/Styles";
import ManualLoginForm from "./ManualLoginForm";
import {
  LoginCardSubtitleS,
  LoginSSOSA,
  LoginRowSD,
  LoginCardTextSD,
  ButtonSB,
} from "./MainLoginCardStyles";

/**
 * HOOK
 * Main login card
 */

export default function MainLoginCard({
  loginSaga,
  loginValues,
  loginLoading,
  togglLoginCard,
  loginURL,
  rememberMe,
  changeRememberMe,
}) {
  // STATES
  // showForm: to show or hide manual login form
  const [showForm, togglForm] = useState(false);

  return (
    <LandingCardSD>
      <CustomLoadingOverlay active={loginLoading}>
        <LandingCardTitleS>Excelsior</LandingCardTitleS>
        <LoginCardSubtitleS>
          Internal Consulting Service Catalog
        </LoginCardSubtitleS>
        <LoginCardTextSD>Please select your user type</LoginCardTextSD>
        <LoginRowSD className="LoginRowSD">
          <LoginSSOSA href={loginURL}>
            <ButtonSB>BLACKBOARD SSO</ButtonSB>
          </LoginSSOSA>
          <ButtonSB showForm={showForm} onClick={() => togglForm(!showForm)}>
            EXTERNAL
          </ButtonSB>
        </LoginRowSD>
        {showForm ? (
          <LoginRowSD className="LoginRowSD">
            <ManualLoginForm
              togglLoginCard={togglLoginCard}
              loginSaga={loginSaga}
              loginValues={loginValues}
              loginLoading={loginLoading}
              rememberMe={rememberMe}
              changeRememberMe={changeRememberMe}
            />
          </LoginRowSD>
        ) : null}
      </CustomLoadingOverlay>
    </LandingCardSD>
  );
}
