// validate if collateral link is correct
export function validURL(str) {
  const pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  if (pattern.test(str)) {
    return true;
  } else {
    return false;
  }
}

// convert lowercase character string
export const toLower = function (x) {
  return x.toLowerCase();
};

// the next three function are used to make a deep copy
// ... of some array or object
export function deep(value) {
  if (typeof value !== "object" || value === null) {
    return value;
  }
  if (Array.isArray(value)) {
    return deepArray(value);
  }
  return deepObject(value);
}

function deepObject(oldObject) {
  const result = {};
  Object.keys(oldObject).forEach((key) => {
    const value = oldObject[key];
    result[key] = deep(value);
  }, {});
  return result;
}

function deepArray(collection) {
  return collection.map((value) => {
    return deep(value);
  });
}

export function getChargingModeName(chargingMode) {
  switch (chargingMode) {
    case "FP":
      return "Fixed price - User band";
    case "TM":
      return "Roles";
    case "BS":
      return "By seats";
    default:
      return "Not specified";
  }
}
