import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import loginBackground from "Assets/Images/loginBackground.jpg";
import { mediaQueries } from "Theme/MediaQueries";

const LandingViewSD = styled.div`
  display: flex;
  min-height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${loginBackground});
  background-size: cover;
  padding-left: 3rem;
  padding-right: 3rem;
  @media ${mediaQueries.tablet} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media ${mediaQueries.mobileL} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const LandingCardSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: auto;
  background: white;
  padding: 4rem;
  font-size: 1.8rem;
  input,
  .ant-input-password {
    font-family: "SourceSansPro-Regular";
    font-size: 1.2rem;
    color: ${Colors.thirdGray};
    padding: 0.5rem;
    width: 100%;
    border: none;
    border-bottom: 0.3rem solid;
    border-color: ${Colors.disabledGray};
    :focus {
      outline: none;
      border-color: ${Colors.secondGray};
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 1.2rem;
      color: ${Colors.fourthGray};
      opacity: 1; /* Firefox */
      height: 10rem;
    }

    ::-webkit-input-placeholder {
      line-height: normal;
    }
  }
  @media ${mediaQueries.tablet} {
    width: 70%;
    font-size: 1.4rem;
    padding: 3rem;
  }

  @media ${mediaQueries.mobileL} {
    width: 90%;
  }
`;

const LandingCardTitleS = styled.div`
  font-size: 3rem;
  color: ${Colors.thirdGray};
  font-family: "SourceSansPro-Semibold";
  @media ${mediaQueries.tablet} {
    font-size: 2.5rem;
  }
`;

const ColumnSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${(props) => (props.centered ? "center" : "flex-start")};
  justify-content: center;
  padding: ${(props) => (props.padding ? props.padding : "none")};
  @media ${mediaQueries.mobileL} {
    margin-top: ${(props) => (props.type === "calculator" ? "1rem" : "none")};
  }
`;

const RowSD = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : "100%")};
  flex-wrap: wrap;
  align-items: ${(props) => (props.centered ? "center" : "flex-start")};
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "none"};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "none"};
  padding: ${(props) => (props.padding ? props.padding : "none")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;

const SubtitleSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Semibold";
  font-size: 1.6rem;
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0rem")};
  @media ${mediaQueries.tablet} {
    font-size: 1.4rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.3rem;
  }
`;

const PricingSubtitleSD = styled.div`
  font-family: "SourceSansPro-Semibold";
  font-size: 1.4rem;
  width: 100%;
  word-break: break-all;
  margin-bottom: ${(props) => (props.marginBottom ? "0.8rem" : "0rem")};
  color: ${Colors.primaryText};
  flex: ${(props) => (props.flex ? props.flex : "auto")};
  text-align: ${(props) => (props.align ? props.align : "start")};
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    flex: ${(props) => (props.flex ? 0.5 : "auto")};
  }
`;

const LoginFormS = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoginInputS = styled.input`
  font-family: "SourceSansPro-Regular";
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  padding: 0.5rem;
  width: 100%;
  border: none;
  border-bottom: 0.3rem solid;
  border-color: ${Colors.disabledGray};
  :focus {
    outline: none;
    border-color: ${Colors.secondGray};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 1.2rem;
    color: ${Colors.fourthGray};
    opacity: 1; /* Firefox */
    height: 10rem;
  }

  ::-webkit-input-placeholder {
    line-height: normal;
  }
`;

const ButtonS = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : "1")};
  min-width: ${(props) => (props.width ? props.width : "auto")};
  background-color: ${(props) =>
    props.buttonType === "cancel" ? Colors.white : Colors.primaryActive};
  color: ${(props) =>
    props.buttonType === "cancel" ? Colors.primaryActive : Colors.white};
  border: 0.1rem solid
    ${(props) =>
      props.buttonType === "cancel" ? Colors.primaryActive : "transparent"};
  border-radius: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  :focus {
    outline: none;
  }

  :before {
    content: "";
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 0rem;
    height: 100%;
    background: rgb(0, 0, 0, 0.2);
    /* transition: all 1s ease; */
  }

  :hover:before {
    width: 100%;
  }
  a {
    color: white;
  }

  @media ${mediaQueries.mobileL} {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
`;

const SectionTitleSD = styled.div`
  font-family: "SourceSansPro-Bold";
  color: ${Colors.blackboard};
  display: flex;
  width: 100%;
  font-weight: 600;
  font-size: 4rem;
  @media ${mediaQueries.mobileL} {
    font-size: 2.6667rem;
  }
`;

const StyledTableSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const FormSubtitleSD = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  min-width: 6rem;
  justify-content: flex-start;
  align-items: center;
  font-family: "SourceSansPro-Regular";
  font-size: ${(props) => (props.size ? props.size : "1.2rem")};
  padding-top: ${(props) => (props.top ? props.top : "0.5rem")};
  padding-bottom: ${(props) => (props.bottom ? props.bottom : "0.3rem")};
  color: ${Colors.primaryText};
`;

const DetailTitleSD = styled.div`
  font-family: "SourceSansPro-Bold";
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  width: 100%;
  font-weight: 600;
  font-size: 4rem;
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  @media ${mediaQueries.tablet} {
    font-size: 2.6667rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.5rem;
    justify-content: center;
  }
`;

const DetailSubtitleSD = styled.div`
  font-family: "SourceSansPro-Semibold";
  flex: 1.7;
  display: flex;
  font-weight: 600;
  font-size: 1.4rem;
  color: ${Colors.primaryText};
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    flex: 0.5;
  }
`;

const DetailDescrSD = styled.div`
  font-family: "SourceSansPro-ExtraLight";
  display: flex;
  flex: 3;
  width: 100%;
  padding-left: 2rem;
  font-size: 1.4rem;
  word-break: ${(props) =>
    props.type === "owner" ? "break-all" : "break-word"};
  font-weight: 400;
  text-align: justify;
  color: ${Colors.primaryText};
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    flex: 1;
    word-break: break-word;
    padding-left: 1rem;
  }
`;

const HighlightDescrSD = styled.p`
  background-color: ${(props) => (props.mark ? "#70DF84" : "none")};
  color: ${(props) => (props.mark ? Colors.primaryText : "auto")};
  margin-bottom: ${(props) => (props.noMarginBottom ? "0rem" : "auto")};
`;

const ChecksRowSD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem;
`;

const PricingSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const PricingItemSD = styled.div`
  display: flex;
  width: 6rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  @media ${mediaQueries.tablet} {
    margin-right: 0.4rem;
  }
`;

const PricingRowSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1rem;
`;

const PricingRowNameSD = styled.div`
  display: flex;
  width: 10rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: flex-start;
`;

const PricingColumnSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 6rem;
  font-size: 0.9rem;
  align-items: center;
  justify-content: flex-start;
  margin-right: 1rem;
`;

const PricingInputSD = styled.div`
  padding-left: 1rem;
  width: 5rem;
  InputNumber {
    width: 5rem;
  }
  input {
    width: auto;
    font-size: 0.9rem;
  }
`;

const PricingColumnTitleSD = styled.div`
  width: 8rem;
  font-size: 0.9rem;
  margin-left: 1rem;
`;

const RequestButtonsSD = styled.div`
  display: flex;
  background-color: ${Colors.detail};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media ${mediaQueries.mobileL} {
    justify-content: space-between;
    padding: 0rem 3rem 2rem;
  }
`;

const ButtonContentSD = styled.div`
  padding: 1rem;
  @media ${mediaQueries.mobileL} {
    padding: 0rem;
  }
`;

const RequestMessageSD = styled.div`
  background-color: ${Colors.primaryText};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SourceSansPro-Bold";
  font-size: 2rem;
  color: ${Colors.white};
  padding: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
`;

const BackgroundSD = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #262626;
`;

const HeaderBackgroundSD = styled.div`
  background-image: url(${(props) => props.image});
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  img {
    height: auto;
    transform: translateY(-50);
  }
`;

const ClearButtonSD = styled.div`
  color: ${Colors.primaryActive};
  font-size: 1.2rem;
  cursor: pointer;
  white-space: nowrap;
`;

const ModalFooterSD = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${Colors.secondaryBorder};
  padding: 1rem 0rem;
  width: 100%;
`;

const LabelSubtitleSD = styled.div`
  width: 30%;
  font-family: "SourceSansPro-Semibold";
`;

const StrongLinkSa = styled.a`
  display: contents;
  color: ${Colors.secondary};
`;

const FilterLabelSD = styled.div`
  min-width: 100px;
  padding: 5px;
  font-family: "SourceSansPro-Semibold";
  align-items: center;
`;

export {
  RowSD,
  ColumnSD,
  LoginFormS,
  LoginInputS,
  ButtonS,
  SectionTitleSD,
  StyledTableSD,
  SubtitleSD,
  FormSubtitleSD,
  DetailTitleSD,
  DetailSubtitleSD,
  DetailDescrSD,
  ChecksRowSD,
  PricingSD,
  PricingRowSD,
  PricingRowNameSD,
  PricingInputSD,
  PricingColumnSD,
  PricingItemSD,
  PricingColumnTitleSD,
  RequestButtonsSD,
  ButtonContentSD,
  RequestMessageSD,
  BackgroundSD,
  HeaderBackgroundSD,
  HighlightDescrSD,
  PricingSubtitleSD,
  ClearButtonSD,
  ModalFooterSD,
  LabelSubtitleSD,
  LandingViewSD,
  LandingCardSD,
  LandingCardTitleS,
  StrongLinkSa,
  FilterLabelSD,
};
