import styled from "styled-components/macro";
import Colors from "Theme/Colors";

// TODO: COLORS
const PackageFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const TagsSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LanguagePopoverSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LanguageSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  :hover {
    background-color: ${Colors.primarySelected};
    color: ${Colors.white};
  }
`;

const TagSD = styled.div`
  padding: 0.2rem;
`;

const PackageFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
`;

const ButtonSD = styled.div`
  display: flex;
`;
const MissingSD = styled.div`
  display: flex;
  padding: 2rem;
  color: red;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.5rem;
    color: red;
  }
`;

export {
  PackageFormSD,
  TagsSD,
  TagSD,
  PackageFormTabsSD,
  ButtonSD,
  LanguageSD,
  LanguagePopoverSD,
  MissingSD,
};
