// this is the component called by the route "/Login" of the main file "App.js"

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceForm from "../NewServiceForm/ServiceForm";

function EditServiceContainer({ setVisible, id, itemIndex }) {
  const dispatch = useDispatch();
  const getOwners = () => {
    dispatch({ type: "GET_OWNERS_SAGA" });
  };

  const getTags = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };

  const updateServiceSaga = (updatedServiceInfo, id) => {
    dispatch({
      type: "UPDATE_SERVICE_SAGA",
      updatedServiceInfo: updatedServiceInfo,
      id: id,
      itemIndex,
    });
  };

  useEffect(() => {
    getOwners();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tags = useSelector((state) => state.system.tags);
  const roles = useSelector((state) => state.system.roles);
  const ownerList = useSelector((state) => state.users.ownerList);
  const languages = useSelector((state) => state.system.languages);
  const serviceInfo = useSelector((state) => state.service.servicesDetail[id]);
  const userBands = useSelector((state) => state.system.userBands);
  const seats = useSelector((state) => state.system.seats);
  const tagsArray = getTagsArray(tags);
  const editServiceLoading = useSelector(
    (state) => state.system.loadings.editServiceLoading
  );

  const serviceMandatoryFields = useSelector(
    (state) => state.system.mandatoryFields.service
  );

  const updateService = (newService) => {
    updateServiceSaga(newService, id);
  };

  // const predefinedProductManager = () => {
  //   let copyServiceInfo = serviceInfo;
  //   let ownerInList = findUserInOwners(copyServiceInfo.productManager);
  //   if (!ownerInList) {
  //     copyServiceInfo.productManager = "";
  //   }
  //   setServiceInfo(copyServiceInfo);
  // };

  return (
    <ServiceForm
      ownerList={ownerList}
      tagList={tagsArray}
      roles={roles}
      type="edit"
      languages={languages}
      serviceValues={serviceInfo}
      setVisible={setVisible}
      pricing={serviceInfo.pricing}
      setPricing={updateService}
      submitService={updateService}
      serviceFormLoading={editServiceLoading}
      serviceMandatoryFields={serviceMandatoryFields}
      userBands={userBands}
      seats={seats}
    />
  );
}

function getTagsArray(tags) {
  let tagsArray = [];
  const tagsHeaders = Object.keys(tags);
  tagsHeaders.forEach((header) => {
    tagsArray = tagsArray.concat(tags[header]);
  });

  return tagsArray;
}

export default EditServiceContainer;
