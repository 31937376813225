import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminRequestsView from "./AdminRequestsView";

/**
 * HOOK
 * This is the component called by the route "/admin/requests" of the main file "App.js"
 * calls the Admin Requests View
 */

export default function AdminRequestsContainer() {
  // we obtain all request loading states
  const requestListLoading = useSelector(
    (state) => state.system.loadings.requestListLoading
  );

  // get the amount of request allowed per page
  const globalItemsPerPage = useSelector(
    (state) => state.system.globalItemsPerPage
  );

  // get the total amount of pages in requests pagination
  const pages = useSelector((state) => state.requests.pages);

  const requestList = useSelector((state) => state.requests.requestList);

  // STATES
  // searchedText: array with searched texts (words, phrases, anything put in the search box)
  // filters: filters different to the searched text in the search box, in the requests page
  // ... page: page in pagination, 1 is the default value
  const [searchedText, setSearchedText] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
  });

  const dispatch = useDispatch();
  // SAGA
  // obtains the requests for the current page, base on filters
  const getRequestsSaga = () => {
    dispatch({
      type: "GET_REQUESTS_SAGA",
      searchedText: filters.searchedText,
      page: filters.page,
      limit: globalItemsPerPage,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  // add new filters in filters state (or update them if they already exists)
  // sortBy: save the key by which the request table will be sorted (e.g cpq, owner, etc)
  // sortOrder: boolean value. 1 for ascending sorting or -1 for descending sorting
  const onChangeQueryParams = (sortBy, sortOrder) => {
    setFilters((filters) => ({
      ...filters,
      sortBy: sortBy,
      sortOrder: sortOrder,
    }));
  };

  // pagination, select a page. When filters change, there is an api request to
  // ... get the list of sorted requests, base on these filters
  const selectPage = (index) => {
    if (index >= 1 && index <= pages) {
      setFilters((filters) => ({ ...filters, page: index }));
    }
  };

  // when filters changes, get request list for the current page
  useEffect(() => {
    getRequestsSaga();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchedText]);

  // when const pages changes, set page filter in 1
  useEffect(() => {
    setFilters((filters) => ({ ...filters, page: 1 }));
  }, [pages]);

  return (
    <AdminRequestsView
      pages={pages}
      selectedPage={filters.page}
      selectPage={selectPage}
      requestList={requestList}
      requestListLoading={requestListLoading}
      searchedText={searchedText}
      setSearchedText={setSearchedText}
      onChangeQueryParams={onChangeQueryParams}
      filters={filters}
    />
  );
}
