import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "../UserForm/UserForm";
import userContext from "Context/user-context";

/**
 * HOOK
 * Update User Container, calls the User form, has all the logic
 */

function UpdateUserContainer(props) {
  const { setVisible, userInfo, roles, setSearchText } = props;
  // obtain the current user through the context
  const currentUser = useContext(userContext);
  // we obtain all user form loading states
  const editUserLoading = useSelector(
    (state) => state.system.loadings.editUserLoading
  );

  // STATES
  // userValues: info of current user values
  const [userValues, setUserValues] = useState({
    id: userInfo._id,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    role: userInfo.role,
  });

  // we create the functions that the Sagas execute
  const dispatch = useDispatch();
  // SAGA
  // it going to send a request to update the current user with the info in userInfo
  const updateUserSaga = (userInfo) => {
    dispatch({
      type: "UPDATE_USER_SAGA",
      userInfo: userInfo,
      id: userValues.id,
      modifierId: currentUser._id,
      history: props.history,
    });
  };

  // calls updateUserSaga to update user info
  // ... also hide the form
  const updateUser = (formInfo) => {
    const newUserInfo = {
      firstName: formInfo.firstName,
      lastName: formInfo.lastName,
      role: formInfo.role,
      email: formInfo.email,
      password: formInfo.password,
    };
    setVisible(false);
    updateUserSaga(newUserInfo);
  };

  // when userInfo changes, update current user values
  useEffect(() => {
    setUserValues({
      id: userInfo._id,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      role: userInfo.role,
    });
  }, [userInfo]);

  return (
    <UserForm
      roles={roles}
      userValues={userValues}
      setVisible={setVisible}
      type="edit"
      submitUser={updateUser}
      userFormLoading={editUserLoading}
      setSearchText={setSearchText}
      currentUser={currentUser}
    />
  );
}

export default withRouter(UpdateUserContainer);
