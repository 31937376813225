// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const AdminRequestsSD = styled.div`
  display: flex;
  width: 100%;
  padding: 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${mediaQueries.mobileL} {
    padding: 1rem;
  }
`;

const RequestsHeaderSD = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const TableContentSD = styled.div`
  display: flex;
  width: 100%;
  padding: "1rem 0rem";
`;

export { AdminRequestsSD, RequestsHeaderSD, TableContentSD };
