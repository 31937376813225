import React, { useState } from "react";
import { CircularButton } from "Components";
import { Modal } from "antd";
import CreateServiceContainer from "./CreateServiceContainer";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function CreateServiceModal() {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <CircularButton
        iconName="plus"
        size={size()}
        onClick={() => setVisible(true)}
      />
      <Modal
        title="Add service"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <CreateServiceContainer setVisible={setVisible} />
      </Modal>
    </div>
  );
}
function size() {
  if (window.screen.width >= 768) {
    return 28;
  } else {
    return 24;
  }
}
