// this is the component called by the route "/Login" of the main file "App.js"

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PackageDetailView from "./PackageDetailView";

export default function PackageDetailContainer({ id }) {
  const [selectedLanguage, setLanguage] = useState("EN-US");
  // selectors values
  const [filters, setFilters] = useState({
    seats: 1,
    tier: "T2",
    userBand: 2000,
  });
  const packageDetail = useSelector(
    (state) => state.packages.packageDetail[id]
  );
  const packageDetailLoading = useSelector(
    (state) => state.system.loadings.packageDetailLoading
  );

  const dispatch = useDispatch();

  const getPackageDetail = () => {
    dispatch({
      type: "GET_SALES_PACKAGE_DETAIL_SAGA",
      id: id,
      filters: filters,
    });
  };

  const deletePackageSaga = (id) => {
    dispatch({ type: "DELETE_PACKAGE_SAGA", id: id });
  };

  useEffect(() => {
    getPackageDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PackageDetailView
      packageInfo={packageDetail}
      selectedLanguage={selectedLanguage}
      setLanguage={setLanguage}
      deletePackageSaga={deletePackageSaga}
      packageDetailLoading={packageDetailLoading}
      filters={filters}
      setFilters={setFilters}
    />
  );
}
