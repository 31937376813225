import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const LoginCardSubtitleS = styled.div`
  padding-bottom: 3%;
  border-bottom: 0.1rem solid ${Colors.disabledGray};
  @media ${mediaQueries.tablet} {
    font-size: 1.5rem;
  }

  @media ${mediaQueries.mobileL} {
    font-size: 1.5rem;
  }
`;

const LoginCardTextSD = styled.div`
  font-size: 1.7rem;
  color: ${Colors.thirdGray};
  padding-top: 2.5rem;
  padding-bottom: 2%;
  font-family: "SourceSansPro-Semibold";
  @media ${mediaQueries.tablet} {
    font-size: 1.5rem;
  }

  @media ${mediaQueries.mobileL} {
    font-size: 1.5rem;
  }
`;

const LoginCardTitleS = styled.div`
  font-size: 3rem;
  color: ${Colors.thirdGray};
  padding-top: 2%;
  font-family: "SourceSansPro-Semibold";
  @media ${mediaQueries.tablet} {
    font-size: 2.5rem;
  }
`;

const LoginSSOSA = styled.a`
  color: white;
  width: 100%;
  button {
    width: 100%;
  }
  flex: 2;
  padding-right: 1rem;
`;

const LoginRowSD = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonSB = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "5rem")};
  background-color: ${(props) =>
    props.buttonColor ? props.buttonColor : Colors.primaryActive};
  border: 0.1rem solid;
  color: ${Colors.white};
  border-radius: 0.6rem;
  font-weight: 600;
  font-size: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  cursor: pointer;
  :focus {
    outline: none;
  }

  :before {
    content: "";
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: ${(props) => (props.showForm ? "100%" : "0rem")};
    height: 100%;
    background: rgb(0, 0, 0, 0.2);

    /* transition: all 1s ease; */
  }
  :hover:before {
    width: 100%;
  }
  flex: ${(props) => (props.flex ? props.flex : "1")};

  @media ${mediaQueries.tablet} {
    width: 8rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1rem;
    height: auto;
    padding: 0.5rem 1rem;
    /* height: ${(props) => (props.height ? props.height : "3rem")}; */
    /* width: 10rem; */
  }

  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
`;

const ManualLoginFormSD = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0rem;
  input,
  .ant-input-password {
    font-family: "SourceSansPro-Regular";
    font-size: 1.2rem;
    color: ${Colors.thirdGray};
    padding: 0.5rem;
    width: 100%;
    border: none;
    border-bottom: 0.3rem solid;
    border-color: ${Colors.disabledGray};
    :focus {
      outline: none;
      border-color: ${Colors.secondGray};
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 1.2rem;
      color: ${Colors.fourthGray};
      opacity: 1; /* Firefox */
      height: 10rem;
    }

    ::-webkit-input-placeholder {
      line-height: normal;
    }
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const ForgotPasswordSD = styled.div`
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  align-self: flex-start;
  justify-self: flex-start;
  cursor: pointer;
`;
export {
  LoginCardSubtitleS,
  LoginCardTitleS,
  LoginSSOSA,
  LoginRowSD,
  LoginCardTextSD,
  ButtonSB,
  ManualLoginFormSD,
  ForgotPasswordSD,
};
