import React from "react";
import {
  RNSidebarButtonSD,
  RNSidebarButtonNameSD,
  RNSidebarButtonLabelSD,
  IconID,
} from "./RNSidebarStyles";
/**
 * HOOK
 * A sidebar button, redirect to a specific release
 * shows the version and date of release
 */

function RNSidebarButton(props) {
  const { selected, icon, handlerClick, name, label, width } = props;
  return (
    <RNSidebarButtonSD
      selected={selected}
      onClick={handlerClick}
      className="RNSidebarButtonSD"
    >
      <RNSidebarButtonNameSD className="RNSidebarButtonNameSD">
        {name !== "Toggle open" ? (
          name
        ) : (
          <IconID src={icon} alt={name} width={width}></IconID>
        )}
      </RNSidebarButtonNameSD>
      <RNSidebarButtonLabelSD className="RNSidebarButtonLabelSD">
        {name !== "Toggle open" ? label : null}
      </RNSidebarButtonLabelSD>
    </RNSidebarButtonSD>
  );
}

export default RNSidebarButton;
