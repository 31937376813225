import Cookie from "js-cookie";

// CookieService.js
const CookieService = (function () {
  var _service;
  function getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function getAccessToken() {
    return Cookie.get("token");
  }

  function setToken(tokenObj) {
    Cookie.set("token", tokenObj);
  }

  function clearToken() {
    Cookie.remove("token");
  }
  return {
    getService,
    setToken,
    clearToken,
    getAccessToken,
  };
})();
export default CookieService;
