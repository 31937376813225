// Reports filters styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const FiltersSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${mediaQueries.mobileL} {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }
`;

export { FiltersSD };
