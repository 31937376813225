import React from "react";
import { Form, Input } from "antd";
import { ButtonS } from "Theme/Styles";
import { ButtonSdiv } from "./NewPasswordStyles";
import { LandingCardTitleS } from "Theme/Styles";
import { FormLabelSD } from "Theme/Styles/FormStyles";

/**
 * COMPONENT
 * New password form
 */

const NewPasswordForm = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.updatePasswordSaga(values.password);
  };

  return (
    <Form
      onSubmit={onFinish}
      form={form}
      name="newPassword"
      onFinish={onFinish}
      scrollToFirstError
    >
      <LandingCardTitleS>Reset password</LandingCardTitleS>
      <FormLabelSD>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
      </FormLabelSD>
      <FormLabelSD>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </FormLabelSD>
      <ButtonSdiv>
        <ButtonS type="submit">RESET PASSWORD</ButtonS>
      </ButtonSdiv>
    </Form>
  );
};

export default NewPasswordForm;
