import styled from "styled-components/macro";

// TODO: COLORS
const PackageFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PackageFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ChecksRowSD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem;
`;

const ButtonSD = styled.div`
  display: flex;
`;

export { PackageFormSD, PackageFormTabsSD, ChecksRowSD, ButtonSD };
