import React from "react";
import { Form } from "antd";
import { LoginInputSI } from "Theme/Styles/FormStyles";
import { LandingCardTitleS, ButtonS, RowSD } from "Theme/Styles";
/**
 * Hook
 * Reset password form
 * Send email to reset the password
 */
export default function ResetPasswordForm(props) {
  const [form] = Form.useForm();
  const { togglLoginCard, resetPasswordSaga } = props;
  const onFinish = (values) => {
    resetPasswordSaga(values);
  };
  return (
    <Form form={form} name="userForm" onFinish={onFinish} scrollToFirstError>
      <LandingCardTitleS>Reset password</LandingCardTitleS>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <LoginInputSI />
      </Form.Item>
      <RowSD padding="2rem 0rem">
        <ButtonS type="submit" width="100%">
          RESET PASSWORD
        </ButtonS>
      </RowSD>
      <ButtonS
        buttonType="cancel"
        width="100%"
        onClick={() => {
          togglLoginCard(true);
        }}
      >
        RETURN TO LOGIN
      </ButtonS>
    </Form>
  );
}
