// Login view styles
import styled from "styled-components/macro";

const TableContentSD = styled.div`
  display: flex;
  width: 100%;
  padding: "1rem 0rem";
`;

export { TableContentSD };
