import styled from "styled-components/macro";
import Colors from "Theme/Colors";

// TODO: COLORS
const ServiceFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ServiceFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ChecksRowSD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem;
`;

const ModalFooterSD = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
`;

const PricingSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ButtonSD = styled.div`
  display: flex;
`;
const ErrorMsgSD = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${Colors.errorIcon};
  font-size: 0.9rem;
`;

export {
  ServiceFormSD,
  ServiceFormTabsSD,
  ChecksRowSD,
  ModalFooterSD,
  ButtonSD,
  PricingSD,
  ErrorMsgSD,
};
