// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const DetailRowSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Light";
  flex-direction: row;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Colors.detail};
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 3rem;
`;

const AdminDetailRowSD = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 2rem 3rem;
  font-size: 1.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.3rem;
    padding: 1rem 2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const AdminDetailColumnSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 3rem;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Colors.detail};
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  font-size: 1.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.3rem;
    padding: 1rem 2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    padding: ${(props) => (props.padding ? props.padding : "2rem 3rem")};
  }
`;

const DetailColumnSD = styled.div`
  display: flex;
  font-family: "SourceSansPro-Light";
  flex-direction: column;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Colors.detail};
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 3rem;
  @media ${mediaQueries.tablet} {
    padding: 1rem 2rem;
  }
`;

const SalesDetailSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  color: ${Colors.primaryText};
  white-space: pre-wrap;
  .ant-affix {
    background: white;
  }
  .ant-select {
    width: auto;
  }
  font-family: "SourceSansPro-Light";
  font-size: 1.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 1.3rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    .ant-select {
      font-size: 1.2rem;
      height: 2.8rem;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
      align-items: center;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2.8rem;
    }
    .ant-input {
      font-size: 1.2rem;
    }
    .ant-input-suffix {
      font-size: 1.2rem;
    }
    .ant-input-affix-wrapper {
      padding: 0.2rem 1.1rem;
    }
    .ant-input-number-input {
      height: 2.8rem;
      font-size: 1.2rem;
    }
  }
`;

const SalesAffixSD = styled.div`
  width: 100%;
`;

const SalesDetailHeaderSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-bottom: 0.6rem solid
    ${(props) => (props.type === "service" ? Colors.service : Colors.package)};
  padding: 1rem 3rem;
  font-family: "SourceSansPro-Regular";
`;

const SalesDetailTitleSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.primaryText};
  font-size: 3rem;
  font-family: "SourceSansPro-Bold";
  @media ${mediaQueries.mobileL} {
    font-size: 2rem;
  }
`;

const SalesDetailNameSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 3rem;
  color: ${Colors.primaryText};
  font-family: "SourceSansPro-Bold";
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  @media ${mediaQueries.tablet} {
    font-size: 2.5rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 2rem;
  }
`;

const SalesDetailIndexSD = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0rem;
  color: ${Colors.primaryActive};
  @media ${mediaQueries.tablet} {
    font-size: 1.5rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.3rem;
    padding: 0.5rem 0rem;
  }
`;

const GeneralDetailSD = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  background-color: ${Colors.detail};
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 3rem;

  @media ${mediaQueries.tablet} {
    flex-direction: column;
    padding: 1rem 3rem;
  }
  @media ${mediaQueries.mobileL} {
    display: block;
  }
`;

const SalesDetailColumnSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.detail};
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 3rem;
`;

const GeneralSectionSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  height: auto;
  padding: 0.5rem;
  flex: 1;
  @media ${mediaQueries.tablet} {
    border: none;
    flex: auto;
  }
  @media ${mediaQueries.mobileL} {
    padding: 0rem;
  }
`;

const GeneralContentSD = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0rem;
  width: 100%;
`;

const DetailSubtitleSD = styled.div`
  font-family: "SourceSansPro-Bold";
  flex-direction: column;
  font-size: 2.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 2rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.5rem;
  }
`;

const DetailSubsubtitleSD = styled.div`
  font-family: "SourceSansPro-Semibold";
  display: flex;
  width: 30%;
  background-color: ${(props) => (props.mark ? "#70DF84" : "transparent")};
  @media ${mediaQueries.tablet} {
    width: 100%;
  }
`;

const DetailDescriptionSD = styled.div`
  display: flex;
  width: 70%;
  word-break: break-all;
  flex-wrap: wrap;
  a {
    margin: 0.5rem;
  }
  @media ${mediaQueries.tablet} {
    width: 100%;
  }
`;

const DetailBasicContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "SourceSansPro-Light";
  font-size: 2rem;
  color: ${Colors.primaryText};
  word-break: break-word;
  text-align: justify;
  width: 100%;
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
    text-align: justify;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    text-align: justify;
  }
`;

const DetailStrongContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "SourceSansPro-Bold";
  font-size: 2rem;
  color: ${Colors.primaryText};
  word-break: break-word;
  text-align: justify;
  width: 100%;
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
    text-align: justify;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    text-align: justify;
  }
`;

const ObjectiveDetailSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Colors.firstGray};
  padding: 3.5rem 5rem;
  align-items: center;
  justify-content: center;
  color: white;
  @media ${mediaQueries.tablet} {
    padding: 3rem;
  }
  @media ${mediaQueries.mobileL} {
    padding: 2rem;
  }
`;

const ObjectiveContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  @media ${mediaQueries.tablet} {
    padding: 1rem;
  }
  @media ${mediaQueries.mobileL} {
    padding: 1rem;
  }
`;

const LightRowDetailSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10 3rem;
`;

const FullRowSD = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

const DetailTagsSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

const DetailTagSD = styled.div`
  margin-left: 0.5rem;
  @media ${mediaQueries.mobileL} {
    padding: 0rem;
    font-size: 1.2rem;
  }
`;

const DetailCollsSD = styled.div`
  width: 100%;
`;

const DetailPaddingColumnSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
`;

const CollateralsContentSD = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const CollateralsLineSD = styled.div`
  border-top: 0.1rem solid ${Colors.firstGray};
  display: flex;
  width: 70%;
  height: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CollaterallsItemSD = styled.div`
  padding: 1rem;
  @media ${mediaQueries.mobileL} {
    width: 100%;
  }
`;

const TagSD = styled.div`
  padding: 0.5rem;
`;

const BorderFSD = styled.div`
  align-items: center;
  height: ${(props) => (props.height ? props.height : null)};
  display: flex;
  justify-content: center;
  @media ${mediaQueries.laptopL} {
    display: none;
  }
`;

const BorderSD = styled.div`
  border-left: 0.2rem solid ${Colors.thirdGray};
  height: ${(props) => (props.height ? props.height : null)};
  margin-left: 2rem;
  padding-bottom: 5rem;
  padding-top: 5rem;
`;

const HorizontalSD = styled.div`
  border-bottom: 0.1rem solid white;
  width: 60%;
`;

export {
  DetailRowSD,
  AdminDetailRowSD,
  AdminDetailColumnSD,
  DetailColumnSD,
  DetailSubtitleSD,
  DetailSubsubtitleSD,
  DetailDescriptionSD,
  LightRowDetailSD,
  GeneralContentSD,
  GeneralDetailSD,
  GeneralSectionSD,
  DetailBasicContentSD,
  TagSD,
  FullRowSD,
  BorderSD,
  BorderFSD,
  CollaterallsItemSD,
  CollateralsContentSD,
  DetailPaddingColumnSD,
  CollateralsLineSD,
  DetailCollsSD,
  DetailStrongContentSD,
  DetailTagSD,
  DetailTagsSD,
  SalesDetailHeaderSD,
  SalesDetailIndexSD,
  SalesDetailNameSD,
  HorizontalSD,
  ObjectiveContentSD,
  ObjectiveDetailSD,
  SalesDetailSD,
  SalesAffixSD,
  SalesDetailTitleSD,
  SalesDetailColumnSD,
};
