import { Tabs } from "antd";
import React from "react";
import BasicTab from "./BasicTab";
import StatusTab from "./StatusTab";
import DeliveryTab from "./DeliveryTab/DeliveryTab";
import CollateralsTab from "./CollateralsTab/CollateralsTab";
import PricingTab from "./PricingTab/PricingTab";

/**
 * HOOK
 */

export default function ServiceFormTabs(props) {
  const {
    cpqCode,
    setCPQCode,
    owner,
    setOwner,
    editors,
    setEditors,
    ownerList,
    selectedLanguage,
    localization,
    onChangeMLField,
    tags,
    setTags,
    tagList,
    visibility,
    setVisibility,
    inSfdc,
    setInSfdc,
    salable,
    setSalable,
    communicable,
    setCommunicable,
    serviceType,
    setServiceType,
    thirdPartyDelivery,
    setThirdPartyDelivery,
    //pricing props
    estimationRequired,
    setEstimationRequired,
    renewable,
    setRenewable,
    pricing,
    setPricing,
    pricingOption,
    setPricingOption,
    userBands,
    seats,
    chargingMode,
    setChargingMode,
    minimumSeats,
    changeMinimumSeats,
    error,
    setError,
    checkboxValues,
    setcheckboxValues,
    type,
    productManager,
    setProductManager,
  } = props;

  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Basic" key="1">
        <BasicTab
          cpqCode={cpqCode}
          setCPQCode={setCPQCode}
          owner={owner}
          setOwner={setOwner}
          editors={editors}
          setEditors={setEditors}
          productManager={productManager}
          setProductManager={setProductManager}
          ownerList={ownerList}
          selectedLanguage={selectedLanguage}
          localization={localization}
          onChangeMLField={onChangeMLField}
          tags={tags}
          setTags={setTags}
          tagList={tagList}
        />
      </TabPane>
      <TabPane tab="Status" key="2">
        <StatusTab
          visibility={visibility}
          setVisibility={setVisibility}
          inSfdc={inSfdc}
          setInSfdc={setInSfdc}
          salable={salable}
          setSalable={setSalable}
          communicable={communicable}
          setCommunicable={setCommunicable}
        />
      </TabPane>
      <TabPane tab="Delivery" key="3">
        <DeliveryTab
          localization={localization}
          onChangeMLField={onChangeMLField}
          selectedLanguage={selectedLanguage}
          serviceType={serviceType}
          setServiceType={setServiceType}
          thirdPartyDelivery={thirdPartyDelivery}
          setThirdPartyDelivery={setThirdPartyDelivery}
        />
      </TabPane>

      <TabPane tab="Sales & Pricing" key="5">
        <PricingTab
          estimationRequired={estimationRequired}
          setEstimationRequired={setEstimationRequired}
          renewable={renewable}
          setRenewable={setRenewable}
          pricing={pricing}
          setPricing={setPricing}
          pricingOption={pricingOption}
          setPricingOption={setPricingOption}
          userBands={userBands}
          seats={seats}
          chargingMode={chargingMode}
          setChargingMode={setChargingMode}
          minimumSeats={minimumSeats}
          changeMinimumSeats={changeMinimumSeats}
          error={error}
          setError={setError}
          checkboxValues={checkboxValues}
          setcheckboxValues={setcheckboxValues}
          type={type}
        />
      </TabPane>
      <TabPane tab="Collaterals" key="6">
        <CollateralsTab
          localization={localization}
          onChangeMLField={onChangeMLField}
          selectedLanguage={selectedLanguage}
        />
      </TabPane>
    </Tabs>
  );
}
