// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const SalesFiltersSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  float: right;
  width: 25rem;
  @media ${mediaQueries.tablet} {
    justify-content: space-between;
    width: 100%;
  }
  @media ${mediaQueries.mobileL} {
    justify-content: space-between;
    width: 100%;
  }
`;

const LanguageSD = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;
`;

const BasicFilterSD = styled.div`
  display: flex;
  align-items: center;
  .ant-select {
    width: 100%;
  }
`;

export { SalesFiltersSD, LanguageSD, BasicFilterSD };
