import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const PricingDetail = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.isTable ? "white" : "transparent")};
  flex-wrap: wrap;
  width: 100%;
  border-radius: 1rem;
  box-shadow: ${(props) =>
    props.isTable ? "rgba(0, 0, 0, 0.12) 0.3rem 0.3rem 0.6rem 0rem" : "none"};
`;

const PricingColumnCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 2)};
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "none"};
  /* text-align: start;  */
`;

const BasicPricingHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 8;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0rem 2rem;
`;

const PricingDescr = styled.div`
  font-family: "SourceSansPro-Light";
  /* width: 100%; */
  font-size: 1.4rem;
  color: ${Colors.primaryText};
  word-break: ${(props) =>
    props.type === "owner" ? "break-all" : "break-word"};
  font-weight: 400;
  padding-left: ${(props) => (props.left ? props.left : "0rem")};
  flex: ${(props) => (props.flex ? props.flex : null)};
  text-align: ${(props) => (props.align ? props.align : "center")};
  @media ${mediaQueries.tablet} {
    font-size: 1.6rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
    flex: 1;
    word-break: break-word;
    padding-left: ${(props) => (props.left ? "1rem" : "0rem")};
  }
`;

const PricingRow = styled.div`
  display: flex;
  color: ${Colors.primaryText};
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "none"};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  color: ${(props) => (props.color ? props.color : Colors.primaryText)};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "none"};
  padding: 0.5rem 0rem;
`;

export const Styles = {
  PricingDetail,
  PricingRow,
  PricingColumnCell,
  PricingDescr,
  BasicPricingHeader,
};
