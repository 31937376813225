import React from "react";
import {
  SidebarButtonSD,
  SidebarButtonIconSD,
  SidebarButtonNameSD,
  IconID,
} from "./AdminSidebarStyles";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

function SidebarButton(props) {
  const { selected, icon, handlerClick, name, width } = props;
  return (
    <SidebarButtonSD selected={selected} onClick={handlerClick}>
      <SidebarButtonIconSD>
        <IconID src={icon} alt={name} width={width}></IconID>
      </SidebarButtonIconSD>
      <SidebarButtonNameSD>
        {name !== "Toggle open" ? name : null}
      </SidebarButtonNameSD>
    </SidebarButtonSD>
  );
}

export default SidebarButton;
