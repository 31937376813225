import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const CollateralsLabelSD = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.primaryActive};
  width: 20rem;
  height: 8rem;
  padding: 1rem;
  border-radius: 0.3rem;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    width: 100%;
    height: auto;
  }
`;

const CollateralsTitle = styled.div`
  font-size: 1.5rem;
  font-family: "SourceSansPro-SemiBold";
  color: ${Colors.white};
`;
const CollateralLinkSa = styled.a`
  display: flex;
`;
export { CollateralsLabelSD, CollateralsTitle, CollateralLinkSa };
