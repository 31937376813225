import React from "react";
import { LoginViewSD } from "./LoginViewStyles";
import MainLoginCard from "./MainLoginCard/MainLoginCard";
import ResetPasswordCard from "./ResetPasswordCard/ResetPasswordCard";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function LoginView({
  loginSaga,
  loginLoading,
  loginURL,
  showLogin,
  togglLoginCard,
  resetPasswordSaga,
  resetPasswordLoading,
  rememberMe,
  changeRememberMe,
}) {
  const loginValues = {};
  return (
    <LoginViewSD className="LoginViewSD">
      {showLogin ? (
        <MainLoginCard
          loginSaga={loginSaga}
          loginValues={loginValues}
          loginLoading={loginLoading}
          togglLoginCard={togglLoginCard}
          loginURL={loginURL}
          rememberMe={rememberMe}
          changeRememberMe={changeRememberMe}
        />
      ) : (
        <ResetPasswordCard
          resetPasswordSaga={resetPasswordSaga}
          togglLoginCard={togglLoginCard}
          resetPasswordLoading={resetPasswordLoading}
        />
      )}
    </LoginViewSD>
  );
}
