import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UsersView from "./UsersView";

/**
 * HOOK
 * This is the component called by the route "/admin/users" of the main file "App.js"
 * calls the Users View
 */

export default function UsersContainer() {
  const dispatch = useDispatch();
  // we obtain all users loading states
  const usersListLoading = useSelector(
    (state) => state.system.loadings.usersListLoading
  );
  // get the amount of request allowed per page
  const globalItemsPerPage = useSelector(
    (state) => state.system.globalItemsPerPage
  );
  // get the total amount of pages in requests pagination
  const pages = useSelector((state) => state.users.pages);
  // get the list of all the users in the aplication
  const userList = useSelector((state) => state.users.userList);
  // get the list of all the roles that exist in the app
  const roles = useSelector((state) => state.system.roles);

  // STATES
  // filters: filters in user page
  // ... roleFilter: role filter, like Administrator, Service owner, etc. All is the default value
  // ... to show all the users
  // ... page: page in pagination, 1 is the default value
  const [filters, setFilters] = useState({
    roleFilter: "All",
    page: 1,
  });
  const [searchedText, setSearchedText] = useState([]);

  // SAGA
  // obtains the users for the current page, base on filters
  const getUsersSaga = () => {
    dispatch({
      type: "GET_USERS_SAGA",
      page: filters.page,
      limit: globalItemsPerPage,
      roleFilter: filters.roleFilter,
      searchedText,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  // pagination, select a page. When filters change, there is an api request to
  // ... get the list of sorted users
  const selectPage = (index) => {
    if (index >= 1 && index <= pages) {
      setFilters((filters) => ({ ...filters, page: index }));
    }
  };

  // add new filters in filters state (or update them if they already exists)
  // sortBy: save the key by which the request table will be sorted (e.g cpq, owner, etc)
  // sortOrder: boolean value. 1 for ascending sorting or -1 for descending sorting
  const onChangeQueryParams = (keyParam, valueParam) => {
    setFilters((filters) => ({
      ...filters,
      sortBy: keyParam,
      sortOrder: valueParam,
    }));
  };

  // when const pages changes, set page filter in 1
  useEffect(() => {
    setFilters((filters) => ({ ...filters, page: 1 }));
  }, [pages]);

  // when filters changes, get users list for the current page
  useEffect(() => {
    getUsersSaga();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchedText]);

  return (
    <UsersView
      userList={userList}
      roles={roles}
      filters={filters}
      setFilters={setFilters}
      usersListLoading={usersListLoading}
      pages={pages}
      selectedPage={filters.page}
      selectPage={selectPage}
      onChangeQueryParams={onChangeQueryParams}
      searchedText={searchedText}
      setSearchedText={setSearchedText}
    />
  );
}
