import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PackageForm from "../NewPackageForm/PackageForm";

/**
 * HOOK
 * Edit package container, calls the Package form. Has all the logic
 */

function EditPackageContainer({ setVisible, id, itemIndex }) {
  const dispatch = useDispatch();
  // SAGA
  // obtain the list of all users who have any of the following roles: Administrator, Service Owner or Product Manager.
  // ... These users are saved in the store
  const getOwners = () => {
    dispatch({ type: "GET_OWNERS_SAGA" });
  };

  // SAGA
  // obtain all the diferent tags and languages.
  // ... These filters are saved in the store
  const getTags = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };

  // SAGA
  // obtain all the services
  // ... These services are saved in the store
  const getServices = () => {
    dispatch({ type: "GET_SERVICES_SAGA", searchedText: [] });
  };

  // SAGA
  // send the information of the edited package to the backend
  const editPackageSaga = (updatedPackageInfo) => {
    dispatch({
      type: "UPDATE_PACKAGE_SAGA",
      updatedPackageInfo: updatedPackageInfo,
      id: id,
      itemIndex,
    });
  };

  // we obtain all packages loading states
  const editPackageLoading = useSelector(
    (state) => state.system.loadings.editPackageLoading
  );

  // get all tags from the store
  const tags = useSelector((state) => state.system.tags);
  // get user roles from the store
  const roles = useSelector((state) => state.system.roles);
  // get all languages from the store
  const languages = useSelector((state) => state.system.languages);
  // get all services from the store
  const serviceList = useSelector((state) => state.service.serviceList);
  // get the owner list from the store
  const ownerList = useSelector((state) => state.users.ownerList);
  // get the current package information from the store
  const packageInfo = useSelector((state) => state.packages.packageDetail[id]);
  // get the mandatory fields in the package form
  const packageMandatoryFields = useSelector(
    (state) => state.system.mandatoryFields.package
  );

  const tagsArray = getTagsArray(tags);
  const updatePackage = (newPackage) => {
    editPackageSaga(newPackage);
  };

  useEffect(() => {
    getOwners();
    getServices();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PackageForm
      ownerList={ownerList}
      tagList={tagsArray}
      type="edit"
      roles={roles}
      languages={languages}
      packageValues={packageInfo}
      setVisible={setVisible}
      serviceList={serviceList}
      submitPackage={updatePackage}
      packageFormLoading={editPackageLoading}
      packageMandatoryFields={packageMandatoryFields}
    />
  );
}
// receives a tag object and returns an array of tags
function getTagsArray(tags) {
  let tagsArray = [];
  const tagsHeaders = Object.keys(tags);
  tagsHeaders.forEach((header) => {
    tagsArray = tagsArray.concat(tags[header]);
  });

  return tagsArray;
}

export default EditPackageContainer;
