import React from "react";
import { CheckError, CollateralsLabel } from "Components/Common";
import {
  AdminDetailColumnSD,
  DetailSubtitleSD,
  DetailSubsubtitleSD,
  CollaterallsItemSD,
  DetailDescriptionSD,
  CollateralsContentSD,
} from "Theme/DetailStyles";
import { RowSD, ColumnSD } from "Theme/Styles";
import DeliveryTaskTable from "./DetailTaskTable/DeliveryTaskTable";
import { validURL } from "Helpers/Helpers";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function DeliveryDetailSection({
  detailInfo,
  selectedLanguage,
  changes,
}) {
  function markLocalization(key, selectedLanguage, changes) {
    if (Object.entries(changes).length === 0) {
      return false;
    } else {
      return changes["localization"][selectedLanguage][key];
    }
  }
  const headers = [
    { name: "Activity", id: "task" },
    { name: "Role", id: "role" },
    { name: "Time", id: "time" },
  ];
  const taskValues =
    detailInfo.localization[selectedLanguage].tasks || detailInfo.tasks || [];

  function renderCollaterals(collaterals) {
    const renderedCollaterals = [];
    collaterals.forEach((label, index) => {
      if (validURL(label.link) && label.name != "Sales sheet") {
        renderedCollaterals.push(
          <CollaterallsItemSD key={index}>
            <CollateralsLabel
              name={label.name}
              link={label.link}
            ></CollateralsLabel>
          </CollaterallsItemSD>
        );
      }
    });
    return renderedCollaterals;
  }
  // return boolean, true if there is at least one collateral
  function showCollaterals(collaterals) {
    let show = false;
    collaterals.forEach((collateral) => {
      if (validURL(collateral.link)) {
        show = true;
      }
    });
    return show;
  }

  const collateralsValues =
    detailInfo.localization[selectedLanguage].collaterals ||
    detailInfo.collaterals ||
    [];
  const renderedCollaterals = renderCollaterals(collateralsValues);
  const showCollateralsBoolean = showCollaterals(collateralsValues);

  return (
    <AdminDetailColumnSD>
      <DetailSubtitleSD>Delivery</DetailSubtitleSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD>Summary:</DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].summary}
        </DetailDescriptionSD>
      </RowSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("objective", selectedLanguage, changes)}
        >
          Objective:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].objective}
        </DetailDescriptionSD>
      </RowSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("benefits", selectedLanguage, changes)}
        >
          Benefits:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].benefits}
        </DetailDescriptionSD>
      </RowSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("audience", selectedLanguage, changes)}
        >
          Audience:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].audience}
        </DetailDescriptionSD>
      </RowSD>
      {/* <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("scope", selectedLanguage, changes)}
        >
          Scope:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].scope}
        </DetailDescriptionSD>
      </RowSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("tasks", selectedLanguage, changes)}
        >
          tasks:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {taskValues.length > 0 ? (
            <DeliveryTaskTable
              headers={headers}
              data={taskValues}
            ></DeliveryTaskTable>
          ) : (
            "None"
          )}
        </DetailDescriptionSD>
      </RowSD> */}
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("serviceType", selectedLanguage, changes)}
        >
          Type:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>{detailInfo.serviceType}</DetailDescriptionSD>
      </RowSD>
      {/* <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization(
            "thirdPartyDelivery",
            selectedLanguage,
            changes
          )}
        >
          Third party delivery:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          <CheckError
            size={15}
            checked={detailInfo.thirdPartyDelivery}
          ></CheckError>
        </DetailDescriptionSD>
      </RowSD>
      <RowSD padding="0.5rem 0rem">
        <DetailSubsubtitleSD
          mark={markLocalization("artifacts", selectedLanguage, changes)}
        >
          Artifacts:
        </DetailSubsubtitleSD>
        <DetailDescriptionSD>
          {detailInfo.localization[selectedLanguage].artifacts}
        </DetailDescriptionSD>
      </RowSD> */}
      {showCollateralsBoolean ? (
        <ColumnSD centered>
          <DetailSubtitleSD>Collaterals</DetailSubtitleSD>
          <CollateralsContentSD>{renderedCollaterals}</CollateralsContentSD>
        </ColumnSD>
      ) : null}
    </AdminDetailColumnSD>
  );
}
