import styled from "styled-components/macro";
import Colors from "Theme/Colors";

// TODO: COLORS
const DeleteServiceModalSD = styled.div`
  padding: 5%;
`;

const TitleSD = styled.div`
  font-family: "Georgia-Regular";
  font-size: 4rem;
  color: ${Colors.thirdGray};
  padding-top: 5%;
  padding-bottom: 5%;
`;

const SubtitleSD = styled.div`
  font-family: "Roboto-Regular";
  font-size: 1.2rem;
  color: ${Colors.thirdGray};
  padding-top: 5%;
  padding-bottom: 5%;
`;

const StrongSD = styled.div`
  display: inline-flex;
  font-family: "SourceSansPro-Semibold";
`;

const ButtonSD = styled.div`
  display: flex;
`;

export { DeleteServiceModalSD, TitleSD, SubtitleSD, StrongSD, ButtonSD };
