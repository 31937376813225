import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Tooltip, Select } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CustomLoadingOverlay } from "Components";
import { ButtonS, ModalFooterSD } from "Theme/Styles";
import { ButtonSD, UserFormSD } from "./UserFormStyles";

const { Option } = Select;

/**
 * HOOK
 * User form, to create a new user or update the information of an user
 */

export default function UserForm(props) {
  const {
    userValues,
    roles,
    setVisible,
    type,
    submitUser,
    userFormLoading,
    currentUser,
  } = props;
  const [form] = Form.useForm();

  // when finished editing the form, save the info of the user
  const onFinish = (values) => {
    submitUser(values);
  };

  // is a flag using to know if the form needs to be open or hide
  // ... it is depends on the backend response, if there is some error
  // ... this variable will be false (and the form it won't close), on the other hand will be true
  const validUserForm = useSelector(
    (state) => state.system.validations.validUserForm
  );
  // when the form is canceled, it hides
  function cancelForm() {
    setVisible(false);
  }

  // reset the fields to initialValues
  const onReset = () => {
    form.resetFields();
  };

  // render all possible roles in the select item
  function renderRoles(roles) {
    const roleIds = Object.keys(roles);
    const options = [];
    roleIds.forEach((id) => {
      options.push(
        <Option key={id} value={parseInt(id)}>
          {roles[id]}
        </Option>
      );
    });

    return options;
  }

  // if user values changes and are correct, the form hides
  useEffect(() => {
    if (validUserForm) {
      cancelForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validUserForm]);

  useEffect(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValues]);

  return (
    <UserFormSD>
      <Form
        form={form}
        name="userForm"
        onFinish={onFinish}
        initialValues={{
          firstName: userValues.firstName,
          lastName: userValues.lastName,
          email: userValues.email,
          role: userValues.role,
        }}
        scrollToFirstError
      >
        <CustomLoadingOverlay active={userFormLoading}>
          <Form.Item
            name="firstName"
            label={"First name"}
            rules={[
              {
                required: true,
                message: "Please input your first name!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={"Last name"}
            rules={[
              {
                required: true,
                message: "Please input your last name!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label={"Role"}
            rules={[
              {
                required: true,
                message: "Please select a role",
              },
            ]}
          >
            <Select placeholder="Select a role">{renderRoles(roles)}</Select>
          </Form.Item>
          {type === "create" ||
          (type === "edit" && currentUser._id === userValues.id) ? (
            <div>
              <Form.Item
                name="password"
                label={
                  <span>
                    Password&nbsp;
                    <Tooltip title="Password must have at least six characters, one special character and one numeric character">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    required: type === "create" ? true : false,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: type === "create" ? true : false,
                    message: "Please confirm your password",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          ) : null}
          <ModalFooterSD>
            <ButtonSD>
              <ButtonS
                key="back"
                type="reset"
                buttonType="cancel"
                onClick={() => cancelForm()}
              >
                CANCEL
              </ButtonS>
            </ButtonSD>
            <ButtonSD>
              <ButtonS type="submit">
                {type === "create" ? "ADD USER" : "SAVE CHANGES"}
              </ButtonS>
            </ButtonSD>
          </ModalFooterSD>
        </CustomLoadingOverlay>
      </Form>
    </UserFormSD>
  );
}
