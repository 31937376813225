import React from "react";
import { SearchBox, Pagination } from "Components/Common";
import { SectionTitleSD } from "Theme/Styles";
import { RequestsHeaderSD, TableContentSD } from "./AdminRequestsViewStyles";
import RequestsTable from "./RequestsTable/RequestsTable";

/**
 * HOOK
 * Admin requests view, the view groups and sorts the visual components
 */
export default function AdminRequestsView({
  pages,
  selectedPage,
  selectPage,
  requestList,
  requestListLoading,
  searchedText,
  setSearchedText,
  onChangeQueryParams,
  filters,
}) {
  return (
    <div>
      <SectionTitleSD>Requests</SectionTitleSD>

      <RequestsHeaderSD>
        <SearchBox
          searchedText={searchedText}
          setSearchedText={setSearchedText}
        />
      </RequestsHeaderSD>
      <TableContentSD>
        <RequestsTable
          requestList={requestList}
          requestListLoading={requestListLoading}
          onChangeQueryParams={onChangeQueryParams}
          filters={filters}
        />
      </TableContentSD>
      <Pagination
        pages={pages}
        selectPage={selectPage}
        selectedPage={selectedPage}
      ></Pagination>
    </div>
  );
}
