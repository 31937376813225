import styled from "styled-components/macro";
import Colors from "Theme/Colors";

const TableSD = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  width: 100%;
`;

const HeaderSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryText};
  color: ${Colors.white};
  border-radius: 1rem 1rem 0rem 0rem;
  font-size: 1.2rem;
  padding: 1rem 0rem 1rem 0;
  z-index: 5;
`;

const HeaderOptionSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-family: "SourceSansPro-Bold";
  font-size: 1.4667rem;
  border-width: 0rem 0rem 0rem ${(props) => (props.divisor ? "0.3rem" : "0rem")};
  border-style: solid;
  border-color: ${Colors.secondary};
`;

const ContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0rem 0rem 1rem 1rem;
  border: 0.1rem solid rgb(238, 238, 238);
  margin-bottom: 1rem;
  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  padding: 0rem 1rem 1rem 1rem;
`;

const DraggableListSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;
`;

const RowSD = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  padding-top: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  .ant-select-selection,
  .ant-select-selection--single {
    box-shadow: none;
  }
`;

const LabelSD = styled.div`
  font-family: "SourceSansPro-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding: 0.2rem 0rem 0.2rem 0rem;
  font-size: 1.2rem;
  margin-top: ${(props) => (props.top ? props.top : "none")};
  input {
    box-shadow: none;
    border-color: #e1cbf7;
  }
`;

const InputLabelSD = styled.div`
  font-family: "SourceSansPro-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  font-size: 1.2rem;
  input:focus {
    border: 0.1rem solid #cea7f6;
    box-shadow: 0 0 0 0.2rem #cea7f6;
    outline: none;
  }
`;

const ErrorMsgSD = styled.div`
  align-items: center;
  justify-content: center;
  color: ${Colors.errorIcon};
  font-size: 0.9rem;
`;

export {
  TableSD,
  DraggableListSD,
  HeaderSD,
  HeaderOptionSD,
  ContentSD,
  RowSD,
  LabelSD,
  ErrorMsgSD,
  InputLabelSD,
};
