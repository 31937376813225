import React from "react";
import { Tabs } from "antd";
import Checkbox from "Components/Common/Form/Checkbox";
import { TabsSD, TabContentSD } from "./ReportsTabsStyles";
import {
  SubtitleSD,
  ReportsRowSD,
  ReportsColumnSD,
} from "../AdminReportsViewStyles";

/**
 * HOOK
 * Reports Tabs
 * Renderize all the checkboxes per tab
 */

export default function ReportsTabs(props) {
  const { type, setField, fields, serviceFields, packageFields } = props;

  const { TabPane } = Tabs;

  // this function generates the checkboxes of a specific tab
  const generateCheckboxes = (type, tab) => {
    let tabFields = [];
    // select the fields or checkboxes to render
    if (type === "services") {
      tabFields = [...new Set(serviceFields[tab])];
    } else {
      tabFields = [...new Set(packageFields[tab])];
    }

    let checkboxesList = [];
    // for each field we create a checkbox
    tabFields.forEach((tabFieldId) => {
      if (fields[tabFieldId]) {
        checkboxesList.push(
          <ReportsColumnSD key={type + tab + tabFieldId}>
            <Checkbox
              checked={fields[tabFieldId].value}
              label={fields[tabFieldId].name}
              onChange={(e) => setField(tabFieldId, e.target.checked)}
            />
          </ReportsColumnSD>
        );
      }
    });
    return checkboxesList;
  };
  return (
    <TabsSD>
      <ReportsRowSD>
        <SubtitleSD>Fields</SubtitleSD>
      </ReportsRowSD>
      <TabsSD>
        {type === "services" ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Basic" key="1">
              <TabContentSD key="servicetab1">
                {generateCheckboxes("services", "basic")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Status" key="2">
              <TabContentSD key="servicetab2">
                {generateCheckboxes("services", "status")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Delivery" key="3">
              <TabContentSD key="servicetab3">
                {generateCheckboxes("services", "delivery")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Sales & Pricing" key="4">
              <TabContentSD key="servicetab4">
                {generateCheckboxes("services", "pricing")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Collaterals" key="5">
              <TabContentSD key="servicetab5">
                {generateCheckboxes("services", "collaterals")}
              </TabContentSD>
            </TabPane>
          </Tabs>
        ) : (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Basic" key="1">
              <TabContentSD key="packagetab1">
                {generateCheckboxes("packages", "basic")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Status" key="2">
              <TabContentSD key="packagetab2">
                {generateCheckboxes("packages", "status")}
              </TabContentSD>
            </TabPane>
            <TabPane tab="Collaterals" key="5">
              <TabContentSD key="packagetab5">
                {generateCheckboxes("packages", "collaterals")}
              </TabContentSD>
            </TabPane>
          </Tabs>
        )}
      </TabsSD>
    </TabsSD>
  );
}
