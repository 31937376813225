import { keyframes } from "styled-components/macro";

const fadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`;

const leftFadeIn = keyframes`
    from {
        transform: translateX(-2rem);
        opacity:0;
    }
    to {
        transform: translateY(0);
        opacity:1;
    }
`;

const topFadeIn = keyframes`
    from {
        transform: translateY(-2rem);
        opacity:0;
    }
    to {
        transform: translateY(0);
        opacity:1;
    }
`;
export { fadeIn, leftFadeIn, topFadeIn };
