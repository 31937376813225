import { DetailTag } from "Components/Common";
import React from "react";
import {
  DetailTagsSD,
  SalesDetailHeaderSD,
  SalesDetailIndexSD,
  SalesDetailNameSD,
} from "Theme/DetailStyles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function SalesDetailHeader({
  localization,
  selectedLanguage,
  setLanguage,
  name,
  cpqCode,
  type,
  scrollToRef,
  id,
}) {
  const tags = renderTags(localization, selectedLanguage, setLanguage);
  return (
    <SalesDetailHeaderSD type={type}>
      <SalesDetailNameSD>
        {name} - {cpqCode}
      </SalesDetailNameSD>
      {type === "service" ? (
        <SalesDetailIndexSD>
          <div onClick={() => scrollToRef("general")}>General -&thinsp;</div>
          <div onClick={() => scrollToRef("objective")}>
            Objective -&thinsp;
          </div>
          {/* <div onClick={() => scrollToRef("scope")}>Scope -&thinsp;</div> */}
          <div onClick={() => scrollToRef("collaterals")}>Collaterals</div>
        </SalesDetailIndexSD>
      ) : null}

      <DetailTagsSD>{tags}</DetailTagsSD>
    </SalesDetailHeaderSD>
  );
}

function renderTags(localization, selectedLanguage, setLanguage) {
  const renderedTags = [];
  const languageIds = Object.keys(localization);
  languageIds.forEach((id) => {
    if (
      id !== "internalNotes" &&
      id !== "collaterals" &&
      id !== "description" &&
      id !== "summary"
    ) {
      renderedTags.push(
        <DetailTag
          key={id}
          text={id}
          isActive={selectedLanguage === id ? true : false}
          onClick={() => setLanguage(id)}
        ></DetailTag>
      );
    }
  });
  return renderedTags;
}
