import ApiUsers from "Api/user";
import { put, takeLatest, call } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import { toast } from "react-toastify";
import Cookie from "js-cookie";

// GET ADMIN PACKAGES
// params: page, limit, searchedText, sortBy, sortOrder, roleFilter
function* getUsersSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "usersListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiUsers.getUsers, action);
    const { users, pages, count } = response.data.data;
    yield delay(300);
    yield put({ type: "SAVE_USERS", users, count: count, pages: pages });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "usersListLoading",
    loadingValue: false,
  });
}

// get users with role "Administrator" or "Service owner"
function* getOwnersSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "ownersListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiUsers.getOwners, action);
    const users = response.data.data.users;
    yield delay(300);
    yield put({ type: "SAVE_OWNERS", users });
    const adminUser = users.find(
      (element) => element.email === "admin@blackboard.com"
    );
    yield put({ type: "SAVE_ADMIN", adminUser });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "ownersListLoading",
    loadingValue: false,
  });
}

// create user from admin
function* createUserSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "createUserLoading",
    loadingValue: true,
  });
  try {
    yield call(ApiUsers.createUser, action);
    // valid user form is a trigger or flag that indicates that the modal form must be closed

    yield put({
      type: "SET_VALIDATION",
      validationName: "validUserForm",
      validationValue: true,
    });
    yield delay(250);
    toast.success("User created successfully");

    yield put({
      type: "SET_VALIDATION",
      validationName: "validUserForm",
      validationValue: false,
    });
    yield put({
      type: "GET_USERS_SAGA",
      page: 1,
      limit: 100,
      roleFilter: "All",
      searchedText: [],
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "createUserLoading",
    loadingValue: false,
  });
}
// update user from admin, returns updated user data
function* updateUserSaga(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "editUserLoading",
    loadingValue: true,
  });
  try {
    yield call(ApiUsers.updateUser, action);

    yield put({
      type: "SET_VALIDATION",
      validationName: "validUserForm",
      validationValue: true,
    });
    yield delay(250);
    yield put({
      type: "SET_VALIDATION",
      validationName: "validUserForm",
      validationValue: false,
    });
    if (action.modifierId === action.id && action.userInfo.role > 0) {
      Cookie.remove("token");
      localStorage.removeItem("currentUser");
      const { history } = action;
      history.push("/login");
    } else {
      yield put({
        type: "GET_USERS_SAGA",
        page: 1,
        limit: 100,
        roleFilter: "All",
        searchedText: [],
      });
    }

    toast.success("User updated successfully");
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "editUserLoading",
    loadingValue: false,
  });
}
// delete user from admin
function* deleteUserSaga(action) {
  try {
    yield call(ApiUsers.deleteUser, action);
    if (action.modifierId === action.id) {
      Cookie.remove("token");
      localStorage.removeItem("currentUser");
      const { history } = action;
      history.push("/login");
    }

    toast.success("User deleted successfully");
    yield put({
      type: "GET_USERS_SAGA",
      page: 1,
      limit: 100,
      roleFilter: "All",
      searchedText: [],
    });
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
}

export const userSagas = [
  takeLatest("GET_USERS_SAGA", getUsersSaga),
  takeLatest("GET_OWNERS_SAGA", getOwnersSaga),
  takeLatest("CREATE_USER_SAGA", createUserSaga),
  takeLatest("UPDATE_USER_SAGA", updateUserSaga),
  takeLatest("DELETE_USER_SAGA", deleteUserSaga),
];
