// Login view styles
import styled from "styled-components/macro";
import LoadingOverlay from "react-loading-overlay";
import Colors from "Theme/Colors";

const StyledLoader = styled(LoadingOverlay)`
  overflow: visible;
  z-index: 3;
  width: 100%;
  min-height: 10vh;
  height: 100%;
  .MyLoader_overlay {
    background: ${Colors.loadingBackground};
  }
  &.MyLoader_wrapper--active {
    overflow: hidden;
  }
`;
export { StyledLoader };
