//Excelsior color palette

const Colors = {
  blackboard: "#272727",
  primaryActive: "rgb(84,0,168,1)",
  primarySelected: "rgb(84,0,168,0.6)",
  primaryDisabled: "rgb(84,0,168,0.4)",
  primaryText: "rgb(0,0,0,0.85)",
  selectedTag: "#332b3c",
  tag: "#E8E8E8", // 515151
  tagText: "#515151", //
  selectedText: "#515151",
  loadingBackground: "rgb(255,255,255,0.5)",
  primaryBorder: "#DECAF2",
  secondaryBorder: "#E8E8E8",
  secondary: "#CEA7F6",
  hover: "#EEE7F5",
  black: "#000000",
  white: "#FFFFFF",
  service: "#0053B2",
  package: "#CD4C00",
  component: "#105D1D",
  firstGray: "#262626",
  secondGray: "#515151",
  thirdGray: "#707070",
  fourthGray: "#767676",
  disabledGray: "#CBCBCB",
  selectedItem: "#E8E8E8",
  detail: "#F8F8F8",
  errorText: "#A40606",
  checkIcon: "#39E379",
  errorIcon: "#FF4A36",
};

export default Colors;
