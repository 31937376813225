// Users filters styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const UsersHeaderSD = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const CreateUserSD = styled.div`
  display: flex;
  padding: 1rem;
  @media ${mediaQueries.mobileL} {
    justify-content: flex-end;
    flex: 1;
  }
`;

const RoleFilterSD = styled.div`
  display: flex;
  min-width: 20rem;
  padding: 1rem;
  height: max-content;
  @media ${mediaQueries.mobileL} {
    justify-content: flex-start;
    flex: 2;
    margin-top: 1rem;
    padding-right: 0rem;
  }
`;

export { UsersHeaderSD, CreateUserSD, RoleFilterSD };
