import React, { useState } from "react";
import { Modal } from "antd";
import EditServiceContainer from "./EditServiceContainer";
import { ButtonS } from "Theme/Styles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function EditServiceModal({ id, itemIndex }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <ButtonS
        width={size("width")}
        height={size("height")}
        onClick={() => {
          setVisible(true);
        }}
      >
        EDIT
      </ButtonS>
      <Modal
        title="Edit service"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <EditServiceContainer
          setVisible={setVisible}
          id={id}
          itemIndex={itemIndex}
        />
      </Modal>
    </div>
  );
}

function size(type) {
  if (window.screen.width >= 768) {
    if (type === "height") {
      return "4rem";
    } else if (type === "width") {
      return "auto";
    }
  } else {
    if (type === "width") {
      return "6rem";
    }
  }
}
