// this is the component called by the route "/Login" of the main file "App.js"

import React from "react";
import {
  RNRoleBoxSD,
  RNRoleBoxTitleSD,
  RNRoleContentSD,
  RNRoleSubtitleSD,
  RNItemSD,
  RNRoleRowSD
} from "./RNRoleBoxStyles";

/**
 * HOOK
 * RNRoleBox contains categories and a list of new features for each category
 */

function RNRoleBox(props) {
  // render all categories
  const renderRoleContent = () => {
    const renderedRoleContent = [];
    // for each category we show a list with the new features
    props.roleInfo.forEach(category => {
      const remderedItems = category.list.map((item, index) => (
        <RNItemSD key={category.subtitle + index}>
          <span>&#9679;</span> &nbsp;{item}
        </RNItemSD>
      ));
      // box
      renderedRoleContent.push(
        <RNRoleRowSD>
          <RNRoleSubtitleSD>{category.subtitle}</RNRoleSubtitleSD>
          {remderedItems}
        </RNRoleRowSD>
      );
    });
    return renderedRoleContent;
  };
  const renderedRoleContent = renderRoleContent();
  return (
    <RNRoleBoxSD className="RNRoleBoxSD">
      <RNRoleBoxTitleSD>{props.roleName}</RNRoleBoxTitleSD>
      <RNRoleContentSD>{renderedRoleContent}</RNRoleContentSD>
    </RNRoleBoxSD>
  );
}

export default RNRoleBox;
