import { initialState } from "Redux/initialState";

/// node: service
const servicesReducer = (state = initialState.service, action) => {
  switch (action.type) {
    case "SAVE_SERVICE_LIST":
      return {
        ...state,
        serviceList: action.serviceList,
        count: action.count,
        pages: action.pages,
      };
    // when we edit a service we receive an updated service, and we save it
    case "SAVE_UPDATED_SERVICE":
      const serviceList = [...state.serviceList];
      const servicesDetail = { ...state.servicesDetail };
      const index = serviceList.findIndex(
        (element) => element.cpqCode === action.updatedService.cpqCode
      );
      serviceList[index] = action.updatedService;
      servicesDetail[action.updatedService._id] = action.updatedService;
      return {
        ...state,
        serviceList,
        servicesDetail,
      };
    // save all data of an specific service and save it into state.services.detail.[service._id]
    case "SAVE_SERVICE_DETAIL":
      let serviceDetailState = { ...state.servicesDetail };
      serviceDetailState[action.serviceId] = action.serviceDetail;
      return {
        ...state,
        servicesDetail: serviceDetailState,
      };

    default:
      return state;
  }
};

export default servicesReducer;
