import React from "react";
import ReleaseNotesView from "./ReleaseNotesView";
import { menu } from "./relaseNotes";

/*
 HOOK: Release Notes Container 
 Takes an array with all release notes and passes it to view
 */
function ReleaseNotesContainer(props) {
  return <ReleaseNotesView menu={menu}></ReleaseNotesView>;
}

export default ReleaseNotesContainer;
