import React from "react";
import { CheckError } from "Components/Common";
import {
  AdminDetailColumnSD,
  DetailSubtitleSD,
  DetailSubsubtitleSD,
  HorizontalSD,
  GeneralSectionSD,
} from "Theme/DetailStyles";
import Colors from "Theme/Colors";
import { RowSD } from "Theme/Styles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

function mark(key, changes) {
  if (Object.entries(changes).length === 0) {
    return false;
  } else {
    return changes[key];
  }
}

export default function SalesInfoDetailSection({
  detailInfo,
  changes,
  detailType,
}) {
  const getChargingModeText = () => {
    let chargingModeText = "";
    switch (detailInfo.chargingMode) {
      case "FP":
        chargingModeText = "Fixed price";
        break;
      case "TM":
        chargingModeText = "Time and Materials";
        break;
      case "BS":
        chargingModeText = "By seats";
        break;
      default:
        chargingModeText = "";
        break;
    }

    return chargingModeText;
  };

  return (
    <AdminDetailColumnSD backgroundColor={Colors.blackboard} color="white">
      <HorizontalSD></HorizontalSD>
      <DetailSubtitleSD>Sales information</DetailSubtitleSD>
      <RowSD padding="0.5rem 0rem">
        <GeneralSectionSD alignItems="flex-end">
          <DetailSubsubtitleSD mark={mark("estimationRequired", changes)}>
            Estimation required:
          </DetailSubsubtitleSD>
        </GeneralSectionSD>
        <GeneralSectionSD>
          <CheckError
            size={15}
            checked={detailInfo.estimationRequired}
          ></CheckError>
        </GeneralSectionSD>
      </RowSD>
      {!detailInfo.estimationRequired && detailType === "service" ? (
        <RowSD padding="0.5rem 0rem">
          <GeneralSectionSD alignItems="flex-end">
            <DetailSubsubtitleSD mark={mark("chargingMode", changes)}>
              Charging mode:
            </DetailSubsubtitleSD>
          </GeneralSectionSD>

          <GeneralSectionSD>{getChargingModeText()}</GeneralSectionSD>
        </RowSD>
      ) : null}
      {/* <RowSD padding="0.5rem 0rem">
        <GeneralSectionSD alignItems="flex-end">
          <DetailSubsubtitleSD mark={mark("estimationRequired", changes)}>
            Renewable:
          </DetailSubsubtitleSD>
        </GeneralSectionSD>
        <GeneralSectionSD>
          <CheckError size={15} checked={detailInfo.renewable}></CheckError>
        </GeneralSectionSD>
      </RowSD> */}
      <RowSD padding="0.5rem 0rem">
        <GeneralSectionSD alignItems="flex-end">
          <DetailSubsubtitleSD mark={mark("estimationRequired", changes)}>
            CPQ code:
          </DetailSubsubtitleSD>
        </GeneralSectionSD>
        <GeneralSectionSD>{detailInfo.cpqCode}</GeneralSectionSD>
      </RowSD>
      <HorizontalSD></HorizontalSD>
    </AdminDetailColumnSD>
  );
}
