import packagesImage from "Assets/Images/AdminImages/Packages_image.jpg";
import requestsImage from "Assets/Images/AdminImages/Requests_image.jpg";
import servicesImage from "Assets/Images/AdminImages/Services_image.jpg";
import usersImage from "Assets/Images/AdminImages/Users_image.jpg";
import React from "react";
import { withRouter } from "react-router-dom";
import { ButtonS } from "Theme/Styles";
import { AdminHeaderButtonsSD, AdminHeaderSD } from "./AdminHeaderStyles";

function AdminHeader(props) {
  const { pathname } = props.history.location;
  const imageType = pathname.substring(7);
  const image = getHeaderImage(imageType);
  return (
    <AdminHeaderSD className="AdminHeaderSD" image={image}>
      <AdminHeaderButtonsSD>
        <ButtonS
          width={size("width")}
          height={size("height")}
          buttonType="cancel"
          onClick={() => props.goToSales()}
        >
          GO TO SALES
        </ButtonS>
      </AdminHeaderButtonsSD>
      <AdminHeaderButtonsSD>
        <ButtonS
          width={size("width")}
          height={size("height")}
          onClick={() => props.logoutSaga()}
        >
          LOGOUT
        </ButtonS>
      </AdminHeaderButtonsSD>
    </AdminHeaderSD>
  );
}

function size(type) {
  if (window.screen.width >= 768) {
    if (type === "height") {
      return "4rem";
    } else if (type === "width") {
      return "auto";
    }
  } else {
    if (type === "width") {
      return "9rem";
    }
  }
}

function getHeaderImage(imageType) {
  switch (imageType) {
    case "services":
      return servicesImage;
    case "packages":
      return packagesImage;
    case "requests":
      return requestsImage;
    case "users":
      return usersImage;
    default:
      return usersImage;
  }
}

export default withRouter(AdminHeader);
