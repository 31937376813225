// this is the component called by the route "/Login" of the main file "App.js"

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import NewPasswordView from "./NewPasswordView";

/**
 * HOOK
 * This is the component called by the route "/resetPassword" of the main file "App.js"
 * calls the New Password view
 */

function NewPasswordContainer(props) {
  // we obtain all new password loading states
  const resetPasswordLoading = useSelector(
    (state) => state.system.loadings.resetPasswordLoading
  );

  const { history } = props;
  // get url params
  const params = new URLSearchParams(props.location.search);
  const token = params.get("token");

  const dispatch = useDispatch();
  // dispatch sagas
  const updatePasswordSaga = (password) => {
    dispatch({
      type: "RESET_PASSWORD_FROM_EMAIL_SAGA",
      token: token,
      password: password,
      history: history,
    });
  };

  return (
    <NewPasswordView
      updatePasswordSaga={updatePasswordSaga}
      resetPasswordLoading={resetPasswordLoading}
    />
  );
}

export default withRouter(NewPasswordContainer);
