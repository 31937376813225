import { Select } from "antd";
import React from "react";
import { BasicFilterSD, LanguageSD } from "./SalesFiltersStyles";
import { FilterSectionSD } from "../SalesStyles";

const { Option } = Select;

/**
 * HOOK
 * Admin view, the view groups and sorts the visual components
 */

export default function SalesFilters({
  languages,
  filterByLanguage,
  sortBy,
  setSortBy,
  actualLanguage,
}) {
  return (
    <FilterSectionSD justifyContent="flex-end">
      <LanguageSD>
        <Select
          defaultValue={actualLanguage}
          onChange={(value) => filterByLanguage(value)}
        >
          {renderLanguageOptions(languages)}
        </Select>
      </LanguageSD>

      <BasicFilterSD>
        <Select value={sortBy} onChange={(value) => setSortBy(value)}>
          <Option value="byRelevance">By relevance</Option>
          <Option value="lastUpdate">By last update</Option>
          <Option value="cpqCode">By CPQ code</Option>
          <Option value="name">By name</Option>
        </Select>
      </BasicFilterSD>
    </FilterSectionSD>
  );
}

function renderLanguageOptions(languages) {
  const renderedOptions = [];
  languages.forEach((language) => {
    renderedOptions.push(
      <Option key={language.id} value={language.id}>
        {language.id}
      </Option>
    );
  });

  return renderedOptions;
}
