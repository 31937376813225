// this is the component called by the route "/Login" of the main file "App.js"
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SalesServiceDetailView from "./SalesServiceDetailView";

export default function SalesServiceDetailContainer({ id, height }) {
  const [selectedLanguage, setLanguage] = useState("EN-US");
  const serviceDetail = useSelector(
    (state) => state.service.servicesDetail[id]
  );
  const serviceDetailLoading = useSelector(
    (state) => state.system.loadings.serviceDetailLoading
  );
  const salesFilters = useSelector((state) => state.system.salesFilters);
  const dispatch = useDispatch();

  const getServiceDetail = () => {
    dispatch({ type: "GET_SERVICE_DETAIL_SAGA", id: id });
  };
  const getReports = (report) => {
    if (report === "SOW (Autogenerate)") {
      dispatch({
        type: "GET_SOW",
        cpqCode: serviceDetail.cpqCode,
        userBand: salesFilters.userBand,
        seats: salesFilters.seats,
        tier: salesFilters.tier,
        language: selectedLanguage,
      });
    } else if (report === "WBS (Autogenerate)") {
      dispatch({
        type: "GET_WBS",
        id: serviceDetail._id,
        language: selectedLanguage,
      });
    }
  };
  useEffect(() => {
    getServiceDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <SalesServiceDetailView
      serviceInfo={serviceDetail}
      selectedLanguage={selectedLanguage}
      setLanguage={setLanguage}
      serviceDetailLoading={serviceDetailLoading}
      getReports={getReports}
    />
  );
}
