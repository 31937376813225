import styled from "styled-components/macro";

const ServicePricing = styled.div`
  padding: 2rem;
  margin: 1rem;
  border-radius: 3px;
  background-color: white;
  border-left: 1rem solid #0053b2;
`;

export const Styles = { ServicePricing };
