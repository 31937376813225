import { CustomLoadingOverlay } from "Components";
import { SalesDetailHeader } from "Components/Common";
import React from "react";
import { SalesDetailSD, SalesAffixSD } from "Theme/DetailStyles";
import DetailCollaterals from "./DetailCollaterals";
import DetailObjective from "./DetailObjective";
import DetailScope from "./DetailScope";
import SalesGeneralDetail from "Components/Common/Sales/SalesGeneralDetail";
import { Affix } from "antd";
import { validURL } from "Helpers/Helpers";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function SalesServiceDetailView({
  serviceInfo,
  selectedLanguage,
  setLanguage,
  serviceDetailLoading,
  getReports,
}) {
  console.log(serviceInfo);
  function showCollaterals(collaterals) {
    let show = false;
    collaterals.forEach((collateral) => {
      if (validURL(collateral.link)) {
        show = true;
      }
    });
    return show;
  }
  const collateralsValues = () => {
    let collaterals = [];
    if (serviceInfo) {
      if (serviceInfo.localization) {
        collaterals = serviceInfo.localization[selectedLanguage].collaterals;
      } else if (serviceInfo.collaterals) {
        collaterals = serviceInfo.collaterals;
      }
    }

    return collaterals;
  };
  const showCollateralsBoolean = showCollaterals(collateralsValues());
  var general = document.getElementById("general");
  var objective = document.getElementById("objective");
  var scope = document.getElementById("scope");
  var drawer = document.getElementsByClassName("ant-drawer-wrapper-body");
  const scrollToRef = (id) => {
    const headerHeight = document.getElementById("affix").clientHeight;
    var y = 0;
    switch (id) {
      case "general":
        y = general.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        general.scrollIntoView();
        break;
      case "objective":
        y = objective.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
      case "scope":
        y = scope.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
      default:
        y = scope.offsetTop;
        drawer[0].scrollTop = y - headerHeight;
        break;
    }
  };
  return (
    <CustomLoadingOverlay active={serviceDetailLoading}>
      {serviceInfo === undefined ? (
        <SalesDetailSD>No detail</SalesDetailSD>
      ) : (
        <SalesDetailSD>
          <SalesAffixSD id="affix">
            <Affix offsetTop={0.1}>
              <SalesDetailHeader
                name={serviceInfo.name}
                cpqCode={serviceInfo.cpqCode}
                localization={serviceInfo.localization}
                selectedLanguage={selectedLanguage}
                setLanguage={setLanguage}
                id={serviceInfo._id}
                type="service"
                scrollToRef={scrollToRef}
              />
              <div id="general"></div>
            </Affix>
          </SalesAffixSD>
          <SalesGeneralDetail
            type="service"
            detailInfo={serviceInfo}
            selectedLanguage={selectedLanguage}
          />
          <div id="objective"></div>
          <DetailObjective
            serviceInfo={serviceInfo}
            selectedLanguage={selectedLanguage}
          ></DetailObjective>
          <div id="scope"></div>
          <DetailScope
            serviceInfo={serviceInfo}
            selectedLanguage={selectedLanguage}
          ></DetailScope>
          {showCollateralsBoolean ? (
            <DetailCollaterals
              serviceInfo={serviceInfo}
              selectedLanguage={selectedLanguage}
              getReports={getReports}
            ></DetailCollaterals>
          ) : null}
        </SalesDetailSD>
      )}
    </CustomLoadingOverlay>
  );
}
