import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const TableSD = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  width: 100%;
  max-width: 90vw;
  padding: 1rem 0rem 1rem 0rem;
  svg {
    color: ${Colors.primaryActive};
  }
  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
    background-color: ${Colors.selectedItem};
  }
  @media ${mediaQueries.tablet} {
    padding: 0rem;
  }
  @media ${mediaQueries.mobileL} {
    .ant-collapse-arrow {
      right: 0.9rem !important;
    }
  }
`;

const HeaderSD = styled.div`
  display: flex;

  background-color: ${Colors.blackboard};
  color: ${Colors.white};
  border-radius: 1.5rem;
  padding: 0.5rem 0.7rem 0.5rem 1.9rem;
  width: 100%;
  align-self: center;
  z-index: 5;
`;

const HeaderContentSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const HeaderOptionSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-family: "SourceSansPro-Semibold";
  font-size: 1.4rem;
  border-width: 0rem 0rem 0rem ${(props) => (props.divisor ? "0.2rem" : "0rem")};
  border-style: solid;
  border-color: ${Colors.secondary};
  word-break: break-word;
  border: 0.2rem solid;
  border-color: transparent;
  border-left-color: ${(props) =>
    props.divisor ? Colors.secondary : "transparent"};
  padding-left: 1.5rem;
  @media ${mediaQueries.tablet} {
    font-size: 0.9rem;
  }
  @media ${mediaQueries.mobileL} {
    padding-left: 0.5rem;
  }
  cursor: pointer;
`;

const ContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0rem 0rem 1rem 1rem;
  border: 0.1rem solid rgb(238, 238, 238);
  margin: 0 0.7rem;
  z-index: 3;
  .ant-collapse-header {
    border-left: 0.6rem solid ${(props) => props.rowBorderColor};
    border-width: 0rem 1rem 0rem 1rem;
    border-radius: 0.3rem;
  }

  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  .ant-collapse.ant-collapse-icon-position-left {
    flex-direction: column;
  }

  @media ${mediaQueries.mobileL} {
    margin: 0 0.5rem;
  }
`;

const RowSD = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  padding-top: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  .ant-select-selection,
  .ant-select-selection--single {
    box-shadow: none;
  }
  flex: 1;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    border-left: 0.8rem solid ${(props) => props.rowBorderColor};
    border-width: 0rem 1rem 0rem 1rem;
    border-radius: 0.3rem;
    height: auto;
  }
  @media ${mediaQueries.mobileL} {
  }
`;

const LabelSD = styled.div`
  flex: 1;
  font-family: "SourceSansPro-Light";
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  color: ${(props) =>
    props.color ? Colors.primaryActive : Colors.primaryText};
  /* padding-left: 1rem; */
  overflow-wrap: break-word;
  word-break: break-word;
  /* border: solid 0.2rem; */
  border: 0.2rem solid;
  border-color: transparent;
  padding-left: 1.5rem;

  @media ${mediaQueries.tablet} {
    font-size: 0.9rem;
  }
  @media ${mediaQueries.mobileL} {
    padding-left: 0.5rem;
  }
`;

const PackageDetailTableSD = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const PackagePricingRowSD = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${(props) =>
    props.paddingV ? props.paddingV + "rem" : "0.1rem"};
  padding-top: 1rem;
  flex: 1;
  background-color: ${Colors.hover};
  font-size: 2rem;
`;

export {
  TableSD,
  HeaderSD,
  HeaderContentSD,
  HeaderOptionSD,
  ContentSD,
  RowSD,
  LabelSD,
  PackageDetailTableSD,
  PackagePricingRowSD,
};
