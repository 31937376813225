import { CustomLoadingOverlay } from "Components";
import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { LandingCardSD } from "Theme/Styles";
/**
 * HOOK
 * Reset password view, the view groups and sorts the visual components
 */

export default function ResetPasswordCard({
  resetPasswordLoading,
  togglLoginCard,
  resetPasswordSaga,
}) {
  return (
    <LandingCardSD>
      <CustomLoadingOverlay active={resetPasswordLoading}>
        <ResetPasswordForm
          togglLoginCard={togglLoginCard}
          resetPasswordSaga={resetPasswordSaga}
        />
      </CustomLoadingOverlay>
    </LandingCardSD>
  );
}
