import React from "react";
import { StyledLoader } from "./CustomLoadingOverlayStyles";
import Colors from "Theme/Colors";
import PacmanLoader from "react-spinners/PacmanLoader";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function CustomLoadingOverlay({ active, children }) {
  return (
    <StyledLoader
      active={active}
      classNamePrefix="MyLoader_"
      spinner={<PacmanLoader color={Colors.primaryActive} size={15} />}
    >
      {children}
    </StyledLoader>
  );
}
