import numeral from "numeral";

export const formatPricing = (value) => {
  if (isNaN(value)) {
    return value;
  }
  if (value === 0) {
    return "N/A";
  } else {
    return String(numeral(value).format("0,0.00")) + " USD";
  }
};

export const calculatePricingBySeats = (pricing, tier, seats) => {};

// to set the default value of pricing matrix when the pricing option is single
// ... "single" is when no checkboxes is checked
export const onChangeSingle = function (
  pricing,
  setPricing,
  chargingMode,
  value,
  minimumSeats
) {
  switch (chargingMode) {
    case "FP":
      setPricing([[0, value, value, value, value]]);
      break;
    case "BS":
      setPricing([[minimumSeats, value, value, value, value]]);
      break;
    case "TM":
      setPricing([["Project Manager", value, value, value, value]]);
      break;
    default:
      break;
  }
};

// used to update the value of a given tier in pricing
export const onChangeTier = function (pricing, setPricing, tier, value, row) {
  let pricingCopy = [...pricing];
  pricingCopy[row][tier] = value;
  setPricing(pricingCopy);
};

// if any row is removed from pricing, it is removed from the matrix
export const onRemove = function (pricing, setPricing, rowIndex) {
  let pricingCopy = [...pricing];
  pricingCopy.splice(rowIndex, 1);
  setPricing(pricingCopy);
};

// used to update the pricing matrix when the pricing option is situational
// ... "situational" is when there is only one checked checkbox and it is not the tier one
// ... that is, any of the following: User band, Roles or By seats
export const onChangeSituational = function (
  pricing,
  setPricing,
  value,
  rowIndex,
  situationalName
) {
  let pricingCopy = [...pricing];
  pricingCopy[rowIndex] = [situationalName, value, value, value, value];
  setPricing(pricingCopy);
};

// if any row is added from pricing, it is added to the matrix
export const onAdd = function (
  pricing,
  setPricing,
  values,
  situationalName,
  chargingMode,
  minimumSeats
) {
  let valuesExist = true;
  let errorMessage = null;
  // it is verified that in the whole row there is no null value
  if (chargingMode === "BS") {
    if (values[0] < minimumSeats) {
      valuesExist = false;
      errorMessage = "Please set a seat greater than minimum seats value";
    }
  }
  values.forEach((element) => {
    if (!element) {
      if (element !== 0) {
        valuesExist = false;
        errorMessage = "Please fill all the fields";
      }
    }
  });
  // it is verified that in the whole row there is no repeated value
  pricing.forEach((element) => {
    if (String(element[0]) === String(values[0])) {
      valuesExist = false;
      errorMessage = situationalName + " repeated";
    }
  });
  if (valuesExist === true) {
    let pricingCopy = [...pricing, values];
    pricingCopy = onSort(pricingCopy);
    setPricing(pricingCopy);
  }
  return errorMessage;
};

// when some new User band or Seats is added, it needs to be organized respectively
// ... in the matrix, so that the ranges are coherent
export const onSort = function (pricingCopy) {
  pricingCopy.sort(function (a, b) {
    if (a[0] > b[0]) {
      return 1;
    }
    if (a[0] < b[0]) {
      return -1;
    }
    return 0;
  });
  return pricingCopy;
};

export const changeMinimumSeatsPricing = (minimumSeats, copyPricing) => {
  if (minimumSeats) {
    if (minimumSeats >= copyPricing[copyPricing.length - 1][0]) {
      copyPricing = [copyPricing[copyPricing.length - 1]];
    } else {
      for (let i = 0; i < copyPricing.length - 1; i++) {
        if (parseInt(minimumSeats) < copyPricing[i + 1][0]) {
          copyPricing = copyPricing.slice(i, copyPricing.length);
          break;
        }
      }
    }
    copyPricing[0][0] = minimumSeats;
    return copyPricing;
  }
};

// to set the default value of pricing matrix when the pricing option is dual or situational
// ... "dual" is when there is two checked checkboxes the tier one and some situational
export function setPricingStructure(chargingMode, pricingOption, minimumSeats) {
  if (chargingMode === "FP") {
    if (pricingOption === "dual" || pricingOption === "situational") {
      return [
        [0, 0, 0, 0, 0],
        [500, 0, 0, 0, 0],
        [1000, 0, 0, 0, 0],
        [2000, 0, 0, 0, 0],
        [5000, 0, 0, 0, 0],
        [10000, 0, 0, 0, 0],
        [20000, 0, 0, 0, 0],
        [50000, 0, 0, 0, 0],
      ];
    } else {
      return [[0, 0, 0, 0, 0]];
    }
  } else if (chargingMode === "BS") {
    if (pricingOption === "dual" || pricingOption === "situational") {
      return changeMinimumSeatsPricing(minimumSeats, [
        [1, 0, 0, 0, 0],
        [15, 0, 0, 0, 0],
        [30, 0, 0, 0, 0],
        [45, 0, 0, 0, 0],
        [100, 0, 0, 0, 0],
        [200, 0, 0, 0, 0],
        [500, 0, 0, 0, 0],
        [1000, 0, 0, 0, 0],
      ]);
    } else {
      return [[minimumSeats, 0, 0, 0, 0]];
    }
  } else if (chargingMode === "TM") {
    return [["Project Manager", 0, 0, 0, 0]];
  } else {
    return [[0, 0, 0, 0, 0]];
  }
}

// to get the the current pricing option
export function getPricingOption(checkboxValues) {
  // single: when no checkbox is checked
  if (checkboxValues.tier === false && checkboxValues.condition === false) {
    return "single";
  }
  // tier: when there is only one checked checkbox and it is the tier one
  if (checkboxValues.tier === true && checkboxValues.condition === false) {
    return "tier";
  }
  // situational: when there is only one checked checkbox and it is not the tier one
  if (checkboxValues.tier === false && checkboxValues.condition === true) {
    return "situational";
  }
  // dual: when there is two checked checkboxes, the tier one and some situational
  if (checkboxValues.tier === true && checkboxValues.condition === true) {
    return "dual";
  }
}

// to set the checkboxes values depends on current pricing option
export function getCheckboxValues(pricingOption) {
  switch (pricingOption) {
    case "single":
      return {
        tier: false,
        condition: false,
      };
    case "tier":
      return {
        tier: true,
        condition: false,
      };

    case "situational":
      return {
        tier: false,
        condition: true,
      };

    case "dual":
      return {
        tier: true,
        condition: true,
      };
    default:
      return {
        tier: false,
        condition: false,
      };
  }
}

//  Function used to validate pricing on service
export function validatePricing(pricing, chargingMode, pricingOption) {
  const response = {};
  response.errors = [];
  if (chargingMode !== "FP" && chargingMode !== "TM" && chargingMode !== "BS") {
    response.errors.push("Invalid charging mode");
    return response;
  }
  for (var e = 0; e < pricing.length; e++) {
    if (pricing[e].length !== 5) {
      response.errors.push("Missing some tier information");
      return response;
    }
  }
  const validTiers = ["T1", "T2", "T3", "T4"];

  let currentError = "";
  switch (pricingOption) {
    case "single":
      //  Case Single: Every value must be the same
      const singleValue = pricing[0][1];
      currentError = `Invalid values, must be the same on every field`;
      //  Checking that every value in the matrix is the same
      rowLoop: for (var i = 0; i < pricing.length; i++) {
        for (var j = 1; j < pricing[0].length; j++) {
          if (pricing[i][j] !== singleValue) {
            response.errors.push(currentError);
            break rowLoop;
          }
        }
      }
      break;
    case "tier":
      //  Case Tier: Every value by tier must be the same
      currentError = `Invalid values, must be the same for every tier`;
      colLoop: for (var tj = 1; tj < validTiers.length; tj++) {
        let currentTierValue = pricing[0][j];
        for (var ti = 1; ti < pricing.length - 1; ti++) {
          if (pricing[ti][tj] !== currentTierValue) {
            response.errors.push(currentError);
            break colLoop;
          }
        }
      }
      break;
    case "situational":
      //  Case Situational : Every value by situational value must be the same
      currentError = `Invalid values, must be the same for every row`;
      columnLoop: for (var si = 0; si < pricing.length; si++) {
        let currentUserBandValue = pricing[si][1];
        for (var sj = 1; sj < validTiers.length; sj++) {
          if (pricing[si][sj] !== currentUserBandValue) {
            response.errors.push(currentError);
            break columnLoop;
          }
        }
      }
      break;
    case "dual":
      break;
    default:
      break;
  }

  return response;
}

//  Ubicate number value on array of numbers
//  arrayOfValues must be sorted from smaller to greater
export function ubicateValueOnArray(value, arrayOfValues) {
  if (isNaN(value)) {
    value = Number(value);
  }
  for (var i = 0; i < arrayOfValues.length; i++) {
    if (i === 0) {
      if (value <= arrayOfValues[i] || arrayOfValues.length === 1) {
        return { value: arrayOfValues[i], index: i };
      }
      if (value >= arrayOfValues[i] && value < arrayOfValues[i + 1]) {
        return { value: arrayOfValues[i], index: i };
      }
    } else if (i < arrayOfValues.length - 1) {
      if (arrayOfValues[i] <= value && value < arrayOfValues[i + 1]) {
        return { value: arrayOfValues[i], index: i };
      }
    } else {
      return {
        value: arrayOfValues[arrayOfValues.length - 1],
        index: arrayOfValues.length - 1,
      };
    }
  }
}

//  Extract array of values from matrix
let extractValuesFromMatrix = (matrix) => {
  if (matrix.length === 0 || matrix[0].length === 0) {
    throw new Error("Matrix must contain at least one row");
  }
  let arrayOfValues = [];
  for (var i = 0; i < matrix.length; i++) {
    arrayOfValues.push(matrix[i][0]);
  }
  return arrayOfValues;
};

//  Return column number from tier
export function columnFromTier(tier) {
  switch (tier) {
    case "T1":
      return 1;
    case "T2":
      return 2;
    case "T3":
      return 3;
    case "T4":
      return 4;
    default:
      console.log("invalid tier");
      break;
  }
}

//  Function used to calculate service pricing

export function calculateServicePrice_FP_or_BS(
  service,
  selectedTier,
  selectedUserband,
  selectedSeats
) {
  let pricingMatrix = service.pricing;
  let matrixRow = 0;
  let matrixColumn = 0;
  let selectedRangeValue = 0;

  if (service.estimationRequired) {
    return "Estimation required";
  }
  if (service.chargingMode === "TM") {
    return "N/A";
  }
  switch (selectedTier) {
    case "T1":
      matrixColumn = 1;
      break;
    case "T2":
      matrixColumn = 2;
      break;
    case "T3":
      matrixColumn = 3;
      break;
    case "T4":
      matrixColumn = 4;
      break;
    default:
      matrixColumn = 2; //Tier 2, by default
      break;
  }
  if (service.chargingMode === "FP") {
    selectedRangeValue = selectedUserband;
  }
  if (service.chargingMode === "BS") {
    selectedRangeValue = selectedSeats;
  }
  if (selectedRangeValue) {
    for (var i = 0; i < pricingMatrix.length; i++) {
      if (selectedRangeValue >= pricingMatrix[i][0]) {
        matrixRow = i;
      }
    }
    if (service.chargingMode === "FP") {
      return pricingMatrix[matrixRow][matrixColumn];
    }
    if (service.chargingMode === "BS") {
      return pricingMatrix[matrixRow][matrixColumn] * selectedSeats;
    }
  } else {
    return "N/A";
  }
}

export function calculateServicePricing(service, valuesForPricing) {
  let finalPrice = {};
  let pricingMatrix = service.pricing;
  let valuesFromMatrix;
  //  TODO: FIX THIS CASE, THIS MUST BE PUSHED TO AN ARRAY OF ESTIMATION REQUIRED SERVICES ON PACKAGE CALCULATION,
  //  MAYBE SOME SERVICES DOESN'T HAVE PRICING, CHECK THAT CASE
  if (pricingMatrix.length === 0) {
    finalPrice.price = 0;
    return finalPrice;
  }
  if (!service.estimationRequired) {
    valuesFromMatrix = extractValuesFromMatrix(pricingMatrix);
  }
  let currentSeats = JSON.parse(JSON.stringify(valuesForPricing.seats));
  if (currentSeats < service.minimunSeats) {
    currentSeats = service.minimunSeats;
  }
  if (service.estimationRequired) {
    finalPrice.price = 0;
    return finalPrice;
    //  For every case must be one tier T1 T2 T3 T4, it indicates is the column of the matrix
  } else if (service.chargingMode === "FP") {
    //  User band is the number of users
    let valueUbicated = ubicateValueOnArray(
      valuesForPricing.userBand,
      valuesFromMatrix
    );
    let tierUbicated = columnFromTier(valuesForPricing.tier);
    finalPrice.price = service.pricing[valueUbicated.index][tierUbicated];
    return finalPrice;
  } else if (service.chargingMode === "BS") {
    //  Seats is the number of seats
    let valueUbicated = ubicateValueOnArray(currentSeats, valuesFromMatrix);
    let tierUbicated = columnFromTier(valuesForPricing.tier);
    if (service.cpqCode === "TESTING-FAILING" && valuesForPricing.seats === 2) {
      console.log(valuesFromMatrix);
      console.log(valueUbicated);
      console.log(service.pricing[valueUbicated.index][tierUbicated]);
    }
    finalPrice.price =
      service.pricing[valueUbicated.index][tierUbicated] * currentSeats;
    return finalPrice;
  } else if (service.chargingMode === "TM") {
    finalPrice.price = service.pricing;
    return finalPrice;
  }
}

//  Function used on calculate package pricing
export function calculatePackagePricing(Package, valuesForPricing) {
  let services = JSON.parse(JSON.stringify(Package.services));
  let currentSeats = JSON.parse(JSON.stringify(valuesForPricing.seats));
  let finalPrice = { tm: [], estimationRequired: [], price: 0, errors: [] };
  let missingServicesForCalculation = [];
  const typePrices = [];
  let servicesBS = [];
  let servicesFP = [];
  let minimumSeats = [];
  //  Categorizing every service according to its pricing type
  for (var i = 0; i < services.length; i++) {
    if (services[i].estimationRequired) {
      finalPrice.estimationRequired.push(services[i]);
    } else if (services[i].chargingMode === "TM") {
      finalPrice.tm.push(services[i]);
    } else {
      if (services[i].chargingMode === "FP") {
        servicesFP.push(services[i]);
      } else if (services[i].chargingMode === "BS") {
        servicesBS.push(services[i]);
        minimumSeats.push(services[i].minimunSeats);
      }
      missingServicesForCalculation.push(services[i]);
      typePrices.push(services[i].chargingMode);
    }
  }

  //  Finding the maximum minimum seats to calculate by seats pricing
  if (minimumSeats.length > 0) {
    let maxMinimumSeats = 0;
    for (var mi = 0; mi < minimumSeats.length; mi++) {
      if (minimumSeats[mi] > maxMinimumSeats) {
        maxMinimumSeats = minimumSeats[mi];
      }
    }
    if (currentSeats < maxMinimumSeats) {
      currentSeats = maxMinimumSeats;
    }
  }

  //  Calculating pricing for every service
  if (missingServicesForCalculation.length === 0) {
    return finalPrice;

    //  Calculating pricing for every FP service (Case FP services but not BS services on package)
  } else if (typePrices.includes("FP") && !typePrices.includes("BS")) {
    //  Extracting user bands from every missing service pricing

    //  let pricingRanges = calculatePricingRanges(missingServicesForCalculation);

    //  Now we should calculate pricing for every possible range if we want to see an overview of the pricing

    //  Calculating the pricing for every service through summation
    let totalPrice = 0;
    for (let i = 0; i < missingServicesForCalculation.length; i++) {
      totalPrice =
        totalPrice +
        calculateServicePricing(
          missingServicesForCalculation[i],
          valuesForPricing
        ).price;
    }
    finalPrice.price = totalPrice;
    return finalPrice;

    //  Calculating pricing for every BS service (Case BS services but not FP services on package)
  } else if (typePrices.includes("BS") && !typePrices.includes("FP")) {
    //  Calculating the pricing of every service through summation
    let totalPrice = 0;
    for (let i = 0; i < missingServicesForCalculation.length; i++) {
      totalPrice =
        totalPrice +
        calculateServicePricing(
          missingServicesForCalculation[i],
          valuesForPricing
        ).price;
    }
    finalPrice.price = totalPrice;
    return finalPrice;
    //  Calculating pricing for every BS and FP service (Case bot BS and FP services on package)
  } else if (typePrices.includes("BS") && typePrices.includes("FP")) {
    //  Calculating the pricing of every BS service through summation
    let pricingBS = 0;
    for (let i = 0; i < servicesBS.length; i++) {
      pricingBS =
        pricingBS +
        calculateServicePricing(servicesBS[i], valuesForPricing).price;
    }

    //  Calculating the pricing of every FP service through summation
    let pricingFP = 0;
    for (let i = 0; i < servicesFP.length; i++) {
      pricingFP =
        pricingFP +
        calculateServicePricing(servicesFP[i], valuesForPricing).price;
    }

    finalPrice.price = pricingFP + pricingBS;
    return finalPrice;
  }
}

export const getChargingModeName = (chargingMode) => {
  switch (chargingMode) {
    case "FP":
      return "User band";
    case "TM":
      return "Time and materials";
    case "BS":
      return "By seats";
    default:
      return "Not specified";
  }
};
