// Login view styles
import styled from "styled-components/macro";

const RequestsTableSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export { RequestsTableSD };
