import React from "react";
import Colors from "Theme/Colors";
import {
  HorizontalSD,
  ObjectiveContentSD,
  ObjectiveDetailSD,
  DetailSubtitleSD,
} from "Theme/DetailStyles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function DetailObjective({ serviceInfo, selectedLanguage }) {
  return (
    <ObjectiveDetailSD>
      <HorizontalSD></HorizontalSD>
      <ObjectiveContentSD>
        <DetailSubtitleSD color={Colors.white} centered>
          Objective
        </DetailSubtitleSD>
        <div>{serviceInfo.localization[selectedLanguage].objective}</div>
      </ObjectiveContentSD>
      <HorizontalSD></HorizontalSD>
    </ObjectiveDetailSD>
  );
}
