import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminPackagesView from "./AdminPackagesView";

/**
 * HOOK
 * This is the component called by the route "/admin/packages" of the main file "App.js"
 * calls the Admin View
 */

export default function AdminPackagesContainer() {
  // we obtain all users loading states
  const packageListLoading = useSelector(
    (state) => state.system.loadings.packageListLoading
  );
  // get the amount of request allowed per page
  const globalItemsPerPage = useSelector(
    (state) => state.system.globalItemsPerPage
  );
  // get the total amount of pages in requests pagination
  const pages = useSelector((state) => state.packages.pages);
  // get the list of all the packages in the aplication
  const packageList = useSelector((state) => state.packages.packageList);

  // STATES
  // searchedText: array with searched texts (words, phrases, anything put in the search box)
  // filters: filters different to the searched text in the search box, in the packages page
  // ... page: page in pagination, 1 is the default value
  const [searchedText, setSearchedText] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
  });

  const dispatch = useDispatch();
  // SAGA
  // obtains the packages for the current page, base on filters
  // ... These packages are saved in the store
  const getPackagesSaga = () => {
    dispatch({
      type: "GET_PACKAGES_SAGA",
      searchedText: searchedText,
      page: filters.page,
      limit: globalItemsPerPage,
      sortBy: filters.sortBy,
      sortOrder: filters.sortOrder,
    });
  };

  // pagination, select a page. When filters change, there is an api request to
  // ... get the list of sorted packages, base on these filters
  const selectPage = (index) => {
    if (index >= 1 && index <= pages) {
      setFilters((filters) => ({ ...filters, page: index }));
    }
  };

  // add new filters in filters state (or update them if they already exists)
  // sortBy: save the key by which the request table will be sorted (e.g cpq, owner, etc)
  // sortOrder: boolean value. 1 for ascending sorting or -1 for descending sorting
  const onChangeQueryParams = (sortBy, sortOrder) => {
    setFilters((filters) => ({
      ...filters,
      sortBy: sortBy,
      sortOrder: sortOrder,
    }));
  };

  // when filters changes, get package list for the current page
  useEffect(() => {
    getPackagesSaga();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchedText]);

  // when const pages changes, set page filter in 1
  useEffect(() => {
    setFilters((filters) => ({ ...filters, page: 1 }));
  }, [pages]);

  return (
    <AdminPackagesView
      pages={pages}
      selectedPage={filters.page}
      filters={filters}
      onChangeQueryParams={onChangeQueryParams}
      selectPage={selectPage}
      packageList={packageList}
      packageListLoading={packageListLoading}
      searchedText={searchedText}
      setSearchedText={setSearchedText}
    />
  );
}
