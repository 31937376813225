import styled from "styled-components/macro";

// TODO: COLORS
const ServiceFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ServiceFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ModalFooterSD = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
`;

const ButtonSD = styled.div`
  display: flex;
`;

export { ServiceFormSD, ServiceFormTabsSD, ModalFooterSD, ButtonSD };
