import { CollapsibleTable } from "Components/Common";
import React, { useState } from "react";
import Media from "react-media";
import { ServicesTableSD } from "./ServicesTableStyles";
import { useDispatch } from "react-redux";

/**
 * HOOK
 * Service table in admin
 */

export default function ServicesTable({
  serviceList,
  serviceListLoading,
  setSearchText,
  filters,
  forReports,
  selectCheckbox,
  onChangeQueryParams,
}) {
  const [selectedItemId, setSelectedItemId] = useState("NOID");
  const headers = [
    { name: "CPQ", key: "cpqCode", flex: 1 },
    { name: "NAME", key: "name", flex: 2 },
    { name: "OWNER", key: "owner.email", subId: "email", flex: 2 },
    { name: "TYPE", key: "serviceType", flex: 1 },
    {
      name: "INTERNAL NOTES",
      key: "localization.EN-US.internalNotes",
      flex: 2,
    },
    { name: "VISIBILITY", key: "visibility", flex: 1 },
    { name: "SELLABLE", key: "salable", flex: 1 },
    { name: "COMMUNICABLE", key: "communicable", flex: 1 },
  ];

  const mobileHeaders = [
    { name: "CPQ", key: "cpqCode", flex: "3 0", color: "primaryActive" },
    { name: "NAME", key: "name", flex: "3 0", color: "primaryActive" },
    { name: "VISIBLILITY", key: "visibility", flex: "1 0" },
    { name: "SELLABLE", key: "salable", flex: "1 0" },
    { name: "COMMUNICABLE", key: "communicable", flex: "1 0" },
  ];

  const dispatch = useDispatch();
  const getServiceDetail = (id) => {
    dispatch({ type: "GET_SERVICE_DETAIL_SAGA", id: id });
  };
  const onClickItem = (itemInfo) => {
    getServiceDetail(itemInfo._id);
  };
  return (
    <ServicesTableSD>
      <Media query={{ minWidth: 992 }}>
        {(matches) =>
          matches ? (
            <CollapsibleTable
              headers={headers}
              data={serviceList}
              type="service"
              loading={serviceListLoading}
              filters={filters}
              forReports={forReports}
              selectCheckbox={selectCheckbox}
              onClickItem={onClickItem}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              onChangeQueryParams={onChangeQueryParams}
              isCollapsible={!forReports}
            />
          ) : (
            <CollapsibleTable
              headers={mobileHeaders}
              data={serviceList}
              type="service"
              loading={serviceListLoading}
              setSearchText={setSearchText}
              filters={filters}
              forReports={forReports}
              selectCheckbox={selectCheckbox}
              onClickItem={onClickItem}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              onChangeQueryParams={onChangeQueryParams}
              isCollapsible={!forReports}
            />
          )
        }
      </Media>
    </ServicesTableSD>
  );
}
