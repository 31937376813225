// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const AdminViewSD = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  padding-left: 6rem;
  @media ${mediaQueries.tablet} {
    padding-left: 5rem;
  }
  @media ${mediaQueries.mobileL} {
    padding-left: 5rem;
  }
`;

const AdminViewContentSD = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const AdminLeftSD = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export { AdminLeftSD, AdminViewContentSD, AdminViewSD };
