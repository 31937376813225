// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const DetailHeaderSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
  @media ${mediaQueries.mobileL} {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2rem 2rem 0rem 2rem;
  }
`;

const DetailOptionsSD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
    justify-content: space-between;
    width: 100%;
  }
`;

const DetailTagSD = styled.div`
  padding: 0.5rem;
`;

export { DetailHeaderSD, DetailOptionsSD, DetailTagSD };
