import React, { useState } from "react";
import { CircularButton } from "Components";
import { Modal } from "antd";
import {
  DeleteServiceModalSD,
  TitleSD,
  SubtitleSD,
  StrongSD,
  ButtonSD,
} from "./DeleteUserModalStyles";
import { ButtonS, ModalFooterSD } from "Theme/Styles";

/**
 * HOOK
 * Delete Modal, shows to modal with a message, to delete or not an user
 */

export default function DeleteModal({ onDelete, email, setSearchText }) {
  // STATES
  // visible: to show or hide the modal
  const [visible, setVisible] = useState(false);

  // if the delete button is selected
  // ... the modal will be hidden
  // ... the searched text will be cleaned
  // ... and onDelete function will be called
  const onClickDelete = () => {
    setVisible(false);
    onDelete();
    setSearchText("");
  };
  return (
    <div>
      <CircularButton
        iconName="delete"
        size={size()}
        onClick={() => setVisible(true)}
      />
      <Modal
        visible={visible}
        footer={null}
        centered
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <DeleteServiceModalSD>
          <TitleSD>Are you sure?</TitleSD>
          <SubtitleSD>
            Remove the user <StrongSD>{email}</StrongSD> ?
          </SubtitleSD>
          <ModalFooterSD>
            <ButtonSD>
              <ButtonS
                key="back"
                type="reset"
                buttonType="cancel"
                onClick={() => setVisible(false)}
              >
                CANCEL
              </ButtonS>
            </ButtonSD>
            <ButtonSD>
              <ButtonS onClick={() => onClickDelete()}>DELETE</ButtonS>
            </ButtonSD>
          </ModalFooterSD>
        </DeleteServiceModalSD>
      </Modal>
    </div>
  );
}

// to determinate the size of the modal depends on the device
function size() {
  if (window.screen.width >= 768) {
    return 23;
  } else {
    return 20;
  }
}
