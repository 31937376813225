import React, { useContext } from "react";
import {
  CreateServiceSD,
  ServicesHeaderSD,
  TableContentSD,
} from "./AdminServicesViewStyles";
import { SectionTitleSD } from "Theme/Styles";
import CreateServiceModal from "./CreateService/CreateServiceModal";
import ServicesTable from "./ServicesTable/ServicesTable";
import { Pagination, SearchBox } from "Components/Common";
import userContext from "Context/user-context";

/**
 * HOOK
 * Admin services view, the view groups and sorts the visual components
 */

export default function AdminServicesView({
  pages,
  selectedPage,
  filters,
  selectPage,
  serviceList,
  serviceListLoading,
  searchedText,
  setSearchedText,
  onChangeQueryParams,
}) {
  const currentUser = useContext(userContext);
  return (
    <div>
      <SectionTitleSD>Services</SectionTitleSD>
      <ServicesHeaderSD>
        <SearchBox
          searchedText={searchedText}
          setSearchedText={setSearchedText}
        ></SearchBox>
        {currentUser.role !== 2 ? (
          <CreateServiceSD>
            <CreateServiceModal />
          </CreateServiceSD>
        ) : null}
      </ServicesHeaderSD>
      <TableContentSD>
        <ServicesTable
          serviceList={serviceList}
          serviceListLoading={serviceListLoading}
          filters={filters}
          onChangeQueryParams={onChangeQueryParams}
        />
      </TableContentSD>
      <Pagination
        pages={pages}
        selectPage={selectPage}
        selectedPage={selectedPage}
      ></Pagination>
    </div>
  );
}
