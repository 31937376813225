import React from "react";
import { withRouter } from "react-router-dom";
import FiltersMenuSection from "./FiltersMenuSection";
import { SC } from "./FiltersMenuStyles";
import { CustomLoadingOverlay } from "Components";
import PriceSection from "./PriceSection";

function FiltersMenu(props) {
  const {
    salesFilters,
    salesMenuLoading,
    tags,
    selectTag,
    filterByTier,
    filterByPricing,
    saveSalesFilters,
    rangePricing,
    selectedItems,
  } = props;
  const headersName = Object.keys(tags);

  const renderRows = () => {
    const renderedRows = [];
    headersName.forEach((header, index) => {
      renderedRows.push(
        <FiltersMenuSection
          key={"FiltersMenuSection" + index}
          headerName={header}
          tags={tags[header]}
          selectedTags={salesFilters.selectedTags}
          selectTag={selectTag}
        />
      );
    });

    return renderedRows;
  };

  const renderedRows = renderRows();

  return (
    <SC.FiltersMenu>
      <CustomLoadingOverlay active={salesMenuLoading}>
        <SC.TableContent>
          {renderedRows}
          <PriceSection
            filterByTier={filterByTier}
            filterByPricing={filterByPricing}
            salesFilters={salesFilters}
            saveSalesFilters={saveSalesFilters}
            rangePricing={rangePricing}
            selectedItems={selectedItems}
          ></PriceSection>
        </SC.TableContent>
      </CustomLoadingOverlay>
    </SC.FiltersMenu>
  );
}

export default withRouter(FiltersMenu);
