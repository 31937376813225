import React from "react";
import { withRouter } from "react-router-dom";
import SalesCollapsibleItem from "./SalesCollapsibleItem";
import { SC } from "./SalesCollapsibleTableStyles";
import { CustomLoadingOverlay } from "Components";

function SalesCollapsibleTable(props) {
  const {
    salesList,
    salesTableLoading,
    currentUser,
    selectedLanguage,
    salesFilters,
  } = props;

  const renderRows = () => {
    const renderedRows = [];
    salesList.forEach((row, index) => {
      const type = row.services ? "package" : "service";
      renderedRows.push(
        <SalesCollapsibleItem
          key={"SalesCollapsibleItem" + index}
          itemInfo={row}
          type={type}
          currentUser={currentUser}
          selectedLanguage={selectedLanguage}
          salesFilters={salesFilters}
        />
      );
    });

    return renderedRows;
  };

  const renderedRows = renderRows();

  return (
    <SC.CollapsibleTable>
      <CustomLoadingOverlay active={salesTableLoading}>
        <SC.TableContent>{renderedRows}</SC.TableContent>
      </CustomLoadingOverlay>
    </SC.CollapsibleTable>
  );
}

export default withRouter(SalesCollapsibleTable);
