import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminDetailView } from "Components/Common";

/**
 * HOOK
 * Package detail container
 * calls the admin view
 */

export default function PackageDetailContainer({ id, itemIndex }) {
  // STATES
  // selectedLanguage: current select language in this detail
  const [selectedLanguage, setLanguage] = useState("EN-US");

  // we obtain all package detail loading states
  const packageDetailLoading = useSelector(
    (state) => state.system.loadings.packageDetailLoading
  );

  // get the package information
  const packageDetail = useSelector(
    (state) => state.packages.packageDetail[id]
  );

  const dispatch = useDispatch();
  // SAGA
  // obtain the package detail
  // ... This detail is saved in the store
  const getPackageDetail = () => {
    dispatch({ type: "GET_PACKAGE_DETAIL_SAGA", id: id });
  };

  // SAGA
  // send the request to the backend to detele a package
  const deletePackageSaga = (id) => {
    dispatch({ type: "DELETE_PACKAGE_SAGA", id: id });
  };
  useEffect(() => {
    getPackageDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // to save all changes made, to hightlight in request detail
  let changes = {};
  return (
    <AdminDetailView
      detailInfo={packageDetail}
      detailType="package"
      selectedLanguage={selectedLanguage}
      setLanguage={setLanguage}
      deleteSaga={deletePackageSaga}
      detailLoading={packageDetailLoading}
      itemIndex={itemIndex}
      changes={changes}
    />
  );
}
