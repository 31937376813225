import React from "react";
import exitTag from "Assets/Icons/SalesIcons/PNG/Icon_sales-x-cancel-tags.png";
import {
  ReportsSelectedTagSD,
  TagNameSD,
  TagCloseIconSD,
} from "./ReportsSelectedTagStyles";

export default function ReportsSelectedTag({ tagName, selectTag }) {
  return (
    <ReportsSelectedTagSD className={"ReportsSelectedTagSD"}>
      <TagNameSD>{tagName}</TagNameSD>
      <TagCloseIconSD
        onClick={() => {
          selectTag();
        }}
      >
        <img src={exitTag} alt="exit X" width="10" />
      </TagCloseIconSD>
    </ReportsSelectedTagSD>
  );
}
