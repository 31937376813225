import { initialState } from "Redux/initialState";

/// node: packages
const packagesReducer = (state = initialState.packages, action) => {
  switch (action.type) {
    case "SAVE_PACKAGE_LIST":
      return {
        ...state,
        packageList: action.packageList,
        count: action.count,
        pages: action.pages,
      };
    // for sales, save min price and max price of package according to filters
    case "SAVE_PACKAGE_PRICE_RANGE":
      const minPrice = action.minPrice !== null ? action.minPrice : 0;
      return {
        ...state,
        minPrice: minPrice,
        maxPrice: action.maxPrice,
      };

    // when we edit a package we receive an updated package, and we save it
    case "SAVE_UPDATED_PACKAGE":
      const packageList = [...state.packageList];
      const packageDetail = { ...state.packageDetail };
      const index = packageList.findIndex(
        (element) => element.cpqCode === action.updatedPackage.cpqCode
      );
      packageList[index] = action.updatedPackage;
      packageDetail[action.updatedPackage._id] = action.updatedPackage;
      return {
        ...state,
        packageList,
        packageDetail,
      };
    // save all data of an specific package and save it into state.packages.detail.[package._id]
    case "SAVE_PACKAGE_DETAIL":
      let packageDetailState = { ...state.packageDetail };
      packageDetailState[action.packageId] = action.packageDetail;
      return {
        ...state,
        packageDetail: packageDetailState,
      };

    default:
      return state;
  }
};

export default packagesReducer;
