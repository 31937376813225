import ApiServices from "Api/services";
import ApiPackages from "Api/packages";

import { put, takeLatest, call } from "redux-saga/effects";
import { delay } from "redux-saga/effects";
import { toast } from "react-toastify";

// get all services cpqCodes
function* getServicesCpq(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "serviceListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiServices.getCpqCodes, action);
    const { services } = response.data;
    // Modify the selected items, it is an arrangement of cpq Codes
    yield put({
      type: "SAVE_SERVICES_CPQ",
      services,
    });

    // checked items are selected items for the report
    yield put({
      type: "SET_CHECKED_ITEMS",
      checkedItems: services,
    });

    yield delay(300);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "serviceListLoading",
    loadingValue: false,
  });
}

// when we get all services cpqCodes, we receive an array of objects, and we need to
// transform this to an array of cpqCode strings
function* getPackagesCpq(action) {
  yield put({
    type: "SET_LOADING",
    loadingName: "packageListLoading",
    loadingValue: true,
  });
  try {
    const response = yield call(ApiPackages.getCpqCodes, action);
    const { packages } = response.data;
    // Modify the selected items, it is an arrangement of cpq Codes
    yield put({
      type: "SAVE_PACKAGES_CPQ",
      packages,
    });
    // checked items are selected items for the report
    yield put({
      type: "SET_CHECKED_ITEMS",
      checkedItems: packages,
    });

    yield delay(300);
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.errors.join();
      if (errorMessage !== "Invalid token") {
        toast.warn(errorMessage);
      }
    } else {
      toast.error(error.toString());
    }
  }
  yield put({
    type: "SET_LOADING",
    loadingName: "packageListLoading",
    loadingValue: false,
  });
}

export const reportsSagas = [
  takeLatest("GET_SERVICES_CPQ", getServicesCpq),
  takeLatest("GET_PACKAGES_CPQ", getPackagesCpq),
];
