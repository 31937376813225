import React from "react";
import { Select, Input, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FormSubtitleSD } from "Theme/Styles";
import { PackageFormSD } from "./BasicTabStyles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";

const { Option } = Select;
const { TextArea } = Input;

/**
 * HOOK
 * Basic tab in package form
 */

function BasicTab({
  cpqCode,
  setCPQCode,
  owner,
  setOwner,
  editors,
  setEditors,
  ownerList,
  selectedLanguage,
  localization,
  onChangeMLField,
  tags,
  setTags,
  tagList,
  serviceList,
  selectedServices,
  setSelectedServices,
  productManager,
  setProductManager,
}) {
  // renders all users in ownerList array
  const renderOwners = () => {
    const options = [];
    ownerList.forEach((owner, index) => {
      options.push(
        <Option key={index} value={owner._id}>
          {owner.email}
        </Option>
      );
    });
    return options;
  };

  // renders all tags in tags array
  const renderTags = () => {
    const options = [];
    tagList.forEach((tag) => {
      options.push(<Option key={tag}>{tag}</Option>);
    });

    return options;
  };

  // renders all services in servicesList array
  const renderServices = () => {
    const options = [];
    serviceList.forEach((service) => {
      options.push(
        <Option key={service._id}>
          {service.cpqCode + " - " + service.name}
        </Option>
      );
    });

    return options;
  };

  const renderedOwners = renderOwners();
  const renderedTags = renderTags();
  const renderedServices = renderServices();
  return (
    <PackageFormSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>CPQ</FormSubtitleSD>
        <Input
          name="input"
          placeholder="MR-SVC"
          value={cpqCode}
          onChange={(e) => setCPQCode(e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD key="label owners">
        <FormSubtitleSD>Owner</FormSubtitleSD>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select an owner"
          value={owner._id}
          onChange={(value) =>
            setOwner({
              _id: value,
            })
          }
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD>
      <ExtendedColumnSD key="Product Manager">
        <FormSubtitleSD>Product Manager</FormSubtitleSD>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Product Manager"
          value={productManager ? productManager._id : ""}
          onChange={(value) =>
            setProductManager({
              _id: value,
            })
          }
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD>
      {/* <ExtendedColumnSD key="label editors">
        <FormSubtitleSD>Editors</FormSubtitleSD>
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Editors"
          value={editors}
          onChange={(value) => setEditors(value)}
        >
          {renderedOwners}
        </Select>
      </ExtendedColumnSD> */}
      <ExtendedColumnSD>
        <FormSubtitleSD>Description</FormSubtitleSD>
        <TextArea
          placeholder="Package description"
          autoSize
          value={localization[selectedLanguage].description}
          onChange={(e) => onChangeMLField("description", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Summary</FormSubtitleSD>
        <TextArea
          placeholder="Package Summary"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].summary}
          onChange={(e) => onChangeMLField("summary", e.target.value)}
        />
      </ExtendedColumnSD>

      <ExtendedColumnSD>
        <FormSubtitleSD>Internal notes</FormSubtitleSD>
        <TextArea
          placeholder="Internal notes"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].internalNotes}
          onChange={(e) => onChangeMLField("internalNotes", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Services</FormSubtitleSD>
        <Select
          mode="multiple"
          optionFilterProp="children"
          placeholder="Services"
          value={selectedServices}
          onChange={(value) => setSelectedServices(value)}
        >
          {renderedServices}
        </Select>
      </ExtendedColumnSD>

      <ExtendedColumnSD>
        <FormSubtitleSD>
          Tags
          <Tooltip title="Select the tags with priority, that will influence the sales page searches">
            <QuestionCircleOutlined />
          </Tooltip>
        </FormSubtitleSD>
        <Select
          showSearch
          mode="tags"
          placeholder="Please select"
          value={tags}
          onChange={(value) => setTags(value)}
        >
          {renderedTags}
        </Select>
      </ExtendedColumnSD>
    </PackageFormSD>
  );
}

export default BasicTab;
