import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "Components";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "Theme/CustomStyles/custom_antd.css";
import "react-toastify/dist/ReactToastify.css";

// This is the javascript file corresponding to index.html.
// This file has the following line of code which is very significant.
// ReactDOM.render(<App />, document.getElementById("root"));

import { configureStore } from "./Redux/configureStore";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
