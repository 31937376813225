import { ROOT_URL, instance } from "./apiConfig";

export default class ApiRequests {
  // Services API Requests
  //    POST one request request accepting a new service creation request
  static acceptCreateService(action) {
    const url = `${ROOT_URL}/requests/services/create/${action.id}`;
    const request = instance.post(url);
    return request;
  }

  //    PUT one request request accepting a new service update request
  static acceptUpdateService(action) {
    const url = `${ROOT_URL}/requests/services/update/${action.id}`;
    const request = instance.put(url);
    return request;
  }

  //    DELETE one request request accepting a service deletation request
  static acceptDeleteService(action) {
    const url = `${ROOT_URL}/requests/services/delete/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a new service creation request
  static rejectCreateService(action) {
    const url = `${ROOT_URL}/requests/services/create/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a new service update request
  static rejectUpdateService(action) {
    const url = `${ROOT_URL}/requests/services/update/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a service deletation request
  static rejectDeleteService(action) {
    const url = `${ROOT_URL}/requests/services/delete/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  // Packages API Requests
  //    POST one request request accepting a new package creation request
  static acceptCreatePackage(action) {
    const url = `${ROOT_URL}/requests/packages/create/${action.id}`;
    const request = instance.post(url);
    return request;
  }

  //    PUT one request request accepting a new service update request
  static acceptUpdatePackage(action) {
    const url = `${ROOT_URL}/requests/packages/update/${action.id}`;
    const request = instance.put(url);
    return request;
  }

  //    DELETE one request request accepting a package deletation request
  static acceptDeletePackage(action) {
    const url = `${ROOT_URL}/requests/packages/delete/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a new package creation request
  static rejectCreatePackage(action) {
    const url = `${ROOT_URL}/requests/packages/create/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a new package update request
  static rejectUpdatePackage(action) {
    const url = `${ROOT_URL}/requests/packages/update/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    DELETE one request request rejecting a package deletation request
  static rejectDeletePackage(action) {
    const url = `${ROOT_URL}/requests/packages/delete/reject/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //    GET all requests request
  static getRequests(action) {
    const params = new URLSearchParams();
    if (action.searchedText) {
      const searchArray = action.searchedText;
      searchArray.forEach((searchItem) => {
        params.append("searchedText", searchItem);
      });
    }
    if (action.page) {
      params.append("page", action.page);
    }
    if (action.limit) {
      params.append("limit", action.limit);
    }
    if (action.sortBy) {
      params.append("sortBy", action.sortBy);
      params.append("sortOrder", action.sortOrder);
    }

    const url = `${ROOT_URL}/requests`;
    const request = instance.get(url, {
      params: params,
    });
    return request;
  }

  //    GET one request request
  static getRequest(action) {
    const url = `${ROOT_URL}/requests/${action.id}`;
    const request = instance.get(url);
    return request;
  }
}
