import { Select, Input } from "antd";
import Checkbox from "Components/Common/Form/Checkbox";
import React from "react";
import { FormSubtitleSD } from "Theme/Styles";
import { ServiceFormSD } from "./DeliveryTabStyles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";
import TasksTable from "./TasksTable";

const { Option } = Select;
const { TextArea } = Input;
function DeliveryTab(props) {
  const {
    onChangeMLField,
    selectedLanguage,
    localization,
    serviceType,
    setServiceType,
    thirdPartyDelivery,
    setThirdPartyDelivery,
  } = props;
  const headers = [
    { name: "Task", id: "task" },
    { name: "Role", id: "role" },
    { name: "Time", id: "time" },
  ];
  return (
    <ServiceFormSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Objective</FormSubtitleSD>
        <TextArea
          placeholder="Objective/Purpose of the service"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].objective}
          onChange={(e) => onChangeMLField("objective", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Benefits</FormSubtitleSD>
        <TextArea
          placeholder="Benefits"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].benefits}
          onChange={(e) => onChangeMLField("benefits", e.target.value)}
        />
      </ExtendedColumnSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Audience</FormSubtitleSD>
        <TextArea
          placeholder="People/Roles interest to take the service"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].audience}
          onChange={(e) => onChangeMLField("audience", e.target.value)}
        />
      </ExtendedColumnSD>
      {/* <ExtendedColumnSD>
        <FormSubtitleSD>Scope</FormSubtitleSD>
        <TextArea
          placeholder="Service detailed scope"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].scope}
          onChange={(e) => onChangeMLField("scope", e.target.value)}
        />
      </ExtendedColumnSD> */}
      {/* <ExtendedColumnSD>
          <FormSubtitleSD>Tasks</FormSubtitleSD>
          <TasksTable
            headers={headers}
            data={localization.tasks}
            taskList={localization[selectedLanguage].tasks}
            onChangeMLField={onChangeMLField}
          />
        </ExtendedColumnSD> */}
      <ExtendedColumnSD>
        <FormSubtitleSD>Type</FormSubtitleSD>
        <Select
          placeholder="Please select a type"
          value={serviceType}
          onChange={(value) => setServiceType(value)}
        >
          <Option key="Subscription">{"Subscription"}</Option>
          <Option key="Delivery">{"Delivery"}</Option>
          <Option key="Maintenance">{"Maintenance"}</Option>
        </Select>
      </ExtendedColumnSD>
      {/* <ExtendedColumnSD>
        <Checkbox
          checked={thirdPartyDelivery}
          label={"Third Party Delivery"}
          onChange={(e) => setThirdPartyDelivery(e.target.checked)}
        />
      </ExtendedColumnSD> */}
      {/* <ExtendedColumnSD>
        <FormSubtitleSD>Artifacts</FormSubtitleSD>
        <TextArea
          placeholder="Service Artifacts"
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={localization[selectedLanguage].artifacts}
          onChange={(e) => onChangeMLField("artifacts", e.target.value)}
        />
      </ExtendedColumnSD> */}
    </ServiceFormSD>
  );
}

export default DeliveryTab;
