import React, { useContext } from "react";
import { DeleteModal } from "Components";
import { DetailTag } from "Components/Common";
import {
  DetailHeaderSD,
  DetailOptionsSD,
  DetailTagSD,
} from "./DetailHeaderStyles";
import { EditPackageModal, EditServiceModal } from "Components";
import userContext from "Context/user-context";
import { DetailTagsSD } from "Theme/DetailStyles";
/**
 * HOOK
 * Detail Header, render EDIT and DELETE buttons, and languages
 */

// render edit modal if the button "EDIT" is clicked
function renderEditmodal(type, id, itemIndex) {
  switch (type) {
    case "service":
      return (
        <EditServiceModal id={id} itemIndex={itemIndex}></EditServiceModal>
      );
    case "package":
      return (
        <EditPackageModal id={id} itemIndex={itemIndex}></EditPackageModal>
      );
    default:
      console.log("Try looking up for a hint.");
  }
}

export default function DetailHeader({
  localization,
  selectedLanguage,
  setLanguage,
  type,
  id,
  cpqCode,
  onDelete,
  isRequest,
  creatorId,
  productManagerId,
  ownerId,
  itemIndex,
  hasRequest,
  editorsId,
}) {
  const tags = renderTags(localization, selectedLanguage, setLanguage);
  // get the current user with the user context
  const currentUser = useContext(userContext);

  return (
    <DetailHeaderSD>
      <DetailTagsSD>{tags}</DetailTagsSD>
      {!isRequest &&
      !hasRequest &&
      (creatorId === currentUser._id ||
        ownerId === currentUser._id ||
        productManagerId === currentUser._id ||
        editorsId.includes(currentUser._id) ||
        currentUser.role === 0) ? (
        <DetailOptionsSD>
          <DetailTagSD>
            <DeleteModal
              id={id}
              cpqCode={cpqCode}
              onDelete={() => onDelete(id)}
            />
          </DetailTagSD>
          <DetailTagSD>{renderEditmodal(type, id, itemIndex)}</DetailTagSD>
        </DetailOptionsSD>
      ) : null}
      {hasRequest ? <DetailOptionsSD>Has request</DetailOptionsSD> : null}
    </DetailHeaderSD>
  );
}

// render tags, like EN-US ...
function renderTags(localization, selectedLanguage, setLanguage) {
  const renderedTags = [];
  const languageIds = Object.keys(localization);
  languageIds.forEach((id) => {
    if (
      id !== "internalNotes" &&
      id !== "collaterals" &&
      id !== "description" &&
      id !== "summary"
    ) {
      renderedTags.push(
        <DetailTag
          key={id}
          text={id}
          isActive={selectedLanguage === id ? true : false}
          onClick={() => setLanguage(id)}
        ></DetailTag>
      );
    }
  });
  return renderedTags;
}
