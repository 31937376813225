import React from "react";
import { SectionTitleSD } from "Theme/Styles";
import UserFilters from "./UserFilters/UserFilters";
import UserPagination from "./UserPagination/UserPagination";
import UsersTable from "./UsersTable/UsersTable";
import { TableContentSD } from "./UsersViewStyles";

/**
 * HOOK
 * Users view, the view groups and sorts the visual components
 */

export default function UsersView({
  userList,
  roles,
  usersListLoading,
  pages,
  selectedPage,
  selectPage,
  onChangeQueryParams,
  filters,
  setFilters,
  searchedText,
  setSearchedText,
}) {
  return (
    <div>
      <SectionTitleSD>Users</SectionTitleSD>
      <UserFilters
        searchedText={searchedText}
        setSearchedText={setSearchedText}
        filters={filters}
        setFilters={setFilters}
        roles={roles}
      ></UserFilters>
      <TableContentSD>
        <UsersTable
          userList={userList}
          roles={roles}
          usersListLoading={usersListLoading}
          onChangeQueryParams={onChangeQueryParams}
        />
      </TableContentSD>
      <UserPagination
        pages={pages}
        selectPage={selectPage}
        selectedPage={selectedPage}
      ></UserPagination>
    </div>
  );
}
