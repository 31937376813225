import { SalesView } from "Components";
import { quickSort } from "Helpers/SortByTags";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toLower } from "Helpers";
import userContext from "../../Context/user-context";
import Cookie from "js-cookie";

/*
 * HOOK
 * Sales Page, calls the Sales view, has all the logic filters
 *
 */

function SalesContainer(props) {
  // we obtain the service and package list to concat them
  const serviceList = useSelector((state) => state.service.serviceList);
  const packageList = useSelector((state) => state.packages.packageList);
  // const packageList = [];
  // we extract the min and max price of services and packages to calculate the selectedMin and selectedMax
  const salesMenuLoading = useSelector(
    (state) => state.system.loadings.salesMenuLoading
  );
  // Left tags
  const tags = useSelector((state) => state.system.tags);
  // Filters
  // tier: "T2", userBand: 2000, seats: 15,
  // selectedMin: null, selectedMax: null,
  // selectedTags: [],
  // selectedLanguage: "EN-US"
  const languages = useSelector((state) => state.system.languages);
  const salesFilters = useSelector((state) => state.system.salesFilters);
  const rangePricing = useSelector((state) => state.system.pricing);

  const salesTableLoading = useSelector(
    (state) => state.system.loadings.salesTableLoading
  );

  // sort order
  const [sortBy, setSortBy] = useState("byRelevance");

  // Wich item type we will show on table?
  const [selectedItems, selectItem] = useState({
    services: true,
    packages: true,
  });

  // Wich type table we will show
  const [simpleListOn, showSimpleList] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  let salesList = [];
  salesList = getSalesList(
    selectedItems,
    serviceList,
    packageList,
    sortBy,
    salesFilters.selectedTags
  );

  // Save the filters in the application state
  const saveSalesFilters = (newFilters) => {
    dispatch({
      type: "SAVE_SALES_FILTERS",
      filters: { ...salesFilters, ...newFilters },
    });
  };

  // select/unselect a tag from left panel
  const selectTag = (tagName) => {
    let newSelectedTags = [...salesFilters.selectedTags];
    const lowerAllTags = salesFilters.selectedTags.map(toLower);
    // if the tag exists, we unselect it
    // remove from the selectedTags array
    if (lowerAllTags.includes(tagName.toLowerCase())) {
      newSelectedTags = newSelectedTags.filter((e) => e !== tagName);
    } else {
      // if the tag doesn't exists, we add it to selectedTags array
      newSelectedTags.push(tagName);
    }
    saveSalesFilters({
      selectedTags: newSelectedTags,
    });
  };

  const addSearchedText = (searchedText) => {
    let newSearchedText = [...salesFilters.searchedText];
    if (!newSearchedText.includes(searchedText)) {
      newSearchedText.push(searchedText);
      saveSalesFilters({
        searchedText: newSearchedText,
      });
    }
    setSearchInput("");
  };

  // select/unselect a tag from left panel
  const removeSearchedText = (searchedText) => {
    let newSearchedText = [...salesFilters.searchedText];
    // if the tag exists, we unselect it
    // remove from the selectedTags array
    if (newSearchedText.includes(searchedText)) {
      newSearchedText = newSearchedText.filter((e) => e !== searchedText);
    }
    saveSalesFilters({
      searchedText: newSearchedText,
    });
  };

  // remove all selected items
  const clearAllSelected = () => {
    saveSalesFilters({
      selectedTags: [],
      searchedText: [],
    });
    setSearchInput("");
  };

  const filterByTier = (tier) => {
    saveSalesFilters({ tier });
  };
  const filterBySeats = (seats) => {
    saveSalesFilters({ seats });
  };
  const filterByUserBands = (userBand) => {
    saveSalesFilters({ userBand });
  };
  const filterByLanguage = (languageId) => {
    saveSalesFilters({ selectedLanguage: languageId });
  };
  const filterByPricing = (selectedMin, selectedMax) => {
    if ((selectedMin, selectedMax)) {
      saveSalesFilters({
        selectedMin: selectedMin,
        selectedMax: selectedMax,
      });
    }
  };

  const dispatch = useDispatch();
  const logoutSaga = () => {
    dispatch({ type: "LOGOUT_SAGA", history: props.history });
  };

  const goToAdmin = () => {
    props.history.push("/admin/services");
  };
  const getSalesServicesSaga = () => {
    dispatch({
      type: "GET_SALES_SERVICES_SAGA",
      filters: salesFilters,
    });
  };
  const getSalesPackagesSaga = () => {
    dispatch({
      type: "GET_SALES_PACKAGES_SAGA",
      filters: salesFilters,
    });
  };

  const getFilterTags = () => {
    dispatch({ type: "GET_FILTER_ITEMS_SAGA" });
  };
  const token = Cookie.get("token");
  const jwtDecode = require("jwt-decode");
  const decoded = jwtDecode(token);
  const currentUser = decoded.user;

  useEffect(() => {
    getFilterTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItems.services) {
      getSalesServicesSaga();
    }
    if (selectedItems.packages) {
      getSalesPackagesSaga();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    salesFilters.selectedTags,
    salesFilters.searchedText,
    salesFilters.tier,
    salesFilters.seats,
    salesFilters.userBand,
    salesFilters.selectedMin,
    salesFilters.selectedMax,
    salesFilters.selectedLanguage,
  ]);

  const switchItem = (itemType, value) => {
    switch (itemType) {
      case "services":
        if (value === false && selectedItems.packages === false) {
          selectItem({
            services: false,
            packages: true,
          });
        } else {
          selectItem((selectedItems) => ({
            ...selectedItems,
            services: value,
          }));
        }
        break;
      case "packages":
        if (value === false && selectedItems.services === false) {
          selectItem({
            services: true,
            packages: false,
          });
        } else {
          selectItem((selectedItems) => ({
            ...selectedItems,
            packages: value,
          }));
        }
        break;
      default:
        break;
    }
  };
  return (
    <userContext.Provider value={currentUser}>
      <SalesView
        salesList={salesList}
        clearAllSelected={clearAllSelected}
        tags={tags}
        languages={languages}
        selectedItems={selectedItems}
        switchItem={switchItem}
        salesTableLoading={salesTableLoading}
        salesMenuLoading={salesMenuLoading}
        //filters
        salesFilters={salesFilters}
        // setBasicFilter={setBasicFilter}
        sortBy={sortBy}
        selectTag={selectTag}
        addSearchedText={addSearchedText}
        removeSearchedText={removeSearchedText}
        filterByLanguage={filterByLanguage}
        // filter by pricing
        filterByTier={filterByTier}
        saveSalesFilters={saveSalesFilters}
        filterBySeats={filterBySeats}
        filterByUserBands={filterByUserBands}
        filterByPricing={filterByPricing}
        setSortBy={setSortBy}
        simpleListOn={simpleListOn}
        showSimpleList={showSimpleList}
        goToAdmin={goToAdmin}
        logoutSaga={logoutSaga}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        rangePricing={rangePricing}
      />
    </userContext.Provider>
  );
}

/*
  getSalesList merge service list and package list,
  Depending on the option selected, we show only packages, only services, or both
*/
function getSalesList(
  selectedItems,
  serviceList,
  packageList,
  sortBy,
  selectedTags
) {
  let salesList = [];

  const serviceListCopy = [...serviceList];
  const packageListCopy = [...packageList];

  if (selectedItems.packages === true && selectedItems.services === true) {
    salesList = serviceListCopy.concat(packageListCopy);
  }
  if (selectedItems.packages === true && selectedItems.services === false) {
    salesList = packageListCopy;
  }
  if (selectedItems.packages === false && selectedItems.services === true) {
    salesList = serviceListCopy;
  }

  salesList = sortByBasicFilter(salesList, sortBy, selectedTags);

  return salesList;
}

// This function takes a list and orders it according to a parameter.
function sortByBasicFilter(list, filter, selectedTags) {
  if (filter === "lastUpdate") {
    list.sort(function (a, b) {
      return new Date(b.lastModification) - new Date(a.lastModification);
    });
  } else if (filter === "byRelevance") {
    // If the option is byRelevance and there are no tags selected, it is sorted by date
    if (selectedTags.length === 0) {
      list.sort(function (a, b) {
        return new Date(b.lastModification) - new Date(a.lastModification);
      });
    } else {
      // if there are tags selected, it is ordered by the weight of the tags
      list = quickSort([...list]);
    }
  } else {
    list.sort((a, b) => (a[filter] > b[filter] ? 1 : -1));
  }
  return list;
}

export default withRouter(SalesContainer);
