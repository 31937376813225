import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const CheckboxLabel = styled.label`
  display: flex;
  padding-right: 1rem;
  align-items: center;
  font-family: "SourceSansPro-Regular";
  font-size: 1.2rem;
  @media ${mediaQueries.mobileL} {
    padding-right: 0rem;
    margin: 0rem;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${Colors.primaryActive};
  stroke-width: 0.1rem;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: ${Colors.primarySelected};
  background-color: white;
  border-radius: 0.6rem;
  border-style: solid;
  border-width: thin;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.1rem ${Colors.primarySelected};
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
  line-height: 0rem;
  cursor: pointer;
  box-shadow: 0.3rem 0rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
`;

const CheckboxSpan = styled.div`
  margin-left: 0.8rem;
`;

export const SC = {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CheckboxLabel,
  CheckboxSpan,
};
