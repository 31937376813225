// this is the component called by the route "/Login" of the main file "App.js"

import React from "react";
import { Route, Switch } from "react-router-dom";
import RNInfo from "./RNInfo/RNInfo";
import {
  AdminLeftSD,
  AdminViewContentSD,
  AdminViewSD,
} from "./ReleaseNotesStyles";
import { Footer } from "Components";
import RNSidebar from "./RNSidebar/RNSidebar";
import { PageNotFound } from "Components/Common";

/**
 * HOOK
 * Release notes view, the view groups and sorts the visual components
 * Call the sidebar
 * Manage routes to a specific release
 */

function ReleaseNotesView(props) {
  return (
    <AdminViewSD>
      <AdminViewContentSD>
        <RNSidebar menu={props.menu} />
        <AdminLeftSD>
          <Switch>
            <Route
              path={`/ReleaseNotes/1_2_0`}
              component={() => <RNInfo releaseInfo={props.menu[0]} />}
            />
            <Route
              path={`/ReleaseNotes/1_1_0`}
              component={() => <RNInfo releaseInfo={props.menu[1]} />}
            />
            <Route
              path={`/ReleaseNotes/1_0_0`}
              component={() => <RNInfo releaseInfo={props.menu[2]} />}
            />
            <Route component={PageNotFound} />
          </Switch>
        </AdminLeftSD>
      </AdminViewContentSD>
      <Footer />
    </AdminViewSD>
  );
}

export default ReleaseNotesView;
