import React from "react";
import { Select } from "antd";

/**
 * HOOK
 * Tier selector
 */
const { Option } = Select;
export default function TierSelect({ selectedTier, setSelectedTier }) {
  return (
    <div>
      <Select
        placeholder="Tier"
        value={selectedTier}
        onChange={(value) => {
          setSelectedTier(value);
        }}
        width="9rem"
      >
        <Option key="T1">Tier 1</Option>
        <Option key="T2">Tier 2</Option>
        <Option key="T3">Tier 3</Option>
        <Option key="T4">Tier 4</Option>
      </Select>
    </div>
  );
}
