import React from "react";
import { FiltersSD } from "./ReportsFiltersStyles";
import { ReportsColumnSD } from "../AdminReportsViewStyles";
import { Select } from "antd";

const { Option } = Select;

/**
 * HOOK
 * Reports Filters, all the filters in reports view (except search filter)
 */

export default function ReportsFilters(props) {
  const {
    languages,
    selectedLanguage,
    selectLanguage,
    reportItemType,
    setReportItemType,
  } = props;

  return (
    <FiltersSD>
      <ReportsColumnSD margin="0rem">
        <Select
          value={reportItemType}
          onChange={(value) => {
            setReportItemType(value);
          }}
        >
          <Option value="services">Services</Option>
          <Option value="packages">Packages</Option>
        </Select>
      </ReportsColumnSD>
      <ReportsColumnSD margin="0rem">
        <Select
          value={selectedLanguage}
          onChange={(value) => selectLanguage(value)}
        >
          {renderLanguageOptions(languages)}
        </Select>
      </ReportsColumnSD>
    </FiltersSD>
  );
}

// Render the options for language selector
function renderLanguageOptions(languages) {
  const renderedOptions = [];
  languages.forEach((language) => {
    renderedOptions.push(
      <Option key={language.id} value={language.id}>
        {language.id}
      </Option>
    );
  });

  return renderedOptions;
}
