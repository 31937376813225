import { ROOT_URL, instance } from "./apiConfig";

export default class ApiServices {
  //    GET CPQ CODE of all services
  static getCpqCodes(action) {
    const url = `${ROOT_URL}/cpqCodes/services`;
    const request = instance.get(url);
    return request;
  }
  //    GET all services request ADMIN
  static getServices(action) {
    const params = new URLSearchParams();
    if (action.searchedText) {
      const searchArray = action.searchedText;
      searchArray.forEach((searchItem) => {
        params.append("searchedText", searchItem);
      });
    }
    if (action.page) {
      params.append("page", action.page);
    }
    if (action.limit) {
      params.append("limit", action.limit);
    }
    if (action.sortBy) {
      params.append("sortBy", action.sortBy);
      params.append("sortOrder", action.sortOrder);
    }
    if (action.selectedLanguage) {
      params.append("searchedLanguage", action.selectedLanguage);
    }
    params.append("onlySellable", false);

    const url = `${ROOT_URL}/services`;
    const request = instance.get(url, {
      params: params,
    });
    return request;
  }

  //    GET all services request ADMIN
  static getAllServiceList(action) {
    const params = new URLSearchParams();
    const url = `${ROOT_URL}/services`;
    const request = instance.get(url, {
      params: params,
    });
    return request;
  }

  //    GET one service request
  static getService(action) {
    const url = `${ROOT_URL}/services/${action.id}`;
    const request = instance.get(url);

    return request;
  }

  //    GET service List based on search input, tags or language
  static getSalesServices(action) {
    const params = new URLSearchParams();
    if (action.filters.selectedTags) {
      action.filters.selectedTags.forEach((tag) => {
        params.append("searchedTags", tag);
      });
    }
    if (action.filters.searchedText) {
      action.filters.searchedText.forEach((text) => {
        params.append("searchedText", text);
      });
    }
    if (action.filters.tier) {
      params.append("tierForFiltering", action.filters.tier);
    }
    if (action.filters.seats) {
      params.append("seatsForFiltering", 1);
    }
    if (action.filters.userBand) {
      params.append("userBandsForFiltering", action.filters.userBand);
    }
    if (action.filters.selectedMax) {
      params.append("minForFiltering", action.filters.selectedMin);
      params.append("maxForFiltering", action.filters.selectedMax);
    }
    if (action.filters.selectedLanguage) {
      params.append("searchedLanguage", action.filters.selectedLanguage);
    }
    params.append("onlySellable", true);

    let url = `${ROOT_URL}/services`;
    const request = instance.get(url, {
      params: params,
    });

    return request;
  }

  //    POST one service request (creating a new service request)
  static createService(action) {
    const url = `${ROOT_URL}/services/precreate`;
    const request = instance.post(url, action.newServiceInfo);
    return request;
  }

  //    DELETE one service request
  static deleteService(action) {
    const url = `${ROOT_URL}/services/predelete/${action.id}`;
    const request = instance.delete(url);
    return request;
  }

  //  UPDATE one service
  static updateService(action) {
    const url = `${ROOT_URL}/services/preupdate/${action.id}`;
    const request = instance.put(url, action.updatedServiceInfo);
    return request;
  }

  //  GET SERVICES REPORT
  static getServicesReport(action) {
    const url = `${ROOT_URL}/reports/generateReportServices`;
    const params = new URLSearchParams();

    action.itemList.forEach((item) => {
      params.append("idList", item._id);
    });
    Object.keys(action).forEach((field) => {
      if (field !== "type" && field !== "itemList") {
        params.append(field, action[field]);
      }
    });
    const request = instance.get(url, {
      params: params,
      responseType: "blob",
    });
    return request;
  }

  //  GET WBS report
  static generateWBS(action) {
    const url = `${ROOT_URL}/reports/generateWBS`;
    const params = new URLSearchParams();
    params.append("id", action.id);
    params.append("language", action.language);
    params.append("type", "Service");
    const request = instance.get(url, { params: params, responseType: "blob" });
    return request;
  }

  //  GET SOW: deprecated
  static sendPetitionSOW(action) {
    const url = `${ROOT_URL}/reports/generateSOW`;
    const params = new URLSearchParams();
    params.append("cpqCode", action.cpqCode);
    params.append("language", action.language);
    params.append("seats", action.seats);
    params.append("userBand", action.userBand);
    params.append("tier", action.tier);
    const request = instance.get(url, { params: params, responseType: "blob" });
    return request;
  }
}
