import { Select } from "antd";
import Checkbox from "Components/Common/Form/Checkbox";
import React from "react";
import { FormSubtitleSD } from "Theme/Styles";
import { ChecksRowSD, PackageFormSD } from "./StatusTabStyles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";
const { Option } = Select;

/**
 * HOOK
 * Status tab in package form
 */

function StatusTab(props) {
  const {
    visibility,
    setVisibility,
    inSfdc,
    setInSfdc,
    salable,
    setSalable,
    communicable,
    setCommunicable,
  } = props;
  return (
    <PackageFormSD>
      <ExtendedColumnSD>
        <FormSubtitleSD>Visibility</FormSubtitleSD>
        <Select value={visibility} onChange={(value) => setVisibility(value)}>
          <Option value={"Everyone"}>Everyone</Option>
          <Option value={"Service owner"}>Service owner</Option>
          <Option value={"GCX team"}>GCX team</Option>
        </Select>
      </ExtendedColumnSD>
      <ChecksRowSD>
        {/* <Checkbox
          checked={inSfdc}
          label={"inSfdc"}
          onChange={(e) => setInSfdc(e.target.checked)}
        /> */}
        <Checkbox
          checked={salable}
          label={"Sellable"}
          onChange={(e) => setSalable(e.target.checked)}
        />
        <Checkbox
          checked={communicable}
          label={"Communicable"}
          onChange={(e) => setCommunicable(e.target.checked)}
        />
      </ChecksRowSD>
    </PackageFormSD>
  );
}

export default StatusTab;
