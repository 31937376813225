import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  PaginationSD,
  PagIconSD,
  PagNumberSD,
  PagNumberListSD,
  PaginationLeftSD,
  PaginationRightSD,
  PagTextSD,
  PagInputSD,
} from "./UserPaginationStyles";
import { InputNumber } from "antd";

/**
 * HOOK
 * Users pagination
 */

export default function UsersView({ pages, selectPage, selectedPage }) {
  return (
    <PaginationSD>
      <PaginationLeftSD>
        <PagIconSD onClick={() => selectPage(selectedPage - 1)}>
          <FaChevronLeft />
        </PagIconSD>
        <PagNumberListSD>
          {renderNumbers(selectedPage, pages, selectPage)}
        </PagNumberListSD>
        <PagIconSD onClick={() => selectPage(selectedPage + 1)}>
          <FaChevronRight />
        </PagIconSD>
      </PaginationLeftSD>
      <PaginationRightSD>
        <PagTextSD>Go to input</PagTextSD>
        <PagInputSD>
          <InputNumber
            min={1}
            max={pages}
            placeholder="page"
            onChange={(value) => {
              selectPage(parseInt(value));
            }}
          ></InputNumber>
        </PagInputSD>
      </PaginationRightSD>
    </PaginationSD>
  );
}

// renders all the number pages in the pagination,
// ... the selected page number it is painted purple
function renderNumbers(selectedPage, pages, selectPage) {
  const renderedPages = [];
  for (let index = 1; index <= pages; index++) {
    const selected = selectedPage === index;
    renderedPages.push(
      <PagNumberSD
        selected={selected}
        key={index}
        onClick={() => selectPage(index)}
      >
        {index}
      </PagNumberSD>
    );
  }
  return renderedPages;
}
