/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { CustomLoadingOverlay } from "Components";
import FormLocalizationTags from "./FormLocalizationTags/FormLocalizationTags";
import PackageFormTabs from "./PackageFormTabs/PackageFormTabs";
import { deep } from "Helpers/Helpers";
import { validateFields } from "Helpers/FormHelpers.js";
import { ButtonSD, MissingSD, PackageFormSD } from "./PackageFormStyles";
import { FormSubtitleSD, ButtonS, ModalFooterSD } from "Theme/Styles";
import { ExtendedColumnSD } from "Theme/Styles/FormStyles";

/**
 * HOOK
 * Package form, to create a new package or update the information of a package
 */

function PackageForm(props) {
  const {
    tagList,
    ownerList,
    packageFormLoading,
    packageMandatoryFields,
    setVisible,
    serviceList,
  } = props;

  // return an array with the editors ids
  const getSelectedEditors = () => {
    const selectedEditors = [];
    props.packageValues.editors.forEach((editor) => {
      selectedEditors.push(editor._id);
    });
    return selectedEditors;
  };

  // return an array with the services ids
  const getSelectedServices = () => {
    const selectedServices = [];
    props.packageValues.services.forEach((service) => {
      selectedServices.push(service._id);
    });
    return selectedServices;
  };

  // STATES
  // selectedLanguage: the current selected language
  // missingFields: the current fields that have not been filled
  // localization: all fields that may vary of language
  // name to selectedServices: basic tab values, that are not in the localization state
  // tags to communicable: status tab values
  const [selectedLanguage, setSelectedLanguage] = useState("EN-US");
  const [missingFields, setMissingFields] = useState([]);
  const [localization, setLocalization] = useState(
    deep(props.packageValues.localization)
  );
  const [name, setName] = useState(props.packageValues.name);
  const [cpqCode, setCPQCode] = useState(props.packageValues.cpqCode);
  const [owner, setOwner] = useState(props.packageValues.owner);
  const [productManager, setProductManager] = useState(
    props.packageValues.productManager
  );
  const [editors, setEditors] = useState(getSelectedEditors());
  const [selectedServices, setSelectedServices] = useState(
    getSelectedServices()
  );
  const [tags, setTags] = useState(props.packageValues.tags);
  const [visibility, setVisibility] = useState(props.packageValues.visibility);
  const [inSfdc, setInSfdc] = useState(props.packageValues.inSfdc);
  const [salable, setSalable] = useState(props.packageValues.salable);
  const [communicable, setCommunicable] = useState(
    props.packageValues.communicable
  );

  // we obtain all packages loading states
  const ownersListLoading = useSelector(
    (state) => state.system.loadings.ownersListLoading
  );
  const salesMenuLoading = useSelector(
    (state) => state.system.loadings.salesMenuLoading
  );
  const serviceListLoading = useSelector(
    (state) => state.system.loadings.serviceListLoading
  );

  // validPackageForm is a trigger or flag that indicates if the modal form must be closed
  const validPackageForm = useSelector(
    (state) => state.system.validations.validPackageForm
  );

  const verifyOwner = () => {
    let owner = props.packageValues.owner;
    if (
      !ownerList.some((element) => element._id === owner._id) &&
      owner._id !== ""
    ) {
      owner = ownerList.find(
        (element) => element.email === process.env.REACT_APP_API_DEFAULT_ADMIN
      );
    }
    return owner;
  };

  const verifyEditors = () => {
    let NewEditors = editors;
    editors.forEach((editor, index) => {
      if (
        !ownerList.some((element) => element._id === editor) &&
        editor._id !== ""
      ) {
        NewEditors.splice(index, index + 1);
      }
    });
    return NewEditors;
  };

  const verifyProductManager = () => {
    let productManager = props.packageValues.productManager;
    if (
      productManager &&
      !ownerList.some((element) => element._id === productManager._id) &&
      productManager._id !== ""
    ) {
      productManager = "";
    }
    return productManager;
  };

  // it is necessary to verify that the owner, product manager and editors belong to the list of owners
  // ... otherwise they are deleted for the last two, or modified by a default user in the first case.
  useEffect(() => {
    setOwner(verifyOwner());
    setEditors(verifyEditors());
    setProductManager(verifyProductManager());
  }, [ownerList]);

  // if the form is canceled, set the default values
  const cancelForm = () => {
    setSelectedLanguage("EN-US");
    setName(props.packageValues.name);
    setCPQCode(props.packageValues.cpqCode);
    setOwner(props.packageValues.owner);
    setProductManager(props.packageValues.productManager);
    setTags(props.packageValues.tags);
    setLocalization(deep(props.packageValues.localization));
    setMissingFields([]);
    setVisible(false);
    setVisibility(props.packageValues.visibility);
    setInSfdc(props.packageValues.inSfdc);
    setSalable(props.packageValues.salable);
    setCommunicable(props.packageValues.communicable);
    setSelectedServices(getSelectedServices());
    setEditors(getSelectedEditors());
  };

  // used when is changed a localization value
  const onChangeMLField = (field, value) => {
    const newLocalization = deep(localization);
    newLocalization[selectedLanguage][field] = value;
    setLocalization(newLocalization);
  };

  // check if the fields are valid, if are correct, then the new package is created
  const submitPackage = () => {
    const newPackage = {
      localization,
      name: name.trim(),
      cpqCode: cpqCode.trim(),
      owner: owner._id,
      editors: editors,
      tags,
      visibility,
      inSfdc,
      salable,
      communicable,
      services: selectedServices,
    };

    if (productManager) {
      newPackage.productManager = productManager._id;
    }

    const miss = validateFields(
      newPackage,
      packageMandatoryFields,
      setMissingFields
    );
    if (miss.length === 0) {
      props.submitPackage(newPackage);
    }
  };

  useEffect(() => {
    setSelectedLanguage("EN-US");
    setName(props.packageValues.name);
    setCPQCode(props.packageValues.cpqCode);
    setOwner(props.packageValues.owner);
    setProductManager(props.packageValues.productManager);
    setTags(props.packageValues.tags);
    setLocalization(deep(props.packageValues.localization));
    setMissingFields([]);
    setVisibility(props.packageValues.visibility);
    setInSfdc(props.packageValues.inSfdc);
    setSalable(props.packageValues.salable);
    setCommunicable(props.packageValues.communicable);
    setEditors(getSelectedEditors());
    setSelectedServices(getSelectedServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.packageValues]);

  useEffect(() => {
    if (validPackageForm) {
      cancelForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validPackageForm]);

  return (
    <CustomLoadingOverlay
      active={
        packageFormLoading ||
        ownersListLoading ||
        salesMenuLoading ||
        serviceListLoading
      }
    >
      <PackageFormSD>
        <ExtendedColumnSD>
          <FormSubtitleSD>Localization</FormSubtitleSD>
          <FormLocalizationTags
            languages={props.languages}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            localization={localization}
            setLocalization={setLocalization}
          ></FormLocalizationTags>
        </ExtendedColumnSD>
        <ExtendedColumnSD>
          <FormSubtitleSD size="1.7333rem">Package Name</FormSubtitleSD>
          <Input
            name="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </ExtendedColumnSD>
        <ExtendedColumnSD>
          <PackageFormTabs
            cpqCode={cpqCode}
            setCPQCode={setCPQCode}
            owner={owner}
            setOwner={setOwner}
            editors={editors}
            setEditors={setEditors}
            ownerList={ownerList}
            selectedLanguage={selectedLanguage}
            localization={localization}
            onChangeMLField={onChangeMLField}
            tags={tags}
            setTags={setTags}
            tagList={tagList}
            // Status tab
            visibility={visibility}
            setVisibility={setVisibility}
            inSfdc={inSfdc}
            setInSfdc={setInSfdc}
            salable={salable}
            setSalable={setSalable}
            communicable={communicable}
            setCommunicable={setCommunicable}
            serviceList={serviceList}
            //
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
            productManager={productManager}
            setProductManager={setProductManager}
          />
        </ExtendedColumnSD>
        {missingFields.length !== 0 ? (
          <MissingSD>
            <h4>
              <b>Please input the following fields:</b>
            </h4>
            <p>
              {missingFields.map((field) =>
                field !== missingFields[missingFields.length - 1]
                  ? field + ", "
                  : field
              )}
            </p>
          </MissingSD>
        ) : null}
        <ModalFooterSD>
          <ButtonSD>
            <ButtonS
              key="back"
              type="reset"
              buttonType="cancel"
              onClick={() => cancelForm()}
            >
              CANCEL
            </ButtonS>
          </ButtonSD>
          <ButtonSD>
            <ButtonS onClick={() => submitPackage()}>
              {props.type === "create" ? "ADD PACKAGE" : "SAVE CHANGES"}
            </ButtonS>
          </ButtonSD>
        </ModalFooterSD>
      </PackageFormSD>
    </CustomLoadingOverlay>
  );
}

export default React.memo(PackageForm);
