import { ROOT_URL, instance } from "./apiConfig";

export default class ApiPackages {
  //    GET CPQ CODE of all services
  static getCpqCodes(action) {
    const url = `${ROOT_URL}/cpqCodes/packages`;
    const request = instance.get(url);
    return request;
  }
  //    GET all packages request
  static getPackages(action) {
    const params = new URLSearchParams();
    if (action.searchedText) {
      const searchArray = action.searchedText;
      searchArray.forEach((searchItem) => {
        params.append("searchedText", searchItem);
      });
    }
    params.append("page", action.page);
    params.append("limit", action.limit);
    if (action.sortBy) {
      params.append("sortBy", action.sortBy);
      params.append("sortOrder", action.sortOrder);
    }
    if (action.selectedLanguage) {
      params.append("searchedLanguage", action.selectedLanguage);
    }
    params.append("onlySellable", false);

    const url = `${ROOT_URL}/packages`;
    const request = instance.get(url, {
      params: params,
    });
    return request;
  }

  //    GET one packages request
  static getPackage(action) {
    const url = `${ROOT_URL}/packages/${action.id}`;
    const request = instance.get(url);
    return request;
  }

  //    GET one package request, price filters
  static getPackageDetail(action) {
    const params = new URLSearchParams();
    const url = `${ROOT_URL}/packages/${action.id}`;

    if (action.filters.tier) {
      params.append("tier", action.filters.tier);
    }
    if (action.filters.seats) {
      params.append("seats", action.filters.seats);
    }
    if (action.filters.userBand) {
      params.append("userBand", action.filters.userBand);
    }

    const request = instance.get(url, {
      params: params,
    });
    return request;
  }

  //    GET package List based on search input, tags or language
  static getSalesPackages(action) {
    const params = new URLSearchParams();
    if (action.filters.selectedTags) {
      action.filters.selectedTags.forEach((tag) => {
        params.append("searchedTags", tag);
      });
    }
    if (action.filters.searchedText) {
      action.filters.searchedText.forEach((text) => {
        params.append("searchedText", text);
      });
    }
    if (action.filters.tier) {
      params.append("tierForFiltering", action.filters.tier);
    }
    if (action.filters.seats) {
      params.append("seatsForFiltering", 1);
    }
    if (action.filters.userBand) {
      params.append("userBandsForFiltering", action.filters.userBand);
    }
    if (action.filters.selectedMax) {
      params.append("minForFiltering", action.filters.selectedMin);
      params.append("maxForFiltering", action.filters.selectedMax);
    }
    if (action.filters.selectedLanguage) {
      params.append("searchedLanguage", action.filters.selectedLanguage);
    }
    params.append("onlySellable", true);

    let url = `${ROOT_URL}/packages`;
    const request = instance.get(url, {
      params: params,
    });
    return request;
  }
  //  GET SERVICES REPORT
  static getPackagesReport(action) {
    const url = `${ROOT_URL}/reports/generateReportPackages`;
    const params = new URLSearchParams();
    action.itemList.forEach((item) => {
      params.append("idList", item._id);
    });
    Object.keys(action).forEach((field) => {
      if (field !== "type") {
        params.append(field, action[field]);
      }
    });
    const request = instance.get(url, {
      params: params,
      data: {
        idList: action.itemList,
      },
      responseType: "blob",
    });
    return request;
  }

  //    POST one package request
  static createPackage(action) {
    const url = `${ROOT_URL}/packages/precreate`;
    const request = instance.post(url, action.newPackageInfo);
    return request;
  }

  static updatePackage(action) {
    const url = `${ROOT_URL}/packages/preupdate/${action.id}`;
    const request = instance.put(url, action.updatedPackageInfo);
    return request;
  }

  static deletePackage(action) {
    const url = `${ROOT_URL}/packages/predelete/${action.id}`;
    const request = instance.delete(url);
    return request;
  }
}
