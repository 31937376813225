import { CollateralsLabel } from "Components/Common";
import React from "react";
import {
  CollaterallsItemSD,
  CollateralsContentSD,
  DetailPaddingColumnSD,
  CollateralsLineSD,
  DetailCollsSD,
} from "Theme/DetailStyles";
import { ColumnSD, DetailTitleSD } from "Theme/Styles";
import { validURL } from "Helpers/Helpers";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

export default function DetailCollaterals({ packageInfo, selectedLanguage }) {
  const collateralsValues =
    packageInfo.localization[selectedLanguage].collaterals ||
    packageInfo.collaterals ||
    [];
  const renderedCollaterals = renderCollaterals(collateralsValues);
  const showCollateralsBoolean = showCollaterals(collateralsValues);

  return (
    <DetailCollsSD>
      {showCollateralsBoolean ? (
        <DetailPaddingColumnSD>
          <ColumnSD centered>
            <CollateralsLineSD></CollateralsLineSD>
            <DetailTitleSD centered>Collaterals</DetailTitleSD>
            <CollateralsContentSD>{renderedCollaterals}</CollateralsContentSD>
          </ColumnSD>
        </DetailPaddingColumnSD>
      ) : null}
    </DetailCollsSD>
  );
}

function renderCollaterals(collaterals) {
  const renderedCollaterals = [];
  collaterals.forEach((label, index) => {
    if (validURL(label.link) && label.name != "Sales sheet") {
      renderedCollaterals.push(
        <CollaterallsItemSD key={index}>
          <CollateralsLabel
            name={label.name}
            link={label.link}
          ></CollateralsLabel>
        </CollaterallsItemSD>
      );
    }
  });
  return renderedCollaterals;
}

function showCollaterals(collaterals) {
  let show = false;
  collaterals.forEach((collateral) => {
    if (validURL(collateral.link)) {
      show = true;
    }
  });
  return show;
}
