import { Drawer } from "antd";
import exitIcon from "Assets/Icons/SalesIcons/PNG/Icon_sales-close-details.png";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SalesPackageDetailContainer from "../SalesPackages/PackageDetail/SalesPackageDetailContainer";
import SalesServiceDetailContainer from "../SalesServices/ServiceDetail/SalesServiceDetailContainer";
import { SalesDrawerImg } from "./DetailDrawerStyles";

function DetailDrawer(props) {
  const { location } = props;
  const [visible, setVisible] = useState(false);
  const params = new URLSearchParams(location.search);
  let itemId = params.get("id");
  let itemType = "a";
  if (props.match) {
    itemType = props.match.params.type;
  }

  const closeDrawer = () => {
    props.history.push("/");
    setVisible(false);
  };

  useEffect(() => {
    if (props.match) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <Drawer
      width={"90%"}
      onClose={() => {
        closeDrawer();
      }}
      placement="right"
      closable={false}
      visible={visible}
    >
      <SalesDrawerImg>
        <img
          className="xcloseyey"
          onClick={() => {
            closeDrawer();
          }}
          src={exitIcon}
          alt="exit icon"
        />
      </SalesDrawerImg>
      <div>
        {itemType === "service" ? (
          <SalesServiceDetailContainer id={itemId} />
        ) : null}
      </div>
      <div>
        {itemType === "package" ? (
          <SalesPackageDetailContainer id={itemId} />
        ) : null}
      </div>
    </Drawer>
  );
}

export default withRouter(DetailDrawer);
