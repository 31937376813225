import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const DetailTagSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SourceSansPro-Semibold";
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  flex-direction: row;
  border: 0.1rem solid ${Colors.primaryActive};
  width: fit-content;
  padding: 0rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.5rem;
  border-radius: 0.3rem;
  @media ${mediaQueries.mobileL} {
    font-size: 1.2rem;
  }
`;

const CloseIconSD = styled.div`
  font-size: 0.8rem;
  color: ${Colors.primaryActive};
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
  cursor: pointer;
`;

export { DetailTagSD, CloseIconSD };
