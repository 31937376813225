import React, { useState } from "react";
import { CircularButton } from "Components";
import { Select, Input } from "antd";
import Colors from "Theme/Colors";
import { deep } from "Helpers/Helpers";
import ReportsSection from "./ReportsSection";
import {
  ButtonSD,
  CollabSubtitleSD,
  ErrorMsgSD,
  InputContentSD,
  LabelRowSD,
  ServiceFormSD,
  VisibilityContentSD,
} from "./CollateralsTabStyles";
import {
  FormSectionTitle,
  FormDescription,
  ExtendedColumnSD,
} from "Theme/Styles/FormStyles";

export default function CollateralsTab({
  localization,
  onChangeMLField,
  selectedLanguage,
}) {
  // when we edit a collateral, we can edit the name, link or visibility (fieldName)
  const editCollateral = (collateralIndex, fieldName, value) => {
    const newCollaterals = deep(localization[selectedLanguage].collaterals); // Deep copy FIXED
    newCollaterals[collateralIndex][fieldName] = value;
    onChangeMLField("collaterals", newCollaterals);
  };

  // if we try to add a collateral with the same of other collateral, we can't add it,
  const addCollateral = (newCollateral) => {
    const newCollaterals = deep(localization[selectedLanguage].collaterals); // Deep copy FIXED
    let addCollateral = true;
    newCollaterals.forEach((collateral) => {
      if (collateral.name.toLowerCase() === newCollateral.name.toLowerCase()) {
        addCollateral = false;
        return;
      }
    });
    if (addCollateral) {
      newCollaterals.push(newCollateral);
    }
    onChangeMLField("collaterals", newCollaterals);
  };

  const removeCollateral = (collateralIndex) => {
    const newCollaterals = localization[selectedLanguage].collaterals.filter(
      function (task, index) {
        return index !== collateralIndex;
      }
    );
    onChangeMLField("collaterals", newCollaterals);
  };

  const [inputs, setInputs] = useState({ visibility: "Everyone" });
  const [error, setError] = useState(false);
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (Object.entries(inputs).length < 3 || !inputs.name || !inputs.link) {
      setError(true);
    } else {
      addCollateral({
        ...inputs,
      });
      setInputs({ visibility: "Everyone" });
      setError(false);
    }
  };

  const handleChange = (e, type) => {
    if (type === "visibility") {
      setInputs((inputs) => ({
        ...inputs,
        visibility: e,
      }));
    } else {
      e.persist();
      setInputs((inputs) => ({
        ...inputs,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const renderedCollaterals = renderCollaterals(
    localization,
    onChangeMLField,
    selectedLanguage,
    editCollateral,
    addCollateral,
    removeCollateral,
    inputs,
    setInputs,
    handleChange,
    handleSubmit
  );

  return (
    <ServiceFormSD>
      <ExtendedColumnSD>
        <FormSectionTitle bottom={"1rem"}>Links</FormSectionTitle>
        {renderedCollaterals}
        {error ? <ErrorMsgSD>Please fill all the fields</ErrorMsgSD> : null}
      </ExtendedColumnSD>
      {/* <ExtendedColumnSD>
        <FormSectionTitle bottom={"1.5rem"}>Reports</FormSectionTitle>
        <ReportsSection
          localization={localization}
          onChangeMLField={onChangeMLField}
          selectedLanguage={selectedLanguage}
        ></ReportsSection>
      </ExtendedColumnSD> */}
    </ServiceFormSD>
  );
}

function renderCollaterals(
  localization,
  onChangeMLField,
  selectedLanguage,
  editCollateral,
  addCollateral,
  removeCollateral,
  inputs,
  setInputs,
  handleChange,
  handleSubmit
) {
  const renderedCollaterals = [];
  const { Option } = Select;

  // These collaterals are always seen on the form
  const mandatoryIndex = ["SOW", "Datasheet"];

  // for each collateral
  localization[selectedLanguage].collaterals.forEach((collateral, index) => {
    if (
      collateral.name !== "SOW (Autogenerate)" &&
      collateral.name !== "WBS (Autogenerate)" &&
      collateral.name !== "Sales sheet"
    ) {
      // if the current collateral is SOW, Sales sheet or Datasheet, we don't show delete collateral button
      const showDeleteButton = !mandatoryIndex.includes(collateral.name);

      // we render the current collateral
      renderedCollaterals.push(
        <LabelRowSD key={"collateral" + index}>
          <CollabSubtitleSD size={"1.0667rem"}>
            {collateral.name}
          </CollabSubtitleSD>
          <InputContentSD key={"collateral input" + index}>
            <Input
              placeholder={"Link"}
              value={collateral.link}
              onChange={(e) => {
                editCollateral(index, "link", e.target.value);
              }}
            />
          </InputContentSD>
          <VisibilityContentSD key={"collateral select" + index}>
            <Select
              placeholder="Visibility"
              onChange={(value) => {
                editCollateral(index, "visibility", value);
              }}
              value={collateral.visibility ? collateral.visibility : "Everyone"}
              disabled={collateral.link ? false : true}
            >
              <Option value={"Everyone"}>Everyone</Option>
              <Option value={"Service owner"}>Service owner</Option>
              <Option value={"GCX team"}>GCX team</Option>
            </Select>
          </VisibilityContentSD>
          {showDeleteButton ? (
            <ButtonSD>
              <CircularButton
                size={18}
                color={Colors.primarySelected}
                iconName="minus"
                onClick={() => removeCollateral(index)}
              />
            </ButtonSD>
          ) : (
            <ButtonSD></ButtonSD>
          )}
        </LabelRowSD>
      );
    }
  });
  renderedCollaterals.push(
    <FormDescription key={"collateral description"}>
      Add a new collateral link (Press + icon)
    </FormDescription>
  );
  renderedCollaterals.push(
    <LabelRowSD key="colInput">
      <CollabSubtitleSD size={"1.0667rem"} key={"new collateral subtitle"}>
        <Input
          name="name"
          placeholder="Name"
          value={inputs.name || ""}
          onChange={(e) => {
            handleChange(e, "name");
          }}
          key={"new collateral input1"}
        />
      </CollabSubtitleSD>
      <InputContentSD>
        <Input
          name="link"
          placeholder="Link"
          value={inputs.link || ""}
          onChange={(e) => {
            handleChange(e, "link");
          }}
          key={"new collateral input2"}
        />
      </InputContentSD>
      <VisibilityContentSD key={"new collateral select"}>
        <Select
          onChange={(e) => {
            handleChange(e, "visibility");
          }}
          value={inputs.link && inputs.name ? inputs.visibility : "Everyone"}
          disabled={inputs.link && inputs.name ? false : true}
        >
          <Option value={"Everyone"}>Everyone</Option>
          <Option value={"Service owner"}>Service owner</Option>
          <Option value={"GCX team"}>GCX team</Option>
        </Select>
      </VisibilityContentSD>
      <ButtonSD>
        <CircularButton
          size={18}
          color={Colors.primaryActive}
          iconName="plus"
          onClick={(e) => {
            handleSubmit(e);
          }}
        />
      </ButtonSD>
    </LabelRowSD>
  );

  return renderedCollaterals;
}
