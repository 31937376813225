import { initialState } from "Redux/initialState";

// no
const reportsReducer = (state = initialState.reports, action) => {
  switch (action.type) {
    // Modify the value of a fields to define if they are selected or not
    case "SET_REPORT_FIELD":
      let fieldState = { ...state.fields };
      fieldState[action.fieldName].value = action.fieldValue;
      return {
        ...state,
        fields: fieldState,
      };
    // Modify the value of a fields to define if they are selected or not
    case "SET_CHECKED_ITEMS":
      let selectedItems = [...state.selectedItems];
      selectedItems = action.checkedItems;
      return {
        ...state,
        selectedItems: selectedItems,
      };
    // save all cpqCodes of a service
    case "SAVE_SERVICES_CPQ":
      return {
        ...state,
        allServices: action.services,
      };
    // save all cpqCodes of a package
    case "SAVE_PACKAGES_CPQ":
      return {
        ...state,
        allPackages: action.packages,
      };
    default:
      return state;
  }
};

export default reportsReducer;
