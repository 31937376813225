import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

// TODO: COLORS
const PackageFormSD = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${mediaQueries.mobileL} {
  }
`;

const PackageFormTabsSD = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ButtonSD = styled.div`
  display: flex;
`;

export { PackageFormSD, PackageFormTabsSD, ButtonSD };
