import React from "react";
import { SC } from "./CheckboxStyles";

/**
 * HOOK
 * Checkbox component
 */

const Checkbox = ({ className, checked, label, ...props }) => (
  <SC.CheckboxLabel>
    <SC.CheckboxContainer className={className}>
      <SC.HiddenCheckbox checked={checked || false} {...props} />
      <SC.StyledCheckbox checked={checked}>
        <SC.Icon viewBox="0 0 28 28">
          <polyline points="23 9 12 20 7 15" />
        </SC.Icon>
      </SC.StyledCheckbox>
    </SC.CheckboxContainer>
    <SC.CheckboxSpan>{label}</SC.CheckboxSpan>
  </SC.CheckboxLabel>
);
export default Checkbox;
