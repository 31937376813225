import React from "react";
import { withRouter } from "react-router-dom";
import { SC } from "./SalesCollapsibleTableStyles";
import { ButtonS } from "Theme/Styles";
import { history } from "Helpers";

/**
 * HOOK
 * Admin view, the view groups and sorts the visual components
 */

function SalesCollapsibleContent(props) {
  const openDrawer = () => {
    history.push({
      pathname: "/" + props.type,
      search:
        "?cpqCode=" + props.cpq + "&name=" + props.name + "&id=" + props.id,
    });
  };
  return (
    <SC.SalesCollapseContentSD>
      <SC.SalesCollapseColumntSD>
        <SC.DescriptionSD>Summary</SC.DescriptionSD>
        <SC.DescriptionTextSD>{props.summary}</SC.DescriptionTextSD>
      </SC.SalesCollapseColumntSD>
      <SC.SalesCollapseColumntSD>
        <ButtonS
          onClick={() => {
            openDrawer();
          }}
        >
          More details
        </ButtonS>
      </SC.SalesCollapseColumntSD>
    </SC.SalesCollapseContentSD>
  );
}

export default withRouter(SalesCollapsibleContent);
