// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";
import { leftFadeIn } from "Theme/Styles/Animations";

const AdminViewSD = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  @media ${mediaQueries.mobileL} {
    .ant-select {
      font-size: 1.2rem;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2.8rem;
    }

    .ant-input {
      font-size: 1.2rem;
    }
    .ant-input-suffix {
      font-size: 1.2rem;
    }
    .ant-input-affix-wrapper {
      padding: 0.2rem 1.1rem;
    }
    .ant-input-number-input {
      height: 2.8rem;
      font-size: 1.2rem;
    }
  }
`;

const AdminViewContentSD = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const AdminLeftSD = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const AdminSectionSD = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  padding: 2rem 2rem 2rem 8rem;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  animation: ${leftFadeIn} 1s;
  @media ${mediaQueries.tablet} {
    padding: 1rem 1rem 1rem 5rem;
  }
  @media ${mediaQueries.mobileL} {
    padding: 1rem 1rem 1rem 5rem;
  }
`;

const AdminSearchSD = styled.div`
  display: flex;
  width: 100%;
  max-width: 50rem;
  flex: 1;

  @media ${mediaQueries.mobileL} {
  }
`;

export {
  AdminViewSD,
  AdminViewContentSD,
  AdminLeftSD,
  AdminSearchSD,
  AdminSectionSD,
};
