import React from "react";
import Colors from "Theme/Colors";
import DeleteTagModal from "./DeleteTagModal";
import { LocalizationTagSD, LocalizationTextSD } from "./LocalizationTagStyles";

/**
 * HOOK
 * Localization tag, example EN-US, ES...
 *
 * PROPS: text: string, type: string (selected, active, inactive)
 *
 */

export default function LocalizationTag({
  text,
  isActive,
  onClick,
  onRemove,
  languageId,
}) {
  const bg = isActive ? Colors.primaryActive : Colors.white;
  const color = isActive ? Colors.white : Colors.primaryActive;
  return (
    <LocalizationTagSD bg={bg}>
      <LocalizationTextSD color={color} onClick={onClick}>
        {text}
      </LocalizationTextSD>
      {languageId === "EN-US" ? null : (
        <DeleteTagModal
          color={color}
          onRemove={onRemove}
          languageId={languageId}
        />
      )}
    </LocalizationTagSD>
  );
}
