import React from "react";
import BasicDetailSection from "./BasicDetailSection";
import SalesInfoDetailSection from "./SalesInfoDetailSection";
import DeliveryDetailSection from "./DeliveryDetailSection";
import {
  ButtonS,
  RequestButtonsSD,
  ButtonContentSD,
  RequestMessageSD,
} from "Theme/Styles";
import { AdminDetailColumnSD } from "Theme/DetailStyles";
import { CustomLoadingOverlay } from "Components";
import { DetailHeader } from "Components/Common";
import PackagePricingDetail from "Components/Common/PackagePricingDetail/PackagePricingDetail";

/**
 * HOOK
 * AdminDetailView
 */

export default function AdminDetailView({
  detailInfo,
  detailType,
  deleteSaga,
  detailLoading,
  isRequest,
  requestActionSaga,
  status,
  userBands,
  seats,
  filters,
  saveFilters,
  itemIndex,
  changes,
  selectedLanguage,
  setLanguage,
}) {
  const editorsId = [];

  // save in editorsId the ids of the editors
  if (detailInfo) {
    Object.values(detailInfo.editors).forEach((editor) => {
      editorsId.push(editor._id);
    });
  }

  return (
    <CustomLoadingOverlay active={detailLoading}>
      {detailInfo === undefined ? (
        <AdminDetailColumnSD>No detail</AdminDetailColumnSD>
      ) : (
        <AdminDetailColumnSD padding="0rem">
          <DetailHeader
            isRequest={isRequest}
            localization={detailInfo.localization}
            selectedLanguage={selectedLanguage}
            setLanguage={setLanguage}
            id={detailInfo._id}
            type={detailType}
            cpqCode={detailInfo.cpqCode}
            onDelete={deleteSaga}
            creatorId={detailInfo.creator._id}
            productManagerId={
              detailInfo.productManager ? detailInfo.productManager._id : ""
            }
            ownerId={detailInfo.owner._id}
            itemIndex={itemIndex}
            hasRequest={detailInfo.hasRequest}
            editorsId={editorsId}
          />
          <BasicDetailSection
            detailType={detailType}
            isRequest={isRequest}
            detailInfo={detailInfo}
            selectedLanguage={selectedLanguage}
            setLanguage={setLanguage}
            deleteSaga={deleteSaga}
            userBands={userBands}
            seats={seats}
            filters={filters}
            saveFilters={saveFilters}
            itemIndex={itemIndex}
            changes={changes}
          />

          {detailType === "service" ? (
            <SalesInfoDetailSection
              detailInfo={detailInfo}
              selectedLanguage={selectedLanguage}
              changes={changes}
            />
          ) : null}
          {detailType === "service" ? (
            <DeliveryDetailSection
              detailInfo={detailInfo}
              selectedLanguage={selectedLanguage}
              changes={changes}
            />
          ) : (
            <PackagePricingDetail
              packageInfo={detailInfo}
            ></PackagePricingDetail>
          )}

          {isRequest ? renderRequestButtons(status, requestActionSaga) : null}
        </AdminDetailColumnSD>
      )}
    </CustomLoadingOverlay>
  );
}

// only for requests, render ACCEPT or REJECT buttons
function renderRequestButtons(status, requestActionSaga) {
  const renderedOptions = [];
  if (status === "Pending") {
    renderedOptions.push(
      <RequestButtonsSD key="RequestButtons">
        <ButtonContentSD>
          <ButtonS onClick={() => requestActionSaga("Accept")}>ACCEPT</ButtonS>
        </ButtonContentSD>
        <ButtonContentSD>
          <ButtonS
            buttonType="cancel"
            onClick={() => requestActionSaga("Reject")}
          >
            REJECT
          </ButtonS>
        </ButtonContentSD>
      </RequestButtonsSD>
    );
  } else {
    renderedOptions.push(
      <RequestMessageSD key="statuskey">{status}</RequestMessageSD>
    );
  }
  return renderedOptions;
}
