import React from "react";
import Moment from "react-moment";
import {
  DetailSubtitleSD,
  GeneralContentSD,
  SalesDetailColumnSD,
} from "Theme/DetailStyles";
/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */
export default function DetailScope({ serviceInfo, selectedLanguage }) {
  const renderEditors = (serviceInfo) => {
    let renderedEditors = "";
    serviceInfo.editors.forEach((editor, index) => {
      renderedEditors += editor.email;
      if (index !== serviceInfo.editors.length - 1) {
        renderedEditors += ", ";
      }
    });

    return renderedEditors;
  };

  return (
    <SalesDetailColumnSD>
      {/* <GeneralContentSD>
        <DetailSubtitleSD>Scope</DetailSubtitleSD>
        <div>{serviceInfo.localization[selectedLanguage].scope}</div>
      </GeneralContentSD> */}
      <GeneralContentSD>
        <DetailSubtitleSD>Owner</DetailSubtitleSD>
        <div>{serviceInfo.owner.email}</div>
      </GeneralContentSD>
      <GeneralContentSD>
        <DetailSubtitleSD>Product Manager</DetailSubtitleSD>
        <div>
          {serviceInfo.productManager
            ? serviceInfo.productManager.email
            : "None"}
        </div>
      </GeneralContentSD>
      {/* <GeneralContentSD>
        <DetailSubtitleSD>Editors</DetailSubtitleSD>
        <div>
          {serviceInfo.editors.length > 0 ? renderEditors(serviceInfo) : "None"}
        </div>
      </GeneralContentSD> */}
      <GeneralContentSD>
        <DetailSubtitleSD>Last Modification</DetailSubtitleSD>
        <div>
          <Moment>{serviceInfo.lastModification}</Moment>
        </div>
      </GeneralContentSD>
    </SalesDetailColumnSD>
  );
}
