import React, { useState } from "react";
import { CircularButton } from "Components";
import { Modal } from "antd";
import CreateUserContainer from "./CreateUserContainer";

/**
 * HOOK
 * Create user modal, calls the form to create a new user
 */

export default function CreateUserModal({ roles }) {
  // STATES
  // visible: to determinate if modal is shown. the default value is false
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <CircularButton
        iconName="plus"
        size={size()}
        onClick={() => setVisible(true)}
      />
      <Modal
        title="Add User"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <CreateUserContainer setVisible={setVisible} roles={roles} />
      </Modal>
    </div>
  );
}

function size() {
  if (window.screen.width >= 768) {
    return 28;
  } else {
    return 24;
  }
}
