// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const SearchInputSD = styled.div`
  display: flex;
  width: 100%;
  max-width: 50rem;
  flex: 2;
  padding-right: 1rem;
  @media ${mediaQueries.tablet} {
    flex: 6;
  }
  @media ${mediaQueries.mobileL} {
    padding-right: 0rem;
  }
`;

export { SearchInputSD };
