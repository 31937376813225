// Login view styles
import styled from "styled-components/macro";
import { mediaQueries } from "Theme/MediaQueries";

const SalesSearchSD = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 1rem;
  img {
    position: absolute;
    z-index: 1;
    top: 0.8rem;
    left: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
  input {
    padding-left: 3rem;
  }

  @media ${mediaQueries.mobileL} {
    img {
      width: 1.1rem;
      height: 1.1rem;
      top: 0.7rem;
      left: 0.7rem;
    }
  }
`;

export { SalesSearchSD };
