import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const SSOCardSD = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  padding: 2rem 7rem 2rem 7rem;

  @media ${mediaQueries.tablet} {
    min-width: 40rem;
    padding: 6rem;
  }

  @media ${mediaQueries.mobileL} {
    min-width: 30rem;
    padding: 4rem;
  }
`;

const LoginTitleSD = styled.div`
  font-family: "Georgia-Regular";
  font-size: 2.6667rem;
  color: ${Colors.thirdGray};
  padding-top: 2%;
  padding-bottom: 2%;

  @media ${mediaQueries.tablet} {
    font-size: 2rem;
  }
`;

const LoginSSOSA = styled.a`
  color: white;
  width: 100%;
  button {
    width: 100%;
  }
`;

export { SSOCardSD, LoginTitleSD, LoginSSOSA };
