import React from "react";
import { FaBars } from "react-icons/fa";

import circularPlus from "Assets/Icons/AdminIcons/PNG/Icons_admin-add.png";
import circularMinus from "Assets/Icons/AdminIcons/PNG/Icons_admin-delete-add.png";
import circularEdit from "Assets/Icons/AdminIcons/PNG/Icons_admin-user-edit.png";
import circularTrash from "Assets/Icons/AdminIcons/PNG/Icons_admin-user-delete.png";
import { CircularButtonSD } from "./CircularButtonStyles";

/**
 * HOOK
 * Login view, the view groups and sorts the visual components
 */

function renderIcon(iconName, size) {
  switch (iconName) {
    case "plus":
      return (
        <img src={circularPlus} alt="radio button plus" width={size || "20"} />
      );
    case "minus":
      return (
        <img
          src={circularMinus}
          alt="radio button minus"
          width={size || "20"}
        />
      );
    case "edit":
      return (
        <img src={circularEdit} alt="radio button edit" width={size || "20"} />
      );
    case "delete":
      return (
        <img
          src={circularTrash}
          alt="radio button trash"
          width={size || "20"}
        />
      );
    case "bars":
      return <FaBars />;
    default:
      return (
        <img src={circularPlus} alt="radio button plus" width={size || "20"} />
      );
  }
}

export default function CircularButton({ color, size, iconName, onClick }) {
  return (
    <CircularButtonSD size={size} color={color} onClick={onClick}>
      {renderIcon(iconName, size)}
    </CircularButtonSD>
  );
}
