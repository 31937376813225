// Login view styles
import styled from "styled-components/macro";

const RequestDetailSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const NoDetailSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 20rem;
`;

export { RequestDetailSD, NoDetailSD };
