import React, { useState, useRef, useEffect } from "react";
import { Affix } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { FaChevronDown } from "react-icons/fa";
import { SC } from "./CollapsibleTableStyles";
import Colors from "Theme/Colors";
import Checkbox from "Components/Common/Form/Checkbox";
import RequestIcon from "./RequestIcon";
import DeleteUserModal from "Components/Admin/Users/DeleteUser/DeleteUserContainer";
import UpdateUserModal from "Components/Admin/Users/UpdateUser/UpdateUserModal";
import {
  CheckError,
  PackageDetailContainer,
  RequestDetailContainer,
  ServiceDetailContainer,
} from "Components";

/**
 * HOOK
 * Collapsible item of the Collapsible table
 */

// to scroll to the current panel
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function CollapsibleItem(props) {
  const {
    itemInfo,
    headers,
    setSearchText,
    type,
    isEven,
    onClickItem,
    selectedItemId,
    setSelectedItemId,
    isCollapsible,
    roles,
    forReports,
  } = props;

  // STATES
  // height: height of the collapsible detail
  // isActive: to know if the collpasible item is selected
  // ... and also show it detail
  const [height, setHeight] = useState("0rem");
  const [isActive, setActive] = useState(false);
  const myRef = useRef();
  const executeScroll = () => scrollToRef(myRef);

  const dispatch = useDispatch();

  // REDUCER
  // Modify the selected items, it is an arrangement of cpq Codes
  const setCheckedItems = (checkedItems) => {
    dispatch({
      type: "SET_CHECKED_ITEMS",
      checkedItems,
    });
  };

  // get selected items in collapse table (only for reports view)
  const checkedItems = useSelector((state) => state.reports.selectedItems);

  // When we check an item from table
  const checkItem = (_id, cpqCode) => {
    let newCheckedItems = [...checkedItems];
    let checked = newCheckedItems.find((o) => o._id === _id);
    if (checked) {
      newCheckedItems = newCheckedItems.filter(function (el) {
        return el._id !== _id;
      });
    } else {
      newCheckedItems.push({
        cpqCode,
        _id,
      });
    }
    setCheckedItems(newCheckedItems);
  };

  // on click a panel
  const toggleAccordion = () => {
    if (isCollapsible) {
      if (!isActive) {
        onClickItem(itemInfo);
        setSelectedItemId(itemInfo._id);
        executeScroll();
      } else {
        setSelectedItemId("NOID");
      }
    }
  };

  useEffect(() => {
    setActive(selectedItemId === itemInfo._id);
    setHeight(selectedItemId === itemInfo._id ? `auto` : `0rem`);
  }, [itemInfo._id, selectedItemId]);

  // render every panel
  const renderCells = (itemInfo) => {
    const renderedCells = [];
    // if the collapsible table is the one of the reports view, print the checkboxes
    if (forReports) {
      let checked = checkedItems.find((o) => o._id === itemInfo._id);

      renderedCells.push(
        <SC.Cell centered key={itemInfo._id + "check"} flex={"0.5 0"}>
          <Checkbox
            checked={checked}
            onChange={(e) =>
              checkItem(itemInfo._id, itemInfo.cpqCode, e.target.checked)
            }
          />
        </SC.Cell>
      );
    }
    headers.forEach((header, index) => {
      // extract content after search a nested key
      const content = header.key.split(".").reduce(function (p, prop) {
        return p[prop];
      }, itemInfo);
      const renderedCellContent = renderCellContent(content, header);
      renderedCells.push(
        <SC.Cell key={header.key} flex={header.flex} accent={header.accent}>
          {renderedCellContent}
        </SC.Cell>
      );
    });

    // if the collapsible table is the one of the users view, print edit and delete buttons
    if (roles) {
      renderedCells.push(
        <SC.Cell key={"extracell"} flex={"0.5 0"}>
          <SC.TableButton>
            <UpdateUserModal
              userInfo={itemInfo}
              roles={roles}
              setSearchText={setSearchText}
            />
          </SC.TableButton>
          <SC.TableButton>
            <DeleteUserModal
              id={itemInfo._id}
              email={itemInfo.email}
              setSearchText={setSearchText}
            />
          </SC.TableButton>
        </SC.Cell>
      );
    } else {
      // render extra space, it's just aesthetic
      if (isCollapsible) {
        renderedCells.push(
          <SC.Cell key={"extracell"} flex={"0.5 0"}>
            {" "}
          </SC.Cell>
        );
      }
    }

    return renderedCells;
  };

  // render the panel detail, if clicked
  const renderCellContent = (content, header) => {
    switch (typeof content) {
      case "string":
        if (header.key === "status") {
          return (
            <SC.CellContent>
              <RequestIcon iconName={content} />
              {content}
            </SC.CellContent>
          );
        } else if (
          header.key === "lastModification" ||
          header.key === "dateCreated"
        ) {
          return <Moment>{content}</Moment>;
        } else {
          return content;
        }
      case "boolean":
        return <CheckError checked={content} size={15} />;
      case "number":
        return roles[content];
      default:
        return "";
    }
  };

  // to get the color of the left line in the panel (red, blue or gray)
  function getRowColor(type) {
    switch (type) {
      case "service":
        return Colors.service;
      case "package":
        return Colors.package;
      case "request":
        return Colors.disabledGray;
      default:
        return Colors.selectedItem;
    }
  }

  // call the component to show the detail of the open panel
  function renderDetail(type, id, setSearchText) {
    switch (type) {
      case "service":
        return <ServiceDetailContainer id={id} setSearchText={setSearchText} />;
      case "package":
        return <PackageDetailContainer id={id} setSearchText={setSearchText} />;
      case "request":
        return <RequestDetailContainer id={id} setSearchText={setSearchText} />;
      default:
        return "Detail error";
    }
  }

  const renderedCells = renderCells(itemInfo);
  const rowBorderColor = getRowColor(type);
  const panel = (
    <SC.CollapsibleTableItemPanel
      isActive={isActive}
      onClick={toggleAccordion}
      rowBorderColor={rowBorderColor}
      isEven={isEven}
      isCollapsible={isCollapsible}
    >
      {renderedCells}
      {isCollapsible ? (
        <SC.CollapsiblItemIcon isActive={isActive}>
          <FaChevronDown size={10} fill={Colors.primaryActive} />
        </SC.CollapsiblItemIcon>
      ) : null}
    </SC.CollapsibleTableItemPanel>
  );

  return (
    <SC.CollapsibleTableItem className={"Item" + itemInfo._id}>
      {isActive && isCollapsible ? (
        <Affix offsetTop={37}>{panel}</Affix>
      ) : (
        panel
      )}
      {isCollapsible ? (
        <SC.CollapsibleTableItemContent height={height} ref={myRef}>
          {renderDetail(type, itemInfo._id, setSearchText)}
        </SC.CollapsibleTableItemContent>
      ) : null}
    </SC.CollapsibleTableItem>
  );
}

export default CollapsibleItem;
