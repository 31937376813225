import React from "react";
import { PricingDetail } from "Components/Common";
import { Styles } from "./PackagePricingDetailStyles";
/**
 * HOOK
 * Package detail information
 */

export default function ServicePricing({ service }) {
  return (
    <Styles.ServicePricing>
      <div key={"name" + service._id}>
        <strong>Name:&nbsp;</strong>
        {service.name}
      </div>
      <div key={"cpq" + service._id}>
        <strong>CPQ:&nbsp;</strong>
        {service.cpqCode}
      </div>
      {service.estimationRequired === false ? (
        <div key={"pricing" + service._id}>
          <strong>Pricing:&nbsp;</strong>
          <PricingDetail
            service={service}
            chargingMode={service.chargingMode}
            pricing={service.pricing}
            pricingOption={service.pricingOption}
            minimumSeats={service.minimunSeats}
          ></PricingDetail>
        </div>
      ) : (
        <div key={"pricing" + service._id}>
          <strong>Pricing:&nbsp;</strong>Estimation required
        </div>
      )}
    </Styles.ServicePricing>
  );
}
