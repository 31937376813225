// Login view styles
import styled from "styled-components/macro";
import Colors from "Theme/Colors";
import { mediaQueries } from "Theme/MediaQueries";

const RNRoleBoxSD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
  margin: 1rem;
  height: fit-content;
  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
  div {
    border-radius: 0.3rem;
  }
  @media ${mediaQueries.tablet} {
    width: 100%;
  }
`;

const RNRoleBoxTitleSD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.service};
  padding: 1.5rem;
  color: white;
  width: 100%;
  height: 6rem;
  font-size: 2.5rem;
  font-family: "SourceSansPro-Semibold";

  @media ${mediaQueries.tablet} {
    font-size: 2.5rem;
    height: 5rem;
  }
  @media ${mediaQueries.mobileL} {
    font-size: 2rem;
    height: 3rem;
  }
`;

const RNRoleContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media ${mediaQueries.tablet} {
  }
  @media ${mediaQueries.mobileL} {
  }
  div {
    width: 100%;
  }
`;

const RNRoleSubtitleSD = styled.div`
  font-family: "SourceSansPro-Semibold";
  text-align: left;
  font-size: 2rem;
`;

const RNItemSD = styled.div`
  text-align: left;
  font-size: 1.5rem;
`;

const RNRoleRowSD = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid ${Colors.disabledGray};
  padding: 1rem;
`;

export {
  RNRoleBoxSD,
  RNRoleBoxTitleSD,
  RNRoleContentSD,
  RNItemSD,
  RNRoleSubtitleSD,
  RNRoleRowSD,
};
