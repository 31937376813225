import React, { useState } from "react";
import { Modal } from "antd";
import EditPackageContainer from "./EditPackageContainer";
import { ButtonS } from "Theme/Styles";

/**
 * HOOK
 * Edit package modal, calls the edit package container
 */

export default function EditPackageModal({ id }) {
  // STATES
  // visible: to determinate if modal is shown. The default value is false
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <ButtonS
        onClick={() => {
          setVisible(true);
        }}
      >
        EDIT
      </ButtonS>

      <Modal
        title="Edit package"
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <EditPackageContainer setVisible={setVisible} id={id} />
      </Modal>
    </div>
  );
}
