import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import RNSidebarButton from "./RNSidebarButton";
import { RNSidebarSD } from "./RNSidebarStyles";
import icons_admin_menu from "Assets/Icons/AdminIcons/PNG/Icons_admin-menu.png";

/**
 * HOOK
 * Display a menu with one button for each release
 */

function RNSidebar(props) {
  const { history } = props;
  const [opened, setOpened] = useState(false);

  // Go to release route
  const goToRoute = (routeName) => {
    history.push(routeName);
    setOpened(false);
  };

  // For each release of the fix, create a button that directs the respective release
  const buttons = props.menu.map((item, i) => (
    <RNSidebarButton
      key={item.routeName}
      handlerClick={() => goToRoute(item.routeName)}
      selected={props.location.pathname === item.routeName}
      name={item.version}
      label={item.date}
    ></RNSidebarButton>
  ));
  return (
    <RNSidebarSD opened={opened} className="RNSidebarSD">
      <RNSidebarButton
        icon={icons_admin_menu}
        handlerClick={() => setOpened(!opened)}
        name="Toggle open"
        width="2rem"
      ></RNSidebarButton>
      {buttons}
    </RNSidebarSD>
  );
}

export default withRouter(RNSidebar);
